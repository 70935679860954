/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from "moment";
import {
  Box,
  Select,
  Tooltip,
  MenuItem,
  IconButton,
  SelectChangeEvent,
  Typography,
  useTheme,
} from "@mui/material";
import {
  LastPage,
  Edit,
  FirstPage,
  ChevronLeft,
  ChevronRight,
} from "@mui/icons-material";
import "./ContentLibrary.scss";
import { Time } from "../../../types/Common";
import { CustomerList } from "../../../types/Customer";
import { useLocation, useNavigate } from "react-router-dom";
import { useChannelIdContext } from "../../../context/ChannelIdContext";
import MaterialReactTable, {
  MRT_ColumnDef,
  MRT_SortingState,
} from "material-react-table";
import React, { FC, useMemo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
const API_URL = process.env.REACT_APP_BACKEND_URL;

const ContentLibraryTable: FC = () => {
  interface DropIn {
    id?: number;
    title: string;
    name: string;
    fileName: string;
    timeUntil: Time;
    timeFrom: Time;
    season: number;
    playMonday: boolean;
    playTuesday: boolean;
    playWednesday: boolean;
    playThursday: boolean;
    playFriday: boolean;
    playSaturday: boolean;
    playSunday: boolean;
    seasonalStart: moment.Moment | null | any;
    seasonalEnd: moment.Moment | null | any;
  }
  const location = useLocation();
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const {
    isUploadAudio,
    searchContent,
    setIsUploadAudio,
    setSelectedAudioEditor,
    setIsTokenExpired,
    setIsTokenUpdated,
    IsCategoryAdded,
    setIsCategoryAdded,
    IsTokenUpdated,
  } = useChannelIdContext();
  let token = JSON.parse(localStorage?.getItem("token") ?? "{}");
  const [totalCount, setTotalCount] = useState<number>(1);
  const [voiceTrackData, setVoiceTrackData] = useState([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number | any>(25);
  const [categoryData, setCategoryData] = useState<any[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [customerData, setCustomerData] = useState<CustomerList[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<string>("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const open = Boolean(anchorEl);

  const formatDate = (value: Date) => {
    const date = moment(value).format("DD.MM.YYYY");
    const time = moment(value).format("HH.mm") + "Uhr";
    return `${date} / ${time}`;
  };

  const columns = useMemo<MRT_ColumnDef<DropIn>[]>(
    () => [
      {
        accessorKey: "title",
        header: t("main.title"),
      },
      {
        accessorKey: "fileName",
        header: t("main.audio_filename"),
      },
      {
        accessorKey: "playMonday",
        header: t("main.monday"),
      },
      {
        accessorKey: "playTuesday",
        header: t("main.tuesday"),
      },
      {
        accessorKey: "playWednesday",
        header: t("main.wednesday"),
      },
      {
        accessorKey: "playThursday",
        header: t("main.thursday"),
      },
      {
        accessorKey: "playFriday",
        header: t("main.friday"),
      },
      {
        accessorKey: "playSaturday",
        header: t("main.saturday"),
      },
      {
        accessorKey: "playSunday",
        header: t("main.sunday"),
      },
      {
        accessorKey: "seasonalStart",
        header: t("main.commence_date"),
        Cell: (renderedCellValue: any) => (
          <span>{formatDate(renderedCellValue.renderedCellValue)}</span>
        ),
      },
      {
        accessorKey: "seasonalEnd",
        header: t("main.expiry_date"),
        Cell: (renderedCellValue: any) => (
          <span>{formatDate(renderedCellValue.renderedCellValue)}</span>
        ),
      },
    ],
    [t]
  );

  const fetchData = useCallback(async () => {
    fetch(
      `${API_URL}/VoiceTrack/GetPaginatedData?pageNumber=${currentPage}&pageSize=${pageSize}&voiceTrackCategoryId=${selectedCategory ? parseInt(selectedCategory) : 0
      }&customerId=${selectedCustomer ? parseInt(selectedCustomer) : 0
      }&searchText=${searchContent}&sortby=${sorting[0] ? sorting[0]?.id : ""
      }&orderBy=${sorting[0] ? sorting[0].desc : true}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token?.accessToken}`,
        },
      }
    )
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data.success === true) {
          setIsUploadAudio(false);
          setVoiceTrackData(data?.data);
          setPageSize(data?.pagination?.pageSize);
          setTotalCount(data?.pagination?.totalCount);
          setCurrentPage(data?.pagination?.currentPage);
        } else {
          setVoiceTrackData([]);
          setPageSize(data?.pagination?.pageSize);
          setTotalCount(data?.pagination?.totalCount);
          setCurrentPage(data?.pagination?.currentPage);
        }
      });
  }, [
    sorting,
    pageSize,
    currentPage,
    searchContent,
    selectedCustomer,
    setIsUploadAudio,
    selectedCategory,
    IsTokenUpdated,
  ]);

  const fetchVoiceTrackCategory = useCallback(async () => {
    if (location?.pathname?.includes("content-library")) {
      fetch(`${API_URL}/VoiceTrackCategory/GetAll`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token?.accessToken}`,
        },
      })
        .then((response) => {
          if (
            response.ok === false &&
            response.status === 401 &&
            token?.refreshToken !== ""
          ) {
            setIsTokenExpired(true);
          } else if (response.status === 401 && token?.refreshToken === "") {
            navigate("/");
            localStorage.removeItem("token");
            localStorage.clear();
          } else {
            setIsTokenUpdated(false);
            return response.json();
          }
        })
        .then((data) => {
          if (data.success === true) {
            setCategoryData(data?.data);
            setIsCategoryAdded(false);
          } else {
            setCategoryData([]);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.pathname, IsTokenUpdated, IsCategoryAdded]);

  const fetchAllCustomer = useCallback(async () => {
    fetch(`${API_URL}/Customer/GetAll`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    })
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data.success === true) {
          setCustomerData(data?.data);
        } else {
          setCustomerData([]);
        }
      });
  }, [IsTokenUpdated]);

  const handleClick = (event: React.MouseEvent<HTMLElement>, row: any) => {
    setSelectedAudioEditor("voiceTrack");
    setAnchorEl(event?.currentTarget);
    navigate(`voice-track-editor/${row?.original?.id}`);
  };

  const handleFirstPage = () => {
    setCurrentPage(1);
  };

  const handleForwardPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlebackwordPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleLastPage = () => {
    if (totalCount / pageSize > parseInt((totalCount / pageSize).toString())) {
      setCurrentPage(parseInt((totalCount / pageSize + 1).toString()));
    } else {
      setCurrentPage(parseInt((totalCount / pageSize).toString()));
    }
  };
  useEffect(() => {
    if (isUploadAudio) {
      setSelectedCategory("");
      fetchData();
    }
  }, [fetchData, isUploadAudio]);

  useEffect(() => {
    fetchData();
    fetchVoiceTrackCategory();
    fetchAllCustomer();
  }, [fetchAllCustomer, fetchData, fetchVoiceTrackCategory]);

  return (
    <>
      <div className="content-filter">
        <Select
          value={
            customerData?.some((item) => item.id === parseInt(selectedCustomer))
              ? selectedCustomer
              : ""
          }
          displayEmpty
          renderValue={(value) => {
            if (value) {
              return customerData?.find(
                (item) => item?.id === parseInt(selectedCustomer)
              )?.name;
            } else {
              return <Typography>{t("main.select_customer")}</Typography>;
            }
          }}
          onChange={(event: SelectChangeEvent<typeof selectedCustomer>) => {
            setSelectedCustomer(event.target.value);
          }}
        >
          {customerData &&
            customerData?.map((item: any) => (
              <MenuItem key={item?.id} value={item?.id}>
                {categoryData?.length < 0
                  ? `${t("main.No_Customer_Found")}`
                  : item?.name}
              </MenuItem>
            ))}
        </Select>
        <Select
          value={
            categoryData?.some((item) => item?.id === selectedCategory)
              ? selectedCategory
              : ""
          }
          displayEmpty
          renderValue={(value) => {
            if (value) {
              return categoryData?.find((item) => item?.id === parseInt(value))
                ?.name;
            } else {
              return <Typography>{t("main.select_category")}</Typography>;
            }
          }}
          onChange={(event: SelectChangeEvent<typeof selectedCategory>) =>
            setSelectedCategory(event.target.value)
          }
        >
          {categoryData &&
            categoryData?.map((item: any) => (
              <MenuItem key={item?.id} value={item?.id}>
                {categoryData?.length < 0
                  ? `${t("main.No_Category_Found")}`
                  : item?.name}
              </MenuItem>
            ))}
        </Select>
      </div>
      <div
        className={
          theme.palette.mode === "dark"
            ? "tableResponsive listTable3 list_table"
            : "tableResponsive listTable list_table"
        }
      >
        <MaterialReactTable
          columns={columns}
          data={selectedCategory && selectedCustomer ? voiceTrackData : []}
          enableRowActions={true}
          enableColumnActions={true}
          enablePagination={false}
          state={{
            sorting,
          }}
          enableBottomToolbar={false}
          manualSorting={true}
          manualFiltering={true}
          onSortingChange={(col: MRT_SortingState | any) => {
            setSorting(col);
          }}
          enableGlobalFilter={true}
          enableGlobalFilterModes
          initialState={{
            showGlobalFilter: true,
          }}
          muiTableBodyCellProps={{
            sx: {
              paddingTop: 0,
              paddingBottom: 0,
            },
          }}
          positionGlobalFilter="left"
          muiSearchTextFieldProps={{
            placeholder: `Search ${voiceTrackData?.length} rows`,
            sx: {
              minWidth: "300px",
              border: "1.5px solid #E8E8E8",
              borderRadius: "9px",
              color: "#E8E8E8",
              "& svg": {
                color: "#E8E8E8",
              },
              "& input": {
                color: "#E8E8E8",
              },
            },
            variant: "outlined",
          }}
          enablePinning
          defaultColumn={{
            maxSize: 400,
            minSize: 80,
            size: 150, //default size is usually 180
          }}
          enableColumnResizing
          columnResizeMode="onChange"
          enableColumnFilters={true}
          enableSorting={true}
          enableTopToolbar={false}
          renderRowActions={({ row }) => (
            <Box className="music-library-table-box">
              <Tooltip title="Audio editor">
                <IconButton
                  onClick={(event) => {
                    handleClick(event, row);
                  }}
                  aria-label="more"
                  id="long-button"
                  aria-controls={open ? "long-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                >
                  <Edit />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        />
      </div>
      <div className="customPagination">
        <span
          style={{ color: theme.palette.mode === "dark" ? "#fff" : "#000" }}
        >
          {t("main.rows_per_page")}
        </span>
        <Select
          sx={{ height: "30px" }}
          value={pageSize}
          onChange={(e) => {
            setPageSize(e.target.value);
          }}
        >
          <MenuItem value="25">25</MenuItem>
          <MenuItem value="50">50</MenuItem>
          <MenuItem value="100">100</MenuItem>
        </Select>
        <span
          style={{ color: theme.palette.mode === "dark" ? "#fff" : "#000" }}
        >
          {(currentPage - 1) * pageSize + 1} -{" "}
          {currentPage * pageSize < totalCount
            ? currentPage * pageSize
            : currentPage * pageSize -
            (currentPage * pageSize - totalCount)}{" "}
          of {totalCount}
        </span>
        <Box className="customPagination__buttons">
          <IconButton
            onClick={handleFirstPage}
            disabled={(currentPage - 1) * pageSize + 1 <= 1}
          >
            <FirstPage />
          </IconButton>
          <IconButton
            onClick={handlebackwordPage}
            disabled={(currentPage - 1) * pageSize + 1 <= 1}
          >
            <ChevronLeft />
          </IconButton>
          <IconButton
            onClick={handleForwardPage}
            disabled={currentPage * pageSize >= totalCount}
          >
            <ChevronRight />
          </IconButton>
          <IconButton
            onClick={handleLastPage}
            disabled={currentPage * pageSize >= totalCount}
          >
            <LastPage />
          </IconButton>
        </Box>
      </div>
    </>
  );
};

export default ContentLibraryTable;

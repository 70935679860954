import {
  TextField,
  InputAdornment,
  useTheme,
} from "@mui/material";
import React from 'react'
import "../../layouts/SearchBar.scss";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useChannelIdContext } from '../../context/ChannelIdContext';
import { useTranslation } from "react-i18next";


function ChanneleditorSearchBar() {
  const theme = useTheme()
  const { t } = useTranslation();
  const { searchInput, setSearchInput } = useChannelIdContext();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchInput(event.target.value);
  };
  return (
    <TextField
    className='customSearch'
    id="search-all"
    value={searchInput}
    placeholder={t("main.search_library")}
    type="text"
    variant="outlined"
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
            <SearchOutlinedIcon style={{color: theme.palette.mode === "dark" ? "#3c434b" : "#3c434b"}} />
          </InputAdornment>
        ),
      }}
      autoComplete='off'
      onChange={handleChange}
    />
  )
}

export default ChanneleditorSearchBar
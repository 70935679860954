/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Add, Close, Edit, Remove } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Paper,
  PaperProps,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useChannelIdContext } from "../../context/ChannelIdContext";
import "./PermissionTable.scss";
const API_URL = process.env.REACT_APP_BACKEND_URL;
function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper
        {...props}
        style={{
          backgroundColor: "white",
          color: "#D9D9D9",
          padding: "1rem",
        }}
      />
    </Draggable>
  );
}

const GroupTable = ({ setIsAdded }: { setIsAdded: any }) => {
  interface formData {
    admin: boolean;
    editSongs: boolean;
    name: string | null;
    editLicense: boolean;
    editDropIns: boolean;
    editSchedules: boolean;
    editVoiceWheels: boolean;
    editVoiceTracks: boolean;
    editSongCategory: boolean;
    overrideFunction: boolean;
    editChannelPlaylist: boolean;
  }

  const { t } = useTranslation();
  const columns = useMemo<MRT_ColumnDef<formData>[]>(
    () => [
      {
        accessorKey: "name",
        header: t("main.group"),
      },
      {
        accessorKey: "admin",
        header: t("main.admin"),
        type: "boolean",
        accessorFn: (rw: formData) =>
          rw.admin != null ? <Checkbox checked={rw.admin} /> : "",
      },
      {
        accessorKey: "editSongs",
        header: t("main.songs"),
        type: "boolean",
        accessorFn: (rw: formData) =>
          rw.editSongs != null ? <Checkbox checked={rw.editSongs} /> : "",
      },
      {
        accessorKey: "editSongCategory",
        header: t("main.song_category"),
        type: "boolean",
        accessorFn: (rw: formData) =>
          rw.editSongCategory != null ? (
            <Checkbox checked={rw.editSongCategory} />
          ) : (
            ""
          ),
      },
      {
        accessorKey: "editChannelPlaylist",
        header: t("main.playlist"),
        type: "boolean",
        accessorFn: (rw: formData) =>
          rw.editSongCategory != null ? (
            <Checkbox checked={rw.editChannelPlaylist} />
          ) : (
            ""
          ),
      },
      {
        accessorKey: "editSchedules",
        header: "Schedules",
        type: "boolean",
        accessorFn: (rw: formData) =>
          rw.editSongCategory != null ? (
            <Checkbox checked={rw.editSchedules} />
          ) : (
            ""
          ),
      },
      {
        accessorKey: "editDropIns",
        header: t("main.Dropins"),
        type: "boolean",
        accessorFn: (rw: formData) =>
          rw.editSongCategory != null ? (
            <Checkbox checked={rw.editDropIns} />
          ) : (
            ""
          ),
      },
      {
        accessorKey: "editVoiceWheels",
        header: t("main.wheel"),
        type: "boolean",
        accessorFn: (rw: formData) =>
          rw.editSongCategory != null ? (
            <Checkbox checked={rw.editVoiceWheels} />
          ) : (
            ""
          ),
      },
      {
        accessorKey: "editVoiceTracks",
        header: t("main.voice_tracks"),
        type: "boolean",
        accessorFn: (rw: formData) =>
          rw.editSongCategory != null ? (
            <Checkbox checked={rw.editVoiceTracks} />
          ) : (
            ""
          ),
      },
      {
        accessorKey: "overrideFunction",
        header: t("main.override_player"),
        type: "boolean",
        accessorFn: (rw: formData) =>
          rw.editSongCategory != null ? (
            <Checkbox checked={rw.overrideFunction} />
          ) : (
            ""
          ),
      },
      {
        accessorKey: "editLicense",
        header: "Lizenz",
        type: "boolean",
        accessorFn: (rw: formData) =>
          rw.editSongCategory != null ? (
            <Checkbox checked={rw.editLicense} />
          ) : (
            ""
          ),
      }
    ],
    [t]
  );

  const theme = useTheme();
  const [isAdmin] = useState<boolean>(false);
  const { currentUser, setIsTokenExpired, setIsTokenUpdated, IsTokenUpdated } =
    useChannelIdContext();
  const [groupData, setGroupData] = useState([]);
  const [editLicense] = useState<boolean>(false);
  const [canEditSongs] = useState<boolean>(false);
  const [canEditDropIns] = useState<boolean>(false);
  const [groupId, setGroupId] = useState<any[]>([]);
  const [canEditSchedules] = useState<boolean>(false);
  const [isOpen, setisOpen] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [canEditVoiceWheels] = useState<boolean>(false);
  const [canEditVoiceTracks] = useState<boolean>(false);
  const [canOverrideFunction] = useState<boolean>(false);
  const [canEditSongCategory] = useState<boolean>(false);
  const [rowSelection, setRowSelection] = useState<any>({});
  const [canEditChannelPlaylist] = useState<boolean>(false);
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  const navigate = useNavigate();

  const [group, setGroup] = useState<formData>({
    name: null,
    admin: isAdmin,
    editSongs: canEditSongs,
    editLicense: editLicense,
    editDropIns: canEditDropIns,
    editSchedules: canEditSchedules,
    editVoiceWheels: canEditVoiceWheels,
    editVoiceTracks: canEditVoiceTracks,
    overrideFunction: canOverrideFunction,
    editSongCategory: canEditSongCategory,
    editChannelPlaylist: canEditChannelPlaylist,
  });

  const fetchData = useCallback(async () => {
    fetch(`${API_URL}/Group/GetAll`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    })
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data.success === true) {
          setGroupData(data?.data);
        } else {
          setGroupData([]);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [IsTokenUpdated]);

  const postNewGroupData = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (Object.values(group)?.includes(null)) {
      toast.error(t("main.Please_fill_the_form"));
      return;
    }
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
      body: JSON.stringify({
        name: group?.name,
        admin: group?.admin,
        editSongs: group?.editSongs,
        editDropIns: group?.editDropIns,
        editLicense: group?.editLicense,
        editSchedules: group?.editSchedules,
        editVoiceWheels: group?.editVoiceWheels,
        editVoiceTracks: group?.editVoiceTracks,
        overrideFunction: group?.overrideFunction,
        editSongCategory: group?.editSongCategory,
        editChannelPlaylist: group?.editChannelPlaylist,
      }),
    };

    fetch(`${API_URL}/Group`, requestOptions)
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data?.success === true) {
          setisOpen(false);
          fetchData();
          setIsAdded(true);
          setGroup({
            name: null,
            admin: false,
            editSongs: false,
            editLicense: false,
            editDropIns: false,
            editSchedules: false,
            editVoiceWheels: false,
            editVoiceTracks: false,
            editSongCategory: false,
            overrideFunction: false,
            editChannelPlaylist: false,
          });
          toast.success(t("main.New_User_Group_Added_Successfully"));
        } else {
          toast.error(data.message);
        }
        setisOpen(false);
      });
  };

  const handleEditUser = async (e: any) => {
    if (Object.keys(rowSelection).length > 1) {
      setIsEdit(false);
      toast.error(`${t("main.Please_Select_Only_One_User_to_Edit")}`);
    } else {
      setIsEdit(true);
      e.preventDefault();
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token?.accessToken}`,
        },
        body: JSON.stringify({
          id: groupId.toString(),
          name: group?.name,
          admin: group?.admin,
          editSongs: group?.editSongs,
          editDropIns: group?.editDropIns,
          editLicense: group?.editLicense,
          editSchedules: group?.editSchedules,
          editVoiceWheels: group?.editVoiceWheels,
          editVoiceTracks: group?.editVoiceTracks,
          overrideFunction: group?.overrideFunction,
          editSongCategory: group?.editSongCategory,
          editChannelPlaylist: group?.editChannelPlaylist,
        }),
      };
      fetch(`${API_URL}/Group`, requestOptions)
        .then((response) => {
          if (
            response.ok === false &&
            response.status === 401 &&
            token?.refreshToken !== ""
          ) {
            setIsTokenExpired(true);
          } else if (response.status === 401 && token?.refreshToken === "") {
            navigate("/");
            localStorage.removeItem("token");
            localStorage.clear();
          } else {
            setIsTokenUpdated(false);
            return response.json();
          }
        })
        .then((data) => {
          if (data?.success) {
            toast.success(t("main.Group_Edited_Successfully"));
            setIsAdded(true);
            fetchData();
          } else {
            toast.error(t("main.SOMETHING_WENT_WRONG"));
          }
          setisOpen(false);
          setRowSelection({});
          setGroup({
            name: null,
            admin: false,
            editSongs: false,
            editLicense: false,
            editDropIns: false,
            editSchedules: false,
            editVoiceWheels: false,
            editVoiceTracks: false,
            editSongCategory: false,
            overrideFunction: false,
            editChannelPlaylist: false,
          });
        });
    }
  };

  const HandleDelete = async () => {
    if (Object.keys(rowSelection)?.length === 0) {
      toast.error(t("main.Please_Select_Any_Group_to_Delete"));
    } else {
      const requestOptions = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token?.accessToken}`,
        },
        body: JSON.stringify(groupId),
      };
      fetch(`${API_URL}/Group`, requestOptions)
        .then((response) => {
          if (
            response.ok === false &&
            response.status === 401 &&
            token?.refreshToken !== ""
          ) {
            setIsTokenExpired(true);
          } else if (response.status === 401 && token?.refreshToken === "") {
            navigate("/");
            localStorage.removeItem("token");
            localStorage.clear();
          } else {
            setIsTokenUpdated(false);
            return response.json();
          }
        })
        .then((data) => {
          fetchData();
          if (data?.success === true) {
            toast.success(t("main.Group_Deleted_Successfully"));
            setIsAdded(true);
            fetchData();
          } else {
            toast.error(data?.message);
          }
          setGroupId([]);
        });
    }
  };
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (rowSelection) {
      const arrOfIndex = Object.keys(rowSelection);
      const arrOfIds = groupData
        .filter((_: any, i: number) => arrOfIndex.includes(i.toString()))
        .map((e: any) => e.id);
      setGroupId(arrOfIds);
    } else {
      setGroupId([]);
    }
  }, [rowSelection]);

  useEffect(() => {
    setRowSelection({});
  }, [groupData?.length]);

  useEffect(() => {
    const groupItem: any = groupData.find(
      (groupItem, index) => index === parseInt(Object.keys(rowSelection)[0])
    );
    if (groupItem) {
      setGroup({ ...groupItem });
    } else {
      setGroup({
        name: null,
        admin: false,
        editSongs: false,
        editLicense: false,
        editDropIns: false,
        editSchedules: false,
        editVoiceWheels: false,
        editVoiceTracks: false,
        overrideFunction: false,
        editSongCategory: false,
        editChannelPlaylist: false,
      });
      setIsEdit(false);
    }
  }, [groupData, rowSelection]);

  return (
    <>
      <div
        className={
          theme.palette.mode === "dark"
            ? "PermissionTable-dark"
            : "PermissionTable"
        }
      >
        <MaterialReactTable
          columns={columns}
          data={groupData}
          enableRowSelection //enable some features
          enableColumnOrdering
          enableSelectAll={false}
          enableFullScreenToggle={false}
          onRowSelectionChange={setRowSelection}
          state={{ rowSelection }}
          muiTableBodyCellProps={{
            sx: {
              paddingTop: 0,
              paddingBottom: 0,
            },
          }}
          enableGlobalFilter={false} //turn off a feature
        ></MaterialReactTable>
        <div className="BtnPart">
          <Button
            className="user-add-btn"
            onClick={() => {
              if (!currentUser.admin) {
                toast.error(
                  t("main.User_have_no_authority_to_edit_group_data")
                );
              } else {
                if (Object.keys(rowSelection).length > 1) {
                  toast.error(t("main.Please_Select_only_one"));
                  setRowSelection({});
                } else if (Object.keys(rowSelection).length === 1) {
                  setIsEdit(true);
                  setisOpen(true);
                } else {
                  setIsEdit(false);
                  setisOpen(true);
                }
              }
            }}
          >
            <Tooltip
              title={
                Object.keys(rowSelection).length === 1
                  ? `${t("main.edit_group")}`
                  : `${t("main.Add_group")}`
              }
            >
              {Object.keys(rowSelection).length > 0 ? <Edit /> : <Add />}
            </Tooltip>
          </Button>
          <Button className="user-add-btn" onClick={HandleDelete}>
            <Tooltip title={t("main.Delete_Group")}>
              <Remove />
            </Tooltip>
          </Button>
        </div>
      </div>
      <Dialog
        open={isOpen}
        onClose={() => {
          setisOpen(false);
          setRowSelection({});
          setGroup({
            name: null,
            admin: false,
            editSongs: false,
            editLicense: false,
            editDropIns: false,
            editSchedules: false,
            editVoiceWheels: false,
            editVoiceTracks: false,
            overrideFunction: false,
            editSongCategory: false,
            editChannelPlaylist: false,
          });
        }}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        maxWidth="sm"
        className="addNwWheelDialog"
      >
        <DialogTitle
          style={{ cursor: "move", color: "#484C52" }}
          id="draggable-dialog-title"
        >
          <Typography sx={{ fontWeight: 700, fontSize: 22 }}>
            {isEdit ? t("main.edit_group") : t("main.add_new_group")}
            <Link
              className="modelCloseBtn"
              onClick={() => {
                setisOpen(false);
                setRowSelection({});
                setGroup({
                  name: null,
                  admin: false,
                  editSongs: false,
                  editLicense: false,
                  editDropIns: false,
                  editSchedules: false,
                  editVoiceWheels: false,
                  editVoiceTracks: false,
                  overrideFunction: false,
                  editSongCategory: false,
                  editChannelPlaylist: false,
                });
              }}
              to={""}
            >
              <Close style={{ color: "#484C52" }} />
            </Link>
          </Typography>
        </DialogTitle>
        <form>
          <FormControl className="modal-new-wheel-input">
            <DialogContent>
              <Box
                component="form"
                sx={{
                  "& > :not(style)": { m: 1, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
                className="modal-new-wheel-form"
              >
                <TextField
                  sx={{ width: 350 }}
                  style={{ border: "1px solid #000", color: "#000" }}
                  variant="outlined"
                  placeholder={t("main.name")}
                  id="name"
                  value={group?.name ? group.name : ""}
                  onChange={(e) => {
                    setGroup({ ...group, name: e.target.value });
                  }}
                  required
                />
                <FormGroup>
                  <FormControlLabel
                    style={{ color: "#34393f" }}
                    control={
                      <Checkbox
                        style={{
                          color:
                            theme.palette.mode === "dark"
                              ? "#3c735c"
                              : "#3c735c",
                        }}
                        value={group?.admin ? group.admin : false}
                        checked={group?.admin}
                        onChange={(e) => {
                          setGroup({
                            ...group,
                            admin: e.target.checked,
                            editSongs: e.target.checked,
                            editSongCategory: e.target.checked,
                            editChannelPlaylist: e.target.checked,
                            editSchedules: e.target.checked,
                            editDropIns: e.target.checked,
                            editVoiceWheels: e.target.checked,
                            editVoiceTracks: e.target.checked,
                            overrideFunction: e.target.checked,
                            //.value
                          });
                        }}
                      />
                    }
                    label={t("main.admin")}
                  />
                  <FormControlLabel
                    style={{ color: "#34393f" }}
                    control={
                      <Checkbox
                        style={{
                          color:
                            theme.palette.mode === "dark"
                              ? "#3c735c"
                              : "#3c735c",
                        }}
                        value={group?.editSongs ? group.editSongs : false}
                        checked={group?.editSongs}
                        onChange={(e) => {
                          setGroup({
                            ...group,
                            editSongs: e.target.checked, //.value
                          });
                        }}
                      />
                    }
                    label={t("main.can_edit_songs")}
                    disabled={group.admin === true}
                  />
                  <FormControlLabel
                    style={{ color: "#34393f" }}
                    control={
                      <Checkbox
                        style={{
                          color:
                            theme.palette.mode === "dark"
                              ? "#3c735c"
                              : "#3c735c",
                        }}
                        value={
                          group?.editSongCategory
                            ? group.editSongCategory
                            : false
                        }
                        checked={group?.editSongCategory}
                        onChange={(e) => {
                          setGroup({
                            ...group,
                            editSongCategory: e.target.checked, //.value
                          });
                        }}
                      />
                    }
                    label={t("main.can_edit_songs_category")}
                    disabled={group.admin === true}
                  />
                  <FormControlLabel
                    style={{ color: "#34393f" }}
                    control={
                      <Checkbox
                        style={{
                          color:
                            theme.palette.mode === "dark"
                              ? "#3c735c"
                              : "#3c735c",
                        }}
                        value={
                          group?.editChannelPlaylist
                            ? group.editChannelPlaylist
                            : false
                        }
                        checked={group?.editChannelPlaylist}
                        onChange={(e) => {
                          setGroup({
                            ...group,
                            editChannelPlaylist: e.target.checked, //.value
                          });
                        }}
                      />
                    }
                    label={t("main.can_edit_channel_playlist")}
                    disabled={group.admin === true}
                  />
                  <FormControlLabel
                    style={{ color: "#34393f" }}
                    control={
                      <Checkbox
                        style={{
                          color:
                            theme.palette.mode === "dark"
                              ? "#3c735c"
                              : "#3c735c",
                        }}
                        value={
                          group?.editSchedules ? group.editSchedules : false
                        }
                        checked={group?.editSchedules}
                        onChange={(e) => {
                          setGroup({
                            ...group,
                            editSchedules: e.target.checked, //.value
                          });
                        }}
                      />
                    }
                    label={t("main.can_edit_schedules")}
                    disabled={group.admin === true}
                  />
                  <FormControlLabel
                    style={{ color: "#34393f" }}
                    control={
                      <Checkbox
                        style={{
                          color:
                            theme.palette.mode === "dark"
                              ? "#3c735c"
                              : "#3c735c",
                        }}
                        value={group?.editDropIns ? group.editDropIns : false}
                        checked={group?.editDropIns}
                        onChange={(e) => {
                          setGroup({
                            ...group,
                            editDropIns: e.target.checked, //.value
                          });
                        }}
                      />
                    }
                    label={t("main.can_edit_dropIns")}
                    disabled={group.admin === true}
                  />
                  <FormControlLabel
                    style={{ color: "#34393f" }}
                    control={
                      <Checkbox
                        style={{
                          color:
                            theme.palette.mode === "dark"
                              ? "#3c735c"
                              : "#3c735c",
                        }}
                        value={
                          group?.editVoiceWheels ? group.editVoiceWheels : false
                        }
                        checked={group?.editVoiceWheels}
                        onChange={(e) => {
                          setGroup({
                            ...group,
                            editVoiceWheels: e.target.checked, //.value
                          });
                        }}
                      />
                    }
                    label={t("main.can_edit_voice_wheels")}
                    disabled={group.admin === true}
                  />
                  <FormControlLabel
                    style={{ color: "#34393f" }}
                    control={
                      <Checkbox
                        style={{
                          color:
                            theme.palette.mode === "dark"
                              ? "#3c735c"
                              : "#3c735c",
                        }}
                        value={
                          group?.editVoiceTracks ? group.editVoiceTracks : false
                        }
                        checked={group?.editVoiceTracks}
                        onChange={(e) => {
                          setGroup({
                            ...group,
                            editVoiceTracks: e.target.checked, //.value
                          });
                        }}
                      />
                    }
                    label={t("main.can_edit_voice_tracks")}
                    disabled={group.admin === true}
                  />
                  <FormControlLabel
                    style={{ color: "#34393f" }}
                    control={
                      <Checkbox
                        style={{
                          color:
                            theme.palette.mode === "dark"
                              ? "#3c735c"
                              : "#3c735c",
                        }}
                        value={
                          group?.overrideFunction
                            ? group.overrideFunction
                            : false
                        }
                        checked={group?.overrideFunction}
                        onChange={(e) => {
                          setGroup({
                            ...group,
                            overrideFunction: e.target.checked, //.value
                          });
                        }}
                      />
                    }
                    label={t("main.can_edit_override_function")}
                    disabled={group.admin === true}
                  />
                </FormGroup>
              </Box>
            </DialogContent>
            <DialogActions
              sx={{ display: "flex", justifyContent: "space-around" }}
            >
              <Button
                type="submit"
                onClick={isEdit ? handleEditUser : postNewGroupData}
                size="medium"
                variant="contained"
                color="primary"
                sx={{ mt: 5 }}
              >
                {isEdit ? t("main.update") : t("main.save")}
              </Button>
              <Button
                onClick={() => {
                  setisOpen(false);
                  setRowSelection({});
                  setGroup({
                    name: null,
                    admin: false,
                    editSongs: false,
                    editLicense: false,
                    editDropIns: false,
                    editSchedules: false,
                    editVoiceWheels: false,
                    editVoiceTracks: false,
                    overrideFunction: false,
                    editSongCategory: false,
                    editChannelPlaylist: false,
                  });
                }}
                size="medium"
                variant="outlined"
                color="secondary"
                sx={{ mt: 5, borderColor: "#fff", backgroundColor: "red" }}
              >
                {t("main.cancel")}
              </Button>
            </DialogActions>
          </FormControl>
        </form>
      </Dialog>
    </>
  );
};

export default GroupTable;

import {
  TextField,
  InputAdornment,
  useTheme,
} from "@mui/material";
import React from 'react'
import "../../layouts/SearchBar.scss";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useChannelIdContext } from '../../context/ChannelIdContext';
import { useTranslation } from "react-i18next";

function ScheduleSearchBar() {
  const theme = useTheme();
  const { t } = useTranslation();
  const { searchContent, setSearchContent } = useChannelIdContext();
  return (
    <TextField
      className='customSearch'
      id="search-all"
      value={searchContent}
      placeholder={t("main.search_library")}
      type="text"
      variant="outlined"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchOutlinedIcon style={{color: theme.palette.mode === "dark" ? "#3c434b" : "#3c434b"}} />
          </InputAdornment>
        ),
      }}
      autoComplete='off'
      onChange={(event) => setSearchContent(event.target.value)}
    />
  )
}

export default ScheduleSearchBar
/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { Song } from "../../types/Customer";
import { CircularProgress } from "@mui/material";
import { useChannelIdContext } from "../../context/ChannelIdContext";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ExactTimePlayHistoryTable from "../Tables/ExactTimePlayHistoryTable";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const API_URL = process.env.REACT_APP_BACKEND_URL;
export default function CustomerExactTimePlay() {
  const { t } = useTranslation();
  let navigate = useNavigate();
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  const [ExactTimePlayedSongs, setExactTimePlayedSongs] = useState<{
    loaded: boolean;
    songs: Song[];
  }>({ loaded: false, songs: [] });
  const { SiteId, setIsTokenExpired, setIsTokenUpdated, IsTokenUpdated } =
    useChannelIdContext();
  const [SelectedRowSongId, setSelectedRowSongId] = useState<number>(0);

  const formatDate = (value: any) => {
    const date = moment(value?.renderedCellValue).format("DD.MM.YYYY");
    return `${date}`;
  };

  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "id",
        header: t("main.Voice_Track_ID"),
        size: 10,
      },
      {
        accessorKey: "title",
        header: t("main.title"),
      },

      {
        accessorKey: "voiceTrackCategory.name",
        header: t("main.voice_track_Category"),
      },
      {
        accessorKey: "zone.name",
        header: t("main.zone"),
      },
      {
        accessorKey: "commenceDate",
        header: t("main.commence_date"),
        Cell: (renderedCellValue: any) => (
          <span>{formatDate(renderedCellValue)}</span>
        ),
      },
      {
        accessorKey: "expireDate",
        header: t("main.expire_date"),
        Cell: (renderedCellValue: any) => (
          <span>{formatDate(renderedCellValue)}</span>
        ),
      },
    ],
    [t]
  );

  const fetchSiteBySiteId = useCallback(async () => {
    fetch(`${API_URL}/Site/GetPlayedVoiceTracksById/${SiteId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    })
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data.success === true && data?.data?.length > 0) {
          console.log(data.data)
          setExactTimePlayedSongs({ loaded: true, songs: data.data });
        } else {
          setSelectedRowSongId(0);
          setExactTimePlayedSongs({ loaded: true, songs: [] });
        }
      });
  }, [SiteId, IsTokenUpdated]);

  useEffect(() => {
    if (SiteId !== 0) {
      fetchSiteBySiteId();
    }
    if (SiteId === 0) {
      setExactTimePlayedSongs({ loaded: true, songs: [] });
      setSelectedRowSongId(0);
    }
  }, [SiteId, fetchSiteBySiteId]);

  return ExactTimePlayedSongs?.loaded === false ? (
    <div style={{ justifyContent: "center", display: "flex" }}>
    <CircularProgress style={{ marginTop: "15rem" }} />
  </div>
  ) : (
    <>
      <div className="selectRow ViewSongs">
        <MaterialReactTable
          columns={columns}
          data={ExactTimePlayedSongs?.songs}
          enablePagination={true}
          enableSorting={true}
          enableBottomToolbar={true}
          enableTopToolbar={false}
          enableColumnActions={true}
          enableColumnOrdering={true}
          enableRowActions={false}
          enableStickyHeader
          enableGlobalFilterModes
          initialState={{
            showGlobalFilter: true,
          }}
          enablePinning
          muiTableBodyProps={{
            sx: {
              "& tr:nth-of-type(even)": {
                backgroundColor: "#F0F4F7",
              },
            },
          }}
          getRowId={(row) => row.id}
          muiTableBodyRowProps={({ row }) => ({
            onClick: () => {
              setSelectedRowSongId(row.original.id);
            },
            selected: SelectedRowSongId === row.original.id,
            sx: {
              cursor: "pointer",
            },
          })}
        />
      </div>
      <ExactTimePlayHistoryTable SelectedRowSongId={SelectedRowSongId} />
    </>
  );
}

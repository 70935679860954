import { Paper } from "@mui/material";
import { useState } from "react";
import GroupTable from "../Tables/GroupTable";
import UserTable from "../Tables/UserTable/UserTable";

export default function MessageImport() {
  const [isAdded, setIsAdded] = useState(false)
  return (
    <Paper elevation={2} sx={{ p: 3 }}>
      <div className="table-heading">
        Group
      </div>
      <GroupTable setIsAdded={setIsAdded}></GroupTable>
      <div className="table-heading">
        User
      </div>
      <UserTable isAdded={isAdded} setIsAdded={setIsAdded}></UserTable>
    </Paper>
  );
}

import {
  Link,
  Paper,
  Dialog,
  Select,
  Button,
  MenuItem,
  useTheme,
  Checkbox,
  TextField,
  PaperProps,
  Typography,
  DialogTitle,
  FormControl,
  DialogActions,
  DialogContent,
  FormControlLabel,
} from "@mui/material";
import "./DialogsMain.scss";
import { Stack } from "@mui/system";
import Draggable from "react-draggable";
import CloseIcon from "@mui/icons-material/Close";
import React, { FC, MouseEventHandler, useMemo, useState } from "react";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import { useTranslation } from "react-i18next";

function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper
        {...props}
        style={{
          backgroundColor: "#34393f",
          color: "#D9D9D9",
          padding: "1rem",
        }}
      />
    </Draggable>
  );
}

type AudioMetaForSelect = {
  id: number;
  title: string;
  artist: string;
};

type ZoneForSelect = {
  id: number;
  name: string;
  machine: string;
};

const data_audio: AudioMetaForSelect[] = [
  {
    id: 1,
    title: "Dizy",
    artist: "Tommy",
  },
  {
    id: 2,
    title: "Dizy 2 ",
    artist: "Tommy 2",
  },
  {
    id: 3,
    title: "Dizy 3",
    artist: "Tommy 3",
  },
];

const data_zone: ZoneForSelect[] = [
  {
    id: 1,
    name: "Zone 1",
    machine: "Zone Machine",
  },
  {
    id: 2,
    name: "Zone 2",
    machine: "Zone Machine 2",
  },
  {
    id: 3,
    name: "Zone 3",
    machine: "Zone Machine",
  },
];

const TableSong: FC = () => {
  //should be memoized or stable
  const columns = useMemo<MRT_ColumnDef<AudioMetaForSelect>[]>(
    () => [
      {
        accessorKey: "title", //access nested data with dot notation
        header: "Song Title",
      },
      {
        accessorKey: "artist",
        header: "Artist",
      },
    ],
    []
  );

  return (
    <MaterialReactTable
      columns={columns}
      data={data_audio}
      enableSorting={false}
      enablePagination={false}
      enableTopToolbar={false}
      enableColumnActions={false}
      enableColumnFilters={false}
      enableBottomToolbar={false}
      muiTableBodyRowProps={{ hover: false }}
    />
  );
};
const TableZone: FC = () => {
  //should be memoized or stable
  const columns = useMemo<MRT_ColumnDef<ZoneForSelect>[]>(
    () => [
      {
        accessorKey: "name", //access nested data with dot notation
        header: "Name of Zone",
      },
      {
        accessorKey: "machine",
        header: "Machine",
      },
    ],
    []
  );

  return (
    <MaterialReactTable
      columns={columns}
      data={data_zone}
      enableSorting={false}
      enableTopToolbar={false}
      enablePagination={false}
      enableColumnFilters={false}
      enableBottomToolbar={false}
      enableColumnActions={false}
      muiTableBodyRowProps={{ hover: false }}
    />
  );
};

export default function OverrideDialog({
  isOpen,
  handleClose,
}: {
  isOpen: boolean;
  handleClose: MouseEventHandler;
}) {
  const [playlist, setPlaylist] = useState("0");
  const {t} = useTranslation();
  const [createNew, setCreateNew] = useState(false);
  const theme = useTheme();
  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          <Typography variant="h5" sx={{ fontWeight: 700 }}>
            Override Player
            <Link onClick={handleClose} className="modelCloseBtn">
              <CloseIcon />
            </Link>
          </Typography>
        </DialogTitle>
        <DialogContent sx={{}}>
          {!createNew ? (
            <>
              <FormControl sx={{ pt: 2 }} fullWidth>
                <Typography variant="h6" sx={{ pb: 1 }}>
                  Playlist
                </Typography>
                <Select
                  id="select-playlist"
                  value={playlist || ""}
                  onChange={(e) => setPlaylist(e.target.value)}
                  color="primary"
                  sx={{
                    "& .MuiInputBase-input": {
                      backgroundColor:
                        theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
                    },
                  }}
                >
                  <MenuItem value="0">
                    <em>No playlist selected ...</em>
                  </MenuItem>
                  <MenuItem value="1">Test Playlist</MenuItem>
                  <MenuItem value="2">Slow Playlist</MenuItem>
                  <MenuItem value="3">Restaurant</MenuItem>
                </Select>
              </FormControl>
              <Stack direction="row-reverse" sx={{ pt: 3 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setCreateNew(true)}
                >
                  Create New
                </Button>
                {playlist !== "0" && (
                  <Button variant="contained" color="error" sx={{ mr: 2 }}>
                    Delete
                  </Button>
                )}
              </Stack>
            </>
          ) : (
            <>
              <FormControl sx={{ pt: 2 }} fullWidth>
                <Typography variant="h6" sx={{ pb: 1 }}>
                  Playlist
                </Typography>
                <TextField
                  aria-label="Playlist"
                  id="text-playlist"
                  placeholder="Enter new name for playlist"
                  value={""}
                  sx={{
                    "& .MuiInputBase-input": {
                      backgroundColor:
                        theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
                    },
                  }}
                ></TextField>
              </FormControl>
              <Stack direction="row-reverse" sx={{ pt: 3 }}>
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => setCreateNew(true)}
                >
                  Add to Playlists
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    mr: 2,
                    bgcolor: "action.disabled",
                    "&:hover": {
                      bgcolor:
                        theme.palette.mode === "dark"
                          ? "action.hover"
                          : "action.active",
                    },
                  }}
                  onClick={() => setCreateNew(false)}
                >
                 {t("main.cancel")}
                </Button>
              </Stack>
            </>
          )}
          <hr />
          <TableSong></TableSong>
          <Stack direction="row-reverse" sx={{ pt: 2 }}>
            <Button variant="contained" color="success">
              Add Song
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{ mr: 2 }}
              onClick={handleClose}
            >
             {t("main.cancel")}
            </Button>
          </Stack>
          <hr />
          <Stack direction="row-reverse">
            <FormControlLabel control={<Checkbox />} label="All Zones" />
          </Stack>
          <TableZone></TableZone>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            size="large"
            variant="outlined"
            color="success"
            sx={{ fontSize: "1.6rem", m: 5, width: "50%" }}
          >
            Launch
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

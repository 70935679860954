import {
    Box,
    Link,
    Grid,
    Theme,
    Avatar,
    Button,
    TextField,
    Typography,
} from "@mui/material";
import React from "react";
import { LoginOperation } from "../../pages/LoginPage";
import MailLockOutlinedIcon from "@mui/icons-material/MailLockOutlined";

const tbStyle = (theme: Theme) => ({
  padding: "0px",
  borderRadius: "4px",
  backgroundColor: theme.palette.mode === "dark" ? "#000000ad" : "#ffffffad",
});

export default function ForgotPassword({
  changeOperationHandler,
}: {
  changeOperationHandler: Function;
}) {
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const data = new FormData(event.currentTarget);
  };
  return (
    <>
      <Avatar sx={{ m: 1, bgcolor: "warning.main" }}>
        <MailLockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Reset Password
      </Typography>
      <Box
        component="form"
        onSubmit={(e) => handleSubmit(e)}
        noValidate
        sx={{ mt: 1 }}
      >
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          sx={tbStyle}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          size="large"
          sx={{ mt: 3, mb: 2 }}
        >
          Reset Password
        </Button>
        <Grid container>
          <Grid container justifyContent="flex-end">
            <Link
              onClick={() => changeOperationHandler(LoginOperation.SignIn)}
              variant="body2"
              sx={{ cursor: "pointer" }}
            >
              Remember Password?
            </Link>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Stack,
  Select,
  styled,
  Toolbar,
  MenuItem,
  useTheme,
  Typography,
  ButtonBase,
  FormControl,
} from "@mui/material";
import { toast } from "react-toastify";
import { Colors } from "../../data/Colors";
import { useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useChannelIdContext } from "../../context/ChannelIdContext";
import { ReactComponent as MlmLogo } from "../../assets/images/mlmLogo.svg";
import { ReactComponent as Setting } from "../../assets/images/settings.svg";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import "./sidebar.scss";
const API_URL = process.env.REACT_APP_BACKEND_URL;
const drawerWidth = 255;
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}
interface Group {
  admin: boolean;
}
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme?.zIndex?.drawer + 1,
  transition: theme?.transitions?.create(["width", "margin"], {
    easing: theme?.transitions?.easing?.sharp,
    duration: theme?.transitions?.duration?.leavingScreen,
  }),
  width: `calc(100% - 80px)`,
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme?.transitions?.create(["width", "margin"], {
      easing: theme?.transitions?.easing?.sharp,
      duration: theme?.transitions?.duration?.enteringScreen,
    }),
  }),
}));
export default function TopPanelTitle({
  headerText,
  open,
}: {
  headerText: string;
  open: boolean;
}) {
  let theme = useTheme();
  let navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const {
    currentUser,
    setIsTokenExpired,
    setIsTokenUpdated,
    IsTokenUpdated,
    Language,
    setLanguage,
  } = useChannelIdContext();
  const [, setGroup] = useState<any>();

  let IsToken = localStorage.getItem("token") ? true : false;
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");

  let pathname = window.location.pathname;

  function handleLanguageChange(event: any) {
    setLanguage(event.target.value);
    i18n.changeLanguage(event.target.value);
  }
  useEffect(() => {
    const fetchData = async () => {
      fetch(`${API_URL}/Group/1`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token?.accessToken}`,
        },
      })
        .then((response) => {
          if (
            response.ok === false &&
            response.status === 401 &&
            token?.refreshToken !== ""
          ) {
            setIsTokenExpired(true);
          } else if (response.status === 401 && token?.refreshToken === "") {
            navigate("/");
            localStorage.removeItem("token");
            localStorage.clear();
          } else {
            setIsTokenUpdated(false);
            return response.json();
          }
        })
        .then((data) => {
          if (data.success === true) {
            setGroup(data?.data);
          } else {
            setGroup("");
          }
        });
    };
    fetchData();
  }, [IsTokenUpdated]);
  return (
    <AppBar
      position="fixed"
      open={open}
      className={
        IsToken === false ? "siteHeaderTop" : "siteHeader"
      }
    >
      <Toolbar
        sx={{
          height: "65px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "end",
          padding: "0 !important",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          spacing={2}
          width="100%"
        >
          {/* MLM */}
          <ButtonBase
            aria-label="BMS"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              color: theme.palette.mode === "dark" ? "#cfd8dc" : "#263238",
              bgcolor: Colors.DeepAquamarine,
              height: "65px",
              width: "78px",
              marginLeft: "10px !important",
              padding: "6px 20px",
            }}
          >
            <MlmLogo />
            <Typography
              variant="subtitle1"
              gutterBottom
              sx={{
                p: 0,
                m: 0,
                fontWeight: 500,
                fontSize: "16px",
                lineHeight: "18px",
                color: "#F5F5F5",
                marginTop: "5px",
              }}
            >
              MLM
            </Typography>
          </ButtonBase>
          <Typography
            variant="h5"
            noWrap
            component="div"
            bgcolor={Colors?.DeepAquamarine}
            sx={{
              mb: 0,
              marginLeft: "54px !important",
              borderTopLeftRadius: "5rem",
              borderTopRightRadius: "5rem",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "18px",
              color: "#E8EBE6",
              padding: "12px 30px",
              borderRadius: "30px 30px 0px 0px",
            }}
          >
            {headerText}
          </Typography>
          <Box
            sx={{
              flex: 1,
              flexDirection: "row",
            }}
          ></Box>
          {/* Settings button */}
          {currentUser?.admin && IsToken ? (
            <>
              <ButtonBase
                aria-label="Settings"
                onClick={() => navigate("/settings")}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  color: theme.palette.mode === "dark" ? "#cfd8dc" : "#263238",
                  bgcolor: Colors.DeepAquamarine,
                  height: "65px",
                  // width: "7%",
                  marginLeft: "10px !important",
                  padding: "6px 20px",
                }}
              >
                <Setting />
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  sx={{
                    p: 0,
                    m: 0,
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "16px",
                    color: "#E8EBE6",
                  }}
                >
                  {t("main.settings")}
                </Typography>
              </ButtonBase>
            </>
          ) : null}
          {IsToken ? (
            <ButtonBase
              aria-label="Settings"
              onClick={() => {
                navigate("/");
                localStorage.removeItem("userData");
                localStorage.clear();
                toast.success(t("main.logged_out_successfully"), {
                  position: "bottom-right",
                  autoClose: 1400,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              }}
              sx={{
                justifyContent: "center",
                alignItems: "center",
                display: "block",
                color: theme.palette.mode === "dark" ? "#cfd8dc" : "#263238",
                bgcolor: Colors.DeepAquamarine,
                height: "65px",
                // width: "7%",
                marginLeft: "10px !important",
                padding: "6px 20px",
              }}
            >
              <LogoutIcon style={{ color: "white" }} />
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{
                  p: 0,
                  m: 0,
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "16px",
                  color: "#E8EBE6",
                }}
              >
                {t("main.logout")}
              </Typography>
            </ButtonBase>
          ) : null}
          {/* Language Dropdown Button */}
          <FormControl>
            <Select
              sx={{
                fontFamily: "Ubuntu",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "15px",
                lineHeight: "16px",
                color: "#E8EBE6",
                "& fieldset": {
                  border: "none",
                  color: "#E8EBE6",
                },
                "& .MuiSvgIcon-root": {
                  color: "#E8EBE6",
                },
              }}
              value={Language || ""}
              onChange={handleLanguageChange}
            >
              <MenuItem value={"en"} key={0}>
                EN
              </MenuItem>
              <MenuItem value={"de"} key={1}>
                DE
              </MenuItem>
              <MenuItem value={"fr"} key={1}>
                FR
              </MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </Toolbar>
    </AppBar>
  );
}

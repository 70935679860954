import React from "react";
import MiniSidePanel from "../layouts/MiniSidePanel/MiniSidePanel";
import { useTheme, ThemeProvider } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

export default function Main() {
  let theme = useTheme();
  const {t} = useTranslation()
  return (
    <ThemeProvider theme={theme}>
      <MiniSidePanel headerText={t("Dashboard")}>
        <>Content</>
      </MiniSidePanel>
    </ThemeProvider>
  );
}

import React, {
  useState,
  useEffect,
  SyntheticEvent,
  MouseEventHandler,
} from "react";
import {
  Link,
  Paper,
  Button,
  Dialog,
  TextField,
  PaperProps,
  Typography,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import Draggable from "react-draggable";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import { ChannelData } from "../../../types/Channel";
import "../AddChannelListDialog/AddChannelListDialog.scss";
import { useChannelIdContext } from "../../../context/ChannelIdContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
const API_URL = process.env.REACT_APP_BACKEND_URL;
function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper
        {...props}
        style={{
          backgroundColor: "#34393f",
          color: "#D9D9D9",
          padding: "1rem",
        }}
      />
    </Draggable>
  );
}

export default function EditChannelNameDialog({
  isOpen,
  handleClose,
}: {
  isOpen: boolean;
  handleClose: any | MouseEventHandler;
}) {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const {
    channelId,
    setIsAdded,
    setIsTokenExpired,
    setIsTokenUpdated,
    IsTokenUpdated,
  } = useChannelIdContext();
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  const [channelData, setChannelData] = useState<ChannelData>({
    id: 0,
    sites: [],
    name: "string",
    attribute1Amount: 0,
    attribute2Amount: 0,
    attribute3Amount: 0,
    attribute4Amount: 0,
    lastPlaySongRuleDays: 0,
    lastPlaySongRuleOn: false,
    lastPlaySongRuleMinutes: 0,
    attribute1Breakable: false,
    attribute2Breakable: false,
    yesterdaySongRuleOn: false,
    attribute3Breakable: false,
    attribute4Breakable: false,
    yesterdaySongRuleMinutes: 0,
    yesterdayArtistRuleOn: false,
    yesterdayArtistRuleMinutes: 0,
    dayRestrictionBreakable: false,
    timeRestrictionBreakable: false,
    seasonalRestrictionBreakable: false,
  });

  const handleEditChannel = async (e: SyntheticEvent) => {
    e.preventDefault();
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
      body: JSON.stringify({
        id: channelData?.id,
        name: channelData?.name,
        attribute1Amount: channelData?.attribute1Amount,
        attribute2Amount: channelData?.attribute2Amount,
        attribute3Amount: channelData?.attribute3Amount,
        attribute4Amount: channelData?.attribute4Amount,
        lastPlaySongRuleOn: channelData?.lastPlaySongRuleOn,
        attribute1Breakable: channelData?.attribute1Breakable,
        attribute2Breakable: channelData?.attribute2Breakable,
        attribute3Breakable: channelData?.attribute3Breakable,
        attribute4Breakable: channelData?.attribute4Breakable,
        yesterdaySongRuleOn: channelData?.yesterdaySongRuleOn,
        lastPlaySongRuleDays: channelData?.lastPlaySongRuleDays,
        yesterdayArtistRuleOn: channelData?.yesterdayArtistRuleOn,
        lastPlaySongRuleMinutes: channelData?.lastPlaySongRuleMinutes,
        dayRestrictionBreakable: channelData?.dayRestrictionBreakable,
        timeRestrictionBreakable: channelData?.timeRestrictionBreakable,
        yesterdaySongRuleMinutes: channelData?.yesterdaySongRuleMinutes,
        yesterdayArtistRuleMinutes: channelData?.yesterdayArtistRuleMinutes,
        seasonalRestrictionBreakable: channelData?.seasonalRestrictionBreakable,
      }),
    };
    fetch(`${API_URL}/Channel`, requestOptions)
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data?.success === true) {
          setIsAdded(true);
          handleClose();
          toast.success(t("main.Channel_Name_Updated_Successfully"));
        } else {
          toast.error(data?.message);
        }
      });
  };

  useEffect(() => {
    const fetchSongListByChannel = async () => {
      if (channelId !== undefined) {
        fetch(`${API_URL}/Channel/${channelId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token?.accessToken}`,
          },
        })
          .then((response) => {
            if (
              response.ok === false &&
              response.status === 401 &&
              token?.refreshToken !== ""
            ) {
              setIsTokenExpired(true);
            } else if (response.status === 401 && token?.refreshToken === "") {
              navigate("/");
              localStorage.removeItem("token");
              localStorage.clear();
            } else {
              setIsTokenUpdated(false);
              return response.json();
            }
          })
          .then((data) => {
            if (data.success === true) {
              setChannelData(data?.data);
            }
          });
      }
    };
    fetchSongListByChannel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelId, IsTokenUpdated]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      maxWidth="sm"
      fullWidth
      className="addChannelModelDialog"
    >
      <DialogTitle>
        <Typography variant="h5" className="addModel-title">
          {t("main.Update_Channel_Name")}
          <Link onClick={handleClose} className="modelCloseBtn">
            <CloseIcon />
          </Link>
        </Typography>
      </DialogTitle>
      <div className="dialogForm">
        <form>
          <div className="dialogForm-field addchanelFields">
            <Typography variant="h5" className="dialogForm-field-label">
              {t("main.channel_name")}
            </Typography>
            <TextField
              required
              value={channelData?.name}
              autoComplete="off"
              onChange={(e) =>
                setChannelData({
                  ...channelData,
                  name: e.target.value,
                })
              }
            ></TextField>
          </div>
          <DialogActions sx={{ display: "flex" }}>
            <Button
              type="submit"
              size="medium"
              variant="contained"
              color="primary"
              sx={{ mt: 5 }}
              onClick={(e: SyntheticEvent) => handleEditChannel(e)}
            >
              {t("main.save")}
            </Button>
            <Button
              size="medium"
              variant="outlined"
              color="primary"
              sx={{ mt: 5 }}
              onClick={() => {
                handleClose();
              }}
            >
              {t("main.cancel")}
            </Button>
          </DialogActions>
        </form>
      </div>
    </Dialog>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Checkbox,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useTheme
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ReactComponent as Edit } from "../../assets/images/EditIcon.svg";
import EditChannelNameDialog from "../../component/Dialogs/EditChannelNameDialog/EditChannelNameDialog";
import { useChannelIdContext } from "../../context/ChannelIdContext";
import { ChannelList } from "../../types/Channel";
import "./channelListSidebar.scss";

const API_URL = process.env.REACT_APP_BACKEND_URL;

const ChannelListSidebar = () => {
  let theme = useTheme();
  const {
    isAdded,
    isAddedSong,
    setIsAddedSong,
    channelId,
    setIsAdded,
    currentUser,
    setChannelId,
    deleteChannelId,
    setDeleteChannelId,
    setIsTokenUpdated,
    setIsTokenExpired,
    IsTokenUpdated,
    setisDeleted,
    isDeleted,
    RegenerateChannelData,
  } = useChannelIdContext();
  let navigate = useNavigate();
  const { t } = useTranslation();
  const [tab, setTab] = useState<number>(0);
  const [songCount, setSongCount] = useState<number>(0);
  const [sitesCount, setSitesCount] = useState<number>(0);
  const [isRename, setIsRename] = useState<boolean>(false);
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  const [channelList, setChannelList] = useState<ChannelList[] | any>([]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const optionId = parseInt(event.target.value, 10);
    const isChecked = event.target.checked;
    if (isChecked) {
      setDeleteChannelId((prevSelected) => [...prevSelected, optionId]);
    } else {
      setDeleteChannelId((prevSelected) =>
        prevSelected.filter((id) => id !== optionId)
      );
    }
  };
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    event.preventDefault();
    setTab(newValue);
  };

  useEffect(() => {
    const fetchChannelList = async () => {
      fetch(`${API_URL}/Channel/GetAll`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token?.accessToken}`,
        },
      })
        .then((response) => {
          if (
            response.ok === false &&
            response.status === 401 &&
            token?.refreshToken !== ""
          ) {
            setIsTokenExpired(true);
          } else if (response.status === 401 && token?.refreshToken === "") {
            navigate("/");
            localStorage.removeItem("token");
            localStorage.clear();
          } else {
            setIsTokenUpdated(false);
            return response.json();
          }
        })
        .then((data) => {
          if (data.success === true) {
            setIsAdded(false);
            setChannelList(data.data);
            setChannelId(data?.data[0]?.id);
            setDeleteChannelId([]);
            setTab(0);
          } else {
            setIsAdded(false);
            setChannelList([]);
          }
        });
    };
    fetchChannelList();
  }, [isAdded, IsTokenUpdated]);

  const fetchChannelList = async () => {
    fetch(`${API_URL}/Channel/${channelId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    })
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data.success === true) {
          setisDeleted(false);
          setSitesCount(data?.data.siteCount);
          setSongCount(data?.data.songCount);
          setIsAddedSong(false);
        } else {
          setSitesCount(0);
          setSongCount(0);
          setisDeleted(false);
          setIsAddedSong(false);
        }
      });
  };
  useEffect(() => {
    if (channelId !== undefined) {
      fetchChannelList();
    }
  }, [
    channelId,
    isAddedSong,
    IsTokenUpdated,
    isDeleted,
    RegenerateChannelData,
  ]);

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          maxWidth: 480,
          borderRight: "1px solid #9E9E9E",
          height: "100%",
        }}
      >
        <Typography
          style={{
            backgroundColor: "#34393F",
            height: "66px",
            padding: theme.spacing(3),
            maxWidth: "100%",
          }}
        ></Typography>
        <div></div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            maxWidth: "100%",
          }}
        >
          <Typography
            style={{
              backgroundColor: "#E0E0E0",
              padding: theme.spacing(2),
              textAlign: "center",
              color: "#34393F",
              width: 170,
              borderRight: "1px solid #c4c4c4",
            }}
          >
            {t("main.name")}
          </Typography>
          {/* <Typography
            style={{
              backgroundColor: "#E0E0E0",
              padding: theme.spacing(2),
              textAlign: "center",
              color: "#34393F",
              width: 100,
              borderRight: "1px solid #c4c4c4",
            }}
          >
            {t("main.static")}
          </Typography> */}
          <Typography
            style={{
              backgroundColor: "#E0E0E0",
              padding: theme.spacing(2),
              textAlign: "center",
              color: "#34393F",
              width: 100,
              borderRight: "1px solid #c4c4c4",
            }}
          >
            {t("main.count")}
          </Typography>
          <Typography
            style={{
              backgroundColor: "#E0E0E0",
              padding: theme.spacing(2),
              textAlign: "center",
              color: "#34393F",
              width: 100,
            }}
          >
            {t("main.Sites")}
          </Typography>
        </div>
        <Box sx={{ flexGrow: 1, bgcolor: "background.paper", display: "flex" }}>
          <Tabs
            orientation="vertical"
            className={`ChannelhorizontalTabs ${channelList.length >= 8 ? "scrollableTabs" : ""
              }`}
            value={tab}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{
              width: "100%",
              maxWidth: "100%",
              backgroundColor:
                theme.palette.mode === "dark" ? "#202020" : "#FFFFFF",
              "& button": {
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "21px",
                borderRadius: 0,
                opacity: 1,
                color: theme.palette.mode === "dark" ? "#fff" : "#000",
                background: "transparent",
              },
              "& button.Mui-selected": {
                color: "#FFFFFF",
                background: "#3c735c ",
              },
              "& .Mui-selected": {
                background:
                  theme.palette.mode === "dark" ? "#303030" : "#34393f",
                borderRight: "1px solid #9E9E9E",
                borderLeft: "1px solid #9E9E9E",
              },
            }}
            variant="fullWidth"
          >
            {channelList?.map((item: ChannelList) => (
              <Tab
                className="ChannelHorizontalTabs__item"
                label={
                  <div
                    style={{
                      width: "100%",
                      maxWidth: "400px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <span className="ChannelHorizontalTabs__item-icon">
                      <Tooltip title={t("main.Edit")}>
                        <Edit
                          style={{ marginLeft: -5 }}
                          onClick={() => {
                            if (!currentUser.editChannelPlaylist) {
                              toast.error(
                                t(
                                  "main.user_have_no_authority_to_edit_channel_playlist"
                                )
                              );
                            } else {
                              setIsRename(true);
                            }
                          }}
                        />
                      </Tooltip>
                    </span>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Typography style={{ width: "108px", fontSize: "13px" }}>
                        {item.name}
                      </Typography>
                    </div>
                    {/* <Checkbox
                      style={{ color: "#fff" }}
                      className="ChannelCheckBoxsidebar"
                      
                      onChange={handleCheckboxChange}
                      value={item?.id}
                    /> */}
                    <div style={{ width: "60px", color: "#fff" }}>
                      {songCount}
                    </div>
                    <div style={{ width: "60px", color: "#fff" }}>
                      {sitesCount}
                    </div>
                  </div>
                }
                key={item?.id}
                sx={{ textTransform: "none", fontSize: "16px", height: 80 }}
                onClick={() => {
                  console.log("selected")
                  setDeleteChannelId(item?.id)
                  setChannelId(item?.id)
                }}
              />
            ))}
          </Tabs>
        </Box>
      </Box>
      {/* <Dialog open={isLoading} className="loadingDialog">
        <div style={{ background: "none", height: "50px", width: "50px" }}>
          <CircularProgress style={{ color: "darkgreen" }} />
        </div>
      </Dialog> */}
      {isRename && (
        <EditChannelNameDialog
          isOpen={isRename}
          handleClose={() => setIsRename(false)}
        />
      )}
    </>
  );
};
export default ChannelListSidebar;

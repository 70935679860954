import * as React from "react";
import { Typography } from "@mui/material";

export interface IProfileProps {
  name: string;
  email: string;
}

export default function Profile(props: IProfileProps) {
  return (
    <>
      <Typography>User Info</Typography>
      <Typography>E-mail</Typography>
      <Typography>{props.email}</Typography>
      <Typography>Name</Typography>
      <Typography>{props.name}</Typography>
    </>
  );
}

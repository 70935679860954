import {
  Box,
  Link,
  Paper,
  Button,
  Dialog,
  TextField,
  PaperProps,
  Typography,
  DialogTitle,
  FormControl,
  DialogActions,
  DialogContent,
  useTheme,
} from "@mui/material";
import { toast } from "react-toastify";
import Draggable from "react-draggable";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import { CategoryMusic } from "../../../types/Category";
import { useTranslation } from "react-i18next";
import { useChannelIdContext } from "../../../context/ChannelIdContext";
import { useNavigate } from "react-router-dom";
const API_URL = process.env.REACT_APP_BACKEND_URL;
function PaperComponent(props: PaperProps) {
  const theme = useTheme();
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper
        {...props}
        style={{
          backgroundColor: theme.palette.mode === "dark" ? "#000" : "#fff",
          color: "#D9D9D9",
          padding: "1rem",
        }}
      />
    </Draggable>
  );
}
interface FormData {
  name: string | null;
  volumeOffset: string | null;
}

type CreateNewCategoriesDialogProps = {
  isOpen: boolean;
  onSave: (data: FormData) => void;
  onClose: (event: React.MouseEvent<HTMLElement>) => void;
  setIsUpdateCategory: React.Dispatch<React.SetStateAction<boolean>>;
  updateTableData: React.Dispatch<React.SetStateAction<CategoryMusic[]>>;
};

export default function CreateNewCategoriesDialog({
  isOpen,
  onClose,
  onSave,
  updateTableData,
  setIsUpdateCategory,
}: CreateNewCategoriesDialogProps) {
  const { setIsCategoryAdded, setIsTokenExpired, setIsTokenUpdated } =
    useChannelIdContext();
  const { t } = useTranslation();
  const [category, setCategory] = useState<FormData>({
    name: "",
    volumeOffset: "",
  });
  const theme = useTheme();
  const [isFormValid, setIsFormValid] = useState(false);
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  const navigate = useNavigate();

  const postAnnouncementData = async (e: React.MouseEvent<HTMLElement>) => {
    if (!category.name?.trim()) {
      toast.error(t("main.Please_fill_the_form"));
    } else {
      e.preventDefault();
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token?.accessToken}`,
        },
        body: JSON.stringify({
          name: category?.name,
          volumeOffset: category?.volumeOffset,
        }),
      };
      try {
        fetch(`${API_URL}/VoiceTrackCategory`, requestOptions)
          .then((response) => {
            if (
              response.ok === false &&
              response.status === 401 &&
              token?.refreshToken !== ""
            ) {
              setIsTokenExpired(true);
            } else if (response.status === 401 && token?.refreshToken === "") {
              navigate("/");
              localStorage.removeItem("token");
              localStorage.clear();
            } else {
              setIsTokenUpdated(false);
              return response.json();
            }
          })
          .then((data) => {
            if (data?.success === true) {
              onClose(e);
              toast.success(t("main.New_Category_Created_Successfully"));
              setIsUpdateCategory(true);
              setIsCategoryAdded(true);
            } else {
              toast.error(data?.message);
            }
          });
      } catch (error) {
        toast.error(t("main.Error_Occurred_While_Saving_Category"));
      }
    }
  };
  useEffect(() => {
    setCategory({ name: "", volumeOffset: "" });
  }, [isOpen]);

  useEffect(() => {
    setIsFormValid(category?.name !== "" && category?.volumeOffset !== "");
  }, [category?.name, category?.volumeOffset]);

  return (
    <Dialog
      fullWidth
      open={isOpen}
      maxWidth="sm"
      onClose={onClose}
      PaperComponent={PaperComponent}
      className="addNewWheelDialog"
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle
        style={{ cursor: "move", color:theme.palette.mode === "dark" ? "#fff" : "#000" }}
        id="draggable-dialog-title"
      >
        <Typography sx={{ fontSize: "25px", fontWeight: 700 }}>
          {t("main.add_new_category")}
          <Link className="modelCloseBtn" onClick={onClose}>
            <CloseIcon style={{ color: "#000" }} />
          </Link>
        </Typography>
      </DialogTitle>
      <FormControl className="modal-new-wheel-input">
        <DialogContent>
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
            className="modal-new-wheel-input"
          >
            <TextField
              sx={{ width: 350 }}
              label={t("main.title")}
              variant="outlined"
              id="name"
              value={category?.name || ""}
              onChange={(e) => {
                setCategory({ ...category, name: e.target.value });
              }}
              required
            />
            <TextField
              sx={{ width: 350 }}
              label={t("main.volume_offset")}
              variant="outlined"
              id="volumeOffset"
              inputProps={{ step: "1" }}
              value={category.volumeOffset || ""}
              onChange={(e) => {
                const value = parseInt(e.target.value);
                setCategory({
                  ...category,
                  volumeOffset: isNaN(value) ? "" : value.toString(),
                });
              }}
              required
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "end" }}>
          <Button
            type="submit"
            onClick={(e) => postAnnouncementData(e)}
            size="medium"
            variant="contained"
            color={isFormValid ? "primary" : "secondary"}
            className="SaveBtn"
            sx={{
              mt: 5,
              "&.Mui-disabled": {
                backgroundColor: "#757575 !important",
                color: "#fff !important",
                border: "2px solid #757575 !important",
              },
              "&:not(.Mui-disabled)": {
                backgroundColor: isFormValid
                  ? "#3c735c !important"
                  : "#115293 ",
              },
              color: "#fff",
            }}
            disabled={!isFormValid}
          >
            {t("main.save")}
          </Button>
          <Button
            onClick={onClose}
            size="medium"
            variant="outlined"
            color="secondary"
            className="ClsBtn"
            sx={{ mt: 5, borderColor: "#fff" }}
          >
            {t("main.cancel")}
          </Button>
        </DialogActions>
      </FormControl>
    </Dialog>
  );
}

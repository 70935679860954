/* eslint-disable react-hooks/exhaustive-deps */
import {
  Link,
  Paper,
  Button,
  Dialog,
  TextField,
  PaperProps,
  Typography,
  DialogActions,
} from "@mui/material";
import "./addEditCustomerDialog.scss";
import Draggable from "react-draggable";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import React, { MouseEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useChannelIdContext } from "../../../context/ChannelIdContext";
import { useNavigate } from "react-router-dom";
import { RegionSiteData } from "../../../types/Customer";

const API_URL = process.env.REACT_APP_BACKEND_URL;
function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper
        {...props}
        style={{
          backgroundColor: "#34393f",
          color: "#D9D9D9",
          padding: "1rem",
        }}
      />
    </Draggable>
  );
}
export default function AddEditSiteDialog({
  isOpen,
  RegionId,
  setIsAdded,
  handleClose,
  selectedSiteId,
  setSelectedSiteId,
}: {
  isOpen: boolean;
  RegionId: any;
  selectedSiteId: number | null;
  handleClose: any | MouseEventHandler;
  setIsAdded: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedSiteId: React.Dispatch<React.SetStateAction<number | null>>;
}) {
  const { t } = useTranslation();
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  const [isDisable, setIsDisable] = useState<boolean>(false);
  const [addSite, setAddSite] = useState<{
    name: string;
  }>({
    name: "",
  });
  const [SiteData, setSiteData] = useState<RegionSiteData>();
  const { setIsTokenExpired, setIsTokenUpdated, IsTokenUpdated } =
    useChannelIdContext();
  const navigate = useNavigate();

  const AddNewSite = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (addSite) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token?.accessToken}`,
        },
        body: JSON.stringify({
          regionId: RegionId,
          name: addSite?.name,
          address1: "",
          address2: "",
          address3: "",
          country: "",
          zipcode: "",
          phoneNumber1: "",
          phoneNumber2: "",
          phoneNumber3: "",
          contactName: "",
          siteUniqueId: "",
          clientSecretKey: "",
        }),
      };
      try {
        fetch(`${API_URL}/Site`, requestOptions)
          .then((response) => {
            if (
              response.ok === false &&
              response.status === 401 &&
              token?.refreshToken !== ""
            ) {
              setIsTokenExpired(true);
            } else if (response.status === 401 && token?.refreshToken === "") {
              navigate("/");
              localStorage.removeItem("token");
              localStorage.clear();
            } else {
              setIsTokenUpdated(false);
              return response.json();
            }
          })
          .then((data) => {
            if (data?.success === true) {
              setAddSite({ name: "" });
              setIsAdded(true);
              handleClose();
              toast.success(t("main.New_Site_Added"));
            } else {
              toast.error(t("main.Failed_to_Save_Site_Data"));
            }
          });
      } catch (error) {
        toast.error(t("main.Failed_to_Save_Site_Data"));
      }
    }
  };
  const UpdateSite = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (addSite) {
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token?.accessToken}`,
        },
        body: JSON.stringify({
          id: selectedSiteId,
          regionId: RegionId,
          name: addSite?.name,
          address1: SiteData?.address1,
          address2: SiteData?.address2,
          address3: SiteData?.address3,
          country: SiteData?.country,
          zipcode: SiteData?.zipcode,
          phoneNumber1: SiteData?.phoneNumber1,
          phoneNumber2: SiteData?.phoneNumber2,
          phoneNumber3: SiteData?.phoneNumber3,
          contactName: SiteData?.contactName,
          siteUniqueId: SiteData?.siteUniqueId,
          clientSecretKey: SiteData?.clientSecretKey,
        }),
      };
      try {
        fetch(`${API_URL}/Site`, requestOptions)
          .then((response) => {
            if (
              response.ok === false &&
              response.status === 401 &&
              token?.refreshToken !== ""
            ) {
              setIsTokenExpired(true);
            } else if (response.status === 401 && token?.refreshToken === "") {
              navigate("/");
              localStorage.removeItem("token");
              localStorage.clear();
            } else {
              setIsTokenUpdated(false);
              return response.json();
            }
          })
          .then((data) => {
            if (data?.success === true) {
              setSelectedSiteId(null);
              setAddSite({ name: "" });
              setIsAdded(true);

              handleClose();
              toast.success(t("main.New_Site_Added"));
            } else {
              toast.error(t("main.Failed_to_Edit_Site_Data"));
            }
          });
      } catch (error) {
        toast.error(t("main.Failed_to_Edit_Site_Data"));
      }
    }
  };

  //useEffect Area
  useEffect(() => {
    const fetchSite = async () => {
      try {
        const requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token?.accessToken}`,
          },
        };
        fetch(`${API_URL}/Site/${selectedSiteId}`, requestOptions)
          .then((response) => {
            if (
              response.ok === false &&
              response.status === 401 &&
              token?.refreshToken !== ""
            ) {
              setIsTokenExpired(true);
            } else if (response.status === 401 && token?.refreshToken === "") {
              navigate("/");
              localStorage.removeItem("token");
              localStorage.clear();
            } else {
              setIsTokenUpdated(false);
              return response.json();
            }
          })
          .then((data) => {
            if (data?.success === true) {
              setSiteData(data?.data);
              setAddSite({ name: data?.data?.name });
            } else {
              toast.error(data?.message);
            }
          });
      } catch (error) {
        toast.error(t("main.Failed to fetch Site data"));
      }
    };
    selectedSiteId !== null && fetchSite();
  }, [selectedSiteId, IsTokenUpdated]);

  useEffect(() => {
    if (addSite?.name !== "") {
      setIsDisable(false);
    } else {
      setIsDisable(true);
    }
  }, [addSite?.name]);

  useEffect(() => {
    selectedSiteId === null && setAddSite({ name: "" });
  }, [selectedSiteId]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      maxWidth="sm"
      fullWidth
      className="addCustomerModelDialog"
    >
      <div className="dialogForm">
        <form>
          <Typography variant="h5" className="addModel-title">
            {selectedSiteId ? t("main.Edit_Site") : t("main.Add_New_Site")}
            <Link onClick={() => {
              handleClose()
              setAddSite({ name: "" });
            }} className="modelCloseBtn">
              <CloseIcon />
            </Link>
          </Typography>
          <div className="dialogForm-field addchanelFields">
            <Typography variant="h5" className="dialogForm-field-label">
              {t("main.site_name")}
            </Typography>
            <TextField
              required
              autoComplete="off"
              name="name"
              value={addSite ? addSite?.name : ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setAddSite({
                  name: e.target.value,
                })
              }
            />
          </div>
          <DialogActions sx={{ display: "flex" }}>
            <Button
              type="submit"
              size="medium"
              variant="contained"
              color="primary"
              sx={{ mt: 5 }}
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                if (selectedSiteId === null) {
                  AddNewSite(e);
                } else {
                  UpdateSite(e);
                }
              }}
              disabled={isDisable}
            >
              {selectedSiteId === null ? t("main.save") : t("main.update")}
            </Button>
            <Button
              size="medium"
              variant="outlined"
              color="primary"
              sx={{ mt: 5 }}
              onClick={handleClose}
            >
              {t("main.cancel")}
            </Button>
          </DialogActions>
        </form>
      </div>
    </Dialog>
  );
}

import {
  Link,
  Paper,
  Button,
  Dialog,
  PaperProps,
  Typography,
  DialogActions,
} from "@mui/material";
import Draggable from "react-draggable";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import { useChannelIdContext } from "../../../context/ChannelIdContext";
import React, { MouseEventHandler, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
const API_URL = process.env.REACT_APP_BACKEND_URL;
function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper
        {...props}
        style={{
          backgroundColor: "#34393f",
          color: "#D9D9D9",
          padding: "1rem",
        }}
      />
    </Draggable>
  );
}

export default function DeleteChannelScheduleDialog({
  isOpen,
  handleClose,
}: {
  isOpen: boolean;
  handleClose: any | MouseEventHandler;
}) {
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  const { t } = useTranslation();
  const {
    scheduleId,
    setisDeleted,
    isDeleted,
    setScheduleId,
    setIsTokenExpired,
    setIsTokenUpdated,
  } = useChannelIdContext();
  const navigate = useNavigate();
  const deleteSchedule = useCallback(
    async (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      const requestOptions = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token?.accessToken}`,
        },
      };
      fetch(`${API_URL}/Schedule/${scheduleId}`, requestOptions)
        .then((response) => {
          if (
            response.ok === false &&
            response.status === 401 &&
            token?.refreshToken !== ""
          ) {
            setIsTokenExpired(true);
          } else if (response.status === 401 && token?.refreshToken === "") {
            navigate("/");
            localStorage.removeItem("token");
            localStorage.clear();
          } else {
            setIsTokenUpdated(false);
            return response.json();
          }
        })
        .then((data) => {
          if (data?.success === true) {
            setisDeleted(true);
            toast.success(t("main.Schedule_Item_Deleted_Successfully"));
          } else {
            toast.error(data?.message);
          }
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [scheduleId, setisDeleted]
  );
  useEffect(() => {
    if (isDeleted === true) {
      handleClose();
      setScheduleId("");
    }
  }, [handleClose, isDeleted, setScheduleId]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={isOpen}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      className="deleteScheduleDialog"
      aria-labelledby="draggable-dialog-title"
    >
      <div className="dialogForm">
        <form>
          <Typography variant="h5" className="deleteModel-title">
            {t("main.Are_you_sure_to_delete_this_Channel_Schedule")}
            <Link onClick={handleClose} className="modelCloseBtn">
              <CloseIcon />
            </Link>
          </Typography>
          <DialogActions sx={{ display: "flex" }}>
            <Button
              type="submit"
              size="medium"
              variant="contained"
              color="primary"
              sx={{ mt: 5 }}
              onClick={deleteSchedule}
            >
              {t("main.Confirm")}
            </Button>
            <Button
              size="medium"
              variant="outlined"
              color="primary"
              sx={{ mt: 5 }}
              onClick={handleClose}
            >
              {t("main.cancel")}
            </Button>
          </DialogActions>
        </form>
      </div>
    </Dialog>
  );
}

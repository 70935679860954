/* eslint-disable react-hooks/exhaustive-deps */
import { Edit } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  MenuItem,
  Paper,
  PaperProps,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import { MouseEventHandler, useCallback, useEffect, useState } from "react";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ReactComponent as Add } from "../../../assets/images/add-squre.svg";
import { ReactComponent as Minus } from "../../../assets/images/minus-squreDark.svg";
import { useChannelIdContext } from "../../../context/ChannelIdContext";
import AddDropinsDialog from "./AddDropinsDialog";
const API_URL = process.env.REACT_APP_BACKEND_URL;
function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper
        {...props}
        style={{
          backgroundColor: "#34393f",
          color: "#D9D9D9",
          padding: "1rem",
        }}
      />
    </Draggable>
  );
}
interface Dropins {
  id: number;
  name: string;
  zone: {
    id: number;
    agcSetting: number;
    name: string;
    synchronizeSchedule: boolean;
    targetDBV: number;
  };
  siteId: number;
  customerId: number;
  channel: {
    id: number;
    name: string;
  };
  startingTime: number;
  endingTime: number;
}
enum PopUpEnum {
  None = 0,
  AddDropin = 1,
}
let token = JSON.parse(localStorage.getItem("token") ?? "{}");

const Table = ({
  siteId,
  rowSelectedId,
  setIsEdit,
  setPopUp,
  setSiteList,
  dropinsList,
  setRowSelectedId,
  selectedCustomerId,
  fetchDropinsFilteredList,
  setEditDropinsList,
}: {
  setPopUp: any;
  siteId: string;
  setIsEdit: any;
  dropinsList: any;
  setSiteList: any;
  setEditDropinsList: any;
  selectedSiteId: number;
  rowSelectedId: number | any;
  setRowSelectedId: number | any;
  fetchDropinsFilteredList: any;
  selectedCustomerId: string | any;
}) => {
  const { currentUser, IsTokenUpdated, setIsTokenUpdated, setIsTokenExpired } =
    useChannelIdContext();
  let navigate = useNavigate();
  const { t } = useTranslation();
  const getSiteById = useCallback(
    async (selectedCustomerId: string) => {
      fetch(`${API_URL}/Site/Get/` + selectedCustomerId, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token?.accessToken}`,
        },
      })
        .then((response) => {
          if (
            response.ok === false &&
            response.status === 401 &&
            token?.refreshToken !== ""
          ) {
            setIsTokenExpired(true);
          } else if (response.status === 401 && token?.refreshToken === "") {
            navigate("/");
            localStorage.removeItem("token");
            localStorage.clear();
          } else {
            setIsTokenUpdated(false);
            return response.json();
          }
        })
        .then((data) => {
          if (data.success === true) {
            setSiteList(data?.data);
          } else {
            setSiteList([]);
          }
        });
    },
    [setSiteList, IsTokenUpdated]
  );

  const columns: MRT_ColumnDef<Dropins>[] = [
    {
      accessorKey: "name",
      header: t("main.name"),
    },
    {
      accessorKey: "zone.name",
      header: t("main.zone"),
    },
    {
      accessorKey: "channel.name",
      header: t("main.channel"),
    },
    {
      accessorKey: "startingTime",
      header: "Start time",
    },
    {
      accessorKey: "endingTime",
      header: "End time",
    },
  ];
  useEffect(() => {
    if (siteId !== "") {
      fetchDropinsFilteredList();
    }
  }, [fetchDropinsFilteredList]);

  useEffect(() => {
    if (selectedCustomerId) {
      getSiteById(selectedCustomerId);
    }
  }, [getSiteById, selectedCustomerId]);

  // const isChannelSelected = (rowData: Channel) => {
  //   const isSubscribed = subscribeChannel?.some(
  //     (attr) => attr.id === rowData.id
  //   );
  //   return {
  //     backgroundColor: isSubscribed ? "#42876A" : "",
  //     color: isSubscribed ? "#fff" : "",
  //   };
  // };

  return (
    <MaterialReactTable
      columns={columns}
      data={dropinsList}
      enableGrouping={true}
      enableSorting={false}
      enablePagination={false}
      enableColumnActions={true}
      enableColumnOrdering={true}
      enableColumnFilters={false}
      enableRowActions={true}
      enableBottomToolbar={false}
      enableColumnResizing
      columnResizeMode="onChange"
      enableTopToolbar
      defaultColumn={{
        maxSize: 400,
        minSize: 80,
        size: 200,
      }}
      renderRowActions={({ row }) => (
        <Box>
          <IconButton
            onClick={() => {
              setIsEdit(true);
              setPopUp(PopUpEnum.AddDropin);
              setEditDropinsList(row.original);
            }}
            disabled={!currentUser.editDropIns}
            aria-label="more"
            id="long-button"
            aria-haspopup="true"
            style={{ color: "#000" }}
          >
            <Edit />
          </IconButton>
        </Box>
      )}
      enableToolbarInternalActions={false}
      muiTableBodyRowProps={({ row }: any) => ({
        onClick: () => {
          setRowSelectedId(row?.original?.id);
        },
        selected: rowSelectedId === row.id,
        sx: {
          "$selected &": {
            cursor: "pointer",
          },
        },
      })}
      muiTableBodyCellProps={({ row }) => ({
        style: {
          backgroundColor:
            row.original.id === rowSelectedId ? "#42876A" : "#FFF",
        },
      })}
    />
  );
};

const ManageDropinsDialog = ({
  isOpen,
  handleClose,
}: {
  isOpen: boolean;
  handleClose: any | MouseEventHandler;
}) => {
  const {
    selectedCustomer,
    selectedSite,
    setIsTokenExpired,
    setIsTokenUpdated,
    IsTokenUpdated,
  } = useChannelIdContext();
  const theme = useTheme();
  const { t } = useTranslation();
  let navigate = useNavigate();
  const [selectedSiteId] = useState<number>(0);
  const { currentUser } = useChannelIdContext();
  const [siteId, setSiteId] = useState<string>("");
  const [popUp, setPopUp] = useState(PopUpEnum.None);
  const [siteList, setSiteList] = useState<any[]>([]);
  const [rowSelectedId, setRowSelectedId] = useState<any>({});
  const [customerList, setCustomerList] = useState<any[]>([]);
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [dropinsList, setDropinsList] = useState<Dropins[]>([]);
  const [editDropInsList, setEditDropinsList] = useState<Dropins[]>([]);
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>("");

  const fetchDropinsFilteredList = useCallback(async () => {
    if (siteId !== "") {
      fetch(`${API_URL}/DropIn/GetbyFilters?siteId=${siteId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token?.accessToken}`,
        },
      })
        .then((response) => {
          console.log(response, "response");
          if (
            response.ok === false &&
            response.status === 401 &&
            token?.refreshToken !== ""
          ) {
            setIsTokenExpired(true);
          } else if (response.status === 401 && token?.refreshToken === "") {
            navigate("/");
            localStorage.removeItem("token");
            localStorage.clear();
          } else {
            setIsTokenUpdated(false);
            return response.json();
          }
        })
        .then((data) => {
          if (data.success === true) {
            setDropinsList(data?.data);
          } else {
            setDropinsList([]);
          }
        });
    }
  }, [siteId, IsTokenUpdated]);

  const handleDelete = async () => {
    fetch(`${API_URL}/DropIn/${rowSelectedId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    })
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data?.success === true) {
          fetchDropinsFilteredList();
          toast.success(t("main.Data_Deleted_Successfully"));
        } else {
          toast.error(t("main.Please_Select_Any_Dropin_to_Remove"));
        }
      });
  };

  const handleChangeSite = (event: SelectChangeEvent) => {
    const {
      target: { value },
    } = event;
    setSiteId(value);
  };

  const getSitesId = useCallback(async (id: number) => {
    const response = await fetch(`${API_URL}/Site/Get/` + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    });
    const data = await response.json();
    return data?.data;
  }, []);

  const fetchCustomerList = useCallback(async () => {
    const response = await fetch(`${API_URL}/Customer/GetAll`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    });
    const data = await response.json();
    let temp = [];
    for (let index = 0; index < data?.data?.length; index++) {
      const element = data.data[index];
      let customer = await getSitesId(element?.id);
      if (customer?.length > 0) {
        temp?.push({ name: element?.name, value: element?.id });
      }
    }
    setCustomerList(temp);
  }, [getSitesId]);
  useEffect(() => {
    if (isOpen === true) {
      fetchCustomerList();
    }
  }, [fetchCustomerList, isOpen]);

  useEffect(() => {
    if (isOpen === true && selectedCustomer !== null && selectedSite !== null) {
      setSelectedCustomerId(selectedCustomer);
      setSiteId(selectedSite);
    }
  }, [isOpen, selectedCustomer]);
  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        handleClose();
        setSiteId("");
        setSiteList([]);
        setDropinsList([]);
        setSelectedCustomerId("");
      }}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      maxWidth="md"
      fullWidth
      className="addModelDialog"
    >
      <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
        <Typography sx={{ fontWeight: 700 }}>
          {t("main.DropIns")}
          <Link
            className="modelCloseBtn"
            onClick={() => {
              handleClose();
              setSiteId("");
              setSiteList([]);
              setDropinsList([]);
              setSelectedCustomerId("");
            }}
          >
            <CloseIcon />
          </Link>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Stack
          direction="row"
          justifyContent="flex-start"
          className="modal-top-dropin-actions"
        >
          <IconButton
            aria-label="Create folder"
            sx={{
              display: "flex",
              flexDirection: "column",
              p: 1,
              pt: 4,
              aspectRatio: "1/1",
              width: "10rem",
              color: theme.palette.mode === "dark" ? "#fff" : "#000",
            }}
            onClick={() => {
              setPopUp(PopUpEnum.AddDropin);
            }}
          >
            <Add />
            <Typography
              variant="subtitle1"
              gutterBottom
              sx={{
                pt: 1,
                color: theme.palette.mode === "light" ? "#cfd8dc" : "#263238",
              }}
            >
              {t("main.new_dropin")}
            </Typography>
          </IconButton>
          <IconButton
            aria-label="Create folder"
            sx={{
              display: "flex",
              flexDirection: "column",
              p: 1,
              pt: 4,
              aspectRatio: "1/1",
              width: "10rem",
              color: theme.palette.mode === "dark" ? "#cfd8dc" : "#263238",
            }}
            onClick={() =>
              currentUser?.editDropIns
                ? handleDelete()
                : toast.error(
                    t("main.User_doesn't_have_authority_to_edit_dropins")
                  )
            }
          >
            <Minus />
            <Typography
              variant="subtitle1"
              gutterBottom
              sx={{
                pt: 1,
                color: theme.palette.mode === "light" ? "#cfd8dc" : "#263238",
              }}
            >
              {t("main.remove_dropin")}
            </Typography>
          </IconButton>
        </Stack>
        <div
          key="manageDropin"
          className="content-filter-inner"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Select
            displayEmpty
            value={
              customerList?.some(
                (item: any) => item?.value === parseInt(selectedCustomerId)
              )
                ? selectedCustomerId
                : ""
            }
            renderValue={(value) => {
              if (value) {
                return customerList?.find(
                  (item: any) => item?.value === parseInt(selectedCustomerId)
                )?.name;
              } else {
                return <Typography>{t("main.customer")}</Typography>;
              }
            }}
            onChange={(event: SelectChangeEvent) => {
              setSelectedCustomerId(event.target.value);
              setSiteId("");
              setDropinsList([]);
            }}
          >
            {customerList?.length === 0 ? (
              <MenuItem
                style={{
                  color: theme.palette.mode === "dark" ? "#000" : "#000",
                }}
              >
                {t("main.No_Customer")}
              </MenuItem>
            ) : (
              customerList?.map((item: any) => (
                <MenuItem
                  value={item?.value}
                  key={item?.value}
                  style={{
                    color: theme.palette.mode === "dark" ? "#000" : "#000",
                  }}
                >
                  {item?.name}
                </MenuItem>
              ))
            )}
          </Select>
          <Select
            displayEmpty
            placeholder="Sites"
            value={siteId ? siteId : ""}
            renderValue={(value) => {
              if (value) {
                return siteList?.find(
                  (item: any) => item?.id === parseInt(siteId)
                )?.name;
              } else {
                return <Typography>{t("main.Site")}</Typography>;
              }
            }}
            onChange={handleChangeSite}
          >
            {siteList?.length === 0 ? (
              <MenuItem
                key="customer"
                style={{
                  color: theme.palette.mode === "dark" ? "#000" : "#000",
                }}
              >
                {t("main.please_select_customer")}
              </MenuItem>
            ) : (
              siteList?.map((item: any) => (
                <MenuItem
                  key={item?.id}
                  value={item?.id}
                  style={{
                    color: theme.palette.mode === "dark" ? "#000" : "#000",
                  }}
                >
                  {item?.name}
                </MenuItem>
              ))
            )}
          </Select>
        </div>
        <Paper elevation={4} sx={{ p: "1px", mt: 3 }}>
          <Table
            setPopUp={setPopUp}
            siteId={siteId}
            dropinsList={dropinsList}
            setIsEdit={setIsEdit}
            setSiteList={setSiteList}
            setEditDropinsList={setEditDropinsList}
            rowSelectedId={rowSelectedId}
            selectedSiteId={selectedSiteId}
            setRowSelectedId={setRowSelectedId}
            selectedCustomerId={selectedCustomerId}
            fetchDropinsFilteredList={fetchDropinsFilteredList}
          />
        </Paper>
      </DialogContent>
      <AddDropinsDialog
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        editDropInsList={editDropInsList}
        isOpen={popUp === PopUpEnum.AddDropin}
        handleClose={setPopUp}
        fetchDropinsFilteredList={fetchDropinsFilteredList}
      />
    </Dialog>
  );
};

export default ManageDropinsDialog;

import { toast } from "react-toastify";
import { ChannelData } from "../../types/Channel";
import SwipeableViews from "react-swipeable-views";
import React, { SyntheticEvent, useState } from "react";
import { Grid, useTheme, Tab, Box, Tabs } from "@mui/material";
import ChannelCharacteristic from "../Channel/ChannelCharacteristic/ChannelCharacteristic";
import { useChannelIdContext } from "../../context/ChannelIdContext";
import SubscribedSites from "../Channel/SubscribedSites/SubscribedSites";
import ChannelListSidebar from "../../layouts/ChannelListSidebar/ChannelListSidebar";
import ChannelListSettings from "../Channel/ChannelListSettings/ChannelListSettings";
import ChannelListSongSettings from "../Channel/ChannelListSongSettings/ChannelListSongSettings";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import './ManageCustomerTabs.scss';
const API_URL = process.env.REACT_APP_BACKEND_URL;
const ChnnelEditorTabs: React.FC = () => {
  const { channelId, setIsTokenExpired, setIsTokenUpdated } =
    useChannelIdContext();
  const { t } = useTranslation();
  const navigate = useNavigate();
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  let theme = useTheme();
  const [tab, setTab] = useState(0);
  const [isUpdated, setIsUpdated] = useState<boolean>(true);
  const [attributeId, setAttributeId] = useState<number[]>([]);
  const [channelData, setChannelData] = useState<ChannelData>({
    id: 0,
    name: "string",
    attribute1Amount: 0,
    attribute2Amount: 0,
    attribute3Amount: 0,
    attribute4Amount: 0,
    attribute1Breakable: false,
    attribute2Breakable: false,
    attribute3Breakable: false,
    attribute4Breakable: false,
    seasonalRestrictionBreakable: false,
    dayRestrictionBreakable: false,
    timeRestrictionBreakable: false,
    lastPlaySongRuleOn: false,
    lastPlaySongRuleDays: 0,
    lastPlaySongRuleMinutes: 0,
    yesterdayArtistRuleOn: false,
    yesterdayArtistRuleMinutes: 0,
    yesterdaySongRuleOn: false,
    yesterdaySongRuleMinutes: 0,
    sites: [],
  });

  const UpdatePlaylistSetting = async () => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
      body: JSON.stringify({
        id: channelData?.id,
        name: channelData?.name,
        attribute1Amount: channelData?.attribute1Amount,
        attribute2Amount: channelData?.attribute2Amount,
        attribute3Amount: channelData?.attribute3Amount,
        attribute4Amount: channelData?.attribute4Amount,
        attribute1Breakable: channelData?.attribute1Breakable,
        attribute2Breakable: channelData?.attribute2Breakable,
        attribute3Breakable: channelData?.attribute3Breakable,
        attribute4Breakable: channelData?.attribute4Breakable,
        seasonalRestrictionBreakable: channelData?.seasonalRestrictionBreakable,
        dayRestrictionBreakable: channelData?.dayRestrictionBreakable,
        timeRestrictionBreakable: channelData?.timeRestrictionBreakable,
        lastPlaySongRuleOn: channelData?.lastPlaySongRuleOn,
        lastPlaySongRuleDays: channelData?.lastPlaySongRuleDays,
        lastPlaySongRuleMinutes: channelData?.lastPlaySongRuleMinutes,
        yesterdayArtistRuleOn: channelData?.yesterdayArtistRuleOn,
        yesterdayArtistRuleMinutes: channelData?.yesterdayArtistRuleMinutes,
        yesterdaySongRuleOn: channelData?.yesterdaySongRuleOn,
        yesterdaySongRuleMinutes: channelData?.yesterdaySongRuleMinutes,
      }),
    };
    fetch(`${API_URL}/Channel`, requestOptions)
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data?.success === true) {
          toast.success(t("main.Playlist_Setting_Updated_Successfully"));
          setIsUpdated(true);
        } else {
          toast.error(data?.message);
          setIsUpdated(false);
        }
      });
  };

  //  handle channel attribute save
  const handleAddChaneelAttributes = async (e: SyntheticEvent) => {
    e.preventDefault();
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
      body: JSON.stringify({
        channelId: channelId,
        attributeId: attributeId,
      }),
    };
    fetch(`${API_URL}/Channel/Attribute`, requestOptions)
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data?.success) setIsUpdated(true);
      });
  };
  return (
    <Grid className="h-100" container spacing={0}>
      <Grid item xs={12} md={4} xl={2.5}>
        <ChannelListSidebar />
      </Grid>
      <Grid item xs={12} md={8} xl={9.5}>
        <Box>
          <Box
            sx={{
              display: "flex",
              bgcolor: "background.paper",
            }}
          >
            <Tabs className="tabOverflow"
              value={tab}
              onChange={(e, v) => {
                if (v === 4) {
                  UpdatePlaylistSetting();
                  handleAddChaneelAttributes(e);
                } else {
                  setTab(v);
                }
              }}
              aria-label="playlist tabs"
              sx={{
                width: "100%",
                minHeight: "50px",
                backgroundColor:
                  theme.palette.mode === "dark" ? "#202020" : "#34393f",
                "& .listSaveBtn": {
                  // width: "100%",
                  // textAlign: "right",
                  // paddingRight: "5%",
                },
                "& button": {
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "21px",
                  color: "#FFFFFF",
                  borderRadius: 0,
                  opacity: 1,
                  background: "transparent",
                },
                "& .MuiButtonBase-root.MuiTab-root": {
                  borderRight: "1px solid #CFCFCF",
                  // width: "215px",
                  minHeight: "50px",
                  // flex: "268px 0 0",
                },
                "& button.Mui-selected": {
                  color: "#FFFFFF",
                  background: "#3c735c",
                },
                "& .Mui-selected": {
                  background:
                    theme.palette.mode === "dark" ? "#303030" : "#34393f",
                  borderRight: "1px solid #9E9E9E",
                  borderLeft: "1px solid #9E9E9E",
                },
              }}
              variant="fullWidth"
            >
              <Tab
                label={t("main.channel_configuration")}
                sx={{
                  textTransform: "none",
                  color: "#fff",
                  fontSize: "17px",
                  // maxWidth: "700px",
                }}
              />
              <Tab
                label={t("main.channel_characterstics")}
                sx={{ textTransform: "none" }}
              />
              <Tab
                label={t("main.channel_song_list")}
                sx={{
                  textTransform: "none",
                  color: "#fff",
                }}
              />
              <Tab
                label={t("main.subscribed_sites")}
                sx={{ textTransform: "none" }}
              />
              <Tab
                label={t("main.save")}
                onChange={(e) => {
                  e.stopPropagation();
                }}
                sx={{
                  backgroundColor: "#51AA75 !important",
                  color: "#FFFFFF",
                  // width: "80px",
                  height: "66px",
                }}
              />
            </Tabs>
          </Box>
          <SwipeableViews
            axis={"x"}
            index={tab}
            onChangeIndex={(v) => setTab(v)}
          >
            <div
              role="tabpanel"
              hidden={tab !== 0}
              id={`vertical-tabpanel-${0}`}
              aria-labelledby={`vertical-tab-${0}`}
            >
              {tab === 0 && (
                <ChannelListSettings
                  setChannelData={setChannelData}
                  channelData={channelData}
                  isUpdated={isUpdated}
                />
              )}
            </div>
            <div
              role="tabpanel"
              hidden={tab !== 1}
              id={`vertical-tabpanel-${1}`}
              aria-labelledby={`vertical-tab-${1}`}
            >
              {tab === 1 && (
                <ChannelCharacteristic
                  setAttributeId={setAttributeId}
                  setChannelData={setChannelData}
                  channelData={channelData}
                />
              )}
            </div>
            <div
              role="tabpanel"
              hidden={tab !== 2}
              id={`vertical-tabpanel-${2}`}
              aria-labelledby={`vertical-tab-${2}`}
            >
              {tab === 2 && (
                <div className="tabTablePage">
                  <ChannelListSongSettings />
                </div>
              )}
            </div>
            <div
              role="tabpanel"
              hidden={tab !== 3}
              id={`vertical-tabpanel-${3}`}
              aria-labelledby={`vertical-tab-${3}`}
            >
              {tab === 3 && (
                <div className="tabTablePage">
                  <SubscribedSites />
                </div>
              )}
            </div>
          </SwipeableViews>
        </Box>
      </Grid>
    </Grid>
  );
};
export default ChnnelEditorTabs;

import {
  Box,
  List,
  Theme,
  styled,
  ListItem,
  useTheme,
  CSSObject,
  IconButton,
  CssBaseline,
  ListItemIcon,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import "./sidebar.scss";
import * as React from "react";
import DrawerHeader from "../DrawerHeader";
import TopPanelTitle from "./TopPanelTitle";
import { useState } from "react";
import MuiDrawer from "@mui/material/Drawer";
import { useLocation, useNavigate } from "react-router-dom";
import ThemeButton from "../../component/ThemeButton";
import ThemeTogglerButton from "../../context/ThemeContext";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import OverrideDialog from "../../component/Dialogs/OverrideDialog";
import GroupsIcon from "@mui/icons-material/Groups";
import { ReactComponent as Clock } from "../../assets/images/clock-history.svg";
import { ReactComponent as Editor } from "../../assets/images/pencil-square.svg";
import { ReactComponent as MusicNote } from "../../assets/images/music-note-list.svg";
import { ReactComponent as Announcement } from "../../assets/images/Announcement.svg";
import { useTranslation } from "react-i18next";
const drawerWidth = 255;
const menuItemStyle = {
  pt: 2,
  pb: 2,
  "& span, & svg": {
    fontSize: "1.2rem",
    color: "#e8ebe6",
  },
};

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme?.transitions?.create("width", {
    easing: theme?.transitions?.easing?.sharp,
    duration: theme?.transitions?.duration?.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme?.transitions?.create("width", {
    easing: theme?.transitions?.easing?.sharp,
    duration: theme?.transitions?.duration?.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme?.spacing(7)} + 16px)`,
  [theme?.breakpoints.up("sm")]: {
    width: `calc(${theme?.spacing(8)} + 16px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniSidePanel({
  children,
  headerText,
}: {
  children: JSX.Element;
  headerText: string;
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [overrideOpen, setOverrideOpen] = useState(false);
  let token = localStorage.getItem("token") ? true : false;
  let pathname = window.location.pathname;
  const handleDrawerOpen = () => {
    setOpen(!open);
  };
  return (
    <Box sx={{ display: "flex" }}>
      <TopPanelTitle headerText={headerText} open={open}/>
      <CssBaseline />
      {token === true && pathname !== "/" ? (
        <Drawer
          className={open ? "sidebar" : "sidebar sidebar--active"}
          variant="permanent"
          open={open}
          sx={{
            "& .MuiDrawer-paper": {
              backgroundColor:
                theme.palette.mode === "dark" ? "#202020" : "#34393f",
              color: "darkgray",
            },
          }}
        >
          <img
            src={`/cooperation-${
              theme.palette.mode === "dark" ? "dark" : "light"
            }.png`}
            alt="cooperation"
            style={{
              padding: "0",
              width: open ? "120px" : "70px",
              height: open ? "80px" : "65px",
              alignSelf: "center",
              marginBottom: open ? "0" : "15px",
            }}
          />

          <DrawerHeader
            style={{ justifyContent: open ? "flex-end" : "center" }}
          >
            <IconButton
              onClick={handleDrawerOpen}
              disabled={!token}
              sx={{ color: "#e8ebe6", background: "#3c434b" }}
            >
              {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </DrawerHeader>
          <Box
            style={{ minHeight: "calc(100vh - 190px)", gap: "10px" }}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-between"}
          >
            <List>
              <ListItem disablePadding>
                <ListItemButton
                  className={
                    location.pathname.includes("manage-customers")
                      ? "menuItem-Link-select"
                      : "menuItem-Link"
                  }
                  onClick={() => navigate("/manage-customers")}
                  sx={menuItemStyle}
                >
                  <ListItemIcon
                    style={{
                      height: open ? "27px" : "25px",
                      width: open ? "35px" : "25px",
                      marginRight: "6px",
                    }}
                  >
                    <GroupsIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("main.manage_customer")} />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  className={
                    location.pathname.includes("music")
                      ? "menuItem-Link-select"
                      : "menuItem-Link"
                  }
                  onClick={() => navigate("/music-library")}
                  sx={menuItemStyle}
                >
                  <ListItemIcon
                    style={{
                      height: open ? "27px" : "25px",
                      width: open ? "35px" : "25px",
                      marginRight: "14px",
                    }}
                  >
                    <MusicNote />
                  </ListItemIcon>
                  <ListItemText primary={t("main.music_library")} />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  className={
                    location.pathname.includes("content")
                      ? "menuItem-Link-select"
                      : "menuItem-Link"
                  }
                  onClick={() => navigate("/content-library")}
                  sx={menuItemStyle}
                >
                  <ListItemIcon
                    style={{
                      height: open ? "27px" : "25px",
                      width: open ? "35px" : "25px",
                      marginRight: "14px",
                    }}
                  >
                    <Announcement />
                  </ListItemIcon>
                  <ListItemText primary={t("main.content_library")} />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  className={
                    location.pathname.includes("channel-editor")
                      ? "menuItem-Link-select"
                      : "menuItem-Link"
                  }
                  onClick={() => navigate("/channel-editor")}
                  sx={menuItemStyle}
                >
                  <ListItemIcon
                    style={{
                      height: open ? "27px" : "25px",
                      width: open ? "35px" : "25px",
                      marginRight: "14px",
                    }}
                  >
                    <Editor height={"27px"} width="35px" />
                  </ListItemIcon>
                  <ListItemText primary={t("main.channel_editor")} />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  className={
                    location.pathname.includes("channel-scheduler")
                      ? "menuItem-Link-select"
                      : "menuItem-Link"
                  }
                  onClick={() => navigate("/channel-scheduler")}
                  sx={menuItemStyle}
                >
                  <ListItemIcon
                    style={{
                      height: open ? "27px" : "25px",
                      width: open ? "35px" : "25px",
                      marginRight: "14px",
                    }}
                  >
                    <Clock height={"27px"} width="35px" />
                  </ListItemIcon>
                  <ListItemText primary={t("main.channel_scheduler")} />
                </ListItemButton>
              </ListItem>
              <ListItem
                disablePadding
                sx={{ justifyContent: "center" }}
              ></ListItem>
            </List>
            <Box className="themeSwitch">
              <ThemeTogglerButton>
                <ThemeButton />
              </ThemeTogglerButton>
            </Box>
          </Box>
        </Drawer>
      ) : null}
      <Box
        component="main"
        style={{ padding: "0" }}
        width={open ? "calc(100% - 255px)" : "calc(100% - 80px)"}
        sx={{ flexGrow: 1, p: 3 }}
      >
        <DrawerHeader />
        {children}
      </Box>
      <OverrideDialog
        isOpen={overrideOpen}
        handleClose={() => setOverrideOpen(false)}
      ></OverrideDialog>
    </Box>
  );
}

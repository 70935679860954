import {
  Paper,
  Link,
  Dialog,
  Button,
  Typography,
  PaperProps,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import "./DialogsMain.scss";
import Draggable from "react-draggable";
import React, { MouseEventHandler } from "react";
import { Close, SettingsOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper
        {...props}
        style={{
          backgroundColor: "#34393f",
          color: "#D9D9D9",
          padding: "1rem",
        }}
      />
    </Draggable>
  );
}

export default function UserLoginRolesDialog({
  isOpen,
  handleClose,
}: {
  isOpen: boolean;
  handleClose: MouseEventHandler;
}) {
  const { t } = useTranslation();
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      maxWidth="xs"
      fullWidth
      className="addNewwheelDialog user-role-dialog"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            height: "325px",
            width: "100%",
          },
        },
        "& .css-4ygzoj-MuiPaper-root-MuiDialog-paper": {
          minWidth: "530px !important",
        },
      }}
    >
      <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
        <Typography sx={{ fontWeight: 700, fontSize: "25px" }}>
          {t("main.Select_Profile")}
          <Link className="modelCloseBtn" onClick={handleClose}>
            <Close />
          </Link>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Paper
          elevation={3}
          sx={{ p: "1px", mt: 3 }}
          className="user-roles-wrapper"
        >
          <Button
            variant="contained"
            fullWidth={true}
            type="submit"
            className="btn-user-role"
          >
            EBH Dev
          </Button>
          <Button
            variant="contained"
            fullWidth={true}
            type="submit"
            className="btn-user-role"
          >
            User Role 1
          </Button>
        </Paper>
      </DialogContent>
      <DialogActions
        className="user-role-actions"
        sx={{ display: "flex", justifyContent: "space-around" }}
      >
        <Button
          className="settings-btn"
          onClick={handleClose}
          size="medium"
          variant="outlined"
          color="secondary"
          sx={{ mt: 5 }}
        >
          <SettingsOutlined />
        </Button>
        <Button
          size="medium"
          variant="contained"
          color="primary"
          sx={{ mt: 5 }}
        >
          {t("main.userRole_Choose")}
        </Button>
        <Button
          onClick={handleClose}
          size="medium"
          variant="outlined"
          color="secondary"
          sx={{ mt: 5 }}
        >
          {t("main.cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import { toast } from "react-toastify";
import SwipeableViews from "react-swipeable-views";
import CustomerSite from "../Customer/CustomerSite";
import CustomerViewSongs from "../Customer/CustomerViewSongs";
import { Region, RegionSiteData } from "../../types/Customer";
import React, { useState, useEffect, useCallback } from "react";
import CustomerAgencyReport from "../Customer/CustomerAgencyReport";
import { useChannelIdContext } from "../../context/ChannelIdContext";
import CustomerExactTimePlay from "../Customer/CustomerExactTimePlay";
import { Box, Tab, Tabs, Grid, useTheme, Button } from "@mui/material";
import CustomerPlayedHistoryTable from "../Tables/CustomerPlayedSongsTable";
import CustomerSiteSubscription from "../Customer/CustomerSiteSubscription";
import CustomerListSidebar from "../../layouts/CustomerListSidebar/CustomerListSidebar";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import './ManageCustomerTabs.scss';


const API_URL = process.env.REACT_APP_BACKEND_URL;
const ManageCustomerTabs: React.FC = () => {
  const { t } = useTranslation();
  let theme = useTheme();
  const { SiteId, setIsTokenExpired, setIsTokenUpdated, isAdded, setIsAdded } =
    useChannelIdContext();
    const navigate = useNavigate();
  const [tab, setTab] = useState<number>(0);
  const [customerId, setCustId] = useState<number>(0);
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  const [isSiteSelected, setIsSiteSelected] = useState<boolean>(false);
  const [customerSiteData, setCustomerSiteData] = useState<RegionSiteData>({
    id: 0,
    name: "",
    regionId: 0,
    address1: "",
    address2: "",
    address3: "",
    country: "",
    zipcode: "",
    phoneNumber1: "",
    phoneNumber2: "",
    phoneNumber3: "",
    contactName: "",
    siteUniqueId: "",
    clientSecretKey: "",
  });
  const [regionByCustomer, setRegionByCustomer] = useState<Region[]>([]);
  const UpdateCustomerSiteData = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
      body: JSON.stringify(customerSiteData),
    };
    fetch(`${API_URL}/Site`, requestOptions)
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data?.success === true) {
          toast.success(t("main.Site_Update_Successfully"));
          setIsUpdated(true);
        } else {
          toast.error(data?.message);
          setIsUpdated(false);
        }
      });
  };

  const GetSiteDataById = useCallback(async () => {
    fetch(`${API_URL}/Site/${SiteId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    })
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
          if (data?.success === true) {
            setIsUpdated(false);
            setCustomerSiteData({
              id: data?.data?.id,
              name: data?.data?.name,
              regionId: data?.data?.region?.id,
              address1: data?.data?.address1,
              address2: data?.data?.address2,
              address3: data?.data?.address3,
              country: data?.data?.country,
              zipcode: data?.data?.zipcode,
              phoneNumber1: data?.data?.phoneNumber1,
              phoneNumber2: data?.data?.phoneNumber2,
              phoneNumber3: data?.data?.phoneNumber3,
              contactName: data?.data?.contactName,
              siteUniqueId: data?.data?.siteUniqueId,
              clientSecretKey: data?.data?.clientSecretKey,
            });
        
      }     });
    
  }, [SiteId]);

  useEffect(() => {
    if (SiteId !== 0) {
      GetSiteDataById();
    }
    if (SiteId === 0) {
      setCustomerSiteData({
        id: 0,
        name: "",
        regionId: 0,
        address1: "",
        address2: "",
        address3: "",
        country: "",
        zipcode: "",
        phoneNumber1: "",
        phoneNumber2: "",
        phoneNumber3: "",
        contactName: "",
        siteUniqueId: "",
        clientSecretKey: "",
      });
    }
  }, [GetSiteDataById, SiteId, isUpdated]);

  useEffect(() => {
    const GetRegionByCustomer = async () => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token?.accessToken}`,
        },
      };
      const response = await fetch(
        `${API_URL}/Customer/${customerId}`,
        requestOptions
      );
      const data = await response.json();
      if (data?.success === true) {
        setIsAdded(false);
        setRegionByCustomer(data?.data?.regions);
      }
    };
    customerId !== 0 && GetRegionByCustomer();
  }, [customerId, isUpdated, isAdded]);

  return (
    <Grid className="h-100" container spacing={0}>
      <Grid className="sidebarLeft" item>
        <CustomerListSidebar
          setIsSiteSelected={setIsSiteSelected}
          isUpdated={isUpdated}
          setIsUpdated={setIsUpdated}
          setCustId={setCustId}
        />
      </Grid>
      <Grid className="MainPartRight" item>
        <Box>
          <Box
            sx={{
              bgcolor: "background.paper",
            }}
          >
            <Tabs
              value={tab}
              onChange={(e, v) => setTab(v)}
              aria-label="playlist tabs"
              className="CustomerTabsTop"
              sx={{
                width: "100%",
                minHeight: "50px",
                backgroundColor:
                  theme.palette.mode === "dark" ? "#202020" : "#34393f",
                "& .listSaveBtn": {
                },
                "& button": {
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "21px",
                  color: "#FFFFFF",
                  borderRadius: 0,
                  opacity: 1,
                  background: "transparent",
                },
                "& .MuiButtonBase-root.MuiTab-root": {
                  borderRight: "1px solid #CFCFCF",
                  minHeight: "50px",
                  flex: "245px 0 0",
                },
                "& button.Mui-selected": {
                  color: "#FFFFFF",
                  background: "#42876A",
                },
                "& .Mui-selected": {
                  background:
                    theme.palette.mode === "dark" ? "#303030" : "#34393f",
                  borderRight: "1px solid #9E9E9E",
                  borderLeft: "1px solid #9E9E9E",
                },
              }}
              variant="fullWidth"
            >
              <Tab
                label={t("main.site")}
                sx={{
                  textTransform: "none",
                  color: "#fff",
                }}
              />
              <Tab
                label={t("main.site_subscriptions")}
                sx={{
                  textTransform: "none",
                }}
              />
              <Tab
                label={t("main.view_songs")}
                sx={{
                  textTransform: "none",
                  color: "#fff",
                }}
              />
              <Tab
                label={t("main.played_songs")}
                sx={{ textTransform: "none" }}
              />
              <Tab
                label={t("main.exact_time_play")}
                sx={{
                  textTransform: "none",
                  color: "#fff",
                }}
              />
              <Tab
                label={t("main.agency_report")}
                sx={{
                  textTransform: "none",
                  color: "#fff",
                }}
              />              
            </Tabs>
          </Box>
          <SwipeableViews
            axis={"x"}
            index={tab}
            onChangeIndex={(v) => setTab(v)}
          >
            <div
              role="tabpanel"
              hidden={tab !== 0}
              id={`vertical-tabpanel-${0}`}
              aria-labelledby={`vertical-tab-${0}`}
            >
              {tab === 0 && (
                <div
                  className={`${isSiteSelected === false ? "disableCustomerSite" : ""
                    }`}
                >
                  <CustomerSite
                    regionByCustomer={regionByCustomer}
                    customerSiteData={customerSiteData}
                    setCustomerSiteData={setCustomerSiteData}
                  />
                </div>
              )}
            </div>
            <div
              role="tabpanel"
              hidden={tab !== 1}
              id={`vertical-tabpanel-${1}`}
              aria-labelledby={`vertical-tab-${1}`}
            >
              {tab === 1 && <CustomerSiteSubscription />}
            </div>
            <div
              role="tabpanel"
              hidden={tab !== 2}
              id={`vertical-tabpanel-${2}`}
              aria-labelledby={`vertical-tab-${2}`}
            >
              {tab === 2 && <CustomerViewSongs />}
            </div>
            <div
              role="tabpanel"
              hidden={tab !== 3}
              id={`vertical-tabpanel-${3}`}
              aria-labelledby={`vertical-tab-${3}`}
            >
              {tab === 3 && <CustomerPlayedHistoryTable />}
            </div>
            <div
              role="tabpanel"
              hidden={tab !== 4}
              id={`vertical-tabpanel-${4}`}
              aria-labelledby={`vertical-tab-${4}`}
            >
              {tab === 4 && <CustomerExactTimePlay />}
            </div>
            <div
              role="tabpanel"
              hidden={tab !== 5}
              id={`vertical-tabpanel-${5}`}
              aria-labelledby={`vertical-tab-${5}`}
            >
              {tab === 5 && <CustomerAgencyReport />}
            </div>
          </SwipeableViews>
        </Box>
      </Grid>
    </Grid>
  );
};
export default ManageCustomerTabs;

import React from "react";
import NavigationPanel from "../layouts/NavigationPanel/NavigationPanel";
import MiniSidePanel from "../layouts/MiniSidePanel/MiniSidePanel";
import { useTranslation } from "react-i18next";

export default function AudioPage() {
  const {t} = useTranslation()
  return (
    <MiniSidePanel headerText={t("main.Audio_Editor/Song_Data")}>
      <AudioContent />
    </MiniSidePanel>
  );
}
function AudioContent() {
  return <NavigationPanel onButtonClick={() => {}}></NavigationPanel>;
}

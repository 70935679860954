/* eslint-disable react-hooks/exhaustive-deps */
//@ts-nocheck

import {
  Link,
  Paper,
  Button,
  Dialog,
  PaperProps,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { Stack } from "@mui/system";
import "./announcementsDialogs.scss";
import Draggable from "react-draggable";
import { Add, Close } from "@mui/icons-material";
import { ExactPlay } from "../../../types/ExactPlay";
import CreateNewTimeManagerDialog from "./CreateNewTimeManagerDialog";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import React, { FC, MouseEventHandler, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useChannelIdContext } from "../../../context/ChannelIdContext";
import { useNavigate } from "react-router-dom";
const API_URL = process.env.REACT_APP_BACKEND_URL;
function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper
        {...props}
        style={{
          backgroundColor: "#34393f",
          color: "#D9D9D9",
          padding: "1rem",
        }}
      />
    </Draggable>
  );
}

const Table: FC = () => {
  const [exactPlayList, setExactPlayList] = React.useState<ExactPlay[]>([]);
  const { t } = useTranslation();
  const {setIsTokenExpired,setIsTokenUpdated,IsTokenUpdated} = useChannelIdContext()
  const navigate= useNavigate()
  const columns = useMemo<MRT_ColumnDef<ExactPlay>[]>(
    () => [
      {
        accessorKey: "voiceTrack.fileName",
        header: `${t("main.name")}`,
      },
      {
        accessorKey: "playDate",
        header: `${t("main.Exact_Time")}`,
      },
      {
        accessorKey: "zone.name",
        header: `${"main.Zone"}`,
      },
      {
        accessorKey: "message",
        header: `${t("main.Message")}`,
      },
      {
        accessorKey: "voiceTrack.voiceTrackCategory",
        header: t("main.category"),
      },
      {
        accessorKey: "musicFade",
        header: `${t("main.Music_Fade")}`,
      },
      {
        accessorKey: "padSeconds",
        header: `${t("main.padSeconds")}`,
      },
      {
        accessorKey: "day",
        header: t("main.day"),
      },
      {
        accessorKey: "immediate",
        header: `${t("main.Immediate")}`,
      },
    ],
    [t]
  );

  useEffect(() => {
    let token = JSON.parse(localStorage.getItem("token") ?? "{}");
    const fetchExactPlayList = async () => {
      fetch(`${API_URL}/ExactPlay/GetAll`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token?.accessToken}`,
        },
      })
        .then((response) => {
          if (
            response.ok === false &&
            response.status === 401 &&
            token?.refreshToken !== ""
          ) {
            setIsTokenExpired(true);
          } else if (response.status === 401 && token?.refreshToken === "") {
            navigate("/");
            localStorage.removeItem("token");
            localStorage.clear();
          } else {
            setIsTokenUpdated(false);
            return response.json();
          }
        })
        .then((data) => {
          if (data.success === true) {
            setExactPlayList(data?.data);
          } else {
            setExactPlayList([]);
          }
        });
    };
    fetchExactPlayList();
  }, [IsTokenUpdated]);

  return (
    <MaterialReactTable
      columns={columns}
      data={exactPlayList}
      enableSorting={false}
      enablePagination={false}
      enableTopToolbar={false}
      enableColumnActions={false}
      enableColumnFilters={false}
      enableBottomToolbar={false}
      muiTableBodyRowProps={{ hover: false }}
    />
  );
};
enum PopUpEnum {
  None = 0,
  AddNewTimeManger = 1,
}
export default function CreateTimeManagerDialog({
  isOpen,
  handleClose,
}: {
  isOpen: boolean;
  handleClose: MouseEventHandler;
}) {
  const [popUp, setPopUp] = React.useState(PopUpEnum.None);
  const { t } = useTranslation();
  const handleNewTimeManager = () => {
    setPopUp(PopUpEnum.AddNewTimeManger);
  };

  return (
    <Dialog
      fullWidth
      open={isOpen}
      maxWidth="lg"
      onClose={handleClose}
      className="exact-time-modal"
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
        <Typography variant="h5" sx={{ fontWeight: 700 }}>
          {t("main.Exact_Time_Manager")}
          <Link className="modelCloseBtn" onClick={handleClose}>
            <Close />
          </Link>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Stack
          direction="row"
          justifyContent="flex-start"
          className="modal-top-actions"
        >
          <Button
            size="medium"
            variant="contained"
            color="primary"
            sx={{ mt: 2, mr: 3 }}
            onClick={handleNewTimeManager}
          >
            <Add style={{ fontSize: "120%", marginRight: "5px" }} />
            {t("main.Add")}
          </Button>
          <Button size="medium" variant="outlined" color="error" sx={{ mt: 2 }}>
            <Close style={{ fontSize: "120%", marginRight: "5px" }} />
            {t("main.Remove_btn")}
          </Button>
        </Stack>
        <Paper elevation={3} sx={{ p: "1px", mt: 3 }} className="dialogTable">
          <Table></Table>
        </Paper>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-around" }}>
        <Button
          size="medium"
          variant="contained"
          color="primary"
          sx={{ mt: 5 }}
        >
          {t("main.save")}
        </Button>
        <Button
          onClick={handleClose}
          size="medium"
          variant="outlined"
          color="secondary"
          sx={{ mt: 5 }}
        >
          {t("main.cancel")}
        </Button>
      </DialogActions>

      <CreateNewTimeManagerDialog
        isOpen={popUp === PopUpEnum.AddNewTimeManger}
        handleClose={() => setPopUp(PopUpEnum.None)}
      />
    </Dialog>
  );
}

import {
  Grid,
  Select,
  MenuItem,
  Typography,
  FormControl,
  SelectChangeEvent,
  useTheme,
} from "@mui/material";
import MyTextField from "../Basic/MyTextField";
import { useLocation, useNavigate } from "react-router-dom";
import { VoiceTrackCategory } from "../../types/Wheel";
import { AudioVoiceTrackEditor } from "../../types/VoiceTrack";
import React, { useState, useEffect, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useChannelIdContext } from "../../context/ChannelIdContext";

const API_URL = process.env.REACT_APP_BACKEND_URL;
export default function ViewContentAudioMetaData({
  voiceTrack,
  setVoiceTrack,
  category,
  setCategory,
}: {
  voiceTrack: AudioVoiceTrackEditor;
  setVoiceTrack: React.Dispatch<SetStateAction<AudioVoiceTrackEditor>>;
  category: any;
  setCategory: React.Dispatch<SetStateAction<any[]>>;
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  let token = JSON.parse(localStorage?.getItem("token") ?? "{}");
  const { setIsTokenExpired, setIsTokenUpdated, IsTokenUpdated } =
    useChannelIdContext();
  const [voiceCategory, setVoiceCategory] = useState<VoiceTrackCategory[]>([]);
  const location = useLocation();
  useEffect(() => {
    const fetchData = async () => {
      fetch(`${API_URL}/VoiceTrackCategory/GetAll`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token?.accessToken}`,
        },
      })
        .then((response) => {
          if (
            response.ok === false &&
            response.status === 401 &&
            token?.refreshToken !== ""
          ) {
            setIsTokenExpired(true);
          } else if (response.status === 401 && token?.refreshToken === "") {
            navigate("/");
            localStorage.removeItem("token");
            localStorage.clear();
          } else {
            setIsTokenUpdated(false);
            return response.json();
          }
        })
        .then((data) => {
          if (data.success === true) {
            setVoiceCategory(data?.data);
          } else {
            setVoiceCategory([]);
          }
        });
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.pathname, IsTokenUpdated]);
  console.log(voiceTrack)
  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid
        item
        xs={12}
        xl={3}
        md={3}
        container
        direction="column"
        alignItems="start"
        justifyContent="center"
      >
        <Typography variant="h6">{t("main.title")}</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        xl={9}
        md={9}
        container
        direction="column"
        justifyContent="center"
      >
        <MyTextField
          hiddenLabel
          value={voiceTrack?.title}
          onChange={(e: any) => {
            setVoiceTrack({ ...voiceTrack, title: e.target.value });
          }}
          variant="filled"
          fullWidth
          size="small"
          aria-readonly
        />
      </Grid>
      <>
        <Grid
          item
          xs={12}
          md={3}
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
        >
          <Typography
            variant="h6"
            style={{ color: theme.palette.mode === "dark" ? "#fff" : "#000" }}
          >
            {t("main.category")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={9}
          container
          direction="column"
          justifyContent="center"
        >
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              displayEmpty
              value={
                voiceCategory?.some((item) => item?.name === category?.name)
                  ? category?.name
                  : ""
              }
              renderValue={(value) => {
                if (value) {
                  return voiceCategory?.find(
                    (item) => item?.name === category?.name
                  )?.name;
                } else {
                  return <Typography>{t("main.select_category")}</Typography>;
                }
              }}
              onChange={(e: SelectChangeEvent) => {
                setCategory({ ...category, name: e.target.value });
              }}
            >
              {voiceCategory?.map((item: any) => (
                <MenuItem
                  key={item?.id}
                  value={item?.name}
                  onClick={() => {
                    setVoiceTrack({
                      ...voiceTrack,
                      voiceTrackCategory: {
                        id: item?.id,
                        name: item.name,
                        volumeOffset: item?.volumeOffset || 0,
                        voiceTracks: item?.voiceTracks || [],
                      },
                    });
                  }}
                >
                  {item?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </>
    </Grid>
  );
}

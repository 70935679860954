import React from "react";
import MiniSidePanel from "../layouts/MiniSidePanel/MiniSidePanel";
import NavigationPanel from "../layouts/NavigationPanel/NavigationPanel";
import ChnnelEditorTabs from "../component/Tabs/ChannelEditorTabs";
import { useTranslation } from "react-i18next";

export default function ChannelEditorPage() {
  const {t} = useTranslation()
  return (
    <MiniSidePanel headerText={t("main.Channel_List_Editor")}>
      <ChannelEditorPageContent />
    </MiniSidePanel>
  );
}

const ChannelEditorPageContent: React.FC = () => {
  return (
    <>
      <NavigationPanel
        onButtonClick={() => {}}
        addChannelItem
        deleteChannelItem
        duplicateChannelItem
        channelTools
        addPlaylistSong
        deletePlaylistSong
        channelEditorSearchbar
      />
      <ChnnelEditorTabs />
    </>
  );
};

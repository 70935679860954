import {
  Link,
  Paper,
  Button,
  Dialog,
  TextField,
  Typography,
  PaperProps,
  DialogTitle,
  FormControl,
  DialogActions,
  DialogContent,
  useTheme,
} from "@mui/material";
import { toast } from "react-toastify";
import Draggable from "react-draggable";
import CloseIcon from "@mui/icons-material/Close";
import React, { MouseEventHandler, useState } from "react";
import { useTranslation } from "react-i18next";
import { useChannelIdContext } from "../../../context/ChannelIdContext";
import { useNavigate } from "react-router-dom";
const API_URL = process.env.REACT_APP_BACKEND_URL;
function PaperComponent(props: PaperProps) {
  const theme = useTheme();
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper
        {...props}
        style={{
          backgroundColor:theme.palette.mode === "dark" ? "#000" : "#fff",
          color: "#D9D9D9",
          padding: "1rem",
          maxWidth: "577px",
        }}
      />
    </Draggable>
  );
}
interface newWheelData {
  name: string | null;
}
let token = JSON.parse(localStorage.getItem("token") ?? "{}");
export default function CreateNewWheelDialog({
  isOpen,
  handleClose,
  fetchVoiceWheelList,
}: {
  isOpen: boolean;
  fetchVoiceWheelList: any;
  handleClose: any | MouseEventHandler;
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const [wheelData, setWheelData] = useState<newWheelData>({ name: null });
  const navigate = useNavigate();
  const { setIsTokenExpired, setIsTokenUpdated } = useChannelIdContext();
  const isSaveDisabled = !wheelData?.name;

  const postWheelData = async (e: React.MouseEvent<HTMLElement>) => {
    if (!wheelData.name?.trim()) {
      toast.error("main.Please_fill_the_form");
    } else {
      e.preventDefault();
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token?.accessToken}`,
        },
        body: JSON.stringify({
          name: wheelData?.name,
        }),
      };
      fetch(`${API_URL}/VoiceWheel`, requestOptions)
        .then((response) => {
          if (
            response.ok === false &&
            response.status === 401 &&
            token?.refreshToken !== ""
          ) {
            setIsTokenExpired(true);
          } else if (response.status === 401 && token?.refreshToken === "") {
            navigate("/");
            localStorage.removeItem("token");
            localStorage.clear();
          } else {
            setIsTokenUpdated(false);
            return response.json();
          }
        })
        .then((data) => {
          if (data?.success === true) {
            fetchVoiceWheelList();
            handleClose();
            toast.success(t("main.New_Wheel_Created_Successfully"));
          } else {
            toast.error(data?.message);
          }
        });
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      maxWidth="sm"
      fullWidth
      className="addNewWheelDialog"
    >
      <form>
        <DialogTitle
          style={{ cursor: "move", color: "#484C52" }}
          id="draggable-dialog-title"
        >
          <Typography sx={{ fontSize: "25px", fontWeight: 700, color: theme.palette.mode === "dark" ? "#fff" : "#000" }}>
            {t("main.new_wheel_designer")}
            <Link className="modelCloseBtn" onClick={handleClose}>
              <CloseIcon style={{ color: theme.palette.mode === "dark" ? "#fff" : "#484C52" }} />
            </Link>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <FormControl className="modal-new-wheel-input">
            <TextField
              label={t("main.enter_the_title_of_the_new_wheel")}
              id="newWheelTitle"
              value={wheelData?.name || ""}
              sx={{ width: "400px", marginTop: "15px" }}
              onChange={(e) => {
                setWheelData({ ...wheelData, name: e.target.value });
              }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "end" }}>
          <Button
            type="submit"
            onClick={(e) => {
              postWheelData(e);
              setWheelData({ name: null });
            }}
            size="medium"
            variant="contained"
            className="SaveBtn"
            sx={{
              mt: 5,
              ...(isSaveDisabled && {
                backgroundColor: "#757575 !important",
                color: "#fff !important",
                border: "2px solid gray !important",
              }),
              ":hover": {
                backgroundColor: "#3c735c",
                color: "#fff",
              },
            }}
            disabled={isSaveDisabled}
          >
            {t("main.save")}
          </Button>
          <Button
            onClick={handleClose}
            size="medium"
            variant="outlined"
            color="secondary"
            className="ClsBtn"
            sx={{ mt: 5, borderColor: "#fff" }}
          >
            {t("main.cancel")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

import {
    Grid,
    Checkbox,
    TextField,
    Typography,
    FormControlLabel,
  } from "@mui/material";
  import React from "react";
  import moment from "moment";
  import { DatePicker } from "@mui/x-date-pickers/DatePicker";
  import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
  import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
  import { useTranslation } from "react-i18next";
  
  export default function AudioExactDate({
    voiceTrack,
    setVoiceTrack,
  }: {
    voiceTrack: any;
    setVoiceTrack: React.Dispatch<React.SetStateAction<any>>;
  }) {
  
    const {t} = useTranslation();
    
    const adjustTimezoneOffset = (date: moment.MomentInput) => {
      const timezoneOffset = moment().utcOffset();
      return moment(date).add(timezoneOffset, "minutes").toISOString();
    };
  
    const handleCommenceDateChange = (value: moment.MomentInput) => {
      const adjustedDate = adjustTimezoneOffset(value);
      setVoiceTrack({
        ...voiceTrack,
        commenceDate: adjustedDate,
      });
    };
  
    const handleExpireDateChange = (value: moment.MomentInput) => {
      const adjustedDate = adjustTimezoneOffset(value);
      setVoiceTrack({
        ...voiceTrack,
        expireDate: adjustedDate,
      });
    };
    return (
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid
          item
          xs={12}
          xl={3}
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
        >
          <Typography component="span" variant="h6">{t("main.Commence")}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          xl={5}
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={voiceTrack?.useCommenceDate}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setVoiceTrack({
                    ...voiceTrack,
                    useCommenceDate: event.target.checked,
                  })
                }
              />
            }
            label={t("main.Use_commence_date?")}
          />
        </Grid>
        <Grid
          item
          xs={12}
          xl={4}
          container
          direction="column"
          justifyContent="center"
          className="DrpCustom"
        >
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label={""}
              inputFormat="DD.MM.YYYY"
              value={voiceTrack?.commenceDate}
              onChange={handleCommenceDateChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid
          item
          xs={12}
          xl={3}
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
        >
          <Typography component="span" variant="h6">{t("main.Expire")}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          xl={5}
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={voiceTrack?.useExpireDate}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setVoiceTrack({
                    ...voiceTrack,
                    useExpireDate: event.target.checked,
                  })
                }
              />
            }
            label={t("main.Use_expire_date?")}
          />
        </Grid>
        <Grid
          item
          xs={12}
          xl={4}
          container
          direction="column"
          justifyContent="center"
          className="DrpCustom"
        >
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label={""}
              inputFormat="DD.MM.YYYY"
              value={voiceTrack?.expireDate}
              onChange={handleExpireDateChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
    );
  }
  
import {
  Box,
  Stack,
  Toolbar,
  useTheme,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
import {
  SearchOutlined,
  AudioFileOutlined,
  CreateNewFolderOutlined,
} from "@mui/icons-material";
import "../layouts/SearchBar.scss";
import { toast } from "react-toastify";
import React, { useRef, useState } from "react";
import { useChannelIdContext } from "../context/ChannelIdContext";
import ImportFileDialog from "../component/Dialogs/ImportFileDialog";
import { ReactComponent as Add } from "../assets/images/add-squre.svg";
import ImportFolderDialog from "../component/Dialogs/ImportFolderDialog";
import { ReactComponent as Minus } from "../assets/images/minus-squreDark.svg";
import { ReactComponent as Duplicate } from "../assets/images/DuplicateDark.svg";
import DuplicateDayDilog from "../component/Dialogs/DuplicateDayDialog/DuplicateDayDialog";
import WheelDesignerDialog from "../component/Dialogs/CategoriesDailog/WheelDesignerDialog";
import CreateNewCategoriesDialog from "../component/Dialogs/CategoriesDailog/CategoriesDialog";
import DuplicateZoneDialog from "../component/Dialogs/DuplicateZoneDialog/DuplicateZoneDialog";
import CreateCatalogDialog from "../component/Dialogs/AnnouncementsDialogs/CreateCatalogDialog";
import CreateTimeManagerDialog from "../component/Dialogs/AnnouncementsDialogs/CreateTimeManagerDialog";
import CreateWheelDesignerDialog from "../component/Dialogs/AnnouncementsDialogs/CreateWheelDesignerDialog";
import { useTranslation } from "react-i18next";

interface IProps {
  importFolder?: boolean;
  importFile?: boolean;
  tools?: boolean;
  categoryManager?: boolean;
  wheelManager?: boolean;
  importContentLibrary?: boolean;
  addScheduleItem?: boolean;
  deleteScheduleItem?: boolean;
  duplicateDay?: boolean;
  duplicateZone?: boolean;
  onButtonClick: (button: string) => void;
}

enum PopUpEnum {
  None = 0,
  FolderUpload = 1,
  FileUpload = 2,
  AddCatalog = 3,
  AddWheelDesigner = 4,
  AddTimeManager = 5,
  AddSchedule = 6,
  DeleteSchedule = 7,
  DuplicateDay = 8,
  DuplicateZone = 9,
  CategoriesDialog = 10,
  WheelDesignerDialog = 11,
}

const ContentLibraryNavigationPanel: React.FC<IProps> = (props: IProps) => {
  const { t } = useTranslation();
  const [popUp, setPopUp] = useState(PopUpEnum.None);
  const theme = useTheme();
  const delay = useRef();
  const { setCategoryModalOpen, setSearchContent, currentUser } =
    useChannelIdContext();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    clearTimeout(delay.current);
    delay.current = setTimeout(
      () => setSearchContent(event.target.value),
      500
    ) as any;
  };

  return (
    <div>
      <Toolbar className="toolbarPanel">
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
          width="100%"
        >
          {props.importFolder && (
            <IconButton
              className="toolbarPanel__menu"
              aria-label="Import folder"
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 1,
                pt: 4,
                aspectRatio: "1/1",
                width: "8rem",
                color: theme.palette.mode === "dark" ? "#fff" : "#263238",
              }}
              onClick={() => setPopUp(PopUpEnum.FolderUpload)}
            >
              <CreateNewFolderOutlined />
              {t("main.add_folder")}
            </IconButton>
          )}
          {/* Add file */}
          {props.importFile && (
            <IconButton
              className="toolbarPanel__menu"
              aria-label="Import file"
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 1,
                pt: 4,
                aspectRatio: "1/1",
                width: "8rem",
                color: theme.palette.mode === "dark" ? "#cfd8dc" : "#263238",
              }}
              onClick={() => {
                setPopUp(PopUpEnum.FileUpload);
                setCategoryModalOpen(false);
              }}
            >
              <AudioFileOutlined />
              {t("main.add_file")}
            </IconButton>
          )}

          {props.categoryManager && (
            <IconButton
              className="toolbarPanel__menu"
              aria-label="Category Manager"
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 1,
                pt: 4,
                aspectRatio: "1/1",
                minWidth: "11%",
                color: theme.palette.mode === "dark" ? "#cfd8dc" : "#263238",
              }}
              onClick={() =>
                currentUser?.editSongCategory
                  ? setPopUp(PopUpEnum.CategoriesDialog)
                  : toast.error(
                      "main.User_doesn't_have_authority_to_edit_song_category"
                    )
              }
            >
              <CreateNewFolderOutlined />
              {t("main.category_manager")}
            </IconButton>
          )}

          {/* wheel manager */}
          {props.wheelManager && (
            <IconButton
              className="toolbarPanel__menu"
              aria-label="Import folder"
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 1,
                pt: 4,
                aspectRatio: "1/1",
                width: "10rem",
                color: theme.palette.mode === "dark" ? "#cfd8dc" : "#263238",
              }}
              onClick={() =>
                currentUser?.editVoiceWheels
                  ? setPopUp(PopUpEnum.WheelDesignerDialog)
                  : toast.error(
                      t("main.User_doesn't_have_authority_to_edit_voice_wheel")
                    )
              }
            >
              <CreateNewFolderOutlined />
              {t("main.wheel_manager")}
            </IconButton>
          )}
          {props.addScheduleItem && (
            <IconButton
              className="toolbarPanel__menu"
              aria-label="Add Schedule"
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 1,
                pt: 4,
                aspectRatio: "1/1",
                width: "8rem",
                color: theme.palette.mode === "dark" ? "#cfd8dc" : "#263238",
              }}
              onClick={() => setPopUp(PopUpEnum.AddSchedule)}
            >
              <Add />
              {t("main.add_schedule_item")}
            </IconButton>
          )}
          {props.deleteScheduleItem && (
            <IconButton
              className="toolbarPanel__menu"
              aria-label="Delete Schedule"
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 1,
                pt: 4,
                aspectRatio: "1/1",
                width: "8rem",
                color: theme.palette.mode === "dark" ? "#cfd8dc" : "#263238",
              }}
              onClick={() => setPopUp(PopUpEnum.DeleteSchedule)}
            >
              <Minus />
              {t("main.delete_schedule_item")}
            </IconButton>
          )}
          {props.duplicateDay && (
            <IconButton
              className="toolbarPanel__menu"
              aria-label="Create folder"
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 1,
                pt: 4,
                aspectRatio: "1/1",
                width: "8rem",
                color: theme.palette.mode === "dark" ? "#cfd8dc" : "#263238",
              }}
              onClick={() => setPopUp(PopUpEnum.DuplicateDay)}
            >
              <Duplicate />
              {t("main.duplicate_day")}
            </IconButton>
          )}
          {props.duplicateZone && (
            <IconButton
              className="toolbarPanel__menu"
              aria-label="Create folder"
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 1,
                pt: 4,
                aspectRatio: "1/1",
                width: "8rem",
                color: theme.palette.mode === "dark" ? "#cfd8dc" : "#263238",
              }}
              onClick={() => setPopUp(PopUpEnum.DuplicateZone)}
            >
              <Duplicate />
              {t("main.duplicate_zone")}
            </IconButton>
          )}
          <Box
            sx={{
              flexGrow: 1,
            }}
          ></Box>
          {props.importContentLibrary ? (
            <TextField
              className="customSearch"
              id="search-all"
              placeholder={t("main.search_library")}
              type="text"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlined style={{color: theme.palette.mode === "dark" ? "#3c434b" : "#3c434b"}} />
                  </InputAdornment>
                ),
              }}
            />
          ) : (
            <TextField
              className="customSearch"
              id="search-all"
              placeholder={t("main.search_library")}
              type="text"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlined style={{color: theme.palette.mode === "dark" ? "#3c434b" : "#3c434b"}} />
                  </InputAdornment>
                ),
              }}
              onChange={handleChange}
            />
          )}
        </Stack>
      </Toolbar>
      <ImportFolderDialog
        props={{ importContentLibrary: true }}
        isOpen={popUp === PopUpEnum.FolderUpload}
        handleClose={() => setPopUp(PopUpEnum.None)}
      ></ImportFolderDialog>
      <ImportFileDialog
        props={{ importContentLibrary: true }}
        isOpen={popUp === PopUpEnum.FileUpload}
        handleClose={() => setPopUp(PopUpEnum.None)}
      ></ImportFileDialog>
      <CreateCatalogDialog
        isOpen={popUp === PopUpEnum.AddCatalog}
        handleClose={() => setPopUp(PopUpEnum.None)}
      ></CreateCatalogDialog>
      <CreateWheelDesignerDialog
        isOpen={popUp === PopUpEnum.AddWheelDesigner}
        handleClose={() => setPopUp(PopUpEnum.None)}
      ></CreateWheelDesignerDialog>

      <CreateTimeManagerDialog
        isOpen={popUp === PopUpEnum.AddTimeManager}
        handleClose={() => setPopUp(PopUpEnum.None)}
      ></CreateTimeManagerDialog>
      <DuplicateDayDilog
        isOpen={popUp === PopUpEnum.DuplicateDay}
        handleClose={() => setPopUp(PopUpEnum.None)}
      ></DuplicateDayDilog>
      <DuplicateZoneDialog
        isOpen={popUp === PopUpEnum.DuplicateZone}
        handleClose={() => setPopUp(PopUpEnum.None)}
      ></DuplicateZoneDialog>
      <CreateNewCategoriesDialog
        isOpen={popUp === PopUpEnum.CategoriesDialog}
        handleClose={() => setPopUp(PopUpEnum.None)}
      ></CreateNewCategoriesDialog>
      <WheelDesignerDialog
        isOpen={popUp === PopUpEnum.WheelDesignerDialog}
        handleClose={() => setPopUp(PopUpEnum.None)}
      ></WheelDesignerDialog>
    </div>
  );
};

export default ContentLibraryNavigationPanel;

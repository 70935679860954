/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import {
  Link,
  Paper,
  Button,
  Dialog,
  Select,
  MenuItem,
  PaperProps,
  Typography,
  DialogTitle,
  FormControl,
  DialogContent,
  DialogActions,
  OutlinedInput,
  SelectChangeEvent,
} from "@mui/material";
import {
  Channel,
  WheelItem,
  VoiceTrackCategory,
  SaveVoiceTrackItems,
} from "../../../types/Wheel";
import "./categoriesDialog.scss";
import { Stack } from "@mui/system";
import { toast } from "react-toastify";
import Draggable from "react-draggable";
import { useLocation, useNavigate } from "react-router-dom";
import { Add, Close } from "@mui/icons-material";
import CreateNewWheelDialog from "./CreateNewWheelDialog";
import ContentWheelTable from "../../Tables/ContentWheelTable/ContentWheelTable";
import { useChannelIdContext } from "../../../context/ChannelIdContext";
import { useTranslation } from "react-i18next";
const API_URL = process.env.REACT_APP_BACKEND_URL;
function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper
        {...props}
        style={{
          backgroundColor: "#34393f",
          color: "#D9D9D9",
          padding: "1rem",
        }}
      />
    </Draggable>
  );
}

enum PopUpEnum {
  None = 0,
  AddNewWheel = 1,
}

export default function WheelDesignerDialog({
  isOpen,
  handleClose,
}: {
  isOpen: boolean;
  handleClose: any;
}) {
  const location = useLocation();
  const [popUp, setPopUp] = useState(PopUpEnum?.None);
  const handleNewWheel = () => {
    setPopUp(PopUpEnum?.AddNewWheel);
  };
  const [voiceWheelList, setVoiceWheelList] = useState<WheelItem[]>([]);
  const [SaveVoiceTrackItem, setSaveVoiceTrackItem] = useState<
    SaveVoiceTrackItems[]
  >([]);
  const [VoiceTrackCategory, setVoiceTrackCategory] = useState<
    VoiceTrackCategory[]
  >([]);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [, setRemoveCategoryItem] = useState<any[]>([]);
  const [, setVoiceTrackChannel] = useState<Channel[]>([]);
  const {
    setVoiceTrackCategoryId,
    setIsTokenExpired,
    setIsTokenUpdated,
    IsTokenUpdated,
  } = useChannelIdContext();
  const [isChange, setIsChange] = useState<any>(false);
  const [rowSelection, setRowSelection] = useState<number>(0);
  const [selectedData, setSelectedData] = useState<any[]>([]);
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  const [isWheelAdded, setIsWheelAdded] = useState<boolean>(false);
  const [isItemDelete, setIsItemDelete] = useState<boolean>(false);
  const [wheelMangerList, setWheelMangerList] = useState<any[]>([]);
  const [selectVoiceWheel, setSelectVoiceWheel] = useState<string>("");
  const [isItemDraggble, setIsItemDraggble] = useState<boolean>(false);
  const [selectVoiceWheelId, setSelectVoiceWheelId] = useState<number>(0);

  const fetchWheelCategoryList = useCallback(async () => {
    fetch(`${API_URL}/VoiceTrackCategory/GetAll`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    })
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data.success === true) {
          setVoiceTrackCategory(data?.data);
        } else {
          setVoiceTrackCategory([]);
        }
      });
  }, [IsTokenUpdated]);

  const fetchWheelChannelList = useCallback(async () => {
    const response = await fetch(`${API_URL}/Channel/GetAll`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    });
    const data = await response.json();
    setVoiceTrackChannel(data?.data);
    setIsWheelAdded(false);
  }, []);

  const fetchVoiceWheelList = useCallback(async () => {
    const response = await fetch(`${API_URL}/VoiceWheel/GetAll`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    });
    const data = await response.json();
    setSelectedData([]);
    setVoiceWheelList(data?.data);
  }, []);

  const getWheelItemBasePosition = useCallback(async () => {
    const response = await fetch(
      `${API_URL}/VoiceWheelItem/Get/${selectVoiceWheel}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token?.accessToken}`,
        },
      }
    );
    const data = await response.json();
    if (data?.data?.length > 0) {
      setIsItemDraggble(false);
      const wheelItems: SaveVoiceTrackItems[] = [];
      data?.data?.map((item: SaveVoiceTrackItems, index: number) => {
        wheelItems?.push({
          index: index,
          isMusic: item?.isMusic,
          position: item?.position,
          voiceWheelId: item?.voiceWheelId,
          isVoiceTrack: item?.isVoiceTrack,
          voiceTrackCategoryId: item?.voiceTrackCategoryId,
        });
      });
      setSelectedData(wheelItems);
      data?.data?.map((list: any, i: number) => {
        list["index"] = i;
      });
      setWheelMangerList(data?.data);
    }
  }, [selectVoiceWheel]);

  const updateItemPosition = useCallback(async () => {
    const newdata = wheelMangerList?.filter((item: any) => {
      if (item?.voiceTrackCategoryId === 0) {
        item.voiceTrackCategoryId = null;
      }
      if (item?.voiceWheel) {
        item.voiceWheel = null;
      }
      if (item?.voiceTrackCategory) {
        item.voiceTrackCategory = null;
      }
      return item;
    });
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
      body: JSON.stringify(newdata),
    };
    fetch(`${API_URL}/VoiceWheelItem/UpdateVoiceWheelItems`, requestOptions)
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data?.success === true) {
          getWheelItemBasePosition();
          toast.success(t("main.Update_Item_Position_Successfully"));
        } else {
          toast.error(data.warning);
        }
      });
  }, [getWheelItemBasePosition, t, wheelMangerList]);

  const handleSave = async () => {
    SaveVoiceTrackItem?.filter((item: any, index: any) => {
      delete item.index;
      if (item?.voiceTrackCategoryId === 0) {
        item.voiceTrackCategoryId = null;
      }
      return item;
    });
    if (selectVoiceWheelId) {
      fetch(`${API_URL}/VoiceWheelItem/RemoveAddVoiceWheelItems`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token?.accessToken}`,
        },
        body: JSON.stringify({
          voiceWheelId: selectVoiceWheelId,
          newVoiceWheelItems: SaveVoiceTrackItem,
        }),
      })
        .then((response) => {
          if (
            response.ok === false &&
            response.status === 401 &&
            token?.refreshToken !== ""
          ) {
            setIsTokenExpired(true);
          } else if (response.status === 401 && token?.refreshToken === "") {
            navigate("/");
            localStorage.removeItem("token");
            localStorage.clear();
          } else {
            setIsTokenUpdated(false);
            return response.json();
          }
        })
        .then((data) => {
          if (data?.success) {
            setVoiceTrackCategoryId(0);
            getWheelItemBasePosition();
            toast.success("Item Saved");
          } else {
            toast.error(data.message);
          }
        });
    }
  };

  const handleVoiceWheelChange = (
    event: SelectChangeEvent<typeof selectVoiceWheel>
  ) => {
    const {
      target: { value },
    } = event;
    setIsChange(true);
    setSelectVoiceWheel(value.toString());
    setSelectVoiceWheel(event.target.value);
    fetchVoiceWheelList();
  };
  const handleAddItemClick = () => {
    if (selectVoiceWheel) {
      const value = selectVoiceWheel?.toString();
      setSelectVoiceWheel(value);
      let itemPosition =
        SaveVoiceTrackItem?.length > 0
          ? SaveVoiceTrackItem[SaveVoiceTrackItem?.length - 1].position + 1
          : 0;
      let selectDataIndex =
        selectedData?.length > 0
          ? selectedData[selectedData?.length - 1].index + 1
          : 0;
      setSelectedData([
        ...selectedData,
        {
          isMusic: false,
          isVoiceTrack: false,
          index: selectDataIndex,
          position: itemPosition,
          voiceTrackCategoryId: null,
          voiceWheelId: selectVoiceWheelId,
        },
      ]);
      setWheelMangerList([
        ...wheelMangerList,
        {
          isMusic: false,
          isVoiceTrack: false,
          position: itemPosition,
          index: selectDataIndex,
          voiceTrackCategoryId: null,
          voiceWheelId: selectVoiceWheelId,
        },
      ]);
      setSaveVoiceTrackItem([
        ...SaveVoiceTrackItem,
        {
          isMusic: false,
          isVoiceTrack: false,
          position: itemPosition,
          index: selectDataIndex,
          voiceTrackCategoryId: null,
          voiceWheelId: selectVoiceWheelId,
        },
      ]);
      setSelectVoiceWheelId(selectVoiceWheelId);
    } else {
      toast.error(t("main.Please_Select_Wheel_to_Add_Item"));
    }
  };

  const handleDeleteWheel = async (event: React.MouseEvent<HTMLElement>) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    };
    fetch(`${API_URL}/VoiceWheel/${selectVoiceWheel}`, requestOptions)
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data?.success === true) {
          fetchVoiceWheelList();
          setWheelMangerList([]);
          toast.success(t("main.Wheel_Deleted_Successfully"));
        } else {
          toast.error(data?.message);
        }
      });
  };

  const handleDeleteWheelItem = async (
    event: React.MouseEvent<HTMLElement>
  ) => {
    if (!isItemDelete) toast.error(t("main.Please_Select_Item_First"));
    else {
      setIsItemDelete(false);
      setWheelMangerList(wheelMangerList?.filter((item) => !item?.isRemove));
      setSaveVoiceTrackItem(wheelMangerList?.filter((item) => !item?.isRemove));
    }
  };

  useEffect(() => {
    if(isOpen === true){
      fetchVoiceWheelList();
    }
  }, [fetchVoiceWheelList,isOpen]);

  useEffect(() => {
    fetchWheelChannelList();
    if (location?.pathname?.includes("content-library") && isOpen === true)
      fetchWheelCategoryList();
  }, [
    fetchWheelCategoryList,
    fetchWheelChannelList,
    location?.pathname,
    selectVoiceWheelId,
    isOpen
  ]);

  useEffect(() => {
    if (isWheelAdded === true) {
      handleClose();
    }
  }, [handleClose, isWheelAdded]);

  useEffect(() => {
    if (Object.keys(selectedData)?.length !== 0) {
      setSaveVoiceTrackItem(selectedData);
    }
  }, [selectedData]);

  useEffect(() => {
    if (isItemDraggble) {
      updateItemPosition();
    }
  }, [isItemDraggble, updateItemPosition]);

  return (
    <>
      <Dialog
        fullWidth
        open={isOpen}
        maxWidth="lg"
        onClose={() => {
          handleClose();
          setSelectedData([]);
          setSelectVoiceWheel("");
        }}
        PaperComponent={PaperComponent}
        className="addNewCategoryDialog"
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          <Typography sx={{ fontSize: "25px", fontWeight: 700 }}>
            {t("main.wheel_manager")}
            <Link
              className="modelCloseBtn"
              onClick={() => {
                handleClose();
                setSelectedData([]);
                setSelectVoiceWheel("");
              }}
            >
              <Close />
            </Link>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Stack
            direction="row"
            justifyContent="flex-start"
            className="modal-top-actions"
          >
            <Button
              size="medium"
              variant="contained"
              color="primary"
              sx={{ mt: 2, mr: 3 }}
              onClick={handleNewWheel}
            >
              <Add style={{ fontSize: "120%", marginRight: "5px" }} />
              {t("main.add_wheel")}
            </Button>
            <Button
              size="medium"
              variant="outlined"
              color="error"
              sx={{
                mt: 2,
                background: "transparent !important",
                border: "2px solid #c66969 !important",
              }}
              onClick={handleDeleteWheel}
            >
              <Close style={{ fontSize: "120%", marginRight: "5px" }} />
              {t("main.remove_wheel")}
            </Button>
            <span className="divider">|</span>
            <Button
              size="medium"
              variant="contained"
              color="primary"
              sx={{ mt: 2, mr: 3 }}
              onClick={handleAddItemClick}
            >
              <Add style={{ fontSize: "120%", marginRight: "5px" }} />
              {t("main.add_item")}
            </Button>
            <Button
              size="medium"
              variant="outlined"
              color="error"
              sx={{ mt: 2 }}
              onClick={handleDeleteWheelItem}
            >
              <Close style={{ fontSize: "120%", marginRight: "5px" }} />
              {t("main.remove_item")}
            </Button>
          </Stack>
          <FormControl className="modal-select">
            <Select
              input={<OutlinedInput label="Select Voice Wheel" />}
              fullWidth
              className="selectBox"
              labelId="simple-select-label"
              id="simple-select"
              displayEmpty
              value={
                voiceWheelList?.some(
                  (item) => item?.id === parseInt(selectVoiceWheel)
                )
                  ? selectVoiceWheel
                  : ""
              }
              renderValue={
                selectVoiceWheel !== ""
                  ? undefined
                  : () => <Typography>{t("main.category")}</Typography>
              }
              onChange={handleVoiceWheelChange}
              sx={{
                m: 1,
                minWidth: 300,
                height: "34px",
                margin: "20px 0",
                border: "1px solid #e8e8e8 !important",
                color: "#e8e8e8 !importnat",
                borderRadius: "10px",
              }}
            >
              {voiceWheelList?.length > 0 && voiceWheelList ? (
                voiceWheelList?.map((voiceWheel) => (
                  <MenuItem
                    value={voiceWheel?.id}
                    key={voiceWheel?.id}
                    onClick={() => {
                      setSelectVoiceWheelId(voiceWheel?.id);
                    }}
                  >
                    {voiceWheel?.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem>{t("main.no_wheel_found")}</MenuItem>
              )}
            </Select>
          </FormControl>
          <Paper elevation={3} sx={{ p: "1px", mt: 3 }} className="dialogTable">
            <ContentWheelTable
              isChange={isChange}
              setIsChange={setIsChange}
              isWheelAdded={isWheelAdded}
              setIsWheelAdded={setIsWheelAdded}
              selectVoiceWheelId={selectVoiceWheelId}
              saveVoiceTrackItem={SaveVoiceTrackItem}
              setWheelMangerList={setWheelMangerList}
              setSaveVoiceTrackItem={setSaveVoiceTrackItem}
              wheelMangerList={selectVoiceWheel ? wheelMangerList : []}
              VoiceTrackCategory={VoiceTrackCategory}
              setRowSelection={setRowSelection}
              rowSelection={rowSelection}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              setRemoveCategoryItem={setRemoveCategoryItem}
              setIsItemDraggble={setIsItemDraggble}
              setIsItemDelete={setIsItemDelete}
            />
          </Paper>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "space-around" }}>
          <Button
            size="medium"
            variant="contained"
            color="primary"
            sx={{ mt: 5 }}
            onClick={handleSave}
          >
            {t("main.save")}
          </Button>
          <Button
            onClick={() => {
              handleClose();
              setSelectedData([]);
              setWheelMangerList([]);
              setSelectVoiceWheel("");
            }}
            size="medium"
            variant="outlined"
            color="secondary"
            sx={{ mt: 5, borderColor: "#fff" }}
          >
            {t("main.cancel")}
          </Button>
        </DialogActions>
        <CreateNewWheelDialog
          fetchVoiceWheelList={fetchVoiceWheelList}
          isOpen={popUp === PopUpEnum.AddNewWheel}
          handleClose={() => setPopUp(PopUpEnum.None)}
        />
      </Dialog>
    </>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { Song } from "../../types/Customer";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useChannelIdContext } from "../../context/ChannelIdContext";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import React, { useMemo, useEffect, useState, useCallback } from "react";
import {
  CircularProgress, TextField, Typography, Box,
  IconButton,
  MenuItem,
  Button,
  Select
} from "@mui/material";
import {
  ChevronLeft,
  ChevronRight,
  Edit,
  FirstPage,
  LastPage,
  Search,
} from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
interface Zone {
  id: number;
  name: string;
}

const API_URL = process.env.REACT_APP_BACKEND_URL;
const CustomerAgencyReport = () => {
  const { t } = useTranslation();
  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "playDate",
        header: t("main.Play_date"),
        Cell: (renderedCellValue: any) => (
          <span>{formatDate(renderedCellValue)}</span>
        ),
      },
      {
        accessorKey: "title",
        header: t("main.title"),
      },
      {
        accessorKey: "voiceTrackCategory.name",
        header: t("main.voice_track_Category"),
      },
      {
        accessorKey: "zone.name",
        header: t("main.zone"),
      },
      {
        accessorKey: "commenceDate",
        header: t("main.commence_date"),
        Cell: (renderedCellValue: any) => (
          <span>{formatDate2(renderedCellValue)}</span>
        ),
      },
      {
        accessorKey: "expireDate",
        header: t("main.expire_date"),
        Cell: (renderedCellValue: any) => (
          <span>{formatDate2(renderedCellValue)}</span>
        ),
      },
    ],
    [t]
  );

  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  const [agencyReportList, setAgencyReportList] = useState<{
    loaded: boolean;
    songs: Song[];
  }>({ loaded: false, songs: [] });
  let navigate = useNavigate();
  const { SiteId, setIsTokenExpired, setIsTokenUpdated, IsTokenUpdated } =
    useChannelIdContext();

  const [zones, setZones] = useState<Zone[]>();
  const [selectedZone, setSelectedZone] = useState<any>(0);
  const [rangeStartDate, setRangeStartDate] = useState<any>(null);
  const [rangeEndDate, setRangeEndDate] = useState<any>(null);
  const [pageSize] = useState<number | any>(25);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(1);
  const [tablePageSize, setTablePageSize] = useState(
    pageSize
      ? JSON.parse(pageSize).trackTable
        ? JSON.parse(pageSize).trackTable
        : 25
      : 25
  );

  const handleLastPage = () => {
    if (
      totalCount / tablePageSize >
      parseInt((totalCount / tablePageSize).toString())
    ) {
      setCurrentPage(parseInt((totalCount / tablePageSize + 1).toString()));
    } else {
      setCurrentPage(parseInt((totalCount / tablePageSize).toString()));
    }
  };

  const adjustTimezoneOffset = (date: moment.MomentInput) => {
    const timezoneOffset = moment().utcOffset();
    return moment(date)?.add(timezoneOffset, "minutes").toISOString();
  };

  const handleRangeStartDateChange = (value: moment.MomentInput) => {
    const adjustedDate = adjustTimezoneOffset(value);
    setRangeStartDate(adjustedDate);
  };

  const handleRangeEndDateChange = (value: moment.MomentInput) => {
    const adjustedDate = adjustTimezoneOffset(value);
    setRangeEndDate(adjustedDate);
  };

  const formatDate = (value: any) => {
    const date = moment(value?.renderedCellValue).format("DD.MM.YYYY");
    const time = moment(value?.renderedCellValue).format("HH:mm") + "";
    return `${date} ${time}`;
  };

  const formatDate2 = (value: any) => {
    const date = moment(value?.renderedCellValue).format("DD.MM.YYYY");
    return `${date}`;
  };

  const fetchData = useCallback(async () => {
    fetch(`${API_URL}/Site/GetExactTimePlayByZoneId/${selectedZone}?page=${currentPage - 1}&pageSize=${tablePageSize}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    })
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data.success === true) {
          if (rangeStartDate === null && rangeEndDate === null) {
            setAgencyReportList({ loaded: true, songs: data?.data });
          } else {
            const filteredData: any[] = data?.data?.filter((song: any) => {
              const playDate = moment(song?.playDate).format("DD.MM.YYYY");
              const startDate = moment(rangeStartDate).format("DD.MM.YYYY");
              const endDate = moment(rangeEndDate).format("DD.MM.YYYY");
              return moment(playDate).isBetween(startDate, endDate, null, "[]");
            });
            setAgencyReportList({ loaded: true, songs: filteredData });
          }
        } else {
          setAgencyReportList({ loaded: true, songs: [] });
        }
      });
  }, [SiteId, rangeEndDate, rangeStartDate, IsTokenUpdated, selectedZone]);


  const fetchZoneData = useCallback(async () => {

    fetch(`${API_URL}/Zone/GetByFilters?siteId=${SiteId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    })
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true)
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false)
          return response.json()
        }
      })
      .then((data) => {
        if (data.success === true) {
          setZones(data?.data);

          if (data?.data.length > 0) {
            setSelectedZone(data?.data[0].id)
          } else {
            setSelectedZone(0)
          }
        } else {
          setZones([]);
        }
      })
  }, [SiteId, IsTokenUpdated]);

  useEffect(() => {
    if (SiteId !== 0) {
      fetchData();
    }
    if (SiteId === 0) {
      setAgencyReportList({ loaded: true, songs: [] });
    }
  }, [SiteId, fetchData, rangeEndDate]);

  useEffect(() => {
    fetchZoneData();
  }, [SiteId])

  return (
    <div className="playSong">
      <div className="music-library">
        <div style={{ display: "flex" }}>
          <Typography variant="h6" fontSize={14}>
            {t("main.zone")}
          </Typography>
          <Select
            className="CustomDrpSelect"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={
              zones?.some((item: any) => item.id === selectedZone)
                ? selectedZone
                : ""
            }
            onChange={(e) => {
              setSelectedZone(e.target.value);
            }}
            required
          >
            {zones &&
              zones?.map((item) => (
                <MenuItem key={item?.id} value={item?.id}>
                  {item?.name}
                </MenuItem>
              ))}
          </Select>
          <Typography variant="h6" fontSize={14}>
            {t("main.RangeStart")}
          </Typography>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label={""}
              inputFormat="DD.MM.YYYY"
              value={rangeStartDate}
              onChange={handleRangeStartDateChange}
              renderInput={(params) => (
                <TextField {...params} autoComplete="off" />
              )}
            />
          </LocalizationProvider>
          <Typography variant="h6" fontSize={14}>
            {t("main.RangeEnd")}
          </Typography>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label={""}
              inputFormat="DD.MM.YYYY"
              value={rangeEndDate}
              onChange={handleRangeEndDateChange}
              renderInput={(params) => (
                <TextField {...params} autoComplete="off" />
              )}
            />
          </LocalizationProvider>
          <Button
            className="Button"
            onClick={() => {
              setRangeStartDate(null)
              setRangeEndDate(null)
            }}
            style={{ backgroundColor: "#34393f", color: "#fff" }}
          >
            {t("main.clear_filter")}
          </Button>
        </div>
      </div>
      {agencyReportList.loaded === false ? (
        <div style={{ justifyContent: "center", display: "flex" }}>
          <CircularProgress style={{ marginTop: "15rem" }} />
        </div>
      ) : (
        <>
          <MaterialReactTable
            columns={columns}
            data={agencyReportList?.songs}
            enableStickyHeader
            enablePagination={true}
            enableSorting={true}
            enableBottomToolbar={true}
            enableTopToolbar={false}
            rowNumberMode="original"
            enableColumnActions={true}
            enableColumnOrdering={true}
            enableRowActions={false}
            enableGlobalFilterModes
            initialState={{
              showGlobalFilter: true,
            }}
            enablePinning
            muiTableBodyProps={{
              sx: {
                "& tr:nth-of-type(even)": {
                  backgroundColor: "#F0F4F6",
                },
              },
            }}
          />
          <div className="customPagination">
            <span>
              {t("main.rows_per_page")}
            </span>
            <Select
              sx={{ height: "30px" }}
              value={tablePageSize}
              onChange={(e) => {
                setTablePageSize(e.target.value);
                let tablePageSize = {
                  ...JSON.parse(pageSize),
                  trackTable: e.target.value,
                };
                localStorage.setItem("pageSize", JSON.stringify(tablePageSize));
              }}
            >
              <MenuItem value="25">25</MenuItem>
              <MenuItem value="50">50</MenuItem>
              <MenuItem value="100">100</MenuItem>
            </Select>
            <span>
              {(currentPage - 1) * tablePageSize + 1} -{" "}
              {currentPage * tablePageSize < totalCount
                ? currentPage * tablePageSize
                : currentPage * tablePageSize -
                (currentPage * tablePageSize - totalCount)}{" "}
              of {totalCount}
            </span>
            <Box className="customPagination__buttons">
              <IconButton
                onClick={() => setCurrentPage(1)}
                disabled={(currentPage - 1) * tablePageSize + 1 <= 1}
              >
                <FirstPage />
              </IconButton>
              <IconButton
                onClick={() => setCurrentPage(currentPage - 1)}
                disabled={(currentPage - 1) * tablePageSize + 1 <= 1}
              >
                <ChevronLeft />
              </IconButton>
              <IconButton
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={currentPage * tablePageSize >= totalCount}
              >
                <ChevronRight />
              </IconButton>
              <IconButton
                onClick={handleLastPage}
                disabled={currentPage * tablePageSize >= totalCount}
              >
                <LastPage />
              </IconButton>
            </Box>
          </div>
        </>
      )}
    </div>
  );
};

export default CustomerAgencyReport;

import {
  Box,
  Link,
  Paper,
  Dialog,
  Button,
  TextField,
  PaperProps,
  Typography,
  DialogActions,
  DialogContent,
  CircularProgress,
} from "@mui/material";
import "./ripperDialog.scss";
import { toast } from "react-toastify";
import Draggable from "react-draggable";
import CloseIcon from "@mui/icons-material/Close";
import React, { MouseEventHandler, useState, useEffect } from "react";
import { useChannelIdContext } from "../../../context/ChannelIdContext";
import { ReactComponent as FileOpenicon } from "../../../assets/images/folder-open.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
const API_URL = process.env.REACT_APP_BACKEND_URL;
function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper
        {...props}
        style={{
          backgroundColor: "#34393F",
          color: "#D9D9D9",
          padding: "1rem",
        }}
      />
    </Draggable>
  );
}
const RipperDialog = ({
  isOpen,
  handleClose,
}: {
  isOpen: boolean;
  handleClose: any | MouseEventHandler;
}) => {
  const { t } = useTranslation();
  const [selectFileName, setSelectFileName] = useState<any>("");
  const [fileLoader, setFileLoader] = useState(false);
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  const navigate = useNavigate();
  const {
    selectedFile,
    setSelectedFile,
    setIsUploadFile,
    setImportXMLFile,
    setIsTokenExpired,
    setIsTokenUpdated,
  } = useChannelIdContext();

  const handleFileChange = (event: any) => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "application/xml, text/xml";
    fileInput.multiple = false;

    fileInput.addEventListener("change", (event: Event) => {
      const target = event.target as HTMLInputElement;
      const files = target.files;
      if (files && files?.length > 0) {
        setSelectedFile(files[0]);
        setSelectFileName(files[0]?.name);
      }
    });
    fileInput?.click();
  };

  const HandleImportFile = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setFileLoader(true);
    const formdata = new FormData();
    formdata?.append("file", selectedFile);
    fetch(`${API_URL}/Song/ImportSongsFromXml`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
      body: formdata,
    })
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data?.success) {
          setImportXMLFile(true);
          setFileLoader(false);
          setSelectedFile(null);
          setIsUploadFile(true);
          setSelectFileName("");
          toast.success(data?.message);
        } else {
          toast.warning(data?.message);
        }
      })
      .catch((error) => {
        setFileLoader(false);
      });
  };

  useEffect(() => {
    if (fileLoader === true) {
      handleClose();
    }
  }, [handleClose, fileLoader]);

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => {
          handleClose();
          setSelectFileName("");
          setSelectedFile(null);
        }}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        maxWidth="sm"
        fullWidth
        className="ImortAudioDialog"
      >
        <div className="ImortAudioDialog__inner">
          <Typography variant="h5" className="ImortAudioDialog-title">
            {t("main.xml_file_import")}
            <Link
              onClick={() => {
                handleClose();
                setSelectFileName("");
                setSelectedFile(null);
              }}
              className="modelCloseBtn"
            >
              <CloseIcon />
            </Link>
          </Typography>
          <DialogContent>
            <Box className="importfieldBox">
              <Box className="importfieldBox__head">
                <Typography
                  variant="body1"
                  className="importfieldBox__head-title"
                >
                  {t("main.import_file")}
                </Typography>
              </Box>
              <Box className="importfieldBox__body">
                <Box className="importfieldBox__body-field ImportField">
                  <TextField
                    aria-readonly
                    required
                    autoComplete="off"
                    value={selectFileName}
                    placeholder={t("main.choose_xml_file")}
                  />
                </Box>
                <Button
                  className="uploadBtn"
                  variant="contained"
                  component="span"
                  onClick={handleFileChange}
                >
                  <FileOpenicon />
                </Button>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions sx={{ display: "flex" }}>
            <Button
              size="medium"
              variant="contained"
              sx={{ mt: 5 }}
              disabled={selectedFile === null || selectFileName === null}
              onClick={(e: React.SyntheticEvent) => HandleImportFile(e)}
            >
              {t("main.Import")}
            </Button>
            <Button
              size="medium"
              variant="outlined"
              sx={{ mt: 5 }}
              onClick={() => {
                handleClose();
                setSelectFileName(null);
                setSelectedFile(null);
              }}
            >
              {t("main.cancel")}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
      <Dialog open={fileLoader} onClose={handleClose} className="loadingDialog">
        <div style={{ justifyContent: "center", display: "flex", overflow: "hidden"}}>
          <CircularProgress />
        </div>
      </Dialog>
    </>
  );
};
export default RipperDialog;

import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import { LightModeOutlined, DarkModeOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const ThemeButton = () => {
    let theme = useTheme();

    const {t} = useTranslation();

    return (
        <>
            {theme &&  theme.palette.mode === 'light' ?
                <Button
                    aria-label="dark"
                    size="large"
                    variant='outlined'
                    startIcon={<DarkModeOutlined fontSize="inherit" />}
                    style={{backgroundColor:"#fff",}}
                >
                    {t("main.switch_to_dark_mode")}
                </Button> :
                <Button
                    aria-label="light"
                    size="large"
                    variant='outlined'
                    startIcon={<LightModeOutlined fontSize="inherit" />}
                    sx={{
                        bgcolor:'#fff2'
                    }}
                >{t("main.switch_to_dark_mode")}</Button>
            }
        </>
    );
}

export default ThemeButton;


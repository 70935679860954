import {
  Box,
  alpha,
  styled,
  IconButton,
  Typography,
  FormControl,
  OutlinedInput,
  InputAdornment,
  FormControlProps,
} from "@mui/material";
import { toast } from "react-toastify";
import React, { useState } from "react";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import { useTranslation } from "react-i18next";

type MyFileFieldProps = {
  importfile?: boolean;
  importFolder?: boolean;
  filechanged: Function;
};

const MyFileField = styled((props: FormControlProps & MyFileFieldProps) => {
  const { t } = useTranslation();
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
  const [selectedFileName, setSelectedFileName] = useState<string>("");
  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      let check = false;
      const filesName = Array.from(files)
        .filter((file) => {
          if (file.name.endsWith(".mp3")) {
            props.filechanged({ files: files, ischange: false });
            return true;
          } else {
            check = true;
            return false;
          }
        })
        .map((file) => file.name);
      if (filesName?.length === 0) {
        toast(`${t("main.This_Folder_Doesnot_Contain_mp3_File")}`);
      } else if (check) {
        toast(`${t("main.This_Folder_Contains_Files_Other_That_mp3")}`);
      } else {
        setSelectedFileName(files[0]?.webkitRelativePath?.split("/")[0]);
        setSelectedFiles(filesName);
      }
      props.filechanged({ files });
    } else {
      setSelectedFileName("");
      setSelectedFiles([]);
      toast.error(t("main.This_Folder_Doesn't_Contain_Any_File"));
    }
  };

  return (
    <FormControl variant="outlined">
      <Box style={{ display: "flex" }}>
        <Typography component="span" style={{ width: "140px" }}>
          {props?.importFolder
            ? `${t("main.folder_name")}`
            : `${t("main.file_name")}`}
        </Typography>
        <OutlinedInput
          style={{
            color: "white",
            border: "1px solid white",
            height: "35px",
            marginLeft: "48px",
          }}
          placeholder={
            props?.importFolder
              ? t("main.choose_folder")
              : t("main.choose_files")
          }
          value={props?.importFolder ? selectedFileName : selectedFiles}
          inputProps={{
            "aria-label": "Upload File/Folder",
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                component="label"
                edge="end"
                color="primary"
                sx={{ transform: "scale(1.2)" }}
              >
                {props?.importFolder && (
                  <input
                    type="file"
                    multiple
                    accept=".mp3"
                    webkitdirectory=""
                    style={{ display: "none" }}
                    onChange={handleFileSelect}
                    {...({ directory: "true" } as any)}
                  />
                )}
                {props?.importfile && (
                  <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleFileSelect}
                    accept=".mp3"
                    multiple
                    {...({ directory: "true" } as any)}
                  />
                )}
                <FolderOpenOutlinedIcon />
              </IconButton>
            </InputAdornment>
          }
        />
      </Box>
    </FormControl>
  );
})(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    border:
      theme.palette.mode === "light"
        ? "1px solid #ffffff"
        : "1px solid #d6d6d5",
    overflow: "hidden",
    borderRadius: 4,
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:hover": {
      backgroundColor: "#e0e0e077",
    },
    "&.Mui-focused": {
      backgroundColor: "#e0e0e055",
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
  },
}));
export default MyFileField;

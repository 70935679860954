/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Grid,
  Paper,
  Button,
  Dialog,
  Select,
  Checkbox,
  MenuItem,
  useTheme,
  PaperProps,
  Typography,
  DialogTitle,
  FormControl,
  DialogActions,
  DialogContent,
  FormControlLabel,
} from "@mui/material";
import "./DialogsMain.scss";
import { toast } from "react-toastify";
import Draggable from "react-draggable";
import MyFileField from "../Basic/MyFileField";
import MyTextField from "../Basic/MyTextField";
import { useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { VoiceTrackCategory } from "../../types/Wheel";
import React, { useCallback, useEffect, useState } from "react";
import { useChannelIdContext } from "../../context/ChannelIdContext";
import { useTranslation } from "react-i18next";

type importProps = {
  importContentLibrary?: boolean;
};

function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper
        {...props}
        style={{
          backgroundColor: "#34393f",
          color: "#D9D9D9",
          padding: "1rem",
        }}
      />
    </Draggable>
  );
}
const API_URL = process.env.REACT_APP_BACKEND_URL;
export default function ImportFolderDialog({
  props,
  isOpen,
  handleClose,
}: {
  props: importProps;
  isOpen: boolean;
  handleClose: any;
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { setIsTokenExpired, setIsTokenUpdated, IsTokenUpdated } =
    useChannelIdContext();
  const [fileList, setFileList] = useState([]);
  const [, setChecked] = useState<boolean>(false);
  const [recursive, setRecursive] = useState(false);
  const [fileListModal, setFileListModal] = useState(false);
  const [customerList, setCustomerList] = useState<any>([]);
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  const [selectCustomer, setSelectCustomer] = useState<any>("");
  const [openCategory, setOpenCategory] = useState<boolean>(false);
  const [selectedCategroy, setSelectedCategroy] = useState<string>("");
  const [selectedAudioList, setSelectedAudioList] = useState<string[]>([]);
  const [categoryList, setCategoryList] = useState<VoiceTrackCategory[]>([]);
  const { setIsUploadAudio } = useChannelIdContext();

  const handleAudioChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    file: string
  ) => {
    if (e.target.checked) {
      setSelectedAudioList([...selectedAudioList, file]);
      setChecked(true);
    } else {
      setSelectedAudioList(selectedAudioList?.filter((item) => item !== file));
      setChecked(false);
    }
  };

  const fetchVoiceTrackCategoryList = useCallback(async () => {
    if (location?.pathname?.includes("content-library")) {
      fetch(`${API_URL}/VoiceTrackCategory/GetAll`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token?.accessToken}`,
        },
      })
        .then((response) => {
          if (
            response.ok === false &&
            response.status === 401 &&
            token?.refreshToken !== ""
          ) {
            setIsTokenExpired(true);
          } else if (response.status === 401 && token?.refreshToken === "") {
            navigate("/");
            localStorage.removeItem("token");
            localStorage.clear();
          } else {
            setIsTokenUpdated(false);
            return response.json();
          }
        })
        .then((data) => {
          if (data.success === true) {
            setCategoryList(data?.data);
          } else {
            setCategoryList([]);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.pathname, setCategoryList, IsTokenUpdated]);

  const handleCheckAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      const allSelectedFiles = Array.from(fileList)?.map(
        (file: string) => file
      );
      setChecked(true);
      setSelectedAudioList(allSelectedFiles);
    } else {
      setChecked(false);
      setSelectedAudioList([]);
    }
  };

  const fetchCustomerList = useCallback(async () => {
    fetch(`${API_URL}/Customer/GetAll`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    })
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data.success === true) {
          setCustomerList(data?.data);
        } else {
          setCustomerList([]);
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [IsTokenUpdated]);

  const postAudio = async () => {
    const formData = new FormData();
    for (let i = 0; i < fileList?.length; i++) {
      formData?.append("files", fileList[i]);
    }

    const response = await fetch(
      `${API_URL}/VoiceTrack/UploadVoiceTracks/${selectedCategroy}?customerId=${selectCustomer}`,
      {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `bearer ${token?.accessToken}`,
        },
      }
    )
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data?.success === true) {
          handleClose();
          setSelectCustomer("");
          setIsUploadAudio(true);
          setOpenCategory(false);
          setSelectedCategroy("");
          setSelectedAudioList([]);
          toast.success(t("main.Audio_Uploaded_Successfully"));
        } else {
          handleClose();
          setSelectCustomer("");
          setSelectedCategroy("");
          setSelectedAudioList([]);
          toast.error(t("main.SOMETHING_IS_BREAKING"));
        }
      });
  };
  useEffect(() => {
    if(isOpen === true){
      fetchVoiceTrackCategoryList();
    }
  }, [fetchVoiceTrackCategoryList,isOpen]);

  useEffect(() => {
    if (location?.pathname?.includes("content-library") && isOpen === true) fetchCustomerList();
  }, [fetchCustomerList, location?.pathname,isOpen]);

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        handleClose();
        setFileListModal(false);
        setSelectedAudioList([]);
        setSelectedCategroy("");
        setSelectCustomer("");
      }}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle
        className="dialog-title"
        style={{ cursor: "move" }}
        id="draggable-dialog-title"
      >
        <Typography sx={{ fontWeight: 700, fontSize: "24px" }}>
          {t("main.new_folder")}
        </Typography>
        <span
          onClick={() => {
            handleClose();
            setFileListModal(false);
            setSelectedCategroy("");
            setSelectCustomer("");
          }}
          className="modelCloseBtn"
        >
          <CloseIcon />
        </span>
      </DialogTitle>
      {!fileListModal ? (
        <>
          <DialogContent className="content">
            <Grid item xs={10} lg={12} xl={7}>
              <div>
                <MyFileField
                  importFolder
                  filechanged={(value: any) => {
                    setFileList(value?.files);
                    setFileListModal(value?.ischange);
                  }}
                ></MyFileField>
              </div>
              <br />
              <div style={{ display: "flex" }}>
                <Typography style={{ width: "68px" }}>
                  {t("main.customer")}
                </Typography>
                <Select
                  style={{
                    width: "50%",
                    color: "white",
                    border: "1px solid",
                    marginLeft: "122px",
                    height: "35px",
                  }}
                  value={selectCustomer || ""}
                  displayEmpty
                  renderValue={(value) => {
                    if (value) {
                      return customerList?.find(
                        (item: any) => item.id === parseInt(selectCustomer)
                      )?.name;
                    } else {
                      return <Typography>{t("main.customer")}</Typography>;
                    }
                  }}
                  onChange={(event: any) =>
                    setSelectCustomer(event.target.value)
                  }
                >
                  {customerList?.map((item: any) => (
                    <MenuItem key={item?.id} value={item?.id}>
                      {item?.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <br />
              <div style={{ display: "flex" }}>
                <Typography style={{ width: "92px" }}>
                  {t("main.category")}
                </Typography>
                <Select
                  style={{
                    width: "50%",
                    color: "white",
                    border: "1px solid",
                    marginLeft: "96px",
                    height: "35px",
                  }}
                  value={selectedCategroy || ""}
                  displayEmpty
                  renderValue={(value) => {
                    if (value) {
                      return categoryList?.find(
                        (item: any) => item?.id === parseInt(selectedCategroy)
                      )?.name;
                    } else {
                      return <Typography>{t("main.category")}</Typography>;
                    }
                  }}
                  onChange={(event: any) =>
                    setSelectedCategroy(event.target.value)
                  }
                >
                  {categoryList?.map((item: any) => (
                    <MenuItem key={item?.id} value={item?.id}>
                      {item?.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </Grid>
            {!props?.importContentLibrary && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={recursive}
                    sx={{
                      "& .MuiSvgIcon-root": { fontSize: 30 },
                      color: "#D9D9D9",
                    }}
                    onChange={(e) => setRecursive(e.target.checked)}
                  />
                }
                label="Recursive"
                sx={{ pt: 2 }}
              />
            )}
            {!props?.importContentLibrary && (
              <Paper elevation={3} sx={{ p: "1px", mt: 3 }}>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: 600, pb: 2, pt: 2, pl: 1 }}
                >
                  {t("main.import_to_playlist")}
                </Typography>
                <Paper
                  elevation={3}
                  sx={{ p: 2, backgroundColor: "#34393f", color: "#D9D9D9" }}
                >
                  <MyTextField
                    sx={{
                      width: 350,
                      "& .MuiOutlinedInput-root": {
                        border:
                          theme.palette.mode === "light"
                            ? "1px solid #ffffff"
                            : "1px solid #d6d6d5",
                      },
                    }}
                  ></MyTextField>
                </Paper>
              </Paper>
            )}
          </DialogContent>
          <DialogActions
            sx={{ display: "flex", justifyContent: "space-around" }}
          >
            {!props?.importContentLibrary ? (
              <>
                <Button
                  onClick={() => {
                    postAudio();
                    setFileListModal(false);
                    handleClose();
                  }}
                  disabled={selectedCategroy === ""}
                  size="medium"
                  variant="contained"
                  color="primary"
                  sx={{ mt: 5 }}
                >
                  {t("main.Import")}
                </Button>
                <Button
                  onClick={() => {
                    handleClose();
                    setFileListModal(false);
                    setSelectedCategroy("");
                    setSelectCustomer("");
                  }}
                  size="medium"
                  variant="outlined"
                  color="secondary"
                  sx={{ mt: 5 }}
                >
                  {t("main.cancel")}
                </Button>
              </>
            ) : (
              <>
                <Button
                  onClick={() => {
                    postAudio();
                    handleClose();
                  }}
                  disabled={selectCustomer === "" || selectedCategroy === ""}
                  size="medium"
                  variant="contained"
                  color="primary"
                  sx={{ mt: 5 }}
                >
                  {t("main.Import")}
                </Button>
                <Button
                  onClick={() => {
                    handleClose();
                    setFileListModal(false);
                    setSelectedCategroy("");
                    setSelectCustomer("");
                  }}
                  size="medium"
                  variant="outlined"
                  color="secondary"
                  sx={{ mt: 5 }}
                >
                  {t("main.cancel")}
                </Button>
              </>
            )}
          </DialogActions>
        </>
      ) : !openCategory ? (
        <>
          <DialogContent>
            <div className="custom-checkbox">
              <input
                className="form-check-input"
                type="checkbox"
                id="selectAll"
                checked={selectedAudioList?.length === fileList?.length}
                onChange={handleCheckAll}
              />
              <label htmlFor="selectAll">Select all</label>
            </div>
            <div className="">
              {Array.from(fileList)?.map((file: any, index) => (
                <div className="custom-checkbox" key={index}>
                  <input
                    checked={selectedAudioList?.includes(file)}
                    type="checkbox"
                    id={`checkbox-${index}`}
                    onChange={(e) => {
                      handleAudioChange(e, file);
                    }}
                  />

                  <label htmlFor={`checkbox-${index}`}>{file?.name}</label>
                </div>
              ))}
            </div>
          </DialogContent>
          <DialogActions
            sx={{ display: "flex", justifyContent: "space-around" }}
          >
            <Button
              onClick={() => setOpenCategory(true)}
              disabled={selectedAudioList?.length === 0}
              size="medium"
              variant="contained"
              color="primary"
              sx={{ mt: 5 }}
            >
              {t("main.import_Open_btn")}
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <FormControl className="modal-select">
            <Select
              onChange={(e: any) => {
                setSelectedCategroy(e.target.value);
              }}
              value={selectedCategroy || ""}
              displayEmpty
              renderValue={(value) => {
                if (value) {
                  return categoryList?.find(
                    (item) => item.id === parseInt(selectedCategroy)
                  )?.name;
                } else {
                  return "Please select voice track category";
                }
              }}
              fullWidth
              className="selectBox"
              labelId="simple-select-label"
              id="simple-select"
              sx={{
                m: 1,
                minWidth: 300,
                height: "34px",
                margin: "20px 0",
                border: "1px solid #E8E8E8 !important",
                color: "#E8E8E8 !importnat",
                borderRadius: "10px",
              }}
            >
              {categoryList?.map((item: any) => {
                return (
                  <MenuItem value={item?.id} key={item?.id}>
                    {item?.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <DialogActions
            sx={{ display: "flex", justifyContent: "space-around" }}
          >
            <Button
              onClick={() => {
                postAudio();
                handleClose();
              }}
              disabled={selectedCategroy === ""}
              size="medium"
              variant="contained"
              color="primary"
              sx={{ mt: 5 }}
            >
              {t("main.Import")}
            </Button>
            <Button
              onClick={() => {
                handleClose();
                setFileListModal(false);
                setSelectCustomer("");
                setSelectedCategroy("");
              }}
              size="medium"
              variant="outlined"
              color="secondary"
              sx={{ mt: 5 }}
            >
              {t("main.cancel")}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}

import { createContext } from "react";
import {  createTheme, Theme } from '@mui/material/styles';

interface ThemeProviderInterface {
    theme?:Theme,
    themeChanged:Function
  }

export const ThemeContext = createContext<ThemeProviderInterface>({theme: createTheme({
    palette: {
      mode: 'light',
      secondary: {
          main: "#d9d9d9",
          light: "#d9d9d9",
          dark: "#d9d9d9",
      },
    }
  }),themeChanged:() => {}});;


  function ThemeTogglerButton({children}:{children:JSX.Element}) {
    return (
      <ThemeContext.Consumer>
        {({theme, themeChanged}) => (        
        <span
            onClick={() => themeChanged(theme?.palette?.mode === 'light' ? 'dark' : 'light')}
        >
            {children}
          </span>
        )}
      </ThemeContext.Consumer>
    );
  }
  
  export default ThemeTogglerButton;
  
  
import PauseCircleRoundedIcon from "@mui/icons-material/PauseCircleRounded";
import {
  Button,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Slider,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import WaveSurfer from "wavesurfer.js";
import RegionsPlugin from "wavesurfer.js/src/plugin/regions";
import { ReactComponent as Pauseicon } from "../../assets/images/Pausebtnicon.svg";
import { ReactComponent as PlayIcon } from "../../assets/images/Playbtnicon.svg";
import { useChannelIdContext } from "../../context/ChannelIdContext";
import { songList } from "../../types/Audio";

const Waveform = ({
  audio,
  playing,
  setPlaying,
  setAudioData,
}: {
  audio: songList;
  playing: boolean;
  setPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  setAudioData: React.Dispatch<React.SetStateAction<songList>>;
}) => {
  const API_URL = process.env.REACT_APP_BACKEND_URL;
  const [devices, setDevices] = useState<any>(null);
  const audioRef = useRef<any>(null);
  const waveformRef = useRef<HTMLDivElement>(null);
  const [startTime, setStartTime] = useState<number>(audio?.queueInPoint);
  const [endTime, setEndTime] = useState<number>(audio?.queueOutPoint);
  const { setIsTokenExpired, setIsTokenUpdated } = useChannelIdContext();
  const [waveform, setWaveform] = useState<WaveSurfer | null>(null);
  const [selectedDeviceId, setSelectedDeviceId] = useState<string>("");
  const { t } = useTranslation();
  let token = JSON.parse(localStorage?.getItem("token") ?? "{}");
  const navigate = useNavigate();
  console.log(new Date(100 * 1000).toISOString().substring(14, 19));
  const listDevices = async () => {
    const devices = await navigator?.mediaDevices?.enumerateDevices?.();
    if (devices) {
      const video: any = [];
      let audio: any = [];
      for (const device of devices) {
        switch (device?.kind) {
          case "audiooutput":
            audio?.push(device);
            const newData = audio.filter(
              (item: any) => item?.deviceId !== "communications"
            );
            audio = [...newData];
            break;
        }
      }
      return { video, audio };
    } else {
      throw new Error("No support for multimedia devices !");
    }
  };

  async function getConnectedDevices(callback: any) {
    try {
      await navigator?.mediaDevices?.getUserMedia({ audio: true });
      const devices = await navigator?.mediaDevices?.enumerateDevices();
      // console.log(devices)
      const filtered = devices?.filter(
        (device: any) =>
          device?.kind === "audiooutput" &&
          device?.deviceId !== "communications" &&
          device?.deviceId !== "default"
      );
      callback(filtered);
    } catch (e) {
      console.log(e);
    }
  }

  const handlePlay = () => {
    if (startTime > 0 && endTime > 0) {
      waveform!.play(startTime, endTime);
      setPlaying(true);
    } else {
      waveform!.play();
      setPlaying(true);
    }
  };

  const handleStop = () => {
    waveform!.stop();
  };

  const handlePause = () => {
    waveform!.pause();
    setPlaying(false);
  };

  const handleStartTimeChange = () => {
    setAudioData({ ...audio, queueInPoint: startTime });
    waveform?.setCurrentTime(startTime);
  };

  const handleEndTimeChange = () => {
    setAudioData({ ...audio, queueOutPoint: endTime });
    waveform!.clearRegions();
    waveform!.addRegion({
      start: startTime,
      end: endTime,
      color: "hsla(191, 188%, 183%, 0.5)",
    });
  };

  const handleSliderChange = (event: Event, newValue: number | any) => {
    waveform!.setVolume(newValue / 100);
  };
  useEffect(() => {
    const fetchSong = () => {
      fetch(`${API_URL}/Song/GetMp3/${audio.id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/octet-stream",
          Authorization: `bearer ${token?.accessToken}`,
        },
      })
        .then((response) => {
          if (
            response.ok === false &&
            response.status === 401 &&
            token?.refreshToken !== ""
          ) {
            setIsTokenExpired(true);
          } else if (response.status === 401 && token?.refreshToken === "") {
            navigate("/");
            localStorage.removeItem("token");
            localStorage.clear();
          } else {
            setIsTokenUpdated(false);
            return response.blob();
          }
        })
        .then((data) => {
          const blob: any = data;
          const wavesurfer = WaveSurfer?.create({
            container: waveformRef?.current!,
            waveColor: "#009b00",
            backgroundColor: "#000000",
            barGap: 2,
            barWidth: 3,
            barRadius: 3,
            cursorWidth: 3,
            cursorColor: "#FFFFFF",
            plugins: [
              RegionsPlugin.create({
                container: "#wave-timeline",
                regions: [
                  {
                    start: startTime, // in seconds
                    end: endTime,
                    color: "hsla(191, 188%, 183%, 0.5)",
                  },
                ],
              }),
            ],
          });
          setWaveform(wavesurfer);
          console.log(blob);
          wavesurfer?.loadBlob(blob);
          wavesurfer?.on("ready", () => {
            wavesurfer?.pause();
            setPlaying(false);
            if (audioRef?.current) {
              wavesurfer!.setVolume(audioRef?.current?.volume);
              wavesurfer!.setPlaybackRate(audioRef?.current?.playbackRate);
            }
          });
          wavesurfer.on("play", () => {
            setPlaying(true);
          });

          wavesurfer.on("pause", () => {
            setPlaying(false);
          });

          wavesurfer.on("finish", () => {
            setPlaying(false);
          });

          if (selectedDeviceId) wavesurfer?.setSinkId(selectedDeviceId);

          return () => {
            wavesurfer.destroy();
          };
        });
    };
    fetchSong();
  }, [audio?.coverImageFile, setPlaying, selectedDeviceId]);

  useEffect(() => {
    if (audioRef?.current) {
      audioRef?.current?.setSinkId(selectedDeviceId);
    }
  }, [selectedDeviceId, audio?.coverImageFile]);

  useEffect(() => {
    getConnectedDevices((devices: any) => {
      setDevices(devices);
    });
  }, [audio?.coverImageFile]);

  const mediaDevice = navigator?.mediaDevices;
  useEffect(() => {
    mediaDevice.ondevicechange = () => {
      getConnectedDevices((devices: any) => {
        setDevices(devices);
      });
    };
  });

  const handleAutoSetMarkers = async () => {
    const filename = audio?.fileName;
    if (filename !== "" || null || undefined) {
      fetch(`${API_URL}/Song/GetMarkersByFileName?filename=${filename}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token?.accessToken}`,
        },
      })
        .then((response) => {
          if (
            response.ok === false &&
            response.status === 401 &&
            token?.refreshToken !== ""
          ) {
            setIsTokenExpired(true);
          } else if (response.status === 401 && token?.refreshToken === "") {
            navigate("/");
            localStorage.removeItem("token");
            localStorage.clear();
          } else {
            setIsTokenUpdated(false);
            return response.json();
          }
        })
        .then((data) => {
          if (data.success === true) {
            const audioElement = new Audio(audio.coverImageFile);
            if (data.success === true) {
              // Update the state and audio data
              setStartTime(data?.data?.queueIn);
              setEndTime(data?.data?.queueOut);
              setAudioData({
                ...audio,
                queueInPoint: data?.data?.queueIn,
                queueOutPoint: data?.data?.queueOut,
              });
              // Use RegionsPlugin's addRegion method
              waveform!.addRegion({
                start: data?.data?.queueIn,
                end: data?.data?.queueOut,
                color: "hsla(191, 188%, 183%, 0.5)",
              });
              // Load the audio element
              audioElement.load();
            }
          }
        });
    }
  };
  return (
    <Stack direction="column">
      <>
        <div id="wave" ref={waveformRef}></div>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          mt={"15px"}
          gap={"10px"}
        >
          <div className="PlayBtn">
            <div className="BtnPart">
              {playing === true ? (
                <IconButton onClick={handlePause}>
                  <PauseCircleRoundedIcon
                    sx={{ fontSize: "1.6rem", color: "#fff" }}
                  />
                </IconButton>
              ) : (
                <IconButton onClick={handlePlay}>
                  <Tooltip title={t("main.Play")}>
                    <PlayIcon />
                  </Tooltip>
                </IconButton>
              )}
              <IconButton aria-label="next song" onClick={handleStop}>
                <Tooltip title={t("main.Pause")}>
                  <Pauseicon />
                </Tooltip>
              </IconButton>
            </div>
            <FormControl className="DrpCustom-btn" fullWidth>
              <Select
                className="DrpCustom"
                displayEmpty
                value={selectedDeviceId || ""}
                renderValue={(value) => {
                  if (value) {
                    return devices?.find(
                      (item: any) => item?.deviceId === selectedDeviceId
                    )?.label;
                  } else {
                    return (
                      <Typography style={{ color: "#000" }}>
                        {t("main.Output_device")}
                      </Typography>
                    );
                  }
                }}
                onChange={async (event: SelectChangeEvent) => {
                  setSelectedDeviceId(event.target.value);
                  await waveform!.setSinkId(event.target.value);
                }}
              >
                {!devices ? (
                  <MenuItem>{t("main.No_Device_Connected")}</MenuItem>
                ) : (
                  devices?.map((device: any, index: number) => (
                    <MenuItem key={device?.deviceId} value={device?.deviceId}>
                      {device?.label || device?.deviceId || device?.groupId}
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>
            <Slider
              className="VolumeSlider"
              sx={{ color: "#777d8569" }}
              defaultValue={100}
              onChange={handleSliderChange}
              step={10}
              min={0}
              max={100}
              marks={true}
              valueLabelDisplay="auto"
            />
          </div>
          <Stack className="SetTime" direction="row" spacing={2}>
            <Stack
              direction="column"
              justifyContent="start"
              sx={{ width: "7rem" }}
            >
              <TextField
                variant="outlined"
                placeholder="hh:mm"
                size="small"
                type="text"
                id="startTime"
                defaultValue={new Date(startTime * 1000)
                  .toISOString()
                  .substring(14, 19)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  // waveform!.setCurrentTime(parseInt(e.target.value, 10) * 1000);
                  if (e.target.value.includes(":")) {
                    var a = e.target.value.split(":"); // split it at the colons
                    var seconds = +a[0] * 60 + +a[1];
                    setStartTime(seconds);
                    setAudioData({ ...audio, queueInPoint: seconds });
                  }
                }}
              />
              {/*<Button
                variant="contained"
                color="primary"
                sx={{ mt: 1, textTransform: "none" }}
                onClick={handleStartTimeChange}
              >
                {t("main.Set_Start")}
              </Button>*/}
            </Stack>
            <Stack
              direction="column"
              justifyContent="start"
              sx={{ width: "7rem" }}
            >
              <TextField
                variant="outlined"
                placeholder="hh:mm"
                size="small"
                type="text"
                id="endTime"
                defaultValue={new Date(endTime * 1000)
                  .toISOString()
                  .substring(14, 19)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  // const value = parseInt(e.target.value, 10);
                  if (e.target.value.includes(":")) {
                    var a = e.target.value.split(":"); // split it at the colons
                    var seconds = +a[0] * 60 + +a[1];
                    setEndTime(seconds);
                    setAudioData({ ...audio, queueOutPoint: seconds });
                  }
                }}
              />
              {/*<Button
                onClick={handleEndTimeChange}
                variant="contained"
                color="primary"
                sx={{ mt: 1, textTransform: "none" }}
              >
                {t("main.Set_Mix")}
              </Button>*/}
            </Stack>
          </Stack>
          <div className="SetTime">
            <Button
              onClick={() => {
                handleAutoSetMarkers();
              }}
              variant="contained"
              color="primary"
              size="large"
              sx={{ flexDirection: "column", textTransform: "none" }}
            >
              {t("main.Auto_Set_Markers")}
            </Button>
          </div>
        </Stack>
      </>
    </Stack>
  );
};

export default Waveform;

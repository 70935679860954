/* eslint-disable react-hooks/exhaustive-deps */
import {
  Grid,
  Select,
  MenuItem,
  Typography,
  FormControl,
  SelectChangeEvent,
  TextField,
  useTheme,
} from "@mui/material";
import PriortySlider from "./PrioritySlider";
import MyTextField from "../Basic/MyTextField";
import { useNavigate } from "react-router-dom";
import { Artist, songList, songType } from "../../types/Audio";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useChannelIdContext } from "../../context/ChannelIdContext";
const API_URL = process.env.REACT_APP_BACKEND_URL;

export function DataInputRow({
  header,
  value,
  inputChanged,
}: {
  header: string;
  value: string;
  inputChanged: Function;
}) {
  return (
    <>
      <Grid
        item
        xs={12}
        xl={3}
        md={3}
        container
        direction="column"
        alignItems="start"
        justifyContent="center"
      >
        <Typography component="span" sx={{ fontSize: 17 }}>
          {header}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        xl={9}
        md={9}
        container
        direction="column"
        justifyContent="center"
      >
        <MyTextField
          hiddenLabel
          value={value}
          onChange={(e) => inputChanged(e.target.value)}
          variant="filled"
          sx={{ width: 350 }}
          size="small"
        ></MyTextField>
      </Grid>
    </>
  );
}

function SliderInputRow({
  header,
  value,
  inputChanged,
}: {
  header: string;
  value: number;
  inputChanged: Function;
}) {
  return (
    <>
      <Grid
        item
        xs={12}
        md={3}
        container
        direction="column"
        alignItems="start"
        justifyContent="center"
      >
        <Typography component="span" style={{ fontSize: 17 }}>
          {header}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={9}
        container
        direction="column"
        justifyContent="center"
      >
        <PriortySlider value={value} changeHandle={inputChanged} />
      </Grid>
    </>
  );
}

export default function ViewAudioMetaData({
  audio,
  setAudioData,
}: {
  audio: songList;
  setAudioData: React.Dispatch<React.SetStateAction<songList>>;
}) {
  const theme = useTheme();
  const {t} = useTranslation();
  let navigate = useNavigate();
  const { setIsTokenExpired, setIsTokenUpdated, IsTokenUpdated } = useChannelIdContext()
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  const [artistList, setArtistList] = useState<Artist[]>([]);
  const [songType, setSongType] = useState<songType[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      fetch(`${API_URL}/Artist/GetAll`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token?.accessToken}`,
        },
      })
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true)
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false)
          return response.json()
        }
      })
      .then((data) => {
        if (data.success === true) {
          setArtistList(data?.data);
        } else {
          setArtistList([]);
        }
      })}
    fetchData();
  }, [IsTokenUpdated]);


  useEffect(() => {
    const fetchSongType = async () => {
      fetch(`${API_URL}/SongType/GetAll`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token?.accessToken}`,
        },
      })
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true)
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false)
          return response.json()
        }
      })
      .then((data) => {
        if (data.success === true) {
          setSongType(data?.data);
        } else {
          setSongType([]);
        }
      })
    }
    fetchSongType()
  }, [IsTokenUpdated]);
  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <DataInputRow
        header={t("main.title")}
        value={audio?.title ? audio?.title : ""}
        inputChanged={(value: string) =>
          setAudioData({ ...audio, title: value })
        }
      />
      <>
        <Grid
          item
          xs={12}
          md={3}
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
        >
          <Typography component="span" style={{ fontSize: 17, color: theme.palette.mode === "dark" ? "#fff" : "#000" }}>
            {t("main.Artist")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={9}
          container
          direction="column"
          justifyContent="center"
        >
          <FormControl sx={{ width: 350 }}>
            <Select
              style={{ color: theme.palette.mode === "dark" ? "#000" : "#000" }}
              value={
                artistList?.some((item) => item.id === audio.artist1Id)
                  ? audio.artist1Id.toString()
                  : ""
              }
              onChange={(e) =>
                setAudioData({ ...audio, artist1Id: parseInt(e.target.value) })
              }
            >
              {artistList.map((item) => (
                  <MenuItem sx={{ width: "10% !important" }} key={item?.id} value={item?.id}>
                    {item?.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      </>
      <>
        <Grid
          item
          xs={12}
          md={3}
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
        >
          <Typography component="span" variant="h6"></Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={9}
          container
          direction="column"
          justifyContent="center"
        >
          <FormControl sx={{ width: 350 }}>
            <Select
              style={{ color: theme.palette.mode === "dark" ? "#000" : "#000" }}
              value={
                artistList?.some((item) => item.id === audio?.artist2Id)
                  ? audio?.artist2Id?.toString()
                  : ""
              }
              onChange={(e) => {
                const selectedArtistId = parseInt(e.target.value);
                const selectedArtist = artistList.find((item) => item.id === selectedArtistId);

                setAudioData({
                  ...audio,
                  artist2Id: selectedArtistId,
                  artist2Name: selectedArtist?.name || "",
                });
              }}
            >
              {artistList.map((item: Artist) => (
                <MenuItem key={item?.id} value={item?.id}>
                  {item?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </>
      <>
        <Grid
          item
          xs={12}
          md={3}
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
        >
          <Typography component="span" variant="h6"></Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={9}
          container
          direction="column"
          justifyContent="center"
        >
          <FormControl sx={{ width: 350 }}>
            <Select
              labelId="demo-simple-select-label"
              style={{ color: theme.palette.mode === "dark" ? "#000" : "#000" }}
              id="demo-simple-select"
              value={
                artistList?.some((item) => item?.id === audio?.artist3Id)
                  ? audio?.artist3Id?.toString()
                  : ""
              }
              onChange={(e) => {
                const selectedArtistId = parseInt(e.target.value);
                const selectedArtist = artistList.find((item) => item.id === selectedArtistId);

                setAudioData({
                  ...audio,
                  artist3Id: selectedArtistId,
                  artist3Name: selectedArtist?.name || "",
                });
              }}
            >
              {artistList?.map((item: Artist) => (
                <MenuItem key={item?.id} value={item?.id}>
                  {item?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </>
      <Grid
        item
        xs={12}
        xl={3}
        md={3}
        container
        direction="column"
        alignItems="start"
        justifyContent="center">
        <Typography component="span" style={{ fontSize: 17 }}>{t("main.Year")}</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        xl={9}
        md={9}
        container
        direction="column"
        justifyContent="center"
      >
        <TextField type="text" style={{ width: "93%" }} value={audio?.releaseYear === "" ? "" : audio?.releaseYear} onChange={(e: any) =>
          setAudioData({ ...audio, releaseYear: e.target.value })
        } />
      </Grid>
      <>
        <Grid
          item
          xs={12}
          md={3}
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
        >
          <Typography component="span" style={{ fontSize: 17 }}>
            {t("main.Type")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={9}
          container
          direction="column"
          justifyContent="center"
        >
          <FormControl sx={{ width: 350 }}>
            <Select
              style={{ color: theme.palette.mode === "dark" ? "#000" : "#000" }}
              value={
                songType?.some((item) => item.id === audio?.songTypeId)
                  ? audio?.songTypeId.toString()
                  : ""
              }
              onChange={(e: SelectChangeEvent) =>
                setAudioData({ ...audio, songTypeId: parseInt(e.target.value) })
              }
            >
              {songType?.map((item: songType) => (
                <MenuItem key={item?.id} value={item?.id} >
                  {item?.typeName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </>
      <SliderInputRow
        header={t("main.priority")}
        value={audio?.priority === 0 ? 0 : audio?.priority}
        inputChanged={(value: number) =>
          setAudioData({ ...audio, priority: value })
        }
      />
    </Grid>
  );
}

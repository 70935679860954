/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  List,
  Grid,
  Paper,
  Button,
  Dialog,
  useTheme,
  Accordion,
  IconButton,
  Typography,
  DialogTitle,
  ListItemText,
  DialogActions,
  ListItemButton,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { Attribute } from "../../types/Audio";

import { Add, Remove, Close } from "@mui/icons-material";
import React, { useCallback, useEffect, useState } from "react";
import { useChannelIdContext } from "../../context/ChannelIdContext";
import { Category_Attributes, Category_List } from "../../types/Category";
import { useTranslation } from "react-i18next";

const API_URL = process.env.REACT_APP_BACKEND_URL;

export default function Characteristics({
  attributeId,
  setAttributeId,
}: {
  attributeId: number[];
  setAttributeId: React.Dispatch<React.SetStateAction<number[]>>;
}) {
  const theme = useTheme();
  let navigate = useNavigate();
  const { t } = useTranslation();
  const [type, setType] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [form, setform] = useState<string>("");
  const { currentUser } = useChannelIdContext();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  const [categoryId, setCategoryId] = useState<number>(0);
  const [categoryList, setCategoryList] = useState<Category_List[]>([]);
  const [selectedAttributeId, setSelectedAttributeId] = useState<number>(0);
  const [selectedAttributes, setSelectedAttributes] = useState<Attribute[]>([]);
  const [selectAttributes, setSelectAttributes] = useState<any[]>([])

  const { id } = useParams();
  const fetchCategoriesList = async () => {
    fetch(`${API_URL}/Category/GetAll`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    })
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data.success === true) {
          console.log(data)
          setCategoryList(data?.data);
        } else {
          setCategoryList([]);
        }
      });
  };

  const handleAdd = (name: string) => {
    if (!currentUser?.editSongCategory) {
      toast.error(t("main.User_doesn't_have_authority_to_edit_song_category"));
      return;
    }
    setType(name);
    setIsOpen(true);
  };
  const handleDoubleClickEdit = (
    id: number,
    name: string,
    preFilledName: string
  ) => {
    setType(name);
    setIsOpen(true);
    setform(preFilledName);
    setSelectedAttributeId(id);
  };

  const { setIsTokenExpired, setIsTokenUpdated, IsTokenUpdated } =
    useChannelIdContext();
  const handleClose = () => {
    setIsOpen(false);
  };

  const handleAddAttribute = async (e: any) => {
    e.preventDefault();
    if (!form) {
      toast.error(t("main.Please_Add_New_Attribute"));
    } else {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token?.accessToken}`,
        },
        body: JSON.stringify([
          {
            name: form,
            categoryId: categoryId,
          },
        ]),
      };
      try {
        fetch(`${API_URL}/Attribute`, requestOptions)
          .then((response) => {
            if (
              response.ok === false &&
              response.status === 401 &&
              token?.refreshToken !== ""
            ) {
              setIsTokenExpired(true);
            } else if (response.status === 401 && token?.refreshToken === "") {
              navigate("/");
              localStorage.removeItem("token");
              localStorage.clear();
            } else {
              setIsTokenUpdated(false);
              return response.json();
            }
          })
          .then((data) => {
            if (data?.success) {
              toast.success(t("main.Attribute_Added_Successfully"));
              handleClose();
              fetchCategoriesList();
            } else {
              toast.error(t("main.SOMETHING_WENT_WRONG"));
            }
          });
      } catch (error) {
        toast.error(t("main.SOMETHING_WENT_WRONG"));
      }
    }
  };

  const handleEditAttribute = async (e: any) => {
    if (!form) {
      toast.error(t("main.Please_Enter_Attribute_to_Edit"));
    } else {
      e.preventDefault();
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token?.accessToken}`,
        },
        body: JSON.stringify({
          id: selectedAttributeId,
          name: form,
          categoryId: categoryId,
        }),
      };
      fetch(`${API_URL}/Attribute`, requestOptions)
        .then((response) => {
          if (
            response.ok === false &&
            response.status === 401 &&
            token?.refreshToken !== ""
          ) {
            setIsTokenExpired(true);
          } else if (response.status === 401 && token?.refreshToken === "") {
            navigate("/");
            localStorage.removeItem("token");
            localStorage.clear();
          } else {
            setIsTokenUpdated(false);
            return response.json();
          }
        })
        .then((data) => {
          if (data?.success) {
            toast.success(`${t("main.Attribute_Edited")}`);
            handleClose();
            fetchCategoriesList();
          } else {
            toast.error(data.message);
          }
        });
    }
  };

  const fetchData = useCallback(async () => {
    fetch(`${API_URL}/Song/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    })
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data.success === true) {
          console.log(data)
          setSelectedAttributes(data?.data?.attributes);
        } else {
          setSelectedAttributes([]);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, IsTokenUpdated]);

  const handleAttributeSelection = (attribute: Category_Attributes) => {
    // const isSelected = selectedAttributes?.some(
    //   (attr) => attr?.id === attribute.id
    // );
    // if (isSelected) {
    //   setSelectedAttributes(
    //     selectedAttributes?.filter((attr) => attr?.id !== attribute.id)
    //   );
    // } else {
    //   setSelectedAttributes([...selectedAttributes, attribute]);
    // }

    const isSelected = selectAttributes?.some(
      (attr) => attr?.id === attribute.id
    );
    if (isSelected) {
      setSelectAttributes(
        selectAttributes?.filter((attr) => attr?.id !== attribute.id)
      );
      setSelectedAttributes(
            selectedAttributes?.filter((attr) => attr?.id !== attribute.id)
          );
    } else {
      setSelectAttributes([...selectAttributes, attribute]);
      setSelectedAttributes([...selectedAttributes, attribute]);
    }

  };

  const isAttributeSelected = (attribute: Category_Attributes) => {
    return selectedAttributes?.some((attr) => attr?.id === attribute.id);
  };

  const handleRemove = async (id: string) => {
    if (!currentUser?.editSongCategory) {
      toast.error(t("main.User_doesn't_have_authority_to_edit_song_category"));
      return;
    }
    const deleteArr = selectAttributes?.filter(
      (att: any) => att.categoryId === id
    );
    if (deleteArr?.length > 0) {
      fetch(`${API_URL}/Attribute`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token?.accessToken}`,
        },
        body: JSON.stringify(deleteArr),
      })
        .then((response) => {
          if (
            response.ok === false &&
            response.status === 401 &&
            token?.refreshToken !== ""
          ) {
            setIsTokenExpired(true);
          } else if (response.status === 401 && token?.refreshToken === "") {
            navigate("/");
            localStorage.removeItem("token");
            localStorage.clear();
          } else {
            setIsTokenUpdated(false);
            return response.json();
          }
        })
        .then((data) => {
          if (data?.success) {
            fetchCategoriesList();
            toast.success(t("main.Attribute_Is_Deleted"));
          } 
        });
    } else {
      toast.error(t("main.Select_The_Attributes"));
    }
    // setSelectedAttributes(
    //   selectedAttributes?.filter((att: any) => att?.categoryId !== id)
    // );

    setSelectAttributes(
      selectAttributes?.filter((att: any) => att?.categoryId !== id)
    )
  };

  useEffect(() => {
    fetchCategoriesList();
  }, [IsTokenUpdated]);

  useEffect(() => {
    fetchData();
  }, [fetchData, id]);

  useEffect(() => {
    setAttributeId(selectedAttributes?.map((attr) => attr?.id));
  }, [selectedAttributes, setAttributeId]);

  return (
    <Paper className="tabsPage tabsPage--characteristics">
      <Grid
        container
        direction="row"
        rowSpacing={2}
        columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 5, xl: 8 }}
      >
        {categoryList?.map((category) => (
          <Grid item key={category?.id} xs={12} md={6} lg={3} xl={3}>
            <Accordion
              expanded={true}
              elevation={5}
              sx={{ width: "100%", minHeight: "50vh" }}
            >
              <AccordionSummary
                style={{
                  backgroundColor:
                    theme.palette.mode === "dark" ? "#151515" : "#D9D9D9",
                }}
              >
                <Typography
                  sx={{
                    flexShrink: 0,
                    fontWeight: 600,
                    letterSpacing: 1,
                  }}
                >
                  {category?.name}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: 0 }}>
                <List
                  component="div"
                  role="list"
                  className="atributeList"
                  style={{ padding: 0 }}
                >
                  {category?.attributes?.length !== 0 &&
                    category?.attributes?.map((attribute: any, inx: number) => {
                      const labelId = `transfer-list-item-${attribute.id}-label`;
                      return (
                        <ListItemButton
                          key={attribute?.id}
                          role="checkbox"                          
                          className={
                            isAttributeSelected(attribute)
                              ? "atributeSelected"
                              : ""
                          }
                          sx={{
                            bgcolor: isAttributeSelected(attribute)
                              ? "#3B5C84"
                              : "#fff",
                          }}
                        >
                          <ListItemText
                            id={labelId}
                            primary={attribute?.name}
                          />
                        </ListItemButton>
                      );
                    })}
                </List>
              </AccordionDetails>
            </Accordion>
            {/* <Box>
              <Tooltip title="Add new attribute">
                <IconButton
                  sx={{
                    border: "1px solid #3c735c",
                    color: "#3c735c",
                    borderRadius: "20px",
                    minWidth: "1px",
                    marginTop: "15px",
                    marginRight: "10px",

                    "&:hover": {
                      background: "#3c735c",
                      color: "white",
                    },
                  }}
                  aria-label="next song"
                  onClick={() => {
                    handleAdd(category?.name);
                    setCategoryId(category?.id);
                    setform("");
                  }}
                >
                  <Add fontSize="small" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete attribute">
                <IconButton
                  sx={{
                    border: "1px solid #ff4500",
                    color: "#ff4500",
                    borderRadius: "20px",
                    minWidth: "1px",
                    marginTop: "15px",
                    "&:hover": {
                      background: "#ff4500",
                      color: "white",
                    },
                  }}
                  onClick={() => handleRemove(category?.id)}
                >
                  <Remove fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box> */}
          </Grid>
        ))}
      </Grid>
      {/* <Dialog
        className="characteristicsAddModel"
        open={isOpen}
        onClose={() => {
          handleClose();
          setIsEdit(false);
        }}
        aria-labelledby="draggable-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle
          className="dialog-title"
          style={{ cursor: "move", color: "#484C52" }}
          id="draggable-dialog-title"
        >
          <Typography
            component="span"
            sx={{
              fontSize: "25px",
              color: "white",
              fontWeight: 700,
              marginLeft: "22px",
            }}
          >
            {type}
          </Typography>
          <span
            onClick={handleClose}
            style={{ marginRight: "30px" }}
            className="modelCloseBtn"
          >
            <Close />
          </span>
        </DialogTitle>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <Typography component="span" variant="h6" sx={{ fontWeight: 700 }}>
            {t("main.Attribute_Name")}
          </Typography>
          <input
            style={{
              width: "350px",
              backgroundColor: "transparent",
              borderRadius: "12px",
              border: "1px solid white",
              color: "white",
            }}
            value={form}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setform(e.target.value)
            }
          />
        </div>
        <DialogActions sx={{ display: "flex", justifyContent: "space-around" }}>
          {!isEdit ? (
            <Button
              size="medium"
              variant="contained"
              color="primary"
              style={{ marginRight: "23px" }}
              sx={{ mt: 5 }}
              onClick={(e) => {
                handleAddAttribute(e);
              }}
            >
              {t("main.Add")}
            </Button>
          ) : (
            <Button
              size="medium"
              variant="contained"
              color="primary"
              style={{ marginRight: "23px" }}
              sx={{ mt: 5 }}
              onClick={(e) => {
                handleEditAttribute(e);
              }}
            >
              {t("main.Edit")}
            </Button>
          )}
        </DialogActions>
      </Dialog> */}
    </Paper>
  );
}

import {
  Box,
  Grid,
  Link,
  Theme,
  Avatar,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoginOperation } from "../../pages/LoginPage";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";

const tbStyle = (theme: Theme) => ({
  padding: "0px",
  borderRadius: "4px",
  backgroundColor: theme.palette.mode === "dark" ? "#000000ad" : "#ffffffad",
});

const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export default function SignUp({
  changeOperationHandler,
}: {
  changeOperationHandler: Function;
}) {
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    if (!validateEmail(data.get("email") as string)) {
      setMessage("E-mail is incorrect!");
    } else if ((data.get("password") as string).length < 6) {
      setMessage("Password must be at least 6 character");
    } else if ((data.get("password-conf") as string).length < 6) {
      setMessage("Passwords doesn't match!");
    } else {
      navigate("/");
    }
  };

  return (
    <>
      <Avatar sx={{ m: 1, bgcolor: "warning.main" }}>
        <AccountCircleOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Sign up
      </Typography>
      <Box
        component="form"
        onSubmit={(e) => handleSubmit(e)}
        noValidate
        sx={{ mt: "1.2rem" }}
      >
        <Typography component="span" color="error" align="center">
          <strong>{message}</strong>
        </Typography>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          sx={tbStyle}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          sx={tbStyle}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password-conf"
          label="Password Confirm"
          type="password"
          id="password-conf"
          autoComplete="none"
          sx={tbStyle}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          size="large"
          sx={{ mt: 3, mb: 2 }}
        >
          Sign up
        </Button>
        <Grid container>
          <Grid container justifyContent="flex-end">
            <Link
              onClick={() => changeOperationHandler(LoginOperation.SignIn)}
              variant="body2"
              sx={{ cursor: "pointer" }}
            >
              Already have account? Sign In
            </Link>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

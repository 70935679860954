import {
  Box,
  Link,
  Paper,
  Button,
  Dialog,
  TextField,
  PaperProps,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import "./announcementsDialogs.scss";
import Draggable from "react-draggable";
import CloseIcon from "@mui/icons-material/Close";
import React, { MouseEventHandler, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useChannelIdContext } from "../../../context/ChannelIdContext";
import { useNavigate } from "react-router-dom";
const API_URL = process.env.REACT_APP_BACKEND_URL;
function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper
        {...props}
        style={{
          backgroundColor: "#34393f",
          color: "#D9D9D9",
          padding: "1rem",
        }}
      />
    </Draggable>
  );
}

export default function CreateNewTimeManagerDialog({
  isOpen,
  handleClose,
}: {
  isOpen: boolean;
  handleClose: MouseEventHandler;
}) {
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  const name = useRef(null);
  const { t } = useTranslation();
  const volumeOffset = useRef(null);
  const navigate = useNavigate();
  const { setIsTokenExpired, setIsTokenUpdated } = useChannelIdContext();
  const postAnnouncementList = async () => {
    const postData = {
      name: name,
      volumeOffset: volumeOffset,
    };
    fetch(`${API_URL}/VoiceTrackCategory`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
      body: JSON.stringify(postData),
    })
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {});
  };

  return (
    <Dialog
      fullWidth
      open={isOpen}
      maxWidth="lg"
      onClose={handleClose}
      className="addNewwheelDialog"
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
        <Typography variant="h5" sx={{ fontWeight: 700 }}>
          {t("main.New_Time_Manager")}
          <Link className="modelCloseBtn" onClick={handleClose}>
            <CloseIcon />
          </Link>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 1, width: "25ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            sx={{ width: 350 }}
            label={t("main.title")}
            variant="outlined"
            id="name"
            ref={name}
          />
          <TextField
            sx={{ width: 350 }}
            label={t("main.volume_offset")}
            variant="outlined"
            id="volumeOffset"
            ref={volumeOffset}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-around" }}>
        <Button
          onClick={postAnnouncementList}
          size="medium"
          variant="contained"
          color="primary"
          sx={{ mt: 5 }}
        >
          {t("main.save")}
        </Button>
        <Button
          onClick={handleClose}
          size="medium"
          variant="outlined"
          color="secondary"
          sx={{ mt: 5, borderColor: "#fff" }}
        >
          {t("main.cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import * as React from "react";
import Profile from "../component/User/Profile";
import SidePanel from "../layouts/_old/PersistentSidePanel";

export default function ProfilePage() {
  return (
    <SidePanel>
      <Profile name="test" email="test@demo.com" />
    </SidePanel>
  );
}

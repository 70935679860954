/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Link,
  Radio,
  Paper,
  Button,
  Select,
  Dialog,
  Checkbox,
  MenuItem,
  RadioGroup,
  PaperProps,
  Typography,
  DialogContent,
  DialogActions,
  FormControlLabel,
  SelectChangeEvent,
  DialogTitle,
} from "@mui/material";
import "./duplicateDayDialog.scss";
import { toast } from "react-toastify";
import Draggable from "react-draggable";
import { useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { ScheduleList } from "../../../types/Zone";
import { useChannelIdContext } from "../../../context/ChannelIdContext";
import React, { MouseEventHandler, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
const API_URL = process.env.REACT_APP_BACKEND_URL;
function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper
        {...props}
        style={{
          backgroundColor: "#34393F",
          color: "#D9D9D9",
          padding: "1rem",
        }}
      />
    </Draggable>
  );
}
const DuplicateDayDilog = ({
  isOpen,
  handleClose,
}: {
  isOpen: boolean;
  handleClose: any | MouseEventHandler;
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  let navigate = useNavigate();
  const [selectDay, setSelectDay] = useState<string>("");
  const [selectZone, setSelectZone] = useState<string>("");
  const [zoneList, setZoneList] = useState<ScheduleList[]>([]);
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  const [selectedDays, setSelectedDays] = useState<number[]>([]);
  const { isAdded, setIsAdded, scheduleList, setIsTokenExpired, setIsTokenUpdated, IsTokenUpdated } = useChannelIdContext();
  const daysOfWeek = [
    { id: 1, day: `${t("main.monday")}` },
    { id: 2, day: `${t("main.tuesday")}` },
    { id: 3, day: `${t("main.wednesday")}` },
    { id: 4, day: `${t("main.thursday")}` },
    { id: 5, day: `${t("main.friday")}` },
    { id: 6, day: `${t("main.saturday")}` },
    { id: 0, day: `${t("main.sunday")}` },
  ];

  const handleDayChange = (id: number) => {
    if (selectedDays?.includes(id)) {
      setSelectedDays(
        selectedDays?.filter((x: number) => {
          return x !== id;
        })
      );
    } else {
      setSelectedDays([...selectedDays, id]);
    }
  };

  const fetchZoneList = useCallback(async () => {
    fetch(`${API_URL}/Zone/GetAll`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    })
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true)
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false)
          return response.json()
        }
      })
      .then((data) => {
        if (data.success === true) {
          setZoneList(data?.data);
        } else {
          setZoneList([]);
        }
      })
  }, [IsTokenUpdated]
  );

  const handleSubmitDay = async () => {
    if (scheduleList?.schedules?.length === 0) {
      toast.error(t("main.Schedules_Are_Not_Available"));
      handleClose();
      return;
    } else {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token?.accessToken}`,
        },
        body: JSON.stringify({
          zoneId: selectZone,
          dayToCopy: selectDay,
          destinationDays: selectedDays,
        }),
      };
      fetch(
        `${API_URL}/Schedule/DuplicateDay`,
        requestOptions
      ).then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
        .then((data) => {
          if (data?.success === true) {
            handleClose();
            setIsAdded(true);
            setSelectDay("");
            setSelectZone("");
            setSelectedDays([]);
            toast.success(t("main.Duplicate_Day_Created_Successfully"));
          } else {
            toast.error(t("main.Please_fill_the_form"));
          }
        })

    }
  };

  useEffect(() => {
    if (location?.pathname?.includes("channel-scheduler") && isOpen)
      fetchZoneList();
  }, [fetchZoneList, location?.pathname, isOpen]);

  useEffect(() => {
    if (isAdded === true) {
      handleClose();
      setSelectZone("");
      setSelectDay("");
      setSelectedDays([]);
    }
  }, [handleClose, isAdded]);

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        handleClose();
        setSelectZone("");
        setSelectDay("");
        setSelectedDays([]);
      }}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      maxWidth="sm"
      fullWidth
      className="duplicateDayDialog"
    >
      <DialogTitle>
        <Typography variant="h5" className="duplicateDayDialog-title">
          {t("main.duplicate_day")}
          <Link onClick={() => {
            handleClose()
            setSelectZone("");
            setSelectDay("");
            setSelectedDays([]);
          }} className="modelCloseBtn">
            <CloseIcon />
          </Link>
        </Typography>
      </DialogTitle>
      <div className="duplicateDayDialog__inner">
        <DialogContent>
          <Box className="fieldBox">
            <Box className="fieldBox__head">
              <Typography variant="body1" className="fieldBox__head-title">
                {t("main.1.select_zone")}
              </Typography>
            </Box>
            <Box className="fieldBox__body">
              <Select
                className="selectBox CustomDrpSelect"
                name="name"
                value={selectZone || ""}
                onChange={(event: SelectChangeEvent<typeof selectZone>) =>
                  setSelectZone(event.target.value)
                }
              >
                {zoneList?.length === 0 ?
                  <MenuItem>
                    {t("main.No_Zone_Found")}
                  </MenuItem>
                  :
                  zoneList?.map((item: any) => (
                    <MenuItem value={item?.id} key={item?.id}>
                      {item?.name}
                    </MenuItem>
                  ))
                }
              </Select>
            </Box>
          </Box>
          <Box className="fieldBox">
            <Box className="fieldBox__head">
              <Typography variant="body1" className="fieldBox__head-title">
                {t("main.2.select_day-to_copy")}
              </Typography>
            </Box>
            <Box className="fieldBox__body">
              <Box className="dayRadioGroup">
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                >
                  {daysOfWeek?.map((days) => (
                    <FormControlLabel
                      key={days?.id}
                      value={days?.id}
                      control={
                        <Radio
                          key={days?.id}
                          name={days?.day}
                          onChange={(
                            event: SelectChangeEvent<typeof selectDay>
                          ) => setSelectDay(event.target.value)}
                        />
                      }
                      label={days?.day}
                    />
                  ))}
                </RadioGroup>
              </Box>
            </Box>
          </Box>
          <Box className="fieldBox">
            <Box className="fieldBox__head">
              <Typography variant="body1" className="fieldBox__head-title">
                {t("main.3.select_destination_days")}
              </Typography>
            </Box>
            <Box className="fieldBox__body">
              <Box className="dayCheckBoxGroup">
                {daysOfWeek?.map((days) => (
                  <FormControlLabel
                    key={days?.id}
                    control={
                      <Checkbox
                        checked={selectedDays?.includes(days?.id)}
                        onChange={() => handleDayChange(days?.id)}
                        name={days?.day}
                      />
                    }
                    label={days?.day}
                  />
                ))}
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ display: "flex" }}>
          <Button
            size="medium"
            variant="contained"
            color="primary"
            sx={{ mt: 5 }}
            style={{ backgroundColor: "#3C735C", border: "none" }}
            onClick={handleSubmitDay}
          >
            {t("main.save")}
          </Button>
          <Button
            size="medium"
            variant="outlined"
            color="primary"
            sx={{ mt: 5 }}
            onClick={() => {
              handleClose();
              setSelectZone("");
              setSelectDay("");
              setSelectedDays([]);
            }}
          >
            {t("main.cancel")}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};
export default DuplicateDayDilog;

/* eslint-disable react-hooks/exhaustive-deps */
import { ChevronLeft, ChevronRight, Close } from "@mui/icons-material";
import {
  Dialog,
  DialogTitle,
  IconButton,
  Link,
  MenuItem,
  Paper,
  PaperProps,
  Select,
  Typography,
  useTheme
} from "@mui/material";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useChannelIdContext } from "../../../context/ChannelIdContext";
import { Song } from "../../../types/Channel";
import "./displayChannelSongDialog.scss";
const API_URL = process.env.REACT_APP_BACKEND_URL;
function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper
        {...props}
        style={{
          backgroundColor: "#34393f",
          color: "#D9D9D9",
          padding: "1rem",
        }}
      />
    </Draggable>
  );
}

export default function DisplayChannelSongDialog({
  isOpen,
  channelId,
  handleClose,
}: {
  isOpen: boolean;
  channelId: number;
  handleClose: any | MouseEventHandler;
}) {
  const delay = useRef();
  const theme = useTheme();
  const { t } = useTranslation();
  let navigate = useNavigate();
  const { setIsTokenExpired, setIsTokenUpdated, IsTokenUpdated } =
    useChannelIdContext();
  const location = useLocation();
  const [songList, setSongList] = useState<Song[]>([]);
  const [rowSelected, selectRowSelected] = useState({});
  const [totalCount, setTotalCount] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number | any>(25);
  const [searchInput, setSearchInput] = useState<string>("");
  const [selectSongs, setSelectSongs] = useState<number[]>([]);
  let token = JSON.parse(localStorage?.getItem("token") ?? "{}");

  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "title",
        header: t("main.title"),
      },
      {
        accessorKey: "artist1Name",
        header: t("main.artist_1"),
      },
      {
        accessorKey: "artist2Name",
        header: t("main.artist_2"),
      },
      {
        accessorKey: "artist3Name",
        header: t("main.artist_3"),
      },
    ],
    [t]
  );

  const fetchSongListByChannel = useCallback(async () => {
    try {
      console.log(channelId)
      fetch(
        `${API_URL}/Channel/ChannelSongs/${channelId}?pageNumber=${currentPage}&pageSize=${pageSize}&searchText=${searchInput}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token?.accessToken}`,
          },
        }
      )
        .then((response) => {
          if (
            response.ok === false &&
            response.status === 401 &&
            token?.refreshToken !== ""
          ) {
            setIsTokenExpired(true);
          } else if (response.status === 401 && token?.refreshToken === "") {
            navigate("/");
            localStorage.removeItem("token");
            localStorage.clear();
          } else {
            setIsTokenUpdated(false);
            return response.json();
          }
        })
        .then((data) => {
          if (data.success === true) {
            setSongList(data?.data);
            selectRowSelected({});
            setTotalCount(data?.pagination?.totalCount);
          } else {
            selectRowSelected({});
          }
        });
    } catch (error) {}
  }, [currentPage, pageSize, searchInput, IsTokenUpdated, channelId]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    clearTimeout(delay.current);
    delay.current = setTimeout(
      () => setSearchInput(event.target.value),
      500
    ) as any;
  };

  const handleForwardPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlebackwordPage = () => {
    setCurrentPage(currentPage - 1);
  };
  useEffect(() => {
    console.log(isOpen)
    console.log(channelId)
    if (location?.pathname?.includes("manage-customers") && isOpen === true && channelId !== 0) {
      console.log("Haha")      
      fetchSongListByChannel();
    }
  }, [
    currentPage,
    pageSize,
    totalCount,
    searchInput,
    fetchSongListByChannel,
    location?.pathname,
    isOpen,
    channelId
  ]);

  useEffect(() => {    
    const songIds = Object.keys(rowSelected);
    setSelectSongs(
      songList
        ?.filter((_, i) => songIds?.includes(i.toString()))
        ?.map((ele) => ele.id)
    );
  }, [rowSelected, songList]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      maxWidth="sm"
      fullWidth
      className="addChannelSongDialog"
    >
      <DialogTitle>
        <Typography variant="h5" className="addModel-title">
          {"Channel Songs"}
          <Link onClick={handleClose} className="modelCloseBtn">
            <Close />
          </Link>
        </Typography>
      </DialogTitle>
      <div className="SongDialogForm">
        <form>
          {/* <div className="songList-dialogForm-field">
            <FormControl className="modelSearchField">
              <TextField
                autoComplete="off"
                size="small"
                variant="outlined"
                placeholder={t("main.title/artist...")}
                onChange={handleChange}
              />
            </FormControl>
          </div> */}
          <div
            className={
              theme.palette.mode === "dark"
                ? "ChannelSongList modelTableDark"
                : "ChannelSongList modelTable"
            }
          >
            <MaterialReactTable
              columns={columns}
              data={songList}
              enableStickyHeader              
              enableColumnActions={true}
              enablePinning={true}
              enableColumnOrdering={true}
              renderTopToolbar={false}              
              state={{ rowSelection: rowSelected }}
              enablePagination={false}
              renderBottomToolbar={true}
              muiTableBodyRowProps={{
                hover: false,
              }}
              muiTablePaperProps={{
                elevation: 0,
                sx: {
                  "& .css-sq9qdz": {
                    backgroundColor: "#3C434B",
                    justifyContent: "flex-start",
                    padding: 0,
                  },
                  "& .css-1cjiko4": {
                    display: "none",
                  },
                },
              }}
              muiTableBodyProps={{
                sx: {
                  "& tr:nth-of-type(even)": {
                    backgroundColor: "#F0F4F6",
                  },
                },
              }}
            />
            <div
              style={{
                backgroundColor: "white",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "10px",
                height: "59px",
              }}
            >
              <span style={{ color: "black" }}>{t("main.rows_per_page")}</span>
              <Select
                sx={{ height: "30px", color: "#000", border: "1px solid #000" }}
                value={pageSize || ""}
                onChange={(e) => {
                  setPageSize(e.target.value);
                }}
              >
                <MenuItem value="25">25</MenuItem>
                <MenuItem value="50">50</MenuItem>
                <MenuItem value="100">100</MenuItem>
              </Select>
              <span style={{ color: "black" }}>
                {(currentPage - 1) * pageSize + 1} -{" "}
                {currentPage * pageSize < totalCount
                  ? currentPage * pageSize
                  : currentPage * pageSize -
                    (currentPage * pageSize - totalCount)}{" "}
                of {totalCount}
              </span>
              <IconButton
                onClick={handlebackwordPage}
                disabled={(currentPage - 1) * pageSize + 1 <= 1}
              >
                <ChevronLeft />
              </IconButton>
              <IconButton
                onClick={handleForwardPage}
                disabled={currentPage * pageSize >= totalCount}
              >
                <ChevronRight />
              </IconButton>
            </div>
          </div>
        </form>
      </div>
    </Dialog>
  );
}

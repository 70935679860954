import {
  Link,
  Paper,
  Button,
  Dialog,
  TextField,
  PaperProps,
  Typography,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import "./AddChannelListDialog.scss";
import { toast } from "react-toastify";
import Draggable from "react-draggable";
import CloseIcon from "@mui/icons-material/Close";
import { useChannelIdContext } from "../../../context/ChannelIdContext";
import React, { MouseEventHandler, useState, SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
const API_URL = process.env.REACT_APP_BACKEND_URL;
function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper
        {...props}
        style={{
          backgroundColor: "#34393f",
          color: "#D9D9D9",
          fontSize: "18px",
          padding: "1rem",
        }}
      />
    </Draggable>
  );
}

export default function AddChannelListDialog({
  isOpen,
  handleClose,
}: {
  isOpen: boolean;
  handleClose: any | MouseEventHandler;
}) {
  const { t } = useTranslation();
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  const [channelName, setChannelName] = useState<string>("");
  const { setIsAdded ,setIsTokenExpired, setIsTokenUpdated} = useChannelIdContext();
  const navigate = useNavigate()
  const handleAddChannel = async (e: SyntheticEvent) => {
    if (!channelName.trim()) {
      toast.error(t("main.Please_fill_the_form"));
    } else {
      e.preventDefault();
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token?.accessToken}`,
        },
        body: JSON.stringify({
          name: channelName,
          attribute1Amount: 0,
          attribute2Amount: 0,
          attribute3Amount: 0,
          attribute4Amount: 0,
          attribute1Breakable: true,
          attribute2Breakable: true,
          attribute3Breakable: true,
          attribute4Breakable: true,
          seasonalRestrictionBreakable: true,
          dayRestrictionBreakable: true,
          timeRestrictionBreakable: true,
          lastPlaySongRuleOn: true,
          lastPlaySongRuleDays: 0,
          lastPlaySongRuleMinutes: 0,
          yesterdayArtistRuleOn: true,
          yesterdayArtistRuleMinutes: 0,
          yesterdaySongRuleOn: true,
          yesterdaySongRuleMinutes: 0,
        }),
      };
      fetch(`${API_URL}/Channel`, requestOptions).then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data?.success === true) {
          setIsAdded(true);
          handleClose();
          toast.success(t("main.Channel_Created_Successfully"));
        } else {
          toast.error(data?.message);
        }
        setChannelName("");
      });

    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        handleClose();
        setChannelName("");
      }}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      maxWidth="sm"
      fullWidth
      className="addChannelModelDialog"
    >
      <DialogTitle>
        <Typography variant="h5" className="addModel-title">
          {t("main.new_channel")}
          <Link
            onClick={() => {
              handleClose();
              setChannelName("");
            }}
            className="modelCloseBtn"
          >
            <CloseIcon />
          </Link>
        </Typography>
      </DialogTitle>
      <div className="dialogForm">
        <form>
          <div className="dialogForm-field addchanelFields">
            <Typography
              style={{ fontSize: "18px !important" }}
              className="dialogForm-field-label"
            >
              {t("main.channel_name")}
            </Typography>
            <TextField
              required
              value={channelName}
              autoComplete="off"
              onChange={(e) => setChannelName(e.target.value)}
            />
          </div>
          <DialogActions sx={{ display: "flex" }}>
            <Button
              type="submit"
              size="medium"
              variant="contained"
              sx={{ mt: 5 }}
              disabled={channelName === ""}
              onClick={(e: SyntheticEvent) => handleAddChannel(e)}
            >
              {t("main.save")}
            </Button>
            <Button
              size="medium"
              variant="outlined"
              color="primary"
              sx={{ mt: 5 }}
              onClick={() => {
                handleClose();
                setChannelName("");
              }}
            >
              {t("main.cancel")}
            </Button>
          </DialogActions>
        </form>
      </div>
    </Dialog>
  );
}

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  MouseEventHandler,
} from "react";
import {
  Link,
  Paper,
  Button,
  Dialog,
  MenuItem,
  PaperProps,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import MaterialReactTable, {
  MRT_ColumnDef,
  MaterialReactTableProps,
} from "material-react-table";
import "./categoriesDialog.scss";
import { Stack } from "@mui/system";
import { toast } from "react-toastify";
import Draggable from "react-draggable";
import { useLocation, useNavigate } from "react-router-dom";
import { Add, Close } from "@mui/icons-material";
import { VT_Category } from "../../../types/VoiceTrack";
import { CategoryMusic } from "../../../types/Category";
import CreateNewCategoriesDialog from "./CreateNewCategoriesDialog";
import { useTranslation } from "react-i18next";
import { useChannelIdContext } from "../../../context/ChannelIdContext";
const API_URL = process.env.REACT_APP_BACKEND_URL;
function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper
        {...props}
        style={{
          backgroundColor: "#34393f",
          color: "#D9D9D9",
          padding: "1rem",
        }}
      />
    </Draggable>
  );
}

const Table = ({
  isUpdateCategory,
  setIsUpdateCategory,
}: {
  setIsUpdateCategory: any;
  isUpdateCategory: boolean;
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    setIsTokenExpired,
    setIsTokenUpdated,
    IsTokenUpdated,
    setIsCategoryAdded,
  } = useChannelIdContext();
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  const [selectedRowbyId, setSelectedRowbyId] = useState<any>();
  const [categoryList, setCategoryList] = useState<CategoryMusic[]>([]);

  const fetchVoiceTrackCategoryList = useCallback(async () => {
    fetch(`${API_URL}/VoiceTrackCategory/GetAll`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    })
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data.success === true) {
          setCategoryList(data?.data);
          setIsUpdateCategory(false);
        } else {
          setCategoryList([]);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsUpdateCategory, IsTokenUpdated]);

  const handleDeleteClick = useCallback(async () => {
    if (!selectedRowbyId) {
      return;
    } else {
      const requestOptions = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token?.accessToken}`,
        },
      };
      fetch(`${API_URL}/VoiceTrackCategory/${selectedRowbyId}`, requestOptions)
        .then((response) => {
          if (
            response.ok === false &&
            response.status === 401 &&
            token?.refreshToken !== ""
          ) {
            setIsTokenExpired(true);
          } else if (response.status === 401 && token?.refreshToken === "") {
            navigate("/");
            localStorage.removeItem("token");
            localStorage.clear();
          } else {
            setIsTokenUpdated(false);
            return response.json();
          }
        })
        .then((data) => {
          fetchVoiceTrackCategoryList();
          if (data?.success) {
            fetchVoiceTrackCategoryList();
            setIsCategoryAdded(true);
            toast.success(t("main.Category_Deleted_Successfully"));
          } else {
            toast.error(data?.message);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchVoiceTrackCategoryList, selectedRowbyId]);

  const validateRow = (row: VT_Category) => {
    const errors: Partial<VT_Category> = {};
    if (!row?.name) {
      errors.name = "Title is required !";
    } else if (row?.name?.length < 3) {
      errors.name = "Title must be at least 3 characters long !";
    } else if (!row?.volumeOffset) {
      errors.volumeOffset = 0;
    }
    return errors;
  };

  const handleSaveRowEdits: MaterialReactTableProps<CategoryMusic>["onEditingRowSave"] =
    async ({ exitEditingMode, row, values }) => {
      const errors = validateRow(values);
      values.id = row?.original?.id;
      if (Object.keys(errors)?.length > 0) {
        return;
      }
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token?.accessToken}`,
        },
        body: JSON.stringify(values),
      };
      try {
        fetch(`${API_URL}/VoiceTrackCategory`, requestOptions)
          .then((response) => {
            if (
              response.ok === false &&
              response.status === 401 &&
              token?.refreshToken !== ""
            ) {
              setIsTokenExpired(true);
            } else if (response.status === 401 && token?.refreshToken === "") {
              navigate("/");
              localStorage.removeItem("token");
              localStorage.clear();
            } else {
              setIsTokenUpdated(false);
              return response.json();
            }
          })
          .then((data) => {
            if (data.success === true) {
              categoryList[row?.index] = values;
              setCategoryList([...categoryList]);
              exitEditingMode();
              setIsCategoryAdded(true);
              toast.success(t("main.Category_Updated_successfully"));
            } else {
              toast.error(data?.message);
            }
          });
      } catch (error) {
        toast.error(t("main.Error_Occurred_While_Updating_Category"));
      }
    };
  const columns = useMemo<MRT_ColumnDef<CategoryMusic>[]>(
    () => [
      {
        accessorKey: "name",
        header: t("main.name"),
      },
      {
        accessorKey: "volumeOffset",
        header: t("main.volume"),
      },
    ],
    [t]
  );

  useEffect(() => {
    if (location?.pathname?.includes("content-library"))
      fetchVoiceTrackCategoryList();
  }, [fetchVoiceTrackCategoryList, isUpdateCategory, location?.pathname]);

  useEffect(() => {
    if (selectedRowbyId) {
      handleDeleteClick();
    }
  }, [handleDeleteClick, selectedRowbyId]);

  return (
    <MaterialReactTable
      renderRowActionMenuItems={({ row, closeMenu }: any) => [
        <MenuItem
          key="delete"
          onClick={() => {
            closeMenu();
            setSelectedRowbyId(row?.original?.id);
          }}
        >
          {t("main.Delete")}
        </MenuItem>,
      ]}
      columns={columns}
      editingMode="row"
      initialState={{
        sorting: [
          { id: "name", desc: true },
          { id: "volumeOffset", desc: true },
        ],
      }}
      enableEditing
      onEditingRowSave={handleSaveRowEdits}
      defaultColumn={{
        maxSize: 400,
        minSize: 80,
        size: 150, //default size is usually 180
      }}
      enableColumnResizing
      columnResizeMode="onChange"
      data={categoryList}
      enableRowActions
      enableColumnActions={true}
      enableColumnOrdering={true}
      enablePinning={true}
      enableColumnFilters={true}
      enablePagination={false}
      enableSorting={true}
      enableBottomToolbar={false}
      enableTopToolbar={false}
      muiTableBodyRowProps={{ hover: false }}
    />
  );
};

enum PopupEnum {
  None = 0,
  AddNewcategory = 1,
}

export default function CategoriesDialog({
  isOpen,
  handleClose,
}: {
  isOpen: boolean;
  handleClose: MouseEventHandler;
}) {
  const { t } = useTranslation();
  const [, setSaveData] = useState<any>([]);
  const [popUp, setPopUp] = useState(PopupEnum?.None);
  const [, setCategoryData] = useState<CategoryMusic[]>([]);
  const [isUpdateCategory, setIsUpdateCategory] = useState<boolean>(false);

  const handleAddCategory = () => {
    setPopUp(PopupEnum?.AddNewcategory);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      maxWidth="md"
      fullWidth
      className="addNewCategoryDialog"
    >
      <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
        <Typography sx={{ fontSize: "25px", fontWeight: 700 }}>
          {t("main.new_music_categories")}
          <Link className="modelCloseBtn" onClick={handleClose}>
            <Close />
          </Link>
        </Typography>
      </DialogTitle>
      <Stack
        direction="row"
        justifyContent="flex-start"
        className="modal-top-actions"
      >
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-around",
            marginLeft: "20px",
          }}
        >
          <Button
            onClick={handleAddCategory}
            size="medium"
            variant="contained"
            color="primary"
            sx={{ mt: 1 }}
            className="addBtn"
          >
            <Add />
            {t("main.add_category")}
          </Button>
        </DialogActions>
      </Stack>
      <DialogContent>
        <Paper elevation={3} sx={{ p: "1px", mt: 3 }} className="dialogTable">
          <Table
            setIsUpdateCategory={setIsUpdateCategory}
            isUpdateCategory={isUpdateCategory}
          ></Table>
        </Paper>
      </DialogContent>
      <CreateNewCategoriesDialog
        isOpen={popUp === PopupEnum?.AddNewcategory}
        onClose={() => setPopUp(PopupEnum?.None)}
        onSave={setSaveData}
        updateTableData={setCategoryData}
        setIsUpdateCategory={setIsUpdateCategory}
      />
    </Dialog>
  );
}

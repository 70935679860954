import * as React from "react";
import { Grid, TextField, useTheme } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useTranslation } from "react-i18next";
import moment from "moment";

const VoiceTrackSeasonalityTimeOfDay = ({
  voiceTrack,
  setVoiceTrack,
}: {
  voiceTrack: any;
  setVoiceTrack: React.Dispatch<React.SetStateAction<any>>;
}) => {
  let theme = useTheme();
  const { t } = useTranslation();
  const formattedSeasonalStart = voiceTrack.seasonalStart
    ? moment(voiceTrack.seasonalStart).format("DD.MM")
    : "";
  const formattedSeasonalEnd = voiceTrack.seasonalEnd
    ? moment(voiceTrack.seasonalEnd).format("DD.MM")
    : "";

  const handleStartDateChange = (newValue: any) => {
    try {
      const newStartDate = newValue ? moment(newValue).format() : null;
      setVoiceTrack({
        ...voiceTrack,
        seasonalStart: newStartDate || null,
      });
    } catch (error) {
      console.error("Error converting start date:", error);
    }
  };

  const handleEndDateChange = (newValue: any) => {
    try {
      const newEndDate = newValue ? moment(newValue).format() : null;
      setVoiceTrack({
        ...voiceTrack,
        seasonalEnd: newEndDate,
      });
    } catch (error) {
      console.error("Error converting end date:", error);
    }
  };
  return (
    <Grid
      className="TimeOfDay"
      container
      rowSpacing={2}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
    >
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          label={t("main.From")}
          value={
            voiceTrack.seasonalStart ? moment(voiceTrack.seasonalStart) : null
          }
          onChange={handleStartDateChange}
          renderInput={(params) => (
            <TextField {...params} value={formattedSeasonalStart} />
          )}
          inputFormat="DD.MM"
        />
        <DatePicker
          label={t("main.To")}
          value={voiceTrack.seasonalEnd ? moment(voiceTrack.seasonalEnd) : null}
          onChange={handleEndDateChange}
          renderInput={(params) => (
            <TextField {...params} value={formattedSeasonalEnd} />
          )}
          inputFormat="DD.MM"
        />
        <span
          style={{
            color: theme.palette.mode === "dark" ? "#F5F5F5" : "#3C434B",
          }}
        >
          {" "}
          (DD.MM)
        </span>
      </LocalizationProvider>
    </Grid>
  );
};

export default VoiceTrackSeasonalityTimeOfDay;

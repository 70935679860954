// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import {
  ArrowDropDown,
  ArrowRight,
  AudioFileOutlined,
  CreateNewFolderOutlined,
  HandymanOutlined,
  SearchOutlined,
} from "@mui/icons-material";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Stack,
  TextField,
  Toolbar,
  useTheme,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { ReactComponent as AddIconDark } from "../../assets/images/AddIconDark.svg";
import { ReactComponent as AddIconLight } from "../../assets/images/AddIconLight.svg";
import { ReactComponent as DuplicateDark } from "../../assets/images/DuplicateDark.svg";
import { ReactComponent as DuplicateLight } from "../../assets/images/DuplicateLight.svg";
import { ReactComponent as ManageDropinsDarkIcon } from "../../assets/images/MangeDropinsDarkIcon.svg";
import { ReactComponent as ManageDropinsLightIcon } from "../../assets/images/MangeDropinsLightIcon.svg";
import { ReactComponent as Add } from "../../assets/images/add-squre.svg";
import { ReactComponent as MinusDark } from "../../assets/images/minus-squreDark.svg";
import { ReactComponent as MinusLight } from "../../assets/images/minus-squreLight.svg";
import ChanneleditorSearchBar from "../../component/Controls/ChanneleditorSearchBar";
import ManageCustomerSearchBar from "../../component/Controls/ManageCustomerSearchBar";
import ScheduleSearchBar from "../../component/Controls/ScheduleSearchBar";
import AddChannelListDialog from "../../component/Dialogs/AddChannelListDialog/AddChannelListDialog";
import AddChannelListSongDialog from "../../component/Dialogs/AddChannelListSongDialog/AddChannelListSongDialog";
import AddScheduleItemDialog from "../../component/Dialogs/AddScheduleItemDialog/AddScheduleItemDialog";
import CreateCatalogDialog from "../../component/Dialogs/AnnouncementsDialogs/CreateCatalogDialog";
import CreateTimeManagerDialog from "../../component/Dialogs/AnnouncementsDialogs/CreateTimeManagerDialog";
import CreateWheelDesignerDialog from "../../component/Dialogs/AnnouncementsDialogs/CreateWheelDesignerDialog";
import AddChannelTimeScheduleDialog from "../../component/Dialogs/ChannelTimeScheduleDialog/AddChannelTimeScheduleDialog";
import AddChannelTimeScheduleDropinsDialog from "../../component/Dialogs/ChannelTimeScheduleDialog/AddChannelTimeScheduleDropinsDialog";
import ManageDropinsDialog from "../../component/Dialogs/ChannelTimeScheduleDialog/ManageDropinsDialog";
import DeleteChannelScheduleDialog from "../../component/Dialogs/DeleteChannelScheduleDialog/DeleteChannelScheduleDialog";
import DeletePlaylistDialog from "../../component/Dialogs/DeletePlaylistDialog/DeletePlaylistDialog";
import DeleteSongConfirmDialog from "../../component/Dialogs/DeleteSongConfirmDialog/DeleteSongConfirmDialog";
import DuplicateDayDialog from "../../component/Dialogs/DuplicateDayDialog/DuplicateDayDialog";
import DuplicateZoneDialog from "../../component/Dialogs/DuplicateZoneDialog/DuplicateZoneDialog";
import ImportFileDialog from "../../component/Dialogs/ImportFileDialog";
import ImportFolderDialog from "../../component/Dialogs/ImportFolderDialog";
import RipperDialog from "../../component/Dialogs/RipperDialog/RipperDialog";
import { useChannelIdContext } from "../../context/ChannelIdContext";
import "../../layouts/SearchBar.scss";
import {
  Dropdown,
  DropdownMenuItem,
  DropdownNestedMenuItem,
} from "../../utils/Dropdown";
import "./navigationPanel.scss";
const API_URL = process.env.REACT_APP_BACKEND_URL;
interface IProps {
  tools?: boolean;
  addFile?: boolean;
  addRipper?: boolean;
  addFolder?: boolean;
  addContent?: boolean;
  addCategory?: boolean;
  channelTools?: boolean;
  duplicateDay?: boolean;
  duplicateZone?: boolean;
  manageDropIns?: boolean;
  addChannelItem?: boolean;
  addScheduleItem?: boolean;
  addPlaylistSong?: boolean;
  addPlaylistItem?: boolean;
  musicLibraryTools?: boolean;
  deleteChannelItem?: boolean;
  duplicateChannelItem?: boolean;
  ScheduleSearchBar?: boolean;
  deleteScheduleItem?: boolean;
  deletePlaylistSong?: boolean;
  deletePlaylistItem?: boolean;
  channelEditorSearchbar?: boolean;
  addChannelTimeSchedule?: boolean;
  manageCustomerSearchbar?: Boolean;
  channelTimeScheduleTools?: boolean;
  deleteChannelTimeSchedule?: boolean;
  addChannelTimeScheduleDropins?: boolean;
  onButtonClick: (button: string) => void;
}

enum PopUpEnum {
  None = 0,
  FolderUpload = 1,
  FileUpload = 2,
  AddCatalog = 3,
  AddWheelDesigner = 4,
  AddTimeManager = 5,
  AddSchedule = 6,
  DeleteSchedule = 7,
  DuplicateDay = 8,
  DuplicateZone = 9,
  AddPlayList = 10,
  DeletePlayList = 11,
  AddPlaylistSong = 12,
  DeletePlaylistSong = 13,
  addChannelItem = 14,
  deleteChannelItem = 15,
  addChannelTimeSchedule = 16,
  addChannelTimeScheduleDropins = 17,
  ChannelTimeScheduleTools = 18,
  AddRipper = 19,
  MusicLibraryTools = 20,
  ManageDropin = 21,
  duplicateChannelItem = 22,
}

const NavigationPanel: React.FC<IProps> = (props: IProps) => {
  const { t } = useTranslation();
  const [popUp, setPopUp] = useState(PopUpEnum.None);
  useChannelIdContext();
  const {
    isAdded,
    setIsAdded,
    deleteChannelId,
    songId,
    scheduleId,
    channelId,
    currentUser,
    setRegenerateChannelData,
  } = useChannelIdContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  const theme = useTheme();
  const location = useLocation();
  const handleCategories = () => {
    setPopUp(PopUpEnum.AddCatalog);
  };
  const handleWheelDesigner = () => {
    setPopUp(PopUpEnum.AddWheelDesigner);
  };

  const handleTimeManager = () => {
    setPopUp(PopUpEnum.AddTimeManager);
  };

  const handleExportAudio = async () => {
    if (channelId !== undefined) {
      try {
        const response = await fetch(
          `${API_URL}/Channel/DownloadAudioFiles/${channelId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `bearer ${token?.accessToken}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Error downloading audio: ${response?.statusText}`);
        }

        const blob = await response.blob();

        // Create a blob URL and trigger download
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = "ChannelAudio_file.zip";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Clean up the blob URL
        URL.revokeObjectURL(blobUrl);
        toast.success(t("main.Audio_File_Saved_Successfully"));
      } catch (error) {
        toast.error(t("main.Error_Saving_Audio_File"));
      }
    }
  };
  const handleExportEncryptedAudio = async () => {
    if (channelId !== undefined) {
      try {
        const response = await fetch(
          `${API_URL}/Channel/DownloadEncryptedAudio/${channelId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `bearer ${token?.accessToken}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Error downloading audio: ${response?.statusText}`);
        }

        const blob = await response.blob();

        // Create a blob URL and trigger download
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = "Channel_Encrypted_Audio_file.zip";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Clean up the blob URL
        URL.revokeObjectURL(blobUrl);
        toast.success(t("main.Audio_File_Saved_Successfully"));
      } catch (error) {
        toast.error(t("main.Error_Saving_Audio_File"));
      }
    }
  };

  const handleImportFile = () => {
    setPopUp(PopUpEnum?.FileUpload);
  };

  const handleRipper = () => {
    setPopUp(PopUpEnum?.AddRipper);
  };

  const duplicateChannel = async () => {
    const response = await fetch(
      `${API_URL}/Channel/Duplicate/${channelId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token?.accessToken}`,
        },
      }
    );
    const data = await response.json();
    if (data.success === true) {
      setIsAdded(true);
      toast.success(data?.message);
    }
  }

  const ReGenerateChannelSongListById = async () => {
    const response = await fetch(
      `${API_URL}/Channel/RegenerateChannelSong/${channelId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token?.accessToken}`,
        },
      }
    );
    const data = await response.json();
    if (data.success === true) {
      setRegenerateChannelData(true);
      toast.success(data?.message);
    }
  };

  return (
    <>
      <Toolbar className="toolbarPanel">
        <Stack
          // className="button-list"
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
          width="100%"
        >
          {props?.addFolder && (
            <IconButton
              className="toolbarPanel__menu"
              aria-label="Create folder"
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 1,
                pt: 4,
                aspectRatio: "1/1",
                width: "8rem",
                color: theme.palette.mode === "dark" ? "#cfd8dc" : "#263238",
              }}
              onClick={() => setPopUp(PopUpEnum.FolderUpload)}
            >
              <CreateNewFolderOutlined
                fontSize="large"
                sx={{
                  transform: "scale(1.3)",
                }}
              />
              {t("main.Add_Folder")}
            </IconButton>
          )}
          {props?.addFile && (
            <IconButton
              className="toolbarPanel__menu"
              aria-label="Create folder"
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 1,
                pt: 4,
                aspectRatio: "1/1",
                width: "8rem",
                color: theme.palette.mode === "dark" ? "#cfd8dc" : "#263238",
              }}
              onClick={() => setPopUp(PopUpEnum.FileUpload)}
            >
              <AudioFileOutlined
                fontSize="large"
                sx={{
                  transform: "scale(1.3)",
                }}
              />
              {t("main.Add_File")}
            </IconButton>
          )}
          {props?.addContent && (
            <>
              <Button
                className="toolbarPanel__menu"
                id={
                  theme.palette.mode === "dark"
                    ? "channel-tools-label-dark"
                    : "channel-tools-label"
                }
                onClick={(e) => {
                  setAnchorEl(e.currentTarget);
                }}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  p: 0,
                  pt: 5,
                  aspectRatio: "1/1",
                  width: "10%",
                  color: theme.palette.mode === "dark" ? "#cfd8dc" : "#263238",
                }}
              >
                <CreateNewFolderOutlined />
                <span>
                  {t("main.add_content")}
                  <ArrowDropDown />
                </span>
              </Button>
              <Menu
                className="toolbar-dropdownMenu"
                open={open}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
              >
                <Tooltip title={t("main.import_file")}>
                  <MenuItem
                    className="dropdown toolsDropdown"
                    onClick={handleImportFile}
                  >
                    {t("main.import_file")}
                  </MenuItem>
                </Tooltip>
                <Tooltip title="Import xml file">
                  <MenuItem
                    className="dropdown toolsDropdown"
                    onClick={handleRipper}
                  >
                    {t("main.Import")}
                  </MenuItem>
                </Tooltip>
              </Menu>
            </>
          )}
          {props?.tools && (
            <IconButton
              className="toolbarPanel__menu"
              aria-label="Create folder"
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 1,
                pt: 4,
                aspectRatio: "1/1",
                width: "8rem",
                color: theme.palette.mode === "dark" ? "#cfd8dc" : "#263238",
              }}
            >
              <HandymanOutlined
                fontSize="large"
                sx={{
                  transform: "scale(1.3)",
                }}
              />
              <FormControl variant="standard" sx={{ m: 0, minWidth: 80 }}>
                <InputLabel id="demo-simple-select-label">
                  {t("main.tools")}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                >
                  <MenuItem onClick={handleCategories}>
                    Announcements Categories
                  </MenuItem>
                  <MenuItem onClick={handleWheelDesigner}>
                    Wheel Designer
                  </MenuItem>
                  <MenuItem onClick={handleTimeManager}>
                    Exact Time Manager
                  </MenuItem>
                </Select>
              </FormControl>
            </IconButton>
          )}
          {props?.addScheduleItem && (
            <IconButton
              className="toolbarPanel__menu"
              aria-label="Add Schedule"
              style={{
                display: "flex",
                flexDirection: "column",
                p: 1,
                pt: 6,
                aspectRatio: "1/1",
                minWidth: "11%",
                color: theme.palette.mode === "dark" ? "#fff" : "#000",
              }}
              onClick={() => {
                currentUser?.editSchedules
                  ? setPopUp(PopUpEnum.AddSchedule)
                  : toast.error(
                      t("main.user_doesn't_have_authority_to_edit_schedule")
                    );
              }}
            >
              {theme.palette.mode === "dark" ? (
                <AddIconDark />
              ) : (
                <AddIconLight />
              )}
              {t("main.add_schedule_item")}
            </IconButton>
          )}
          {props?.deleteScheduleItem && (
            <IconButton
              className="toolbarPanel__menu"
              aria-label="Delete Schedule"
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 1,
                pt: 7,
                aspectRatio: "1/1",
                width: "11rem",
                color: theme.palette.mode === "dark" ? "#cfd8dc" : "#263238",
              }}
              disabled={!scheduleId}
              onClick={() => setPopUp(PopUpEnum.DeleteSchedule)}
            >
              {theme.palette.mode === "dark" ? <MinusDark /> : <MinusLight />}
              {t("main.delete_schedule_item")}
            </IconButton>
          )}
          {props?.duplicateDay && (
            <IconButton
              className="toolbarPanel__menu"
              aria-label="Create folder"
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 1,
                pt: 4.5,
                aspectRatio: "1/1",
                width: "9rem",
                color: theme.palette.mode === "dark" ? "#cfd8dc" : "#263238",
              }}
              onClick={() => setPopUp(PopUpEnum.DuplicateDay)}
            >
              {theme.palette.mode === "dark" ? (
                <DuplicateDark />
              ) : (
                <DuplicateLight />
              )}
              {t("main.duplicate_day")}
            </IconButton>
          )}
          {props?.duplicateZone && (
            <IconButton
              className="toolbarPanel__menu"
              aria-label="Create folder"
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 1,
                pt: 4.5,
                aspectRatio: "1/1",
                width: "9rem",
                color: theme.palette.mode === "dark" ? "#cfd8dc" : "#263238",
              }}
              onClick={() => setPopUp(PopUpEnum.DuplicateZone)}
            >
              {theme.palette.mode === "dark" ? (
                <DuplicateDark />
              ) : (
                <DuplicateLight />
              )}
              {t("main.duplicate_zone")}
            </IconButton>
          )}
          {props?.manageDropIns && (
            <IconButton
              className="toolbarPanel__menu"
              aria-label="Create folder"
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 1,
                pt: 5.5,
                aspectRatio: "1/1",
                width: "10rem",
                // color: theme.palette.mode === "dark" ? "#cfd8dc" : "#263238",
              }}
              onClick={() => setPopUp(PopUpEnum.ManageDropin)}
            >
              {theme.palette.mode === "dark" ? (
                <ManageDropinsDarkIcon />
              ) : (
                <ManageDropinsLightIcon />
              )}
              {t("main.manage_dropIns")}
            </IconButton>
          )}
          {props?.addPlaylistItem && (
            <IconButton
              className="toolbarPanel__menu"
              aria-label="Add Playlist"
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 1,
                pt: 4,
                aspectRatio: "1/1",
                width: "8rem",
                color: theme.palette.mode === "dark" ? "#cfd8dc" : "#263238",
              }}
              onClick={() => setPopUp(PopUpEnum.AddPlayList)}
            >
              <Add />
              Add Playlist
            </IconButton>
          )}
          {props?.deletePlaylistItem && (
            <IconButton
              className="toolbarPanel__menu"
              aria-label="Delete Playlist"
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 1,
                pt: 4,
                aspectRatio: "1/1",
                width: "8rem",
                color: theme.palette.mode === "dark" ? "#cfd8dc" : "#263238",
              }}
              disabled={deleteChannelId?.length === 0}
              onClick={() => setPopUp(PopUpEnum.DeletePlayList)}
            >
              {theme.palette.mode === "dark" ? <MinusDark /> : <MinusLight />}
              Delete Playlist
            </IconButton>
          )}
          {/* {props?.addPlaylistSong && (

            <IconButton
              className="toolbarPanel__menu"
              aria-label="Add Song"
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 1,
                pt: 4,
                aspectRatio: "1/1",
                width: "8rem",
              }}
              onClick={() => setPopUp(PopUpEnum.AddPlaylistSong)}
            >
              <Add style={{filter:theme.palette.mode === "dark" ? "invert(1) brightness(6)" : ""}}/>
              {t("main.add_song")}
            </IconButton>
          )} */}

          {props?.addPlaylistSong && (
            <IconButton
              className="toolbarPanel__menu"
              aria-label="Create folder"
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 1,
                pt: 4,
                aspectRatio: "1/1",
                width: "8rem",
                color: theme.palette.mode === "dark" ? "#fff" : "#000",
              }}
              onClick={() => setPopUp(PopUpEnum.AddPlaylistSong)}
            >
              {theme.palette.mode === "dark" ? (
                <AddIconDark />
              ) : (
                <AddIconLight />
              )}
              {t("main.add_song")}
            </IconButton>
          )}
          {props?.deletePlaylistSong && (
            <IconButton
              className="toolbarPanel__menu"
              aria-label="Delete Song"
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 1,
                pt: 4,
                aspectRatio: "1/1",
                width: "8rem",
                // color: theme.palette.mode === "dark" ? "#cfd8dc" : "#263238",
              }}
              onClick={() => setPopUp(PopUpEnum.DeletePlaylistSong)}
              disabled={songId?.length === 0}
            >
              {theme.palette.mode === "dark" ? <MinusDark /> : <MinusLight />}
              {t("main.delete_song")}
            </IconButton>
          )}
          {props?.addChannelItem && (
            <IconButton
              className="toolbarPanel__menu"
              aria-label="Add Playlist"
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 1,
                pt: 4,
                aspectRatio: "1/1",
                width: "8rem",
                color: theme.palette.mode === "dark" ? "#cfd8dc" : "#263238",
              }}
              onClick={() => setPopUp(PopUpEnum.AddPlayList)}
            >
              {theme.palette.mode === "dark" ? (
                <AddIconDark />
              ) : (
                <AddIconLight />
              )}
              {t("main.add_channel")}
            </IconButton>
          )}
          {props?.deleteChannelItem && (
            <IconButton
              className="toolbarPanel__menu"
              aria-label="Delete Channel"
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 1,
                pt: 5,
                aspectRatio: "1/1",
                width: "9rem",
                color: theme.palette.mode === "dark" ? "#cfd8dc" : "#263238",
              }}
              disabled={deleteChannelId?.length === 0}
              onClick={() => setPopUp(PopUpEnum.DeletePlayList)}
            >
              {theme.palette.mode === "dark" ? <MinusDark /> : <MinusLight />}
              {t("main.delete_channel")}
            </IconButton>
          )}
          {props?.deleteChannelItem && (
            <IconButton
              className="toolbarPanel__menu"
              aria-label="Duplicate Channel"
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 1,
                pt: 5,
                aspectRatio: "1/1",
                width: "9rem",
                color: theme.palette.mode === "dark" ? "#cfd8dc" : "#263238",
              }}
              disabled={deleteChannelId?.length === 0}
              onClick={() => duplicateChannel()}
            >
              {theme.palette.mode === "dark" ? <DuplicateDark /> : <DuplicateLight />}
              {t("main.duplicate_channel")}
            </IconButton>
          )}
          {/* Music library tools */}
          {props?.musicLibraryTools && (
            <IconButton
              className="toolbarPanel__menu"
              id={
                theme.palette.mode === "dark"
                  ? "channel-tools-label-dark"
                  : "channel-tools-label"
              }
              aria-label="Create folder"
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 1,
                pt: 4,
                aspectRatio: "1/1",
                width: "8rem",
                color: theme.palette.mode === "dark" ? "#cfd8dc" : "#263238",
              }}
            >
              <HandymanOutlined />
              {t("main.tools")}
            </IconButton>
          )}
          {/* Test Chnnel tools dropdown */}
          {location.pathname.includes("manage-customers")
            ? ""
            : props?.channelTools && (
                <Dropdown
                  className="toolbarPanel__menu"
                  trigger={
                    <Button
                      id={
                        theme.palette.mode === "dark"
                          ? "channel-tools-label-dark"
                          : "channel-tools-label"
                      }
                      className="toolbarPanel__menu"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        p: 1,
                        pt: 4,
                        aspectRatio: "1/1",
                        width: "8rem",
                        color:
                          theme.palette.mode === "dark" ? "#cfd8dc" : "#263238",
                      }}
                    >
                      <HandymanOutlined />
                      <span>
                        {t("main.Tools")} <ArrowDropDown />
                      </span>
                    </Button>
                  }
                  menu={[
                    <DropdownNestedMenuItem
                      className="dropdown toolsDropdown"
                      label={t("main.re-generate_channel_song_list")}
                      onClick={() => ReGenerateChannelSongListById()}
                    />,
                    <DropdownNestedMenuItem
                      className="dropdown toolsDropdown"
                      label={t("main.export")}
                      rightIcon={<ArrowRight />}
                      menu={[
                        <Box className="toolbar-dropdownMenuList">
                          {/* <DropdownMenuItem>
                            {t("main.export_data")}
                          </DropdownMenuItem> */}
                          <DropdownMenuItem
                            onClick={() => {
                              handleExportAudio();
                            }}
                          >
                            {t("main.export_audiofile")}
                          </DropdownMenuItem>
                          <DropdownMenuItem
                            onClick={() => {
                              handleExportEncryptedAudio();
                            }}
                          >
                            {t("main.export_encrypted_audiofile")}
                          </DropdownMenuItem>
                        </Box>,
                      ]}
                    />,
                  ]}
                />
              )}
          {/* Channel Time Schedule Starts here */}
          {props?.addChannelTimeSchedule && (
            <IconButton
              className="toolbarPanel__menu"
              aria-label="Add Playlist"
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 1,
                pt: 4,
                aspectRatio: "1/1",
                width: "8rem",
                color: theme.palette.mode === "dark" ? "#cfd8dc" : "#263238",
              }}
              onClick={() => setPopUp(PopUpEnum.addChannelTimeSchedule)}
            >
              <Add />
              {t("main.AddItem")}
            </IconButton>
          )}
          {props?.deleteChannelTimeSchedule && (
            <IconButton
              className="toolbarPanel__menu"
              aria-label="Delete Channel"
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 1,
                pt: 4,
                aspectRatio: "1/1",
                width: "8rem",
                color: theme.palette.mode === "dark" ? "#cfd8dc" : "#263238",
              }}
            >
              {theme.palette.mode === "dark" ? <MinusDark /> : <MinusLight />}

              {t("main.Remove_Item")}
            </IconButton>
          )}
          {props?.channelTimeScheduleTools && (
            <IconButton
              className="toolbarPanel__menu"
              aria-label="Tools"
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 1,
                pt: 4,
                aspectRatio: "1/1",
                width: "8rem",
                color: theme.palette.mode === "dark" ? "#cfd8dc" : "#263238",
              }}
            >
              <HandymanOutlined
                fontSize="large"
                sx={{
                  transform: "scale(1.3)",
                }}
              />
              <FormControl variant="standard" sx={{ m: 0, minWidth: 80 }}>
                <InputLabel id="demo-simple-select-label">Tools</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                >
                  <MenuItem
                    onClick={() =>
                      setPopUp(PopUpEnum.addChannelTimeScheduleDropins)
                    }
                  >
                    {t("main.Dropins")}
                  </MenuItem>
                </Select>
              </FormControl>
            </IconButton>
          )}
          <Box
            sx={{
              flexGrow: 1,
            }}
          ></Box>
          {props?.channelEditorSearchbar && <ChanneleditorSearchBar />}
          {props?.ScheduleSearchBar && <ScheduleSearchBar />}
          {props?.manageCustomerSearchbar && <ManageCustomerSearchBar />}
          {!props?.channelEditorSearchbar &&
            !props?.ScheduleSearchBar &&
            !props?.manageCustomerSearchbar && (
              <>
                {location?.pathname?.includes("music") ? (
                  ""
                ) : (
                  <TextField
                    className="customSearch"
                    id="search-all"
                    placeholder={t("main.search_library")}
                    type="text"
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchOutlined
                            style={{
                              color:
                                theme.palette.mode === "dark"
                                  ? "#3c434b"
                                  : "#3c434b",
                            }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </>
            )}
        </Stack>
      </Toolbar>
      <ImportFolderDialog
        props={{ importContentLibrary: false }}
        isOpen={popUp === PopUpEnum.FolderUpload}
        handleClose={() => setPopUp(PopUpEnum.None)}
      ></ImportFolderDialog>
      <ImportFileDialog
        props={{ importContentLibrary: false }}
        isOpen={popUp === PopUpEnum.FileUpload}
        handleClose={() => setPopUp(PopUpEnum.None)}
      ></ImportFileDialog>
      <CreateCatalogDialog
        isOpen={popUp === PopUpEnum.AddCatalog}
        handleClose={() => setPopUp(PopUpEnum.None)}
      ></CreateCatalogDialog>
      <CreateWheelDesignerDialog
        isOpen={popUp === PopUpEnum.AddWheelDesigner}
        handleClose={() => setPopUp(PopUpEnum.None)}
      ></CreateWheelDesignerDialog>
      <CreateTimeManagerDialog
        isOpen={popUp === PopUpEnum.AddTimeManager}
        handleClose={() => setPopUp(PopUpEnum.None)}
      ></CreateTimeManagerDialog>
      <AddScheduleItemDialog
        isOpen={popUp === PopUpEnum.AddSchedule}
        handleClose={() => setPopUp(PopUpEnum.None)}
      ></AddScheduleItemDialog>
      <DeleteChannelScheduleDialog
        isOpen={popUp === PopUpEnum.DeleteSchedule}
        handleClose={() => setPopUp(PopUpEnum.None)}
      ></DeleteChannelScheduleDialog>
      <DuplicateDayDialog
        isOpen={popUp === PopUpEnum.DuplicateDay}
        handleClose={() => setPopUp(PopUpEnum.None)}
      ></DuplicateDayDialog>
      <DuplicateZoneDialog
        isOpen={popUp === PopUpEnum.DuplicateZone}
        handleClose={() => setPopUp(PopUpEnum.None)}
      ></DuplicateZoneDialog>
      <AddChannelListDialog
        isOpen={popUp === PopUpEnum.AddPlayList}
        handleClose={() => setPopUp(PopUpEnum.None)}
      ></AddChannelListDialog>
      <DeletePlaylistDialog
        isOpen={popUp === PopUpEnum.DeletePlayList}
        handleClose={() => setPopUp(PopUpEnum.None)}
      ></DeletePlaylistDialog>
      <AddChannelListSongDialog
        isOpen={popUp === PopUpEnum.AddPlaylistSong}
        handleClose={() => setPopUp(PopUpEnum.None)}
      ></AddChannelListSongDialog>
      <DeleteSongConfirmDialog
        isOpen={popUp === PopUpEnum.DeletePlaylistSong}
        handleClose={() => setPopUp(PopUpEnum.None)}
        voice
      ></DeleteSongConfirmDialog>
      <ManageDropinsDialog
        isOpen={popUp === PopUpEnum.ManageDropin}
        handleClose={() => setPopUp(PopUpEnum.None)}
      ></ManageDropinsDialog>
      <AddChannelTimeScheduleDialog
        isOpen={popUp === PopUpEnum.addChannelTimeSchedule}
        handleClose={() => setPopUp(PopUpEnum.None)}
      ></AddChannelTimeScheduleDialog>
      <AddChannelTimeScheduleDropinsDialog
        isOpen={popUp === PopUpEnum.addChannelTimeScheduleDropins}
        handleClose={() => setPopUp(PopUpEnum.None)}
      ></AddChannelTimeScheduleDropinsDialog>
      <RipperDialog
        isOpen={popUp === PopUpEnum.AddRipper}
        handleClose={() => setPopUp(PopUpEnum.None)}
      ></RipperDialog>
    </>
  );
};

export default NavigationPanel;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Button,
  Checkbox,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Stack } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useChannelIdContext } from "../../../context/ChannelIdContext";
import { CustomerList } from "../../../types/Customer";
import "./Licence.scss";
const API_URL = process.env.REACT_APP_BACKEND_URL;
export default function Licensing() {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const [customers, setCustomers] = useState<CustomerList[]>([]);
  const [sites, setSites] = useState([]);
  const [customerId, setCustomerId] = useState<any>();
  const [siteId, setSiteId] = useState<any>();
  const [, setIsSite] = useState<boolean>(false);
  const { currentUser, setIsTokenExpired, setIsTokenUpdated, IsTokenUpdated } =
    useChannelIdContext();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isLicenseKey, setIsLicenseKey] = useState<boolean>(false);
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  const [licenseFormData, setLicenseFormData] = useState<any>({
    id: null,
    siteId: null,
    customerId: null,
    amountOfZones: null,
    licenseValidDate: null,
    isDropInActive: null,
    dropInValidDate: null,
    isOverridePlayerActive: null,
    overridePlayerValidDate: null,
    isVoiceTrackActive: null,
    voiceTracksValidDate: null,
  });
  const adjustTimezoneOffset = (date: moment.MomentInput) => {
    const timezoneOffset = moment().utcOffset();
    return moment(date)?.add(timezoneOffset, "minutes").toISOString();
  };

  const handleLicenseValidDate = (value: moment.MomentInput) => {
    const adjustedDate = adjustTimezoneOffset(value);
    setLicenseFormData({ ...licenseFormData, licenseValidDate: adjustedDate });
  };

  const handlevoiceTracksValidDate = (value: moment.MomentInput) => {
    const TracksValidDate = adjustTimezoneOffset(value);
    setLicenseFormData({
      ...licenseFormData,
      voiceTracksValidDate: TracksValidDate,
    });
  };

  const handleoverridePlayerValidDate = (value: moment.MomentInput) => {
    const PlayerValidDate = adjustTimezoneOffset(value);
    setLicenseFormData({
      ...licenseFormData,
      overridePlayerValidDate: PlayerValidDate,
    });
  };

  const handledropInValidDate = (value: moment.MomentInput) => {
    const dropInValidDate = adjustTimezoneOffset(value);
    setLicenseFormData({
      ...licenseFormData,
      dropInValidDate: dropInValidDate,
    });
  };

  const handleLicenseData = async (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (!currentUser?.editLicense) {
      toast.error(t("main.User_doesn't_have_authority_to_edit_license"));
    } else if (
      licenseFormData.amountOfZones === null ||
      licenseFormData.isDropInActive === null ||
      licenseFormData.licenseValidDate === null ||
      licenseFormData.isVoiceTrackActive === null ||
      licenseFormData.voiceTracksValidDate === null ||
      licenseFormData.isOverridePlayerActive === null ||
      licenseFormData.overridePlayerValidDate === null
    ) {
      toast.error(t("main.Please_fill_the_form"));
    } else {
      const requestOptions = {
        method: isEdit ? "POST" : "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token?.accessToken}`,
        },
        body: JSON.stringify({
          id: licenseFormData.id,
          customerId: licenseFormData.customerId,
          siteId: licenseFormData.siteId, //siteId if site present
          amountOfZones: licenseFormData?.amountOfZones,
          isDropInActive: licenseFormData?.isDropInActive,
          dropInValidDate: licenseFormData?.dropInValidDate,
          licenseValidDate: licenseFormData?.licenseValidDate,
          isVoiceTrackActive: licenseFormData?.isVoiceTrackActive,
          voiceTracksValidDate: licenseFormData?.voiceTracksValidDate,
          isOverridePlayerActive: licenseFormData?.isOverridePlayerActive,
          overridePlayerValidDate: licenseFormData?.overridePlayerValidDate,
        }),
      };
      fetch(`${API_URL}/License/License`, requestOptions)
        .then((response) => {
          if (
            response.ok === false &&
            response.status === 401 &&
            token?.refreshToken !== ""
          ) {
            setIsTokenExpired(true);
          } else if (response.status === 401 && token?.refreshToken === "") {
            navigate("/");
            localStorage.removeItem("token");
            localStorage.clear();
          } else {
            setIsTokenUpdated(false);
            return response.json();
          }
        })
        .then((data) => {
          if (data?.success === true) {
            toast.success(
              isEdit
                ? t("main.Licensing_Data_Updated_Successfully")
                : t("main.Licensing_Data_Posted_Successfully")
            );
            setLicenseFormData({ licenseFormData: null });
          } else {
            toast.error(data?.error);
          }
        });
    }
  };

  const fetchLoadFile = async () => {
    setIsSite(true);
    if (customerId) {
      fetch(`${API_URL}/License/LoadLicFileCustomer?customerId=${customerId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token?.accessToken}`,
        },
      })
        .then((response) => {
          if (
            response.ok === false &&
            response.status === 401 &&
            token?.refreshToken !== ""
          ) {
            setIsTokenExpired(true);
          } else if (response.status === 401 && token?.refreshToken === "") {
            navigate("/");
            localStorage.removeItem("token");
            localStorage.clear();
          } else {
            setIsTokenUpdated(false);
            return response.json();
          }
        })
        .then((data) => {
          if (data.success === true) {
            setLicenseFormData(data?.data);
            setIsLicenseKey(true);
            toast.success(t("main.License_Data_According_To_Customer"));
            setIsEdit(true);
          } else {
            toast.error(data?.message);
          }
        });
    } else {
      toast.error(t("main.Please_Select_Site"));
    }
  };

  const fetchLoadFileBySite = async () => {
    setIsSite(false);
    if (siteId) {
      const response = await fetch(
        `${API_URL}/License/LoadLicFileSite?siteId=${siteId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token?.accessToken}`,
          },
        }
      );
      const data = await response.json();
      if (!data?.success) {
        toast.error(t("main.No_Lic_Found"));
        setLicenseFormData({ licenseFormData: null });
        setSiteId("");
        console.log("Mach weg 1");
      } else {
        setIsLicenseKey(true);
        setLicenseFormData(data?.data);
        toast.success(t("main.License_Data_According_To_Site"));
        setSiteId("");
        console.log("Mach weg 2");
        setIsEdit(true);
      }
    } else {
      toast.error(t("main.Please_Select_Site"));
    }
  };

  const fetchCustomer = useCallback(async () => {
    fetch(`${API_URL}/Customer/GetAll`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    })
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data.success === true) {
          setCustomers(data?.data);
        } else {
          setCustomers([]);
        }
      });
  }, [IsTokenUpdated]);

  const getSiteById = useCallback(async (customerId: string) => {
    const response = await fetch(`${API_URL}/Site/Get/` + customerId, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    });
    const data = await response.json();
    setSites(data?.data);
  }, []);

  useEffect(() => {
    if (customerId) {
      getSiteById(customerId);
    }
  }, [customerId, getSiteById]);

  useEffect(() => {
    fetchCustomer();
  }, [fetchCustomer]);
  return (
    <Paper elevation={2} sx={{ p: 3 }}>
      <Grid item xs={12} lg={9} xl={7}>
        <div className="card-block">
          <Typography
            variant="h5"
            style={{ color: theme.palette.mode === "dark" ? "#000" : "#000" }}
            sx={{ flexShrink: 0, fontWeight: 600, letterSpacing: 1 }}
          >
            {t("main.licensing")}
          </Typography>
          <div className="card-body">
            <Stack direction="column" spacing={3}>
              <div className="form-block">
                <Typography>{t("main.customer")}</Typography>
                <Select
                  style={{
                    color: theme.palette.mode === "dark" ? "#000" : "#000",
                  }}
                  value={
                    customers?.some(
                      (item: any) => item.id === licenseFormData?.customerId
                    )
                      ? licenseFormData?.customerId
                      : ""
                  }
                  onChange={(e) => {
                    setLicenseFormData({
                      ...licenseFormData,
                      customerId: e.target.value,
                    });
                    setCustomerId(e.target.value);
                  }}
                >
                  {customers?.map((item: any) => {
                    return (
                      <MenuItem key={item?.id} value={item?.id}>
                        {item?.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
              <div className="form-block">
                <Typography>{t("main.Site")}</Typography>
                <Select
                  style={{ color: "#000" }}
                  value={
                    sites?.some(
                      (item: any) => item.id === licenseFormData?.siteId
                    )
                      ? licenseFormData?.siteId
                      : ""
                  }
                  onChange={(e) => {
                    setLicenseFormData({
                      ...licenseFormData,
                      siteId: e.target.value,
                    });
                    // setCustomerId(e.target.value);
                    setSiteId(e.target.value);
                  }}
                >
                  {sites?.length === 0 ? (
                    <MenuItem>{t("main.Select_Customer")}</MenuItem>
                  ) : (
                    sites?.map((item: any) => {
                      return (
                        <MenuItem key={item?.id} value={item?.id}>
                          {item?.name}
                        </MenuItem>
                      );
                    })
                  )}
                </Select>
              </div>
              <div className="form-block">
                <Typography>{t("main.license_Is_valid_until")}</Typography>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    disablePast
                    inputFormat="DD-MM-YYYY"
                    value={licenseFormData?.licenseValidDate || null}
                    onChange={handleLicenseValidDate}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
              <div className="form-block">
                <Typography>{t("main.zone_number")}</Typography>
                <TextField
                  type="number"
                  value={
                    licenseFormData?.amountOfZones
                      ? licenseFormData?.amountOfZones
                      : ""
                  }
                  onChange={(event: any) =>
                    setLicenseFormData({
                      ...licenseFormData,
                      amountOfZones: event.target.value,
                    })
                  }
                />
              </div>
              <div className="form-block">
                <div className="">
                  <Typography>{t("main.drop-Ins")}</Typography>
                </div>
                <div className="input-field-block">
                  <Checkbox
                    checked={
                      licenseFormData?.isDropInActive
                        ? licenseFormData?.isDropInActive
                        : false
                    }
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setLicenseFormData({
                        ...licenseFormData,
                        isDropInActive: event.target.checked,
                      })
                    }
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <Typography>{t("main.valid_until")}</Typography>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      disablePast
                      inputFormat="DD-MM-YYYY"
                      value={licenseFormData?.dropInValidDate || null}
                      onChange={handledropInValidDate}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </div>
              </div>
              <div className="form-block">
                <div className="">
                  <Typography>{t("main.voice_tracks")}</Typography>
                </div>
                <div className="input-field-block">
                  <Checkbox
                    checked={
                      licenseFormData?.isVoiceTrackActive
                        ? licenseFormData?.isVoiceTrackActive
                        : false
                    }
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setLicenseFormData({
                        ...licenseFormData,
                        isVoiceTrackActive: event.target.checked,
                      })
                    }
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <Typography>{t("main.valid_until")}</Typography>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      disablePast
                      inputFormat="DD-MM-YYYY"
                      value={licenseFormData?.voiceTracksValidDate || null}
                      onChange={handlevoiceTracksValidDate}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </div>
              </div>
              <div className="form-block">
                <div className="">
                  <Typography>{t("main.override_player")}</Typography>
                </div>
                <div className="input-field-block">
                  <Checkbox
                    checked={
                      licenseFormData?.isOverridePlayerActive
                        ? licenseFormData?.isOverridePlayerActive
                        : false
                    }
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setLicenseFormData({
                        ...licenseFormData,
                        isOverridePlayerActive: event.target.checked,
                      })
                    }
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <Typography>{t("main.valid_until")}</Typography>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      disablePast
                      inputFormat="DD-MM-YYYY"
                      value={licenseFormData?.overridePlayerValidDate || null}
                      onChange={handleoverridePlayerValidDate}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </div>
              </div>
              {isLicenseKey === true && (
                <div className="form-block">
                  <Typography>{t("main.License_Key")}</Typography>
                  <TextField
                    sx={{ width: "40%" }}
                    type="text"
                    value={
                      licenseFormData?.licenseKey
                        ? licenseFormData?.licenseKey
                        : ""
                    }
                    aria-readonly
                  />
                </div>
              )}
              <div className="btn-block">
                <Button>{t("main.set_default_values")}</Button>
                <Button
                  onClick={(e: any) => {
                    handleLicenseData(e);
                    setLicenseFormData({});
                  }}
                >
                  {t("main.safe_Lic_files")}
                </Button>
                <Button
                  onClick={() => {
                    siteId ? fetchLoadFileBySite() : fetchLoadFile();
                  }}
                >
                  {t("main.Load_Lic_files")}
                </Button>
              </div>
            </Stack>
          </div>
        </div>
      </Grid>
    </Paper>
  );
}

import React from "react";
import Typography from "@mui/material/Typography";
import TrackTable from "../component/Tables/TrackTable/TrackTable";
import SidePanel from "../layouts/_old/PersistentSidePanel";
import { useTranslation } from "react-i18next";

export default function VoiceTrackPage() {
  return (
    <SidePanel>
      <VoiceTrackContent />
    </SidePanel>
  );
}

function VoiceTrackContent() {
  const {t} = useTranslation()
  return (
    <>
      <Typography variant="body1" noWrap component="div">
        {t("main.List_of_uploaded_voice_tracks")}
      </Typography>
      <TrackTable></TrackTable>
    </>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress } from "@mui/material";
import MaterialReactTable from "material-react-table";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useChannelIdContext } from "../../../context/ChannelIdContext";
import "./subscribedSite.scss";

const API_URL = process.env.REACT_APP_BACKEND_URL;
const SubscribedSites = () => {
  let navigate = useNavigate();
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  const { channelId, setIsTokenExpired, setIsTokenUpdated, IsTokenUpdated } =
    useChannelIdContext();
  const [channelSiteData, setChannelSiteData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //  Fetch channel subscribedSites sites
  const fetchChannelSites = useCallback(async () => {
    try {
      setIsLoading(true);
      fetch(`${API_URL}/Channel/SubscribedSites?id=` + channelId, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token?.accessToken}`,
        },
      })
        .then((response) => {
          if (
            response.ok === false &&
            response.status === 401 &&
            token?.refreshToken !== ""
          ) {
            setIsTokenExpired(true);
          } else if (response.status === 401 && token?.refreshToken === "") {
            navigate("/");
            localStorage.removeItem("token");
            localStorage.clear();
          } else {
            setIsTokenUpdated(false);
            return response.json();
          }
        })
        .then((data) => {
          if (data.success === true) {
            // console.log(data);

            setIsLoading(false);
            // const outputArray = data?.data?.map((item: any) => {
            //   return {
            //     customerName: item?.customerName,
            //     subRows: item?.sites?.map((site: any) => {
            //       return { customerName: site?.name };
            //     }),
            //   };
            // });
            setChannelSiteData(data.data);
            console.log(channelSiteData);
          }
        });
    } catch (error) {
      setIsLoading(false);
    }
  }, [channelId, IsTokenUpdated]);

  const column = [
    {
      accessorKey: "customerName",
      header: "Customer Name",
    },
    {
      accessorKey: "siteName",
      header: "Site",
    },
  ];
  useEffect(() => {
    fetchChannelSites();
  }, [fetchChannelSites]);

  return isLoading === true ? (
    <div style={{ justifyContent: "center", display: "flex" }}>
      <CircularProgress style={{ marginTop: "15rem" }} />
    </div>
  ) : (
    <>
      <div className="materialTable  materialSiteTable selectRow">
        <MaterialReactTable
          columns={column}
          data={channelSiteData}
          enablePagination={false}
          enableExpandAll
          enableColumnActions={true}
          enableColumnOrdering={true}
          getRowId={(originalRow: any) => originalRow?.id}
        />
      </div>
    </>
  );
};
export default SubscribedSites;

import React from "react";
import { songList } from "../../types/Audio";
import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function ViewDaysofWeek({
  audio,
  setAudioData,
}: {
  audio: songList;
  setAudioData: React.Dispatch<React.SetStateAction<songList>>;
}) {

  const {t} = useTranslation();

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={1}
    >
      <Grid item>
        <FormControlLabel
          control={
            <Checkbox
              size="medium"
              checked={audio?.playMonday}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setAudioData({
                  ...audio,
                  playMonday: event.target.checked,
                })
              }
            />
          }
          sx={{ "& .MuiSvgIcon-root": { fontSize: 32 } }}
          label={t("main.monday")}
          labelPlacement="bottom"
        />
        <FormControlLabel
          control={
            <Checkbox
              size="medium"
              checked={audio?.playTuesday}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setAudioData({
                  ...audio,
                  playTuesday: event.target.checked,
                })
              }
            />
          }
          sx={{ "& .MuiSvgIcon-root": { fontSize: 32 } }}
          label={t("main.tuesday")}
          labelPlacement="bottom"
        />
        <FormControlLabel
          control={
            <Checkbox
              size="medium"
              checked={audio?.playWednesday}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setAudioData({
                  ...audio,
                  playWednesday: event.target.checked,
                })
              }
            />
          }
          sx={{ "& .MuiSvgIcon-root": { fontSize: 32 } }}
          label={t("main.wednesday")}
          labelPlacement="bottom"
        />
        <FormControlLabel
          control={
            <Checkbox
              size="medium"
              checked={audio?.playThursday}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setAudioData({
                  ...audio,
                  playThursday: event.target.checked,
                })
              }
            />
          }
          sx={{ "& .MuiSvgIcon-root": { fontSize: 32 } }}
          label={t("main.thursday")}
          labelPlacement="bottom"
        />
        <FormControlLabel
          control={
            <Checkbox
              size="medium"
              checked={audio?.playFriday}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setAudioData({
                  ...audio,
                  playFriday: event.target.checked,
                })
              }
            />
          }
          sx={{ "& .MuiSvgIcon-root": { fontSize: 32 } }}
          label={t("main.friday")}
          labelPlacement="bottom"
        />
        <FormControlLabel
          control={
            <Checkbox
              size="medium"
              checked={audio?.playSaturday}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setAudioData({
                  ...audio,
                  playSaturday: event.target.checked,
                })
              }
            />
          }
          sx={{ "& .MuiSvgIcon-root": { fontSize: 32 } }}
          label={t("main.saturday")}
          labelPlacement="bottom"
        />
        <FormControlLabel
          control={
            <Checkbox
              size="medium"
              checked={audio?.playSunday}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setAudioData({
                  ...audio,
                  playSunday: event.target.checked,
                })
              }
            />
          }
          sx={{ "& .MuiSvgIcon-root": { fontSize: 32 } }}
          label={t("main.sunday")}
          labelPlacement="bottom"
        />
      </Grid>
    </Grid>
  );
}

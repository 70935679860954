import PauseCircleRoundedIcon from "@mui/icons-material/PauseCircleRounded";
import {
  FormControl,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Slider,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import WaveSurfer from "wavesurfer.js";
import RegionsPlugin from "wavesurfer.js/src/plugin/regions";
import { ReactComponent as Pauseicon } from "../../assets/images/Pausebtnicon.svg";
import { ReactComponent as PlayIcon } from "../../assets/images/Playbtnicon.svg";
import { AudioVoiceTrackEditor } from "../../types/VoiceTrack";

const VoiceTrackWave = ({
  playing,
  voiceTrack,
  setPlaying,
  setVoiceTrack,
}: {
  playing: boolean;
  voiceTrack: AudioVoiceTrackEditor;
  setPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  setVoiceTrack: React.Dispatch<React.SetStateAction<AudioVoiceTrackEditor>>;
}) => {
  const audioRef = useRef<any>(null);
  const theme = useTheme();
  const { t } = useTranslation();
  const waveformRef = useRef<HTMLDivElement>(null);
  const [endTime, setEndTime] = useState<number>(0);
  const [startTime, setStartTime] = useState<number>(0);
  const [devices, setDevices] = React.useState<any>(null);
  const [waveform, setWaveform] = useState<WaveSurfer | null>(null);
  const [selectedDeviceId, setSelectedDeviceId] = useState<string>("");
  async function getConnectedDevices(type: any, callback: any) {
    try {
      await navigator?.mediaDevices?.getUserMedia({ audio: true });
      const devices = await navigator?.mediaDevices?.enumerateDevices();
      const filtered = devices?.filter(
        (device: any) =>
          device?.kind === type &&
          device?.deviceId !== "communications" &&
          device?.deviceId !== "default"
      );
      callback(filtered);
    } catch (e) {
      console.log(e);
    }
  }

  const handlePlay = () => {
    if (startTime > 0 && endTime > 0) {
      waveform!.play(startTime, endTime);
      setPlaying(true);
    } else {
      waveform!.play();
      setPlaying(true);
    }
  };

  const handleStop = () => {
    waveform!.stop();
  };

  const handlePause = () => {
    waveform!.pause();
    setPlaying(false);
  };
  const handleStartTimeChange = () => {
    setVoiceTrack({ ...voiceTrack });
    waveform?.setCurrentTime(startTime);
  };

  const handleEndTimeChange = () => {
    setVoiceTrack({ ...voiceTrack });
    waveform!.setRegion(startTime, endTime);
  };

  const handleSliderChange = (event: Event, newValue: number | any) => {
    waveform!.setVolume(newValue / 100);
  };

  useEffect(() => {
    const wavesurfer = WaveSurfer.create({
      container: waveformRef?.current!,
      waveColor: "#009b00",
      backgroundColor: "#000000",
      barGap: 2,
      barWidth: 3,
      barRadius: 3,
      cursorWidth: 3,
      cursorColor: "#FFFFFF",
      plugins: [
        RegionsPlugin.create({
          container: "#wave-timeline",
          regions: [
            {
              start: startTime,
              end: endTime,
              color: "hsla(191, 188%, 183%, 0.5)",
            },
          ],
        }),
      ],
    });
    setWaveform(wavesurfer);
    wavesurfer?.load(voiceTrack?.coverImageFile);
    wavesurfer?.on("ready", () => {
      wavesurfer?.pause();
      setPlaying(false);
      if (audioRef.current) {
        wavesurfer!.setVolume(audioRef?.current?.volume);
        wavesurfer!.setPlaybackRate(audioRef?.current?.playbackRate);
      }
    });
    wavesurfer.on("play", () => {
      setPlaying(true);
    });
    wavesurfer.on("pause", () => {
      setPlaying(false);
    });
    wavesurfer.on("finish", () => {
      setPlaying(false);
    });

    if (selectedDeviceId) wavesurfer?.setSinkId(selectedDeviceId);

    return () => {
      wavesurfer.destroy();
    };
  }, [
    endTime,
    voiceTrack?.coverImageFile,
    startTime,
    setPlaying,
    selectedDeviceId,
  ]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef?.current?.setSinkId(selectedDeviceId);
    }
  }, [selectedDeviceId, voiceTrack?.coverImageFile]);

  useEffect(() => {
    getConnectedDevices("audioinput", (devices: any) => {
      setDevices(devices);
    });
  }, [voiceTrack?.coverImageFile]);

  const mediaDevice = navigator?.mediaDevices;
  useEffect(() => {
    mediaDevice.ondevicechange = () => {
      getConnectedDevices("audioinput", (devices: any) => {
        setDevices(devices);
      });
    };
  });

  return (
    <Stack direction="column">
      <>
        <div id="wave" ref={waveformRef}></div>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          mt={"15px"}
          gap={"10px"}
          flexWrap={"wrap"}
        >
          <div className="PlayBtn">
            <div className="BtnPart">
              {playing === true ? (
                <IconButton onClick={handlePause}>
                  <PauseCircleRoundedIcon
                    sx={{ fontSize: "1.6rem", color: "#fff" }}
                  />
                </IconButton>
              ) : (
                <IconButton onClick={handlePlay}>
                  <Tooltip title={t("main.Play")}>
                    <PlayIcon />
                  </Tooltip>
                </IconButton>
              )}
              <IconButton aria-label="next song" onClick={handleStop}>
                <Tooltip title={t("main.Pause")}>
                  <Pauseicon />
                </Tooltip>
              </IconButton>
            </div>
            <FormControl className="DrpCustom-btn" fullWidth>
              <Select
                className="DrpCustom"
                style={{
                  color: theme.palette.mode === "dark" ? "#000" : "#000",
                }}
                displayEmpty
                value={selectedDeviceId || ""}
                placeholder="Ausgabegerät"
                renderValue={(value) => {
                  if (value) {
                    return devices?.find(
                      (item: any) => selectedDeviceId === item.deviceId
                    )?.label;
                  } else {
                    return <Typography>{t("main.Output_device")}</Typography>;
                  }
                }}
                onChange={async (event: SelectChangeEvent) => {
                  setSelectedDeviceId(event.target.value);
                  await waveform!.setSinkId(event.target.value);
                }}
              >
                {devices?.length === 0 ? (
                  <MenuItem>{t("main.No_Device_Connected")}</MenuItem>
                ) : (
                  devices?.map((device: any, index: number) => (
                    <MenuItem key={device?.deviceId} value={device?.deviceId}>
                      {device?.label || device?.deviceId || device?.groupId}
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>
            <Slider
              className="VolumeSlider"
              sx={{ color: "#777d8569" }}
              defaultValue={100}
              onChange={handleSliderChange}
              step={10}
              min={0}
              max={100}
              marks={true}
              valueLabelDisplay="auto"
            />
          </div>
          {/* <Stack className="SetTime" direction="row" spacing={2}>
            <Stack
              direction="column"
              justifyContent="start"
              sx={{ width: "7rem" }}
            >
              <TextField
                variant="outlined"
                placeholder="hh:mm"
                size="small"
                type="text"
                id="startTime"
                defaultValue={new Date(endTime * 1000)
                  .toISOString()
                  .substring(14, 19)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (e.target.value.includes(":")) {
                    var a = e.target.value.split(":"); // split it at the colons
                    var seconds = +a[0] * 60 + +a[1];
                    setEndTime(seconds);
                    setVoiceTrack({ ...voiceTrack, queueOutPoint: seconds });
                  }
                }}
              />
              <Button
                variant="contained"
                color="primary"
                sx={{ mt: 1, textTransform: "none" }}
                onClick={handleStartTimeChange}
              >
                {t("main.Set_Start")}
              </Button>
            </Stack>
            <Stack
              direction="column"
              justifyContent="start"
              sx={{ width: "7rem" }}
            >
              <TextField
                variant="outlined"
                placeholder="hh:mm"
                size="small"
                type="number"
                id="endTime"
                value={endTime}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const value = parseInt(e.target.value, 10);
                  if (value > 0) setEndTime(value);
                }}
              />
              <Button
                onClick={handleEndTimeChange}
                variant="contained"
                color="primary"
                sx={{ mt: 1, textTransform: "none" }}
              >
                {t("main.Set_Mix")}
              </Button>
            </Stack>
          </Stack> */}
          {/* <div className="SetTime">
            <Button
              onClick={() => {
                setEndTime(0);
                setStartTime(0);
              }}
              variant="contained"
              color="primary"
              size="large"
              sx={{ flexDirection: "column", textTransform: "none" }}
            >
              {t("main.Reset")}
            </Button>
          </div> */}
        </Stack>
      </>
    </Stack>
  );
};

export default VoiceTrackWave;


import * as React from 'react';
import Slider from '@mui/material/Slider';
import { useTranslation } from 'react-i18next';

function valueLabelFormat(value: number) {
    if (value < 7) return "Low";
    if (value < 15) return "Mid";
    return "High";
}

export default function PriortySlider({ value, changeHandle }: { value: number, changeHandle: Function }) {
    const { t } = useTranslation()
    const marks = [
        {
            value: 1,
            label: `${t("main.low_priorty")}`,
        },
        {
            value: 10,
            label: `${t("main.mid_priorty")}`,
        },
        {
            value: 20,
            label: `${t("main.high_priorty")}`,
        },
    ];
    return (
        <Slider
            aria-label="Restricted values"
            value={value}
            onChange={(e, n) => changeHandle(n)}
            valueLabelFormat={valueLabelFormat}
            step={1}
            max={20}
            min={1}
            valueLabelDisplay="auto"
            marks={marks}
            sx={{ color: "#777d8569" }}
        />
    );
}

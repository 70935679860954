import { useTranslation } from "react-i18next";
import TrackTable from "../component/Tables/TrackTable/TrackTable";
import MiniSidePanel from "../layouts/MiniSidePanel/MiniSidePanel";
import NavigationPanel from "../layouts/NavigationPanel/NavigationPanel";

export default function MusicLibraryPage() {
  const {t} = useTranslation()
  return (
      <MiniSidePanel headerText={t("main.Music_Player")}>
        <MusicLibraryPageContent />
      </MiniSidePanel>
  );
}

function MusicLibraryPageContent() {
  return (
    <>
      <NavigationPanel
        onButtonClick={() => {}}
        addContent
      ></NavigationPanel>
      <TrackTable></TrackTable>
    </>
  );
}

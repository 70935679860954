/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Paper,
  Button,
  Dialog,
  Select,
  MenuItem,
  useTheme,
  PaperProps,
  Typography,
  FormControl,
  DialogTitle,
  DialogContent,
  DialogActions,
  SelectChangeEvent,
} from "@mui/material";
import "./DialogsMain.scss";
import { toast } from "react-toastify";
import Draggable from "react-draggable";
import MyFileField from "../Basic/MyFileField";
import MyTextField from "../Basic/MyTextField";

import CloseIcon from "@mui/icons-material/Close";
import { CustomerList } from "../../types/Customer";
import { VoiceTrackCategory } from "../../types/Wheel";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import { useChannelIdContext } from "../../context/ChannelIdContext";
import { useTranslation } from "react-i18next";

type importProps = {
  importContentLibrary?: boolean;
};

function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper
        {...props}
        style={{
          backgroundColor: "#34393f",
          color: "#D9D9D9",
          padding: "1rem",
        }}
      />
    </Draggable>
  );
}

const API_URL = process.env.REACT_APP_BACKEND_URL;
export default function ImportFileDialog({
  props,
  isOpen,
  handleClose,
}: {
  isOpen: boolean;
  handleClose: any;
  props: importProps;
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  let location = useLocation();
  const navigate = useNavigate();
  const {
    setIsUploadAudio,
    setIsTokenExpired,
    setIsTokenUpdated,
    IsTokenUpdated,
  } = useChannelIdContext();
  const [selectedAudio, setSelectedAudio] = useState<any>([]);
  let token = JSON.parse(localStorage?.getItem("token") ?? "{}");
  const [selectedCategroy, setSelectedCategroy] = useState<string>("");
  const [categoryModalOpen, setCategoryModalOpen] = useState<boolean>(false);
  const [categoryData, setCategoryData] = useState<VoiceTrackCategory[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<string>("");
  const [customerData, setCustomerData] = useState<CustomerList[]>([]);

  const handleAddCategory = async () => {
    const formData = new FormData();
    for (let index = 0; index < selectedAudio?.length; index++) {
      const element = selectedAudio[index];
      formData.append("files", element);
    }

    fetch(`${API_URL}/Song/UploadSongs`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `bearer ${token?.accessToken}`,
      },
    })
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data?.success === true) {
          handleClose();
          setSelectedAudio([]);
          setIsUploadAudio(true);
          setSelectedCategroy("");
          setSelectedCustomer("");
          setCategoryModalOpen(false);
          toast.success(t("main.Audio_Uploaded_Successfully"));
          if (location?.pathname?.includes("music")) {
            navigate(`audio-editor/${data?.data[data?.data?.length - 1]?.id}`);
          }
        } else {
          handleClose();
          setSelectedAudio([]);
          setSelectedCategroy("");
          setSelectedCustomer("");
          toast.error(t("main.SOMETHING_IS_BREAKING"));
        }
      });
  };

  const postVoiceTrack = async () => {
    const formData = new FormData();
    for (let index = 0; index < selectedAudio?.length; index++) {
      const element = selectedAudio[index];
      formData.append("files", element);
    }
    fetch(
      `${API_URL}/VoiceTrack/UploadVoiceTracks/${selectedCategroy}?customerId=${selectedCustomer}`,
      {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `bearer ${token?.accessToken}`,
        },
      }
    )
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data?.success === true) {
          handleClose();
          setIsUploadAudio(true);
          setSelectedCustomer("");
          setSelectedCategroy("");
          setCategoryModalOpen(false);
          toast.success(t("main.Voice_Track_Uploaded_Successfully"));
        } else {
          handleClose();
          setSelectedAudio([]);
          setSelectedCategroy("");
          setSelectedCustomer("");
          toast.error(t("main.SOMETHING_IS_BREAKING"));
        }
      });
  };

  const fetchVoiceTrackCategoriesList = useCallback(async () => {
    fetch(`${API_URL}/VoiceTrackCategory/GetAll`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    })
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data.success === true) {
          setCategoryData(data?.data);
        } else {
          setCategoryData([]);
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [IsTokenUpdated]);

  const fetchAllCustomer = useCallback(async () => {
    fetch(`${API_URL}/Customer/GetAll`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    })
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data.success === true) {
          setCustomerData(data?.data);
        } else {
          setCustomerData([]);
        }
      });
  }, [IsTokenUpdated]);

  useEffect(() => {
    if(isOpen === true){
      fetchVoiceTrackCategoriesList();
      if (location?.pathname?.includes("content-library")) {
        fetchAllCustomer();
      }
    }
  }, [isOpen,fetchAllCustomer, fetchVoiceTrackCategoriesList, location?.pathname]);

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        handleClose();
        setSelectedAudio([]);
        setSelectedCategroy("");
        setSelectedCustomer("");
        setCategoryModalOpen(false);
      }}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle
        className="dialog-title"
        style={{ cursor: "move" }}
        id="draggable-dialog-title"
      >
        <Typography component="span" sx={{ fontWeight: 700, fontSize: "24px" }}>
          {t("main.file_import")}
        </Typography>
        <span
          onClick={() => {
            handleClose();
            setSelectedCategroy("");
            setSelectedCustomer("");
            setCategoryModalOpen(false);
          }}
          className="modelCloseBtn"
        >
          <CloseIcon />
        </span>
      </DialogTitle>
      {!props?.importContentLibrary ? (
        !categoryModalOpen ? (
          <DialogContent sx={{ p: "3px", overflow: "hidden" }}>
            <Paper elevation={3} sx={{ p: "1px" }}>
              <Typography
                variant="body1"
                sx={{ fontWeight: 600, pb: 1, pt: 1, pl: 1 }}
              >
                {t("main.import_file(s)")}
              </Typography>
              <Paper
                elevation={3}
                sx={{ p: 2, backgroundColor: "#34393f", color: "#D9D9D9" }}
              >
                <MyFileField
                  disabled
                  importfile
                  filechanged={(value: any) => {
                    if (value.files[0].name.endsWith(".mp3")) {
                      setSelectedAudio(value.files);
                      // handleAddCategory();
                      //setCategoryModalOpen(true);
                    } else {
                      handleClose();
                      setSelectedAudio([]);
                      setCategoryModalOpen(false);
                    }
                  }}
                />
                <>
                <DialogActions sx={{ display: "flex", justifyContent: "space-around" }}>
              <Button
                size="medium"
                variant="contained"
                color="primary"
                sx={{ mt: 5 }}                
                onClick={() => {
                  handleClose();
                  handleAddCategory();
                  setSelectedAudio([]);
                  setSelectedCategroy("");
                  setSelectedCustomer("");
                  setCategoryModalOpen(false);
                }}
              >
                {t("main.Import")}
              </Button>
              <Button
                onClick={() => {
                  handleClose();
                  setSelectedAudio([]);
                  setSelectedCategroy("");
                  setSelectedCustomer("");
                  setCategoryModalOpen(false);
                }}
                size="medium"
                variant="outlined"
                color="secondary"
                sx={{ mt: 5 }}
              >
                {t("main.cancel")}
              </Button>
              </DialogActions>
            </>
              </Paper>
            </Paper>
            {/* <Paper elevation={3} sx={{ p: "1px", mt: 3 }}>
              <Typography
                variant="body1"
                sx={{ fontWeight: 600, pb: 1, pt: 1, pl: 1 }}
              >
                {t("main.import_to_playlist")}
              </Typography>
              <Paper
                elevation={3}
                sx={{ p: 2, backgroundColor: "#34393f", color: "#D9D9D9" }}
              >
                <MyTextField
                  sx={{
                    minWidth: "100%",
                    "& .MuiOutlinedInput-root": {
                      height: 35,
                      borderRadius: 1.5,
                      border:
                        theme.palette.mode === "light"
                          ? "1px solid #ffffff"
                          : "1px solid #d6d6d5",
                    },
                  }}
                ></MyTextField>
              </Paper>
            </Paper> */}
          </DialogContent>
        ) : (
          <DialogContent sx={{ p: "0", overflow: "hidden" }}>
            <Typography
              component="span"
              variant="body1"
              sx={{ fontWeight: 600, pb: 2, pt: 2, pl: 1 }}
            >
              <FormControl className="modal-select w-100">
                <Select
                  onChange={(e: SelectChangeEvent) => {
                    setSelectedCategroy(e.target.value);
                  }}
                  value={selectedCategroy || ""}
                  displayEmpty
                  renderValue={(value) => {
                    if (value) {
                      return categoryData?.find(
                        (item) => item?.name === selectedCategroy
                      )?.name;
                    } else {
                      return `${t("main.select_voicetrack_category")}`;
                    }
                  }}
                  fullWidth
                  className="selectBox"
                  labelId="simple-select-label"
                  id="simple-select"
                  sx={{
                    m: 1,
                    minWidth: 300,
                    height: "34px",
                    margin: "20px 0",
                    border: "1px solid #e8e8e8 !important",
                    color: "#e8e8e8 !importnat",
                    borderRadius: "10px",
                  }}
                >
                  {categoryData?.map((item: any) => {
                    return (
                      <MenuItem value={item?.name} key={item?.id}>
                        {item?.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Typography>
          </DialogContent>
        )
      ) : (
        <DialogContent sx={{ p: "1", overflow: "hidden" }}>
          <FormControl>
            <Box className="contentLibraryDialog">
              <MyFileField
                importfile
                filechanged={(value: any) => {
                  setSelectedAudio(value.files);
                }}
              ></MyFileField>
            </Box>
            <br />
          </FormControl>
          <FormControl>
            <Box className="contentLibraryDialog">
              <Typography component="span" style={{ width: "82px" }}>
                {t("main.customer")}
              </Typography>
              <Select
                displayEmpty
                style={{
                  width: "260px",
                  color: "white",
                  border: "1px solid",
                  marginInline: "105px 18px",
                  height: "35px",
                }}
                value={selectedCustomer || ""}
                renderValue={(value) => {
                  if (value) {
                    return customerData?.find(
                      (item) => item?.id === parseInt(selectedCustomer)
                    )?.name;
                  } else {
                    return <Typography>{t("main.customer")}</Typography>;
                  }
                }}
                onChange={(event: SelectChangeEvent<typeof selectedCustomer>) =>
                  setSelectedCustomer(event.target.value)
                }
              >
                {customerData &&
                  customerData?.map((item: any) => (
                    <MenuItem key={item?.id} value={item?.id}>
                      {categoryData?.length < 0
                        ? `${t("main.No_Customer_Found")}`
                        : item?.name}
                    </MenuItem>
                  ))}
              </Select>
            </Box>
            <br />
          </FormControl>
          <FormControl>
            <Box className="contentLibraryDialog">
              <Typography component="span" style={{ width: "80px" }}>
                {t("main.category")}
              </Typography>
              <Select
                displayEmpty
                style={{
                  width: "260px",
                  color: "white",
                  border: "1px solid",
                  marginInline: "110px 18px",
                  height: "35px",
                }}
                value={selectedCategroy}
                renderValue={(value) => {
                  if (value) {
                    return categoryData?.find(
                      (item) => item?.id === parseInt(selectedCategroy)
                    )?.name;
                  } else {
                    return <Typography>{t("main.category")}</Typography>;
                  }
                }}
                onChange={(event: SelectChangeEvent<typeof selectedCategroy>) =>
                  setSelectedCategroy(event.target.value)
                }
              >
                {categoryData &&
                  categoryData?.map((item: any) => (
                    <MenuItem key={item?.id} value={item?.id}>
                      {item?.name}
                    </MenuItem>
                  ))}
              </Select>
            </Box>
          </FormControl>
        </DialogContent>
      )}
      {!props?.importContentLibrary ? (
        <DialogActions sx={{ display: "flex", justifyContent: "space-around" }}>
          {categoryModalOpen && (
            <>
              <Button
                size="medium"
                variant="contained"
                color="primary"
                sx={{ mt: 5 }}
                disabled={selectedCategroy === ""}
                onClick={() => {
                  handleClose();
                  handleAddCategory();
                  setSelectedAudio([]);
                  setSelectedCategroy("");
                  setSelectedCustomer("");
                  setCategoryModalOpen(false);
                }}
              >
                {t("main.Import")}
              </Button>
              <Button
                onClick={() => {
                  handleClose();
                  setSelectedAudio([]);
                  setSelectedCategroy("");
                  setSelectedCustomer("");
                  setCategoryModalOpen(false);
                }}
                size="medium"
                variant="outlined"
                color="secondary"
                sx={{ mt: 5 }}
              >
                {t("main.cancel")}
              </Button>
            </>
          )}
        </DialogActions>
      ) : (
        <DialogActions sx={{ display: "flex", justifyContent: "space-around" }}>
          <Button
            size="medium"
            variant="contained"
            color="primary"
            sx={{ mt: 5 }}
            disabled={selectedCustomer === "" || selectedCategroy === ""}
            onClick={() => {
              postVoiceTrack();
              setSelectedAudio([]);
              setSelectedCategroy("");
              setSelectedCustomer("");
            }}
          >
            {t("main.Import")}
          </Button>
          <Button
            onClick={() => {
              handleClose();
              setSelectedAudio([]);
              setSelectedCategroy("");
              setSelectedCustomer("");
            }}
            size="medium"
            variant="outlined"
            color="secondary"
            sx={{ mt: 5 }}
          >
            {t("main.cancel")}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}

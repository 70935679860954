/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useCallback,
  MouseEventHandler,
} from "react";
import {
  Link,
  Paper,
  Stack,
  Select,
  Button,
  Dialog,
  MenuItem,
  TextField,
  PaperProps,
  Typography,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import "./addScheduleItemDialog.scss";
import { toast } from "react-toastify";
import Draggable from "react-draggable";
import CloseIcon from "@mui/icons-material/Close";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useChannelIdContext } from "../../../context/ChannelIdContext";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
const API_URL = process.env.REACT_APP_BACKEND_URL;
function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper
        {...props}
        style={{
          backgroundColor: "#34393f",
          color: "#D9D9D9",
          padding: "1rem",
        }}
      />
    </Draggable>
  );
}

interface Zone {
  id: number;
  name: string;
}
interface Wheel {
  id: number;
  name: string;
}
interface Channel {
  id: number;
  name: string;
}
interface Dropins {
  id: number;
  name: string;
}
interface formData {
  zoneId: string | null;
  dropInId: string | null;
  dayOfWeek: string | null;
  channelId: string | null;
  startingTime: Dayjs | null;
  voiceWheelId: string | null;
}

export default function AddScheduleItemDialog({
  isEdit,
  isOpen,
  handleClose,
  scheduleEditList,
}: {
  isOpen: boolean;
  isEdit: boolean;
  scheduleEditList: any;
  handleClose: any | MouseEventHandler;
}) {

  switch (scheduleEditList?.dayOfWeek) {
    case 'Monday':
      scheduleEditList.dayOfWeek = 1
      break;
    case 'Tuesday':
      scheduleEditList.dayOfWeek = 2
      break;
    case 'Wednesday':
      scheduleEditList.dayOfWeek = 3
      break;
    case 'Thursday':
      scheduleEditList.dayOfWeek = 4
      break;
    case 'Friday':
      scheduleEditList.dayOfWeek = 5
      break;
    case 'Saturday':
      scheduleEditList.dayOfWeek = 6
      break;
    case 'Sunday':
      scheduleEditList.dayOfWeek = 0
      break;
    default:
      break;
  }
  // console.log(isEdit)
  const { t } = useTranslation();
  let navigate = useNavigate();
  const { setIsTokenExpired, setIsTokenUpdated, IsTokenUpdated, selectedSite } = useChannelIdContext()
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  const [zones, setZones] = useState<Zone[]>();
  const [wheels, setWheels] = useState<Wheel[]>();
  const [dropins, setDropins] = useState<Dropins[]>();
  const { isAdded, setIsAdded } = useChannelIdContext();
  const [channels, setChannels] = useState<Channel[]>();
  const [schedule, setSchedule] = useState<any>({
    startingTime: isEdit
      ? dayjs(scheduleEditList?.startingTime, "HH:mm")
      : null,
    zoneId: isEdit ? scheduleEditList?.zoneId : null,
    dropInId: isEdit ? scheduleEditList?.dropInId : null,
    dayOfWeek: isEdit ? scheduleEditList?.dayOfWeek : null,
    channelId: isEdit ? scheduleEditList?.channel?.id : null,
    voiceWheelId: isEdit ? scheduleEditList?.voiceWheel?.id : null,
  });
  // console.log(dayjs(schedule?.startingTime).format("HH:mm"))

  const fetchZoneData = useCallback(async () => {
    fetch(`${API_URL}/Zone/GetByFilters?siteId=${selectedSite}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    })
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true)
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false)
          return response.json()
        }
      })
      .then((data) => {
        if (data.success === true) {
          setZones(data?.data);
        } else {
          setZones([]);
        }
      })
  }, [isOpen, IsTokenUpdated]);

  const initialValues = [{ id: 0, name: "Empty" }]

  const fetchWheelData = useCallback(async () => {
    fetch(`${API_URL}/VoiceWheel/GetAll`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    })
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true)
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false)
          return response.json()
        }
      })
      .then((data) => {
        if (data.success === true) {
          const wheels = initialValues.concat(data?.data)
          setWheels(wheels);
        } else {
          setWheels([]);
        }
      })
  }, [isOpen, IsTokenUpdated]);

  const fetchChannelData = useCallback(async () => {
    fetch(`${API_URL}/Site/GetSiteChannel/${selectedSite}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    })
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true)
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false)
          return response.json()
        }
      })
      .then((data) => {
        if (data.success === true) {
          data?.data.unshift({ id: 0, name: "Command: STOP" })
          setChannels(data?.data);
        } else {
          setChannels([]);
        }
      })
  }, [isOpen, IsTokenUpdated]);

  const fetchDropinsData = useCallback(async () => {
    fetch(`${API_URL}/DropIn/GetAll`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    })
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true)
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false)
          return response.json()
        }
      })
      .then((data) => {
        if (data.success === true) {
          const dropIns = initialValues.concat(data?.data)
          setDropins(dropIns);
        } else {
          setDropins([]);
        }
      })
  }, [isOpen, IsTokenUpdated]);

  const handleAddEditSchedule = useCallback(
    async (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      const requestOptions: any = {
        method: isEdit ? "PUT" : "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token?.accessToken}`,
        },
        body: JSON.stringify({
          zoneId: schedule?.zoneId,
          dropInId: schedule?.dropInId == "0" ? null : schedule?.dropInId,
          dayOfWeek: schedule?.dayOfWeek,
          channelId: schedule?.channelId,
          voiceWheelId: schedule?.voiceWheelId == "0" ? null : schedule?.voiceWheelId,
          id: isEdit ? scheduleEditList?.id : "",
          startingTime: schedule?.startingTime ? dayjs(schedule?.startingTime).format("HH:mm:ss") : dayjs("00:00:00", "HH:mm:ss").format("HH:mm:ss"),
        }),
      };
      if (isEdit) {
        fetch(`${API_URL}/Schedule/`, requestOptions).then((response) => {
          if (
            response.ok === false &&
            response.status === 401 &&
            token?.refreshToken !== ""
          ) {
            setIsTokenExpired(true);
          } else if (response.status === 401 && token?.refreshToken === "") {
            navigate("/");
            localStorage.removeItem("token");
            localStorage.clear();
          } else {
            setIsTokenUpdated(false);
            return response.json();
          }
        })
          .then((data) => {
            if (data?.success === true) {
              handleClose();
              toast.success(t("main.Schedule_Updated_Successfully"));
            }
          });
      } else {
        fetch(`${API_URL}/Schedule`, requestOptions).then((response) => {
          if (
            response.ok === false &&
            response.status === 401 &&
            token?.refreshToken !== ""
          ) {
            setIsTokenExpired(true);
          } else if (response.status === 401 && token?.refreshToken === "") {
            navigate("/");
            localStorage.removeItem("token");
            localStorage.clear();
          } else {
            setIsTokenUpdated(false);
            return response.json();
          }
        })
          .then((data) => {
            if (data?.success === true) {
              toast.success(t("main.Schedule_Created_Successfully"));
              setIsAdded(true);
              handleClose();
              setSchedule({
                startingTime: null,
                zoneId: null,
                dayOfWeek: null,
                channelId: null,
                voiceWheelId: null,
                dropInId: null,
              });
            } else {
              toast.warning(data.message)
            }
          });

      }
    },
    [handleClose, isEdit, schedule, scheduleEditList?.id, setIsAdded]
  );
  useEffect(() => {
    if (isOpen) {
      fetchZoneData();
      fetchWheelData();
      fetchChannelData();
      fetchDropinsData();
    }
  }, [
    fetchChannelData,
    fetchDropinsData,
    fetchWheelData,
    fetchZoneData,
    isOpen,
  ]);

  useEffect(() => {
    if (isAdded === true) {
      handleClose();
    }
  }, [handleClose, isAdded]);

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        handleClose();
        setSchedule({
          startingTime: null,
          zoneId: null,
          dayOfWeek: null,
          channelId: null,
          voiceWheelId: null,
          dropInId: null,
        });
      }}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      maxWidth="sm"
      fullWidth
      className="addModelDialog"
    >
      <DialogTitle>
        <Typography variant="h5" className="addModelDialog-title">
          {isEdit ? `${t("main.edit_schedule_item")}` : `${t("main.add_schedule_item")}`}
          <Link onClick={() => {
            handleClose()
            setSchedule({
              startingTime: null,
              zoneId: null,
              dayOfWeek: null,
              channelId: null,
              voiceWheelId: null,
              dropInId: null,
            });
          }} className="modelCloseBtn">
            <CloseIcon />
          </Link>
        </Typography>
      </DialogTitle>
      <div className="dialogForm">
        <form>
          <div className="dialogForm-field dialogForm-field-2">
            <Typography variant="h5" className="dialogForm-field-label">
              {t("main.start_time_(hhmm)")}
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack spacing={3}>
                {/* <TimePicker
                  inputFormat="HH:mm"  
                  className="TimePicker"
                  renderInput={(params) => <TextField {...params} />}
                  value={schedule?.startingTime ? schedule?.startingTime : null}
                  onChange={(newValue) => {
                    setSchedule({ ...schedule, startingTime: newValue });
                  }}

                /> */}
                <TextField
                  id="time"
                  type="time"
                  className="TimePicker"
                  defaultValue={dayjs(schedule?.startingTime).format("HH:mm")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}

                  sx={{
                    '& input[type="time"]::-webkit-calendar-picker-indicator': {
                      filter:
                        'invert(100%)',
                    }, width: 150
                  }}
                  onChange={(e) => setSchedule({ ...schedule, startingTime: dayjs(e.target.value + ":00", "HH:mm:ss") })}
                />
              </Stack>
            </LocalizationProvider>
          </div>
          <div className="dialogForm-field dialogForm-field-2">
            <Typography variant="h5" className="dialogForm-field-label">
              {t("main.zone")}
            </Typography>
            <Select
              className="CustomDrpSelect"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={
                zones?.some((item: any) => item.id === schedule?.zoneId)
                  ? schedule?.zoneId
                  : ""
              }
              onChange={(e) => {
                setSchedule({ ...schedule, zoneId: e.target.value });
              }}
              required
            >

              {zones &&
                zones?.map((item) => (
                  <MenuItem key={item?.id} value={item?.id}>
                    {item?.name}
                  </MenuItem>
                ))}
            </Select>
          </div>
          <div className="dialogForm-field dialogForm-field-2">
            <Typography variant="h5" className="dialogForm-field-label">
              {t("main.day")}
            </Typography>
            <Select
              className="CustomDrpSelect"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              defaultValue={schedule?.dayOfWeek}
              onChange={(e) => {
                setSchedule({ ...schedule, dayOfWeek: e.target.value });
              }}
              required
            >
              <MenuItem key={1} value="1">
                {t("main.monday")}
              </MenuItem>
              <MenuItem key={2} value="2">
                {t("main.tuesday")}
              </MenuItem>
              <MenuItem key={3} value="3">
                {t("main.wednesday")}
              </MenuItem>
              <MenuItem key={4} value="4">
                {t("main.thursday")}
              </MenuItem>
              <MenuItem key={5} value="5">
                {t("main.friday")}
              </MenuItem>
              <MenuItem key={6} value="6">
                {t("main.saturday")}
              </MenuItem>
              <MenuItem key={0} value="0">
                {t("main.sunday")}
              </MenuItem>
            </Select>
          </div>
          <div className="dialogForm-field dialogForm-field-2">
            <Typography variant="h5" className="dialogForm-field-label">
              {t("main.channel")}
            </Typography>
            <Select
              className="CustomDrpSelect"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={
                channels?.some((item: any) => item.id === schedule?.channelId)
                  ? schedule?.channelId
                  : "Command: STOP"
              }
              onChange={(e) => {
                setSchedule({ ...schedule, channelId: e.target.value });
              }}
              required
            >
              {channels &&
                channels?.map((item) => (
                  <MenuItem key={item?.id} value={item?.id}>
                    {item?.name}
                  </MenuItem>
                ))}
            </Select>
          </div>
          <div className="dialogForm-field dialogForm-field-2">
            <Typography variant="h5" className="dialogForm-field-label">
              {t("main.wheel")}
            </Typography>
            <Select
              className="CustomDrpSelect"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              required
              value={
                wheels?.some((item: any) => item?.id === schedule?.voiceWheelId)
                  ? schedule?.voiceWheelId
                  : "0"
              }
              onChange={(e) => {
                setSchedule({ ...schedule, voiceWheelId: e.target.value });
              }}
            >
              {wheels &&
                wheels?.map((item) => (
                  <MenuItem key={item?.id} value={item?.id}>
                    {item?.name}
                  </MenuItem>
                ))}
            </Select>
          </div>
          <div className="dialogForm-field dialogForm-field-2">
            <Typography variant="h5" className="dialogForm-field-label">
              {t("main.dropIn")}
            </Typography>
            <Select
              className="CustomDrpSelect"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={
                dropins?.some((item: any) => item?.id === schedule?.dropInId)
                  ? schedule?.dropInId
                  : "0"
              }
              onChange={(e) => {
                setSchedule({ ...schedule, dropInId: e.target.value });
              }}
              required
            >
              {dropins &&
                dropins?.map((item: any) => (
                  <MenuItem key={item?.id} value={item?.id}>
                    {item?.name}
                  </MenuItem>
                ))}
            </Select>
          </div>
          <DialogActions sx={{ display: "flex" }}>
            <Button
              type="submit"
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                setIsAdded(true);
                handleAddEditSchedule(e);
              }}
              size="medium"
              variant="contained"
              color="primary"
              sx={{ mt: 5 }}
            >
              {t("main.save")}
            </Button>
            <Button
              size="medium"
              variant="outlined"
              color="primary"
              sx={{ mt: 5 }}
              onClick={() => {
                handleClose();
                setSchedule({
                  startingTime: null,
                  zoneId: null,
                  dayOfWeek: null,
                  channelId: null,
                  voiceWheelId: null,
                  dropInId: null,
                });
              }}
            >
              {t("main.cancel")}
            </Button>
          </DialogActions>
        </form>
      </div >
    </Dialog >
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Grid,
  useTheme,
  Accordion,
  Typography,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import { songList } from "../../types/Audio";
import { Stack } from "@mui/system";
import { useLocation } from "react-router-dom";
import { DataInputRow } from "./ViewAudioMetaData";
import { TextControl } from "../Controls/TextControl";
import { formData, grouData } from "../../types/Settings";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { SetStateAction, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const API_URL = process.env.REACT_APP_BACKEND_URL;

export default function UserDefinedField({
  audioData,
  setAudioData,
  userDefinitionData,
  setUserDefinitionData,
}: {
  audioData: songList;
  setAudioData: React.Dispatch<SetStateAction<songList>>;
  userDefinitionData: formData[];
  setUserDefinitionData: React.Dispatch<SetStateAction<formData[]>>;
}) {
  const [expanded, setExpanded] = useState<{
    metadata: boolean;
    timeofday: boolean;
    seasonality: boolean;
    weeksday: boolean;
    files: boolean;
    audiowawe: boolean;
  }>({
    metadata: true,
    timeofday: true,
    seasonality: true,
    weeksday: true,
    files: true,
    audiowawe: true,
  });
  const { t } = useTranslation();
  let theme = useTheme();
  let location = useLocation();
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  const [fieldData, setFieldData] = useState<grouData[]>([]);

  const userDefinitionBySettingGroup = useCallback(async () => {
    const response = await fetch(`${API_URL}/Settings/GetByGroup`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    });
    const data = await response.json();
    let userDefinedByGroup = data?.data
      ?.filter((item: grouData) => item.groupName === "User Definition")
      ?.map((item: grouData) => item.settings);
    let newData = userDefinedByGroup[0]?.filter((item: grouData) => item.value);
    let newUserDefinitionDataWithGroupName = newData.map((item: grouData) => ({
      ...item,
      groupName: "User Definition",
    }));
    setFieldData(newUserDefinitionDataWithGroupName);
    setUserDefinitionData(newUserDefinitionDataWithGroupName);
  }, [setUserDefinitionData]);

  const DynmicUserDefination = (
    index: number,
    value: string,
    audioData: songList
  ) => {
    switch (index) {
      case 0:
        Object.assign(audioData, { userDefinition1: value });
        break;
      case 1:
        Object.assign(audioData, { userDefinition2: value });
        break;
      case 2:
        Object.assign(audioData, { userDefinition3: value });
        break;
      case 3:
        Object.assign(audioData, { userDefinition4: value });
        break;
      case 4:
        Object.assign(audioData, { userDefinition5: value });
        break;
      case 5:
        Object.assign(audioData, { userDefinition6: value });
        break;
      case 6:
        Object.assign(audioData, { userDefinition7: value });
        break;
      case 7:
        Object.assign(audioData, { userDefinition8: value });
        break;
      case 8:
        Object.assign(audioData, { userDefinition9: value });
        break;
      default:
        break;
    }
  };

  const renderUserDefination = (index: number) => {
    let userDefination = "";
    switch (index) {
      case 0:
        userDefination = audioData?.userDefinition1;
        break;
      case 1:
        userDefination = audioData?.userDefinition2;
        break;
      case 2:
        userDefination = audioData?.userDefinition3;
        break;
      case 3:
        userDefination = audioData?.userDefinition4;
        break;
      case 4:
        userDefination = audioData?.userDefinition5;
        break;
      case 5:
        userDefination = audioData?.userDefinition6;
        break;
      case 6:
        userDefination = audioData?.userDefinition7;
        break;
      case 7:
        userDefination = audioData?.userDefinition8;
        break;
      case 8:
        userDefination = audioData?.userDefinition9;
        break;
      default:
        break;
    }
    return userDefination;
  };

  //useEffect Area
  useEffect(() => {
    userDefinitionBySettingGroup();
  }, [userDefinitionBySettingGroup]);

  return (
    <Box className="tabsPage">
      <Grid
        container
        rowSpacing={2}
        columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 5, xl: 8 }}
      >
        {location?.pathname?.includes("settings") ? (
          <AccordionDetails style={{ margin: 2, marginTop: 2 }}>
            <Stack direction="column" spacing={1}>
              {userDefinitionData?.map((item: any) => (
                <div key={item?.id} style={{ display: "flex", justifyContent: 'center' }}>
                  <Typography sx={{ maxWidth: 145 }}>
                    {item?.name}
                  </Typography>
                  <TextControl
                    key={item?.id}
                    header={item?.name}
                    value={item?.value}
                    inputChanged={(value: string) => {
                      const filteredData = userDefinitionData?.map((i: any) => {
                        if (i.id === item.id) {
                          return { ...i, value };
                        } else {
                          return i;
                        }
                      });
                      setUserDefinitionData(filteredData);
                    }}
                  />
                </div>
              ))}
            </Stack>
          </AccordionDetails>
          // <>
          //   <Grid item xs={12} lg={6} sx={{ margin: "30px 0 0 25px", }}>
          //     <Grid
          //       container
          //       rowSpacing={.5}
          //       columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          //     >
          //       <AccordionDetails style={{ margin: 2, marginTop: 2 }}>
          //         <Stack direction="column" spacing={1}>
          //           {userDefinitionData?.map((item: any) => (
          //             <div key={item?.id} style={{ display: "flex", justifyContent: 'center' }}>
          //               <Typography sx={{ maxWidth: 145 }}>
          //                 {item?.name}
          //               </Typography>
          //               <TextControl
          //                 key={item?.id}
          //                 header={item?.name}
          //                 value={item?.value}
          //                 inputChanged={(value: string) => {
          //                   const filteredData = userDefinitionData?.map((i: any) => {
          //                     if (i.id === item.id) {
          //                       return { ...i, value };
          //                     } else {
          //                       return i;
          //                     }
          //                   });
          //                   setUserDefinitionData(filteredData);
          //                 }}
          //               />
          //             </div>
          //           ))}
          //         </Stack>
          //       </AccordionDetails>


          //     </Grid>
          //   </Grid>
          // </>
        ) : (
          <Grid item xs={12} lg={6}>
            <Accordion
              className="SongDataEditor"
              expanded={expanded?.metadata}
              onChange={(e, isExpanded) =>
                setExpanded({ ...expanded, metadata: isExpanded ?? false })
              }
              elevation={5}
            >
              <AccordionSummary
                className="AccordionTitle"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                style={{
                  backgroundColor:
                    theme.palette.mode === "dark" ? "#151515" : "#d9d9d9",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ flexShrink: 0, fontWeight: 600, letterSpacing: 1 }}
                >
                  {t("main.User_defined_Fields")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ margin: 5, marginTop: 20 }}>
                <Grid
                  container
                  rowSpacing={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  {fieldData?.map((item: grouData, index: number) => (
                    <DataInputRow
                      key={index}
                      header={item?.value}
                      value={renderUserDefination(index)}
                      inputChanged={(value: string) => {
                        DynmicUserDefination(index, value, audioData);
                        const filteredData = fieldData?.map((i: grouData) => {
                          if (i.id === item.id) {
                            return { ...i, name: value };
                          } else {
                            return i;
                          }
                        });
                        setFieldData(filteredData);
                      }}
                    />
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { Song } from "../../types/Customer";
import {
  Box,
  CircularProgress,
  IconButton,
  MenuItem,
  Select,
  useTheme,
} from "@mui/material";
import { useChannelIdContext } from "../../context/ChannelIdContext";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import CustomerSongHistoryTable from "../Tables/CustomerSongHistoryTable";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  LastPage,
  FirstPage,
  ChevronLeft,
  ChevronRight,
} from "@mui/icons-material";

const API_URL = process.env.REACT_APP_BACKEND_URL;
export default function CustomerViewSongs() {
  const { t } = useTranslation();
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  const [SiteSongs, setSiteSongs] = useState<{
    loaded: boolean;
    song: Song[];
  }>({ loaded: false, song: [] });
  const [SelectedRowSongId, setSelectedRowSongId] = useState<number>(0);
  
    const { SiteId, setIsTokenExpired, setIsTokenUpdated ,searchInput} =
      useChannelIdContext();
  const [rowData, setRowData] = useState<Song | null>(null);
  const [Isloading,setIsLoading]= useState<boolean>(false)
  const [filteredData, setFilteredData] = useState<any>([]);
  const hasInitialLoad = useRef<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number | any>(25);
  const theme = useTheme();
  const navigate = useNavigate();
  const formatDate = (value: any) => {
    const date = moment(value.renderedCellValue).format("YYYY");
    return `${date}`;
  };
  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "id",
        header: t("main.Song_ID"),
        size: 15,
      },
      {
        accessorKey: "title",
        header: t("main.title"),
      },
      {
        accessorKey: "artist1.name",
        header: t("main.artist_1"),
      },
      {
        accessorKey: "artist2.name",
        header: t("main.artist_2"),
      },
      {
        accessorKey: "artist3.name",
        header: t("main.artist_3"),
      },
      {
        accessorKey: "releaseYear",
        header: t("main.release_year"),
        size: 15,
      },
      {
        accessorKey: "commenceDate",
        header: t("main.commence_date"),
        Cell: (renderedCellValue: any) => (
          <span>{formatDate(renderedCellValue)}</span>
        ),
        size: 15,
      },
      {
        accessorKey: "expireDate",
        header: t("main.expiry_date"),
        Cell: (renderedCellValue: any) => (
          <span>{formatDate(renderedCellValue)}</span>
        ),
        size: 15,
      },
      {
        accessorKey: "priority",
        header: t("main.priority"),
        size: 10,
      },
    ],
    [t]
  );

  const FilterChannelSongData = useCallback(() => {
    const filteredList = SiteSongs?.song?.filter((item: any) => {
      return item?.title?.toLowerCase()?.includes(searchInput?.toLowerCase());
    });
    setFilteredData(filteredList);
  }, [searchInput, SiteSongs?.song]);

  const fetchSiteBySiteId = async () => {
    fetch(
      `${API_URL}/Site/GetSiteChannelSongs/${SiteId}?pageNumber=${currentPage}&pageSize=${pageSize}&searchText=${searchInput}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token?.accessToken}`,
        },
      }
    )
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        setIsLoading(true)
        if (data?.success) {
          setSiteSongs({ loaded: true, song: data?.data });
          setTotalCount(data?.pagination?.totalCount);
        } else {
          setSelectedRowSongId(0);
          setSiteSongs({ loaded: true, song: [] });
        }
      });
  }

  useEffect(() => {
    FilterChannelSongData();
  }, [FilterChannelSongData]);

  useEffect(() => {
    if (SiteId !== 0) {
      fetchSiteBySiteId();
    }
    if (SiteId === 0) {
      setSiteSongs({ loaded: true, song: [] });
      setSelectedRowSongId(0);
    }
  }, [SiteId, currentPage, pageSize, totalCount, searchInput]);
  const handleLastPage = () => {
    if (totalCount / pageSize > parseInt((totalCount / pageSize).toString())) {
      setCurrentPage(parseInt((totalCount / pageSize + 1).toString()));
    } else {
      setCurrentPage(parseInt((totalCount / pageSize).toString()));
    }
  };
  return Isloading === false ? (
    <div style={{ justifyContent: "center", display: "flex" }}>
      <CircularProgress style={{ marginTop: "15rem" }} />
    </div>
  ) : (
    <>
      <div className="selectRow ViewSongs">
        <MaterialReactTable
          columns={columns}
          data={searchInput ? filteredData : SiteSongs?.song}
          enablePagination={false}
          enableSorting={true}
          enableBottomToolbar={false}
          enableTopToolbar={false}
          enableColumnActions={true}
          enableColumnOrdering={true}
          enableRowActions={false}
          enableStickyHeader
          enableGlobalFilterModes
          initialState={{
            showGlobalFilter: true,
          }}
          muiTableBodyCellProps={{
            sx: {
              paddingTop: 0,              
              paddingBottom: 0,
            },
          }}  
          enablePinning
          muiTableBodyProps={{
            sx: {
              "& tr:nth-of-type(even)": {
                backgroundColor: "#F0F4F7",
              },
            },
          }}
          muiTableBodyRowProps={({ row }) => ({
            onClick: () => {
              setSelectedRowSongId(row.original.id);
              setRowData(row.original);
            },
            selected: SelectedRowSongId === row.original.id,
            sx: {
              cursor: "pointer",
            },
          })}
        />
        <div className="customPagination">
          <span
            style={{
              color: theme.palette.mode === "dark" ? "#FFFFFF" : "#3C434B",
            }}
          >
            {t("main.rows_per_page")}
          </span>
          <Select
            sx={{ height: "30px" }}
            value={pageSize}
            onChange={(e) => {
              setPageSize(e.target.value);
              hasInitialLoad.current = false;
            }}
          >
            <MenuItem value="25">25</MenuItem>
            <MenuItem value="50">50</MenuItem>
            <MenuItem value="100">100</MenuItem>
          </Select>
          <span
            style={{
              color: theme.palette.mode === "dark" ? "#FFFFFF" : "#3C434B",
            }}
          >
            {(currentPage - 1) * pageSize + 1} -{" "}
            {currentPage * pageSize < totalCount
              ? currentPage * pageSize
              : currentPage * pageSize -
                (currentPage * pageSize - totalCount)}{" "}
            of {totalCount}
          </span>
          <Box className="customPagination__buttons">
            <IconButton
              onClick={() => {
                setCurrentPage(1);
                hasInitialLoad.current = false;
              }}
              disabled={(currentPage - 1) * pageSize + 1 <= 1}
            >
              <FirstPage />
            </IconButton>
            <IconButton
              onClick={() => {
                setCurrentPage(currentPage - 1);
                hasInitialLoad.current = false;
              }}
              disabled={(currentPage - 1) * pageSize + 1 <= 1}
            >
              <ChevronLeft />
            </IconButton>
            <IconButton
              onClick={() => {
                setCurrentPage(currentPage + 1);
                hasInitialLoad.current = false;
              }}
              disabled={currentPage * pageSize >= totalCount}
            >
              <ChevronRight />
            </IconButton>
            <IconButton
              onClick={() => {
                handleLastPage();
                hasInitialLoad.current = false;
              }}
              disabled={currentPage * pageSize >= totalCount}
            >
              <LastPage />
            </IconButton>
          </Box>
        </div>
      </div>
      <CustomerSongHistoryTable
        SelectedRowSongId={SelectedRowSongId}
        rowData={rowData}
      />
    </>
  );
}

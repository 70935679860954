// @ts-nocheck

import React, { useEffect, useState } from "react";
import {
  SaveVoiceTrackItems,
  VoiceTrackCategory,
  WheelList,
} from "../../../types/Wheel";
import "./ContentWheelTable.scss";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
const API_URL = process.env.REACT_APP_BACKEND_URL;
function ContentWheelTable({
  isChange,
  setIsChange,
  isWheelAdded,
  selectedData,
  setIsItemDelete,
  wheelMangerList,
  setIsWheelAdded,
  setSelectedData,
  setIsItemDraggble,
  setWheelMangerList,
  saveVoiceTrackItem,
  selectVoiceWheelId,
  VoiceTrackCategory,
  setSaveVoiceTrackItem,
}: {
  rowSelection: any;
  selectedData: any;
  isWheelAdded: boolean;
  saveVoiceTrackItem: any;
  wheelMangerList: WheelList[];
  saveVoiceTrackItem: any;
  isChange: boolean;
  setIsChange: Boolean | any;
  setIsItemDraggble: Boolean | any;
  setIsItemDelete: Boolean | any;
  selectVoiceWheelId: number | any;
  VoiceTrackCategory: VoiceTrackCategory[];
  setRowSelection: React.Dispatch<React.SetStateAction<any>>;
  setSelectedData: React.Dispatch<React.SetStateAction<any>>;
  setIsWheelAdded: React.Dispatch<React.SetStateAction<boolean>>;
  setWheelMangerList: React.Dispatch<React.SetStateAction<WheelList[]>>;
  setRemoveCategoryItem: React.Dispatch<React.SetStateAction<WheelList[]>>;
  setSaveVoiceTrackItem: React.Dispatch<
    React.SetStateAction<SaveVoiceTrackItems[]>
  >;
}) {
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  const { t } = useTranslation();
  const [, setWheelItemList] = useState<any>([]);
  const [, setRemoveWheelItem] = useState<any>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [categoryDisable, setCategoryDisable] = useState<any>({
    isDisable: false,
    index: 0,
  });
  const [channelDisable] = useState<any>({
    isDisable: false,
    index: 0,
  });
  const [isCategoryClick, setIsCateogryClick] = useState<boolean>(false);

  useEffect(() => {
    const fetchWheelDesignerList = async () => {
      const response = await fetch(
        `${API_URL}/VoiceWheelItem/Get/${selectVoiceWheelId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token?.accessToken}`,
          },
        }
      );
      const data = await response.json();

      if (data?.data?.length > 0) {
        // eslint-disable-next-line array-callback-return
        data?.data?.map((list: WheelList, i: number) => {
          list["index"] = i;
        });
        setWheelMangerList(data.data);
        const wheelItems: SaveVoiceTrackItems[] = [];
        // eslint-disable-next-line array-callback-return
        data?.data?.map((item: SaveVoiceTrackItems, index: number) => {
          wheelItems.push({
            index: index,
            isMusic: item.isMusic,
            position: item.position,
            channelId: item.channelId,
            isVoiceTrack: item.isVoiceTrack,
            voiceWheelId: item.voiceWheelId,
            voiceTrackCategoryId: item.voiceTrackCategoryId,
          });
        });
        setIsChange(false);
        setIsWheelAdded(false);
        setSelectedData(wheelItems);
        setWheelItemList(wheelItems);
        setRemoveWheelItem(wheelItems);
        setSaveVoiceTrackItem(wheelItems);
      } else {
        setWheelMangerList([]);
        setSaveVoiceTrackItem([]);
      }
    };

    if (selectVoiceWheelId) fetchWheelDesignerList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectVoiceWheelId, isWheelAdded, isChange]);

  const handleItemCheckSelect = (index: number) => {
    wheelMangerList[index]["isRemove"] = true;
    setIsItemDelete(true);
    setWheelMangerList(wheelMangerList);
  };

  const SortableItem = SortableElement<WheelList>(({ value }: any) => {
    let index = value.index;

    return (
      <tr style={{ backgroundColor: "#34393f" }}>
        <td
          style={{
            padding: "5px 10px",
            borderBottom: "1px solid #585858",
            width: "700px",
          }}
        >
          <div className="draggableBlock">
            <DragIndicatorIcon className="drag-handler" />
            <input
              type="checkbox"
              style={{ accentColor: "#3C735C" }}
              onChange={() => handleItemCheckSelect(index)}
            ></input>
            <select
              style={{
                width: "100%",
                backgroundColor: "#34393f",
                color: "#e8e8e8",
                border: "0px",
                padding: "10px",
                fontSize: "16px",
                fontWeight: "normal",
              }}
              disabled={
                channelDisable?.isDisable && channelDisable?.index === index
              }
              value={
                selectedData[index]?.voiceTrackCategoryId
                  ? selectedData[index]?.voiceTrackCategoryId
                  : "music"
              }
              onChange={(e) => {
                setCategoryDisable({ isDisable: false, index: index });
                let itemPosition =
                  selectedData.length > 0
                    ? selectedData[selectedData.length - 1].position + 1
                    : 0;
                if (e.target.value === "music") {
                  if (selectedData.length > 0) {
                    selectedData[index].voiceTrackCategoryId = undefined;
                    selectedData[index].isVoiceTrack = true;
                    selectedData[index].isMusic = false;
                    setSelectedData(passData);
                  }
                } else {
                  setCategoryDisable({ isDisable: true, index: index });
                  let selectedId = JSON.parse(e.target.value);
                  const cloneSelectData = [...selectedData];
                  cloneSelectData[index].voiceTrackCategoryId = selectedId;
                  setSelectedData(cloneSelectData);
                  if (selectedData.length > 0) {
                    selectedData[index].voiceTrackCategoryId = selectedId;
                    selectedData[index].isVoiceTrack = true;
                    selectedData[index].isMusic = false;
                    setSelectedData(selectedData);
                  } else {
                    setSelectedData([
                      ...selectedData,
                      {
                        voiceWheelId: selectVoiceWheelId,
                        position: itemPosition,
                        voiceTrackCategoryId: selectedId,
                        isMusic: false,
                        isVoiceTrack: true,
                      },
                    ]);
                  }
                }
              }}
            >
              <>
                {!value.isVoiceTrack ? (
                  <option value={"music"}>Music</option>
                ) : (
                  <option>{value?.voiceTrackCategory?.name}</option>
                )}
              </>
              {VoiceTrackCategory?.length > 0 &&
                VoiceTrackCategory?.map((item) => (
                  <option
                    key={item?.id}
                    value={item?.id}
                    style={{ backgroundColor: "#e8e8e8", color: "#34393f" }}
                  >
                    {item?.name}
                  </option>
                ))}
            </select>
          </div>
        </td>
      </tr>
    );
  });

  const SortableList = SortableContainer<{ items: WheelList[] }>(
    ({ items }: any) => {
      return (
        <div>
          <table
            cellSpacing="0"
            cellPadding="0"
            style={{
              width: "100%",
              border: "0px",
              backgroundColor: "#34393f",
              fontFamily: "'Roboto', sans-serif",
              marginBottom: "0",
            }}
          >
            <thead
              style={{
                background: "#fff",
                color: "#000",
                padding: "5px 10px",
                fontWeight: "normal",
              }}
            >
              <tr
                style={{
                  padding: "5px 10px",
                  fontWeight: "normal",
                }}
              >
                <th className="sort-arrow">
                  {t("main.message_category")}
                  {isCategoryClick ? (
                    <ArrowUpward
                      onClick={() => {
                        items.sort(function (a, b) {
                          var nameA =
                            a.voiceTrackCategory === null
                              ? "Music"
                              : a.voiceTrackCategory?.name.toUpperCase();
                          var nameB =
                            b.voiceTrackCategory === null
                              ? "Music"
                              : b.voiceTrackCategory?.name.toUpperCase();
                          if (nameB < nameA) {
                            return -1;
                          }
                          if (nameB > nameA) {
                            return 1;
                          }
                          return 0;
                        });

                        if (isCategoryClick) setIsCateogryClick(false);
                        else setIsCateogryClick(true);
                      }}
                    />
                  ) : (
                    <ArrowDownward
                      onClick={() => {
                        setIsCateogryClick(true);
                        items.sort(function (a, b) {
                          var nameA =
                            a.voiceTrackCategory === null
                              ? "Music"
                              : a.voiceTrackCategory?.name.toUpperCase();
                          var nameB =
                            b.voiceTrackCategory === null
                              ? "Music"
                              : b.voiceTrackCategory?.name.toUpperCase();
                          if (nameA < nameB) {
                            return -1;
                          }
                          if (nameA > nameB) {
                            return 1;
                          }
                          return 0;
                        });
                        if (isCategoryClick) setIsCateogryClick(false);
                        else setIsCateogryClick(true);
                      }}
                    />
                  )}
                </th>
              </tr>
            </thead>
            <tbody
              style={{
                backgroundColor: "#34393f",
                fontFamily: "'Roboto', sans-serif",
                width: "100%",
              }}
            >
              {items.length > 0 ? (
                items?.map((value: WheelList, index: number) => (
                  <SortableItem key={index} index={index} value={value} />
                ))
              ) : (
                <></>
              )}
            </tbody>
          </table>
          {items.length === 0 ? (
            <div
              style={{
                color: "#fff",
                textAlign: "center",
                background: "rgb(52, 57, 63)",
              }}
            >
              {t("main.item_not_found")}
            </div>
          ) : (
            ""
          )}
        </div>
      );
    }
  );

  /**
   * change postion of wheel item
   */

  const onSortEnd = async ({ oldIndex, newIndex }: any) => {
    const postionData = wheelMangerList.every((ele) => (ele.id ? true : false));
    if (!postionData) {
      toast.warning(t("main.Postion_can't_be_change_until_data_is_saved"));
      return;
    }
    const saveCheck = selectedData.every(
      (item, index) =>
        item.voiceTrackCategoryId ===
        wheelMangerList[index].voiceTrackCategoryId
    );
    if (!saveCheck) {
      toast.warning(t("main.Postion_can't_be_change_until_data_is_saved"));
      return;
    }
    wheelMangerList[newIndex].position = oldIndex + 1;
    wheelMangerList[oldIndex].position = newIndex + 1;
    setIsItemDraggble(true);
  };

  return (
    <SortableList
      items={wheelMangerList}
      onSortEnd={onSortEnd}
      helperClass="draggable-item"
    />
  );
}
export default ContentWheelTable;

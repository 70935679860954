import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Link,
  Paper,
  PaperProps,
  Typography,
} from "@mui/material";
import { MouseEventHandler, SyntheticEvent } from "react";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useChannelIdContext } from "../../../context/ChannelIdContext";
import "./leaveWithoutSavingDialog.scss";
const API_URL = process.env.REACT_APP_BACKEND_URL;
function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper
        {...props}
        style={{
          backgroundColor: "#34393f",
          color: "#D9D9D9",
          padding: "1rem",
        }}
      />
    </Draggable>
  );
}

export default function LeaveWithoutSavingDialog({
  isOpen,
  handleClose,
  setConfirmation
}: {
  isOpen: boolean;
  handleClose: any | MouseEventHandler;
  setConfirmation: any;
}) {
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  const {
    selectedZoneId,
    deleteZoneId,
    setIsAdded,
    setSongListByChannel,
    setIsTokenExpired,
    setIsTokenUpdated,
  } = useChannelIdContext();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSubmit = async (event: SyntheticEvent) => {    
    event.preventDefault()
    setConfirmation(true)
    handleClose()
    // const requestOptions = {
    //   method: "DELETE",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `bearer ${token?.accessToken}`,
    //   },
    // };
    // fetch(`${API_URL}/Zone/${selectedZoneId}`, requestOptions)
    //   .then((response) => {
    //     if (
    //       response.ok === false &&
    //       response.status === 401 &&
    //       token?.refreshToken !== ""
    //     ) {
    //       setIsTokenExpired(true);
    //     } else if (response.status === 401 && token?.refreshToken === "") {
    //       navigate("/");
    //       localStorage.removeItem("token");
    //       localStorage.clear();
    //     } else {
    //       setIsTokenUpdated(false);
    //       return response.json();
    //     }
    //   })
    //   .then((data) => {
    //     if (data?.success === true) {
    //       toast.success(t("main.Zone_Deleted_Successfully"));       
    //       handleClose()      
    //     } else {
    //       toast.error(data?.message);
    //     }
    //   });
  };

  const handleCancel = async (event: SyntheticEvent) => {    
    event.preventDefault()
    setConfirmation(false)
    handleClose()
    // const requestOptions = {
    //   method: "DELETE",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `bearer ${token?.accessToken}`,
    //   },
    // };
    // fetch(`${API_URL}/Zone/${selectedZoneId}`, requestOptions)
    //   .then((response) => {
    //     if (
    //       response.ok === false &&
    //       response.status === 401 &&
    //       token?.refreshToken !== ""
    //     ) {
    //       setIsTokenExpired(true);
    //     } else if (response.status === 401 && token?.refreshToken === "") {
    //       navigate("/");
    //       localStorage.removeItem("token");
    //       localStorage.clear();
    //     } else {
    //       setIsTokenUpdated(false);
    //       return response.json();
    //     }
    //   })
    //   .then((data) => {
    //     if (data?.success === true) {
    //       toast.success(t("main.Zone_Deleted_Successfully"));       
    //       handleClose()      
    //     } else {
    //       toast.error(data?.message);
    //     }
    //   });
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={isOpen}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      className="deleteChannelListDialog"
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle>
        <Typography variant="h5" className="addModel-title">
          Are you sure to go back without saving?
          <Link onClick={handleClose} className="modelCloseBtn">
            <CloseIcon />
          </Link>
        </Typography>
      </DialogTitle>
      <div className="dialogForm">
        <form>
          <DialogActions sx={{ display: "flex" }}>
            <Button
              type="submit"
              size="medium"
              variant="contained"
              color="primary"
              sx={{ mt: 5 }}
              onClick={(e: SyntheticEvent) => handleSubmit(e)}
            >
              {t("main.Confirm")}
            </Button>
            <Button
              size="medium"
              variant="outlined"
              color="primary"
              sx={{ mt: 5 }}
              onClick={(e: SyntheticEvent) => handleCancel(e)}
            >
              {t("main.cancel")}
            </Button>
          </DialogActions>
        </form>
      </div>
    </Dialog>
  );
}

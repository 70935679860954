import {
  Box,
  Stack,
  Slider,
  styled,
  useTheme,
  IconButton,
  Typography,
} from "@mui/material";
import {
  PauseRounded,
  VolumeUpRounded,
  PlayArrowRounded,
  FastRewindRounded,
  VolumeDownRounded,
  FastForwardRounded,
} from "@mui/icons-material";
import { useParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";

const Widget = styled("div")(({ theme }) => ({
  padding: 16,
  borderRadius: 16,
  width: "40rem",
  maxWidth: "100%",
  margin: "auto",
  position: "relative",
  zIndex: 1,
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(0,0,0,0.6)" : "rgba(255,255,255,0.4)",
  backdropFilter: "blur(40px)",
}));

const CoverImage = styled("div")({
  width: 100,
  height: 100,
  objectFit: "cover",
  overflow: "hidden",
  flexShrink: 0,
  borderRadius: 8,
  backgroundColor: "rgba(0,0,0,0.08)",
  "& > img": {
    width: "100%",
  },
});

const TinyText = styled(Typography)(({ theme }) => ({
  fontSize: "0.85rem",
  opacity: 0.7,
  fontWeight: 600,
  letterSpacing: 0.2,
  color: theme.palette.mode === "dark" ? "#90caf9" : "#0c3b68",
}));

export default function MusicPlayer() {
  const theme = useTheme();
  const { id } = useParams<string>();
  const [position, setPosition] = useState(0);
  const [volume] = useState(90);
  const [paused, setPaused] = useState(true);
  const audioElement = useRef<HTMLAudioElement>(null);
  const [duration, setDuration] = useState<number>(100);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (paused) {
      audioElement?.current?.pause();
    } else {
      audioElement?.current?.play();
    }
    let dr = audioElement?.current?.duration ?? duration;
    setDuration(Number.isNaN(dr) ? duration : dr);
  });

  function formatDuration(value: number) {
    if (Number.isNaN(value)) return "";
    const val = Math.floor(value);
    const minute = Math.floor(val / 60);
    const secondLeft = val - minute * 60;
    return `${minute}:${secondLeft < 10 ? `0${secondLeft}` : secondLeft}`;
  }
  const mainIconColor = theme.palette.mode === "dark" ? "#fff" : "#000";
  const lightIconColor =
    theme.palette.mode === "dark" ? "rgba(255,255,255,0.4)" : "rgba(0,0,0,0.4)";

  return (
    <Box sx={{ width: "100%", overflow: "hidden" }}>
      <Widget>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <CoverImage>
            <img
              alt=""
              src="/albumart/889a7b73e1e04f7c89fef0f170baa722.jpg"
              style={{ objectFit: "cover", aspectRatio: "1/1" }}
            />
          </CoverImage>
          <Box sx={{ ml: 1.5, minWidth: 0 }}>
            <Stack direction="row" justifyContent="space-between">
              <Typography
                variant="caption"
                color="text.secondary"
                fontWeight={500}
              >
                Pink Floyd
              </Typography>
              <Typography color="error.main" sx={{ pl: 5 }}>
                {id}
              </Typography>
            </Stack>
            <Typography
              noWrap
              fontWeight={500}
              color="text.primary"
              fontSize={"1.3rem"}
            >
              <b>Money - 1981 Re-recording</b>
            </Typography>
            <Typography noWrap color="text.secondary" letterSpacing={-0.25}>
              The Dark Side of the Moon
            </Typography>
          </Box>
        </Box>
        <Slider
          aria-label="time-indicator"
          size="small"
          value={position}
          min={0}
          step={1}
          max={duration}
          onChange={(_, value) => {
            if (audioElement && audioElement?.current) {
              audioElement.current.currentTime = value as number;
              setPosition(value as number);
            }
          }}
          sx={{
            color: theme.palette.mode === "dark" ? "#fff" : "rgba(0,0,0,0.87)",
            height: 4,
            "& .MuiSlider-thumb": {
              width: 8,
              height: 8,
              transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
              "&:before": {
                boxShadow: "0 2px 12px 0 rgba(0,0,0,0.4)",
              },
              "&:hover, &.Mui-focusVisible": {
                boxShadow: `0px 0px 0px 8px ${
                  theme.palette.mode === "dark"
                    ? "rgb(255 255 255 / 16%)"
                    : "rgb(0 0 0 / 16%)"
                }`,
              },
              "&.Mui-active": {
                width: 20,
                height: 20,
              },
            },
            "& .MuiSlider-rail": {
              opacity: 0.28,
            },
          }}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mt: -1,
          }}
        >
          <TinyText theme={theme}>{formatDuration(position)}</TinyText>
          <TinyText theme={theme}>
            -{formatDuration(duration - position)}
          </TinyText>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: -1,
          }}
        >
          <IconButton aria-label="previous song">
            <FastRewindRounded fontSize="large" htmlColor={mainIconColor} />
          </IconButton>
          <IconButton
            aria-label={paused ? "play" : "pause"}
            onClick={() => setPaused(!paused)}
          >
            {paused ? (
              <PlayArrowRounded
                sx={{ fontSize: "3rem" }}
                htmlColor={mainIconColor}
              />
            ) : (
              <PauseRounded
                sx={{ fontSize: "3rem" }}
                htmlColor={mainIconColor}
              />
            )}
          </IconButton>
          <IconButton aria-label="next song">
            <FastForwardRounded fontSize="large" htmlColor={mainIconColor} />
          </IconButton>
        </Box>
        <Stack
          spacing={2}
          direction="row"
          sx={{ mb: 1, px: 1, ml: "6rem", mr: "6rem" }}
          alignItems="center"
        >
          <VolumeDownRounded htmlColor={lightIconColor} />
          <Slider
            aria-label="Volume"
            defaultValue={volume}
            onChange={(_, value) => {
              if (audioElement && audioElement.current) {
                audioElement.current.volume = (value as number) / 100;
              }
            }}
            sx={{
              color:
                theme.palette.mode === "dark" ? "#fff" : "rgba(0,0,0,0.87)",
              "& .MuiSlider-track": {
                border: "none",
              },
              "& .MuiSlider-thumb": {
                width: 24,
                height: 24,
                backgroundColor: "#fff",
                "&:before": {
                  boxShadow: "0 4px 8px rgba(0,0,0,0.4)",
                },
                "&:hover, &.Mui-focusVisible, &.Mui-active": {
                  boxShadow: "none",
                },
              },
            }}
          />
          <VolumeUpRounded htmlColor={lightIconColor} />
        </Stack>
        <audio
          src={"/songs/Pink Floyd - Money.mp3"}
          ref={audioElement}
          onCanPlay={(event) => setDuration(event.currentTarget.currentTime)}
          onTimeUpdate={(event) => setPosition(event.currentTarget.currentTime)}
        ></audio>
      </Widget>
    </Box>
  );
}

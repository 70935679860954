import React from "react";
import MiniSidePanel from "../layouts/MiniSidePanel/MiniSidePanel";
import NavigationPanel from "../layouts/NavigationPanel/NavigationPanel";
import ScheduleTable from "../component/Tables/ScheduleTable/ScheduleTable";
import { useTranslation } from "react-i18next";

export default function MusicLibraryPage() {
  const {t} = useTranslation();

  return (
      <MiniSidePanel headerText={t("main.Zone_Player/Channel_Scheduler")}>
        <MusicLibraryPageContent />
      </MiniSidePanel>
  )
}

function MusicLibraryPageContent() {
  return (
    <>
      <NavigationPanel
        onButtonClick={() => {}}
        addScheduleItem
        deleteScheduleItem
        duplicateDay
        duplicateZone
        manageDropIns
        ScheduleSearchBar
      ></NavigationPanel>
      <ScheduleTable></ScheduleTable>
    </>
  );
}

import dayjs from "dayjs";
import * as React from "react";
import { songList } from "../../types/Audio";
import { Grid, TextField } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useTranslation } from "react-i18next";

export default function ViewTimeofDay({
  audio,
  setAudioData,
}: {
  audio: songList;
  setAudioData: React.Dispatch<React.SetStateAction<songList>>;
}) {

  const {t} = useTranslation();
  
  const handleTimeFromChange = (newValue: any) => {
    const formattedValue = dayjs(newValue).format("HH:mm");
    setAudioData({
      ...audio,
      timeFrom: formattedValue,
    });
  };
  const handleTimeUntilChange = (newValue: any) => {
    const formattedValue = dayjs(newValue).format("HH:mm");
    setAudioData({
      ...audio,
      timeUntil: formattedValue,
    });
  };
  return (
    <Grid
      className="TimeOfDay"
      container
      rowSpacing={2}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimePicker
        PaperProps={{
          color: "#fff",
        }}
          label={t("main.From")}
          value={dayjs(audio?.timeFrom, "HH:mm")}
          onChange={handleTimeFromChange}
          renderInput={(params) => <TextField {...params} />}
          ampm={false}
          views={["hours", "minutes"]}
          inputFormat="HH:mm"
        />
        <TimePicker
          label={t("main.To")}
          value={dayjs(audio?.timeUntil, "HH:mm")}
          onChange={handleTimeUntilChange}
          renderInput={(params) => <TextField {...params} />}
          ampm={false}
          views={["hours", "minutes"]}
          inputFormat="HH:mm"
        />
        <span> (HHMM)</span>
      </LocalizationProvider>
    </Grid>
  );
}

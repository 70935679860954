import { toast } from "react-toastify";
import { Button, Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { VoiceTrackCloud } from "../../types/VoiceTrack";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import MaterialReactTable, { type MRT_ColumnDef } from "material-react-table";
import { useTranslation } from "react-i18next";
import { useChannelIdContext } from "../../context/ChannelIdContext";

const API_URL = process.env.REACT_APP_BACKEND_URL;

const VoiceTrackCloudList = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  let navigate = useNavigate();
  const { setIsTokenExpired, setIsTokenUpdated, IsTokenUpdated } =
    useChannelIdContext();
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  const [voiceTrackCloudData, setVoiceTrackCloudData] = useState<
    VoiceTrackCloud[]
  >([]);

  const fetchVoiceTrackCloudUpload = async () => {
    fetch(`${API_URL}/VoiceTrackCloud/Upload/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    })
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data.success === true) {
          fetchVoiceTrackCloudData();
          toast.success("Data Uploaded to Cloud Successfully");
        } else {
          toast.error(data?.data?.False);
        }
      });
  };

  const fetchVoiceTrackCloudDownload = async () => {
    fetch(`${API_URL}/VoiceTrackCloud/Download/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    })
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data.success === true) {
          fetchVoiceTrackCloudData();
          toast.success("Data Downloaded from Cloud Successfully");
        } else {
          toast.error(data?.data?.False);
        }
      });
  };

  const fetchVoiceTrackCloudData = useCallback(async () => {
    fetch(`${API_URL}/VoiceTrackCloud/GetAll/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token?.accessToken}`,
      },
    })
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data.success === true) {
          setVoiceTrackCloudData(data?.data);
        } else {
          setVoiceTrackCloudData([]);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, IsTokenUpdated]);

  const fetchVoiceTrackCloudDelete = async () => {
    fetch(`${API_URL}/VoiceTrackCloud/Delete/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    })
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data.success === true) {
          fetchVoiceTrackCloudData();
          toast.success(t("main.Data_Deleted_from_Cloud_Successfully"));
        } else {
          toast.error(data?.data?.False);
        }
      });
  };

  const columns = useMemo<MRT_ColumnDef<VoiceTrackCloud>[]>(
    () => [
      {
        accessorKey: "lastModified",
        header: `${t("main.Last_Modified")}`,
      },
      {
        accessorKey: "action",
        header: `${t("main.Action")}`,
      },
    ],
    [t]
  );
  useEffect(() => {
    fetchVoiceTrackCloudData();
  }, [fetchVoiceTrackCloudData, IsTokenUpdated]);

  return (
    <>
      <Box padding="12px 20px">
        <Button onClick={fetchVoiceTrackCloudUpload}>{t("main.Upload")}</Button>
        <Button onClick={fetchVoiceTrackCloudDownload}>
          {t("main.Download")}
        </Button>
        <Button onClick={fetchVoiceTrackCloudDelete}>{t("main.Delete")}</Button>
      </Box>
      <div className="ChannelAssTable">
        <MaterialReactTable
          columns={columns}
          data={voiceTrackCloudData}
          renderTopToolbar={false}
          enablePagination={false}
          enableColumnActions={true}
          enableColumnOrdering={true}
        />
      </div>
    </>
  );
};

export default VoiceTrackCloudList;

import React, {useState} from "react";
import "./App.css";
import RouterConfig from "./configs/RouterConfig";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { ThemeContext } from "./context/ThemeContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ChannelIdContextProvider } from "./context/ChannelIdContext";
import { deDE } from "@mui/material/locale";

const deftheme = createTheme(
  {
    palette: {
      mode: "light",
      primary: {
        main: "#3C735C",
        light: "#d9d9d9",
        dark: "#d9d9d9",
      },
    },
  },
  deDE
);

function App() {
  const [theme, setTheme] = useState(deftheme);

  return (
    <div>
      <header>
        <ThemeContext.Provider
          value={{
            theme: theme,
            themeChanged: (newValue: string) =>
              setTheme(
                createTheme({
                  palette: {
                    mode: newValue === "light" ? "light" : "dark",
                    primary: {
                      main: "#3C735C",
                      light: "#d9d9d9",
                      dark: "#d9d9d9",
                    },
                  },
                })
              ),
          }}
        >
          <ThemeProvider theme={theme}>
            <ChannelIdContextProvider>
              <RouterConfig></RouterConfig>
            </ChannelIdContextProvider>
          </ThemeProvider>
        </ThemeContext.Provider>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          className="toast-message "
        />
      </header>
    </div>
  );
}

export default App;

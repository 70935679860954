/* eslint-disable react-hooks/exhaustive-deps */
import {
  Link,
  Paper,
  Button,
  Dialog,
  IconButton,
  Typography,
  PaperProps,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import Draggable from "react-draggable";
import Stack from "@mui/material/Stack";
import "./addChannelTimeScheduleDialog.scss";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import React, { FC, MouseEventHandler, useEffect, useState } from "react";
import { ReactComponent as Add } from "../../../assets/images/add-squre.svg";
import { ReactComponent as Minus } from "../../../assets/images/minus-squreDark.svg";
import { useTranslation } from "react-i18next";
import { useChannelIdContext } from "../../../context/ChannelIdContext";
import { useNavigate } from "react-router-dom";
const API_URL = process.env.REACT_APP_BACKEND_URL;
function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper
        {...props}
        style={{
          backgroundColor: "#34393f",
          color: "#D9D9D9",
          padding: "1rem",
        }}
      />
    </Draggable>
  );
}
interface Dropins {
  id: number;
  name: string;
  zone: string;
  endDate: Date;
  startDate: Date;
  channel: string;
}

const Table: FC = () => {
  const { t } = useTranslation();
  const { setIsTokenExpired, setIsTokenUpdated, IsTokenUpdated } =
    useChannelIdContext();
  let navigate = useNavigate();
  const [dropinsList, setDropinsList] = useState<Dropins[]>([]);
  const fetchDropinsList = async () => {
    let token = JSON.parse(localStorage.getItem("token") ?? "{}");
    fetch(`${API_URL}/DropIn/GetAll`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    })
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data.success === true) {
          setDropinsList(data?.data);
        } else {
          setDropinsList([]);
        }
      });
  };

  const columns: MRT_ColumnDef<Dropins>[] = [
    {
      accessorKey: "name",
      header: `${t("main.totle")}`,
    },
    {
      accessorKey: "zone",
      header: `${t("main.zone")}`,
    },
    {
      accessorKey: "channel",
      header: `${t("main.channel")}`,
    },
    {
      accessorKey: "startDate",
      header: `${t("main.start:mmddd")}`,
    },
    {
      accessorKey: "endDate",
      header: `${t("main.end:mmdd")}`,
    },
  ];
  useEffect(() => {
    fetchDropinsList();
  }, [IsTokenUpdated]);

  return (
    <MaterialReactTable
      columns={columns}
      data={dropinsList}
      enableSorting={false}
      enableTopToolbar={false}
      enablePagination={false}
      enableColumnActions={false}
      enableBottomToolbar={false}
      enableColumnFilters={false}
      muiTableBodyRowProps={{ hover: false }}
    />
  );
};

export default function AddChannelTimeScheduleDropinsDialog({
  isOpen,
  handleClose,
}: {
  isOpen: boolean;
  handleClose: any | MouseEventHandler;
}) {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Dialog
      fullWidth
      open={isOpen}
      maxWidth="lg"
      onClose={handleClose}
      PaperComponent={PaperComponent}
      className="addModelDialog"
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
        <Typography variant="h5" sx={{ fontWeight: 700 }}>
          {t("main.DropIns")}
          <Link className="modelCloseBtn" onClick={handleClose}>
            <CloseIcon />
          </Link>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Stack
          direction="row"
          justifyContent="flex-start"
          className="modal-top-dropin-actions"
        >
          <IconButton
            aria-label="Add DropIn"
            sx={{
              display: "flex",
              flexDirection: "column",
              p: 1,
              pt: 4,
              aspectRatio: "1/1",
              width: "8rem",
              color: theme.palette.mode === "dark" ? "#263238" : "#cfd8dc",
            }}
          >
            <Add />
            <Typography variant="subtitle1" gutterBottom sx={{ pt: 1 }}>
              {t("main.add_item")}
            </Typography>
          </IconButton>
          <IconButton
            aria-label="Delete Dropin"
            sx={{
              display: "flex",
              flexDirection: "column",
              p: 1,
              pt: 4,
              aspectRatio: "1/1",
              width: "8rem",
              color: theme.palette.mode === "dark" ? "#263238" : "#cfd8dc",
            }}
          >
            <Minus />
            <Typography variant="subtitle1" gutterBottom sx={{ pt: 1 }}>
              {t("main.add_item")}
            </Typography>
          </IconButton>
        </Stack>
        <Paper elevation={3} sx={{ p: "1px", mt: 3 }} className="dialogTable">
          <Table></Table>
        </Paper>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-around" }}>
        <Button
          size="medium"
          variant="contained"
          color="primary"
          sx={{ mt: 5 }}
        >
          {t("main.save")}
        </Button>
        <Button
          onClick={handleClose}
          size="medium"
          variant="outlined"
          color="secondary"
          sx={{ mt: 5 }}
        >
          {t("main.cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import { Add, Close, Edit, Remove } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Paper,
  PaperProps,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useChannelIdContext } from "../../../context/ChannelIdContext";
import "./usersDialogs.scss";
const API_URL = process.env.REACT_APP_BACKEND_URL;
function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper
        {...props}
        style={{
          backgroundColor: "white",
          color: "#D9D9D9",
          padding: "1rem",
        }}
      />
    </Draggable>
  );
}

const UserTable = ({
  isAdded,
  setIsAdded,
}: {
  isAdded: any;
  setIsAdded: any;
}) => {
  interface User {
    userName: string;
    firstName: string;
    familyName: string;
    groupName: string;
  }

  const { t } = useTranslation();
  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "userName",
        header: t("main.name"),
      },
      {
        accessorKey: "firstName",
        header: t("main.first_name"),
      },
      {
        accessorKey: "familyName",
        header: t("main.last_name"),
      },
      {
        accessorKey: "group.name",
        header: t("main.group"),
      },
    ],
    [t]
  );

  const navigate = useNavigate();
  const theme = useTheme();
  const [users, setUsers] = useState([]);
  const [isOpen, setisOpen] = useState(false);
  const [userId, setUserId] = useState<any[]>([]);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [groupList, setGroupList] = useState<any[]>([]);
  const [selectGroup, setSelectGroup] = useState<string>("");
  const { setIsTokenExpired, setIsTokenUpdated, IsTokenUpdated } =
    useChannelIdContext();
  const [rowSelection, setRowSelection] = useState<any>({});
  const [userData, setUserData] = useState<any>({
    groupId: null,
    comment: null,
    userName: null,
    firstName: null,
    familyName: null,
    password: null,
  });

  let token = JSON.parse(localStorage.getItem("token") ?? "{}");

  const fetchData = useCallback(async () => {
    fetch(`${API_URL}/User/GetAll`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    })
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data.success === true) {
          setUsers(data?.data);
          console.log(users);
        } else {
          setUsers([]);
        }
      });
  }, [IsTokenUpdated]);

  const postNewUserData = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
      body: JSON.stringify({
        groupId: userData?.groupId,
        comment: userData?.comment ? userData?.comment : "",
        userName: userData?.userName,
        password: userData?.password,
        firstName: userData?.firstName,
        familyName: userData?.familyName,
      }),
    };
    fetch(`${API_URL}/User`, requestOptions)
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data?.success === true) {
          setisOpen(false);
          fetchData();
          toast.success(t("main.New_User_Added_Successfully"));
          setUserData({
            userName: null,
            firstName: null,
            familyName: null,
            groupId: null,
            password: null,
            comment: null,
          });
        } else {
          toast.error(data?.message);
        }
        setisOpen(false);
      });
  };

  const handleEditUser = async (e: any) => {
    if (Object.keys(rowSelection)?.length > 1) {
      setIsEdit(false);
      toast.error(t("main.Please_Select_Only_One_User_to_Edit"));
    } else {
      setIsEdit(true);
      e.preventDefault();
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token?.accessToken}`,
        },
        body: JSON.stringify({
          id: userData?.id,
          groupId: userData?.groupId,
          comment: userData?.comment,
          userName: userData?.userName,
          password: userData?.password,
          firstName: userData?.firstName,
          familyName: userData?.familyName,
        }),
      };
      fetch(`${API_URL}/User`, requestOptions)
        .then((response) => {
          if (
            response.ok === false &&
            response.status === 401 &&
            token?.refreshToken !== ""
          ) {
            setIsTokenExpired(true);
          } else if (response.status === 401 && token?.refreshToken === "") {
            navigate("/");
            localStorage.removeItem("token");
            localStorage.clear();
          } else {
            setIsTokenUpdated(false);
            return response.json();
          }
        })
        .then((data) => {
          if (data?.success) {
            toast.success(t("main.User_Edited_Successfully"));
            fetchData();
          } else {
            toast.error(t("main.SOMETHING_WENT_WRONG"));
          }
          setisOpen(false);
          setRowSelection({});
          setUserData({
            groupId: null,
            password: "",
            comment: null,
            userName: null,
            firstName: null,
            familyName: null,
          });
        });
    }
  };

  const fetcUserGroupList = useCallback(async () => {
    fetch(`${API_URL}/Group/GetAll`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    })
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data.success === true) {
          setGroupList(data?.data);
          setIsAdded(false);
        } else {
          setGroupList([]);
          setIsAdded(false);
        }
      });
  }, [IsTokenUpdated]);

  const handleGroupChange = (event: SelectChangeEvent<typeof selectGroup>) => {
    const {
      target: { value },
    } = event;
    setSelectGroup(value);
  };

  const HandleDeleteUser = async () => {
    if (Object.keys(rowSelection)?.length === 0) {
      toast.error(t("main.Please_Select_Any_User_to_Delete"));
    } else {
      const requestOptions = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token?.accessToken}`,
        },
        body: JSON.stringify(userId),
      };
      fetch(`${API_URL}/User`, requestOptions)
        .then((response) => {
          if (
            response.ok === false &&
            response.status === 401 &&
            token?.refreshToken !== ""
          ) {
            setIsTokenExpired(true);
          } else if (response.status === 401 && token?.refreshToken === "") {
            navigate("/");
            localStorage.removeItem("token");
            localStorage.clear();
          } else {
            setIsTokenUpdated(false);
            return response.json();
          }
        })
        .then((data) => {
          if (data?.success === true) {
            fetchData();
            toast.success(t("main.User_Deleted_Successfully"));
          } else {
            toast.error(data?.message);
          }
          setUserId([]);
        });
    }
  };

  useEffect(() => {
    fetchData();
    fetcUserGroupList();
  }, [fetcUserGroupList, fetchData, isAdded]);

  useEffect(() => {
    if (rowSelection) {
      const arrOfIndex = Object.keys(rowSelection);
      const arrOfIds = users
        ?.filter((_, i: number) => arrOfIndex.includes(i.toString()))
        ?.map((e: any) => e.id);
      setUserId(arrOfIds);
    } else {
      setUserId([]);
    }
  }, [rowSelection]);

  useEffect(() => {
    setRowSelection({});
  }, [users?.length]);

  useEffect(() => {
    const user: any = users?.find(
      (user, index) => index === parseInt(Object.keys(rowSelection)[0])
    );
    if (user) {
      setUserData({ ...user, password: "123456" });
    } else {
      setUserData({
        groupId: null,
        comment: null,
        userName: null,
        firstName: null,
        familyName: null,
        password: null,
      });
      setIsEdit(false);
    }
  }, [rowSelection, users]);

  return (
    <>
      <div
        className={
          theme.palette.mode === "dark"
            ? "PermissionTable-dark"
            : "PermissionTable"
        }
      >
        <MaterialReactTable
          columns={columns}
          data={users}
          enableRowSelection //enable some features
          enableColumnOrdering
          enableSelectAll={false}
          onRowSelectionChange={setRowSelection}
          state={{ rowSelection }}
          muiTableBodyCellProps={{
            sx: {
              paddingTop: 0,
              paddingBottom: 0,
            },
          }}
          enableGlobalFilter={false} //turn off a feature
          enableFullScreenToggle={false}
        ></MaterialReactTable>
        <div className="BtnPart">
          <Button
            className="user-add-btn"
            onClick={() => {
              if (Object.keys(rowSelection)?.length > 1) {
                toast.error(t("main.Please_Select_Only_One"));
                setRowSelection({});
              } else if (Object.keys(rowSelection)?.length === 1) {
                setIsEdit(true);
                setisOpen(true);
              } else {
                setIsEdit(false);
                setisOpen(true);
              }
            }}
          >
            <Tooltip
              title={
                Object.keys(rowSelection).length === 1
                  ? `${t("main.edit_user")}`
                  : `${t("main.Add_User")}`
              }
            >
              {Object.keys(rowSelection).length > 0 ? <Edit /> : <Add />}
            </Tooltip>
          </Button>
          <Button className="user-add-btn" onClick={HandleDeleteUser}>
            <Tooltip title={t("main.Delete_user")}>
              <Remove />
            </Tooltip>
          </Button>
        </div>
      </div>
      <Dialog
        open={isOpen}
        onClose={() => {
          setisOpen(false);
          fetcUserGroupList();
          setRowSelection({});
          setUserData({
            password: "",
            comment: null,
            groupId: null,
            userName: null,
            firstName: null,
            familyName: null,
          });
        }}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        maxWidth="sm"
        fullWidth
        className="addNwWheelDialog"
      >
        <DialogTitle
          style={{ cursor: "move", color: "#484C52" }}
          id="draggable-dialog-title"
        >
          <Typography style={{ fontSize: "25px" }}>
            {isEdit ? `${t("main.edit_user")}` : t("main.add_new_user")}
            <Link
              className="modelCloseBtn"
              onClick={() => {
                setisOpen(false);
                setRowSelection({});
                setUserData({
                  userName: null,
                  firstName: null,
                  familyName: null,
                  groupId: null,
                  password: "",
                  comment: null,
                });
              }}
              to={""}
            >
              <Close style={{ color: "#484C52" }} />
            </Link>
          </Typography>
        </DialogTitle>
        <form>
          <FormControl className="modal-new-user">
            <DialogContent>
              <Box
                component="form"
                sx={{
                  "& > :not(style)": { m: 1, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
                className=""
              >
                <TextField
                  sx={{ width: 350 }}
                  label={t("main.username")}
                  variant="outlined"
                  id="userName"
                  value={userData?.userName ? userData?.userName : ""}
                  onChange={(e) => {
                    setUserData({ ...userData, userName: e.target.value });
                  }}
                  required
                />
                <TextField
                  sx={{ width: 350 }}
                  label={t("main.first_name")}
                  variant="outlined"
                  id="volumeOffset"
                  value={userData?.firstName ? userData?.firstName : ""}
                  onChange={(e) => {
                    setUserData({
                      ...userData,
                      firstName: e.target.value,
                    });
                  }}
                  required
                />
                <TextField
                  sx={{ width: 350 }}
                  label={t("main.family_name")}
                  variant="outlined"
                  id="familyName"
                  className="familyNameInput"
                  value={userData?.familyName ? userData?.familyName : ""}
                  onChange={(e) => {
                    setUserData({
                      ...userData,
                      familyName: e.target.value,
                    });
                  }}
                  required
                />
                {!isEdit ? (
                  <TextField
                    sx={{ width: 350 }}
                    style={{ width: "100%" }}
                    label={"Password"}
                    variant="outlined"
                    id="password"
                    type="password"
                    className="passwordInput"
                    value={userData?.password ? userData?.password : ""}
                    onChange={(e) => {
                      setUserData({
                        ...userData,
                        password: e.target.value,
                      });
                    }}
                    required
                  />
                ) : (
                  <></>
                )}
                <br />
                <FormControl className="user-group-select">
                  <Select
                    label={t("main.select_group")}
                    value={
                      groupList?.some((item) => item?.id === userData?.groupId)
                        ? userData?.groupId
                        : ""
                    }
                    displayEmpty
                    renderValue={(value) => {
                      if (value) {
                        return groupList?.find(
                          (item: any) => item.id === userData?.groupId
                        )?.name;
                      } else {
                        return (
                          <Typography>{t("main.select_group")}</Typography>
                        );
                      }
                    }}
                    className="selectGroupBox"
                    labelId="simple-select-label"
                    id="Select-Group-Box"
                    onChange={(e) => {
                      // @ts-ignore
                      handleGroupChange(e);
                      setUserData({
                        ...userData,
                        groupId: e.target.value,
                      });
                    }}
                    sx={{
                      m: 1,
                      width: "100%",
                      height: "auto",
                      margin: "10px 0",
                      border: "1px solid #484C52 !important",
                      color: "#484C52 !important",
                      borderRadius: "10px",
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#484C52",
                          color: "#000",
                        },
                      },
                    }}
                  >
                    {groupList?.map((group) => (
                      <MenuItem value={group?.id} key={group?.id}>
                        {group?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <br />
                <TextField
                  placeholder={t("main.Enter_Comments")}
                  multiline
                  rows={5}
                  label={t("main.comment")}
                  variant="outlined"
                  id="comment"
                  className="userComment"
                  value={userData?.comment ? userData?.comment : ""}
                  onChange={(e) => {
                    setUserData({
                      ...userData,
                      comment: e.target.value,
                    });
                  }}
                />
              </Box>
            </DialogContent>
            <DialogActions
              sx={{ display: "flex", justifyContent: "space-around" }}
            >
              <Button
                type="submit"
                onClick={isEdit ? handleEditUser : postNewUserData}
                size="medium"
                variant="contained"
                color="primary"
                sx={{ mt: 5 }}
              >
                {isEdit ? t("main.update") : t("main.save")}
              </Button>
              <Button
                onClick={() => {
                  setisOpen(false);
                  setRowSelection({});
                  setUserData({
                    userName: null,
                    firstName: null,
                    familyName: null,
                    groupId: null,
                    password: "",
                    comment: null,
                  });
                }}
                size="medium"
                variant="outlined"
                color="secondary"
                sx={{ mt: 5, borderColor: "#fff" }}
              >
                {t("main.cancel")}
              </Button>
            </DialogActions>
          </FormControl>
        </form>
      </Dialog>
    </>
  );
};

export default UserTable;

import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import MiniSidePanel from "../layouts/MiniSidePanel/MiniSidePanel";
import AudioEditorTabs from "../component/Tabs/AudioEditorTabs/AudioEditorTabs";
import { useTranslation } from "react-i18next";

export default function EditSongPage() {
  const { t } = useTranslation();
  const [tab, setTab] = useState<number>(0);
  let location = useLocation();
  return (
    <>
      {location.pathname.includes("music") ||
      location.pathname.includes("channel") ? (
        <MiniSidePanel headerText={t("main.music_player/audio_editor")}>
          <EditSongPageContent tab={tab} setTab={setTab} />
        </MiniSidePanel>
      ) : (
        <MiniSidePanel headerText={t("main.content_library/audio_editor")}>
          <EditSongPageContent tab={tab} setTab={setTab} />
        </MiniSidePanel>
      )}
    </>
  );
}

const EditSongPageContent = ({
  tab,
  setTab,
}: {
  tab: number;
  setTab: React.Dispatch<React.SetStateAction<number>>;
}) => {
  return <AudioEditorTabs tab={tab} setTab={setTab}></AudioEditorTabs>;
};

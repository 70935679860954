/* eslint-disable react-hooks/exhaustive-deps */
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { Box, Button, Tab, Tabs, useTheme } from "@mui/material";
import dayjs from "dayjs";
import React, { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import { toast } from "react-toastify";
import { useChannelIdContext } from "../../../context/ChannelIdContext";
import { songList } from "../../../types/Audio";
import { formData } from "../../../types/Settings";
import { AudioVoiceTrackEditor } from "../../../types/VoiceTrack";
import AudioEditorDetail from "../../Audio/AudioEditorDetail";
import AudioLogData from "../../Audio/AudioLogData";
import AudioMetaData from "../../Audio/AudioMetaData";
import Characteristics from "../../Audio/Characteristics";
import CloudList from "../../Audio/CloudList";
import Restrictions from "../../Audio/Restrictions";
import UserDefinedField from "../../Audio/UserDefinedField";
import LeaveWithoutSavingDialog from "../../Dialogs/LeaveWithoutSavingDialog/LeaveWithoutSavingDialog";
import VoiceTrackCloudList from "../../VoiceTrack/VoiceTrackCloudList";
import VoiceTrackRestrictions from "../../VoiceTrack/VoiceTrackRestrictions";
import VoicetrackDistributionTab from "../../VoiceTrack/VoicetrackDistributionTab";
import ChannelAssignmentsTab from "../ChannelAssignmentsTab";
import "./audioEditorTabs.scss";

const API_URL = process.env.REACT_APP_BACKEND_URL;

export interface IAudioEditorTabsProps {
  tab: number;
  setTab: React.Dispatch<React.SetStateAction<number>>;
}

export default function AudioEditorTabs({
  tab,
  setTab,
}: IAudioEditorTabsProps) {
  let theme = useTheme();
  const { t } = useTranslation();
  const { id } = useParams();
  const location = useLocation();
  let navigate = useNavigate();
  const { setIsTokenExpired, setIsTokenUpdated, IsTokenUpdated } =
    useChannelIdContext();
  const [confirmation, setConfirmation] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  // const [saveOnLeave, setSaveOnLeave] = useState<boolean>(false)
  const [category, setCategory] = useState<any>([]);
  const [playing, setPlaying] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedSong, setSelectedSong] = useState<any>()
  const [selectedArtist, setSelectedArtist] = useState<any>()
  const [, setIsUpdated] = useState<boolean>(false);
  const [attributeId, setAttributeId] = useState<number[]>([]);
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  const [userDefinitionData, setUserDefinitionData] = useState<formData[]>([]);
  const [audioData, setAudioData] = useState<songList | any>({
    id: id,
    title: "",
    fileName: "",
    fileData: "",
    isFileUpload: false,
    artist1Id: null,
    artist1Name: "",
    artist2Id: null,
    artist2Name: "",
    artist3Id: null,
    artist3Name: "",
    releaseYear: "",
    commenceDate: dayjs(),
    useCommenceDate: false,
    expireDate: dayjs(),
    useExpireDate: false,
    priority: 0,
    creationDate: dayjs(),
    lastAudioUpdate: dayjs(),
    userDefinition1: "",
    userDefinition2: "",
    userDefinition3: "",
    userDefinition4: "",
    userDefinition5: "",
    userDefinition6: "",
    userDefinition7: "",
    userDefinition8: "",
    userDefinition9: "",
    userDefinition10: "",
    coverImageFile: "",
    queueInPoint: 0,
    queueOutPoint: 0,
    introPoint: 0,
    playMonday: true,
    playTuesday: true,
    playWednesday: true,
    playThursday: true,
    playFriday: true,
    playSaturday: true,
    playSunday: true,
    seasonalStart: Date(),
    seasonalEnd: Date(),
    timeFrom: "10:26:52",
    timeUntil: "11:26:52",
    duration: "0001-01-01T00:00:00",
    attributes: [],
    songTypeId: 0,
    updateDate: Date(),
  });
  const [originalAudioData, setOriginalAudioData] = useState<songList | any>({
    id: id,
    title: "",
    fileName: "",
    fileData: "",
    isFileUpload: false,
    artist1Id: null,
    artist1Name: "",
    artist2Id: null,
    artist2Name: "",
    artist3Id: null,
    artist3Name: "",
    releaseYear: "",
    commenceDate: dayjs(),
    useCommenceDate: false,
    expireDate: dayjs(),
    useExpireDate: false,
    priority: 0,
    creationDate: dayjs(),
    lastAudioUpdate: dayjs(),
    userDefinition1: "",
    userDefinition2: "",
    userDefinition3: "",
    userDefinition4: "",
    userDefinition5: "",
    userDefinition6: "",
    userDefinition7: "",
    userDefinition8: "",
    userDefinition9: "",
    userDefinition10: "",
    coverImageFile: "",
    queueInPoint: 0,
    queueOutPoint: 0,
    introPoint: 0,
    playMonday: true,
    playTuesday: true,
    playWednesday: true,
    playThursday: true,
    playFriday: true,
    playSaturday: true,
    playSunday: true,
    seasonalStart: Date(),
    seasonalEnd: Date(),
    timeFrom: "10:26:52",
    timeUntil: "11:26:52",
    duration: "0001-01-01T00:00:00",
    attributes: [],
    songTypeId: 0,
    updateDate: Date(),
  });
  const [voiceTrack, setVoiceTrack] = useState<AudioVoiceTrackEditor | any>({
    id: 0,
    title: "",
    file: "",
    isFileUpload: false,
    fileName: "",
    customerId: 0,
    coverImageFile: "",
    commenceDate: Date(),
    expireDate: Date(),
    playMonday: true,
    playTuesday: true,
    playWednesday: true,
    playThursday: true,
    playFriday: true,
    playSaturday: true,
    playSunday: true,
    seasonalStart: Date(),
    seasonalEnd: Date(),
    timeFrom: "00:00:00",
    timeUntil: "00:00:00",
    useCommenceDate: false,
    useExpireDate: false,
    voiceTrackCategory: {
      id: 0,
      name: "",
      volumeOffset: 0,
      voiceTracks: [""],
    },
  });

  const [originalVoiceTrack, setOriginalVoiceTrack] = useState<AudioVoiceTrackEditor | any>({
    id: 0,
    title: "",
    file: "",
    isFileUpload: false,
    fileName: "",
    customerId: 0,
    coverImageFile: "",
    commenceDate: Date(),
    expireDate: Date(),
    playMonday: true,
    playTuesday: true,
    playWednesday: true,
    playThursday: true,
    playFriday: true,
    playSaturday: true,
    playSunday: true,
    seasonalStart: Date(),
    seasonalEnd: Date(),
    timeFrom: "00:00:00",
    timeUntil: "00:00:00",
    useCommenceDate: false,
    useExpireDate: false,
    voiceTrackCategory: {
      id: 0,
      name: "",
      volumeOffset: 0,
      voiceTracks: [""],
    },
  });

  const fetchData = useCallback(async () => {
    fetch(`${API_URL}/Song/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    })
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data.success === true) {
          setIsLoading(true);
          setIsUpdated(false);
          setSelectedSong(data?.data?.title)
          setSelectedArtist(data?.data?.artist1Name)
          setAudioData({
            id: data?.data?.id,
            title: data?.data?.title ? data?.data?.title : "",
            fileName: data?.data?.fileName ? data?.data?.fileName : "",
            fileData: data?.data?.fileData ? data?.data?.fileData : "",
            isFileUpload: false,
            artist1Id: data?.data?.artist1Id ? data?.data?.artist1Id : null,
            artist1Name:
              data?.data?.artist1Name === "" ? "" : data?.data?.artist1Name,
            artist2Id: data?.data.artist2Id ? data?.data?.artist2Id : null,
            artist2Name:
              data?.data?.artist2Name === "" ? "" : data?.data?.artist2Name,
            artist3Id: data?.data?.artist3Id ? data?.data?.artist3Id : null,
            artist3Name:
              data?.data?.artist3Name === "" ? "" : data?.data?.artist3Name,
            releaseYear: data?.data?.releaseYear,
            commenceDate: data?.data?.commenceDate,
            useCommenceDate: data?.data?.useCommenceDate,
            expireDate: data?.data?.expireDate,
            useExpireDate: data?.data?.useExpireDate,
            priority: data?.data?.priority,
            creationDate: data?.data?.creationDate,
            lastAudioUpdate: data?.data?.lastAudioUpdate,
            userDefinition1:
              data?.data?.userDefinition1 === ""
                ? "0"
                : data?.data?.userDefinition1,
            userDefinition2:
              data?.data?.userDefinition2 === ""
                ? "0"
                : data?.data?.userDefinition2,
            userDefinition3:
              data?.data?.userDefinition3 === ""
                ? "0"
                : data?.data?.userDefinition3,
            userDefinition4:
              data?.data?.userDefinition4 === ""
                ? "0"
                : data?.data?.userDefinition4,
            userDefinition5:
              data?.data?.userDefinition5 === ""
                ? "0"
                : data?.data?.userDefinition5,
            userDefinition6:
              data?.data?.userDefinition6 === ""
                ? "0"
                : data?.data?.userDefinition6,
            userDefinition7:
              data?.data?.userDefinition7 === ""
                ? "0"
                : data?.data?.userDefinition7,
            userDefinition8:
              data?.data?.userDefinition8 === ""
                ? "0"
                : data?.data?.userDefinition8,
            userDefinition9:
              data?.data?.userDefinition9 === ""
                ? "0"
                : data?.data?.userDefinition9,
            userDefinition10:
              data?.data?.userDefinition10 === ""
                ? "0"
                : data?.data?.userDefinition10,
            coverImageFile:
              data?.data?.coverImageFile === ""
                ? data?.data?.fileName
                : data?.data?.coverImageFile,
            queueInPoint: data?.data?.queueInPoint,
            queueOutPoint: data?.data?.queueOutPoint,
            introPoint: data?.data?.introPoint,
            playMonday: data?.data?.playMonday,
            playTuesday: data?.data?.playTuesday,
            playWednesday: data?.data?.playWednesday,
            playThursday: data?.data?.playThursday,
            playFriday: data?.data?.playFriday,
            playSaturday: data?.data?.playSaturday,
            playSunday: data?.data?.playSunday,
            seasonalStart: data?.data?.seasonalStart,
            seasonalEnd: data?.data?.seasonalEnd,
            timeFrom: data?.data?.timeFrom,
            timeUntil: data?.data?.timeUntil,
            duration: data?.data?.duration,
            attributes: data?.data?.attributes,
            songTypeId: data?.data?.songTypeId,
            updateDate: data?.data?.updateDate,
          });
          setOriginalAudioData({
            id: data?.data?.id,
            title: data?.data?.title ? data?.data?.title : "",
            fileName: data?.data?.fileName ? data?.data?.fileName : "",
            fileData: data?.data?.fileData ? data?.data?.fileData : "",
            isFileUpload: false,
            artist1Id: data?.data?.artist1Id ? data?.data?.artist1Id : null,
            artist1Name:
              data?.data?.artist1Name === "" ? "" : data?.data?.artist1Name,
            artist2Id: data?.data.artist2Id ? data?.data?.artist2Id : null,
            artist2Name:
              data?.data?.artist2Name === "" ? "" : data?.data?.artist2Name,
            artist3Id: data?.data?.artist3Id ? data?.data?.artist3Id : null,
            artist3Name:
              data?.data?.artist3Name === "" ? "" : data?.data?.artist3Name,
            releaseYear: data?.data?.releaseYear,
            commenceDate: data?.data?.commenceDate,
            useCommenceDate: data?.data?.useCommenceDate,
            expireDate: data?.data?.expireDate,
            useExpireDate: data?.data?.useExpireDate,
            priority: data?.data?.priority,
            creationDate: data?.data?.creationDate,
            lastAudioUpdate: data?.data?.lastAudioUpdate,
            userDefinition1:
              data?.data?.userDefinition1 === ""
                ? "0"
                : data?.data?.userDefinition1,
            userDefinition2:
              data?.data?.userDefinition2 === ""
                ? "0"
                : data?.data?.userDefinition2,
            userDefinition3:
              data?.data?.userDefinition3 === ""
                ? "0"
                : data?.data?.userDefinition3,
            userDefinition4:
              data?.data?.userDefinition4 === ""
                ? "0"
                : data?.data?.userDefinition4,
            userDefinition5:
              data?.data?.userDefinition5 === ""
                ? "0"
                : data?.data?.userDefinition5,
            userDefinition6:
              data?.data?.userDefinition6 === ""
                ? "0"
                : data?.data?.userDefinition6,
            userDefinition7:
              data?.data?.userDefinition7 === ""
                ? "0"
                : data?.data?.userDefinition7,
            userDefinition8:
              data?.data?.userDefinition8 === ""
                ? "0"
                : data?.data?.userDefinition8,
            userDefinition9:
              data?.data?.userDefinition9 === ""
                ? "0"
                : data?.data?.userDefinition9,
            userDefinition10:
              data?.data?.userDefinition10 === ""
                ? "0"
                : data?.data?.userDefinition10,
            coverImageFile:
              data?.data?.coverImageFile === ""
                ? data?.data?.fileName
                : data?.data?.coverImageFile,
            queueInPoint: data?.data?.queueInPoint,
            queueOutPoint: data?.data?.queueOutPoint,
            introPoint: data?.data?.introPoint,
            playMonday: data?.data?.playMonday,
            playTuesday: data?.data?.playTuesday,
            playWednesday: data?.data?.playWednesday,
            playThursday: data?.data?.playThursday,
            playFriday: data?.data?.playFriday,
            playSaturday: data?.data?.playSaturday,
            playSunday: data?.data?.playSunday,
            seasonalStart: data?.data?.seasonalStart,
            seasonalEnd: data?.data?.seasonalEnd,
            timeFrom: data?.data?.timeFrom,
            timeUntil: data?.data?.timeUntil,
            duration: data?.data?.duration,
            attributes: data?.data?.attributes,
            songTypeId: data?.data?.songTypeId,
            updateDate: data?.data?.updateDate,
          });
        } else {
          toast.error(data?.message);
        }
      });
  }, [id, IsTokenUpdated]);

  const fetchVoiceTrackList = useCallback(async () => {
    fetch(`${API_URL}/VoiceTrack/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    })
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data.success === true || data?.data !== null) {
          setIsLoading(true);
          setIsUpdated(false);
          setVoiceTrack({
            id: data?.data?.id,
            customerId: data?.data.customerId ? data?.data.customerId : "",
            title: data?.data?.title ? data?.data?.title : "",
            isFileUpload: false,
            file: data?.data?.file ? data?.data?.file : "",
            fileName: data?.data?.fileName ? data?.data?.fileName : "",
            coverImageFile: data?.data?.coverImageFile
              ? data?.data?.coverImageFile
              : "",
            commenceDate: data?.data?.commenceDate,
            expireDate: data?.data?.expireDate,
            playMonday: data?.data?.playMonday,
            playTuesday: data?.data?.playTuesday,
            playWednesday: data?.data?.playWednesday,
            playThursday: data?.data?.playThursday,
            playFriday: data?.data?.playFriday,
            playSaturday: data?.data?.playSaturday,
            playSunday: data?.data?.playSunday,
            seasonalStart: data?.data?.seasonalStart,
            seasonalEnd: data?.data?.seasonalEnd,
            timeFrom: data?.data?.timeFrom,
            timeUntil: data?.data?.timeUntil,
            useCommenceDate: data?.data?.useCommenceDate,
            useExpireDate: data?.data?.useExpireDate,

            voiceTrackCategory: {
              id: data?.data?.voiceTrackCategory?.id,
              name: data?.data?.voiceTrackCategory?.name,
              volumeOffset: data?.data?.voiceTrackCategory?.volumeOffset,
              voiceTracks: [data?.data?.voiceTrackCategory?.voiceTracks],
            },
          });
          setCategory(data?.data?.voiceTrackCategory);
        } else {
          toast.error(data?.message);
        }
      });
  }, [id, IsTokenUpdated]);

  const handleAddSongAttributes = useCallback(
    async (e: SyntheticEvent) => {
      e.preventDefault();
      if (tab === 4) {
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token?.accessToken}`,
          },
          body: JSON.stringify({
            songId: id,
            attributeId: attributeId,
          }),
        };
        fetch(`${API_URL}/Song/Attribute`, requestOptions)
          .then((response) => {
            if (
              response.ok === false &&
              response.status === 401 &&
              token?.refreshToken !== ""
            ) {
              setIsTokenExpired(true);
            } else if (response.status === 401 && token?.refreshToken === "") {
              navigate("/");
              localStorage.removeItem("token");
              localStorage.clear();
            } else {
              setIsTokenUpdated(false);
              return response.json();
            }
          })
          .then((data) => {
            if (data.success === true) {
              setIsUpdated(true);
              toast.success(
                t("main.Audio_characteristics_Updated_Successfully")
              );
              fetchData();
            }
          });
      }
    },
    [attributeId, id, tab]
  );

  const handleBack = (e: SyntheticEvent) => {
    e.preventDefault()
    console.log(audioData)
    console.log(originalAudioData)

    if (JSON.stringify(audioData) === JSON.stringify(originalAudioData)) {
      console.log("same")
      navigate("/music-library")
    } else {
      setOpen(true)
    }
  }

  useEffect(() => {
    if (confirmation) {
      navigate("/music-library")
      setConfirmation(false)
    }
  }, [confirmation])
  const handleSongUpdate = useCallback(
    async (e: SyntheticEvent) => {
      const currentDate = new Date().toISOString();
      audioData.updateDate = currentDate;
      e.preventDefault();
      const formdata = new FormData();
      formdata.append("id", audioData?.id);
      formdata.append("customerId", audioData?.customerId);
      formdata.append("title", audioData?.title);
      formdata.append("fileName", audioData?.fileName);
      formdata.append("fileData", audioData?.fileData);
      formdata.append("isFileUpload", audioData?.isFileUpload);
      formdata.append("artist1Id", audioData?.artist1Id);
      if (audioData?.artist2Id)
        formdata.append("artist2Id", audioData?.artist2Id);
      if (audioData?.artist3Id)
        formdata.append("artist3Id", audioData?.artist3Id);
      formdata.append("artist1Name", audioData?.artist1Name);
      formdata.append("artist2Name", audioData?.artist2Name);
      formdata.append("artist3Name", audioData?.artist3Name);
      formdata.append("commenceDate", audioData?.commenceDate);
      formdata.append("useCommenceDate", audioData?.useCommenceDate);
      formdata.append("expireDate", audioData?.expireDate);
      formdata.append("useExpireDate", audioData?.useExpireDate);
      formdata.append("priority", audioData?.priority);
      formdata.append("creationDate", audioData?.creationDate);
      formdata.append("lastAudioUpdate", audioData?.lastAudioUpdate);
      formdata.append("userDefinition1", audioData?.userDefinition1);
      formdata.append("userDefinition2", audioData?.userDefinition2);
      formdata.append("userDefinition3", audioData?.userDefinition3);
      formdata.append("userDefinition4", audioData?.userDefinition4);
      formdata.append("userDefinition5", audioData?.userDefinition5);
      formdata.append("userDefinition6", audioData?.userDefinition6);
      formdata.append("userDefinition7", audioData?.userDefinition7);
      formdata.append("userDefinition8", audioData?.userDefinition8);
      formdata.append("userDefinition9", audioData?.userDefinition9);
      formdata.append("userDefinition10", audioData?.userDefinition10);
      formdata.append("coverImageFile", audioData?.coverImageFile);
      formdata.append("queueInPoint", audioData?.queueInPoint);
      formdata.append("queueOutPoint", audioData?.queueOutPoint);
      formdata.append("introPoint", audioData?.introPoint);
      formdata.append("playMonday", audioData?.playMonday);
      formdata.append("playTuesday", audioData?.playTuesday);
      formdata.append("playWednesday", audioData?.playWednesday);
      formdata.append("playThursday", audioData?.playThursday);
      formdata.append("playFriday", audioData?.playFriday);
      formdata.append("playSaturday", audioData?.playSaturday);
      formdata.append("playSunday", audioData?.playSunday);
      formdata.append("seasonalStart", audioData?.seasonalStart);
      formdata.append("seasonalEnd", audioData?.seasonalEnd);
      formdata.append("timeFrom", audioData?.timeFrom);
      formdata.append("timeUntil", audioData?.timeUntil);
      formdata.append("duration", audioData?.duration);
      formdata.append("updateDate", audioData?.updateDate);
      if (audioData?.songTypeId > 0) {
        formdata.append("songTypeId", audioData?.songTypeId);
      }
      if (audioData?.releaseYear) {
        formdata.append("ReleaseYear", audioData?.releaseYear);
      }

      if ((tab === 0 || tab === 1) && location?.pathname?.includes("music")) {
        if (!formdata) {
          toast.error(t("main.Data_Not_Found"));
          return;
        } else {
          fetch(`${API_URL}/Song`, {
            method: "PUT",
            headers: {
              Accept: "application/json",
              Authorization: `bearer ${token?.accessToken}`,
            },
            body: formdata,
          })
            .then((response) => {
              if (
                response.ok === false &&
                response.status === 401 &&
                token?.refreshToken !== ""
              ) {
                setIsTokenExpired(true);
              } else if (
                response.status === 401 &&
                token?.refreshToken === ""
              ) {
                navigate("/");
                localStorage.removeItem("token");
                localStorage.clear();
              } else {
                setIsTokenUpdated(false);
                return response.json();
              }
            })
            .then((data) => {
              if (data.success === true) {
                toast.success(t("main.Audio_Updated_Successfully"));
                fetchData();
              }
            });
        }
      }
    },
    [audioData, location?.pathname, tab]
  );

  const handleVoiceTrackUpdate = useCallback(
    async (e: SyntheticEvent) => {
      e.preventDefault();
      const formdata = new FormData();
      formdata.append("id", voiceTrack?.id);
      formdata.append("title", voiceTrack?.title);
      formdata.append("commenceDate", voiceTrack?.commenceDate);
      formdata.append("expireDate", voiceTrack?.expireDate);
      formdata.append("playMonday", voiceTrack?.playMonday);
      formdata.append("playTuesday", voiceTrack?.playTuesday);
      formdata.append("playWednesday", voiceTrack?.playWednesday);
      formdata.append("playThursday", voiceTrack?.playThursday);
      formdata.append("playFriday", voiceTrack?.playFriday);
      formdata.append("playSaturday", voiceTrack?.playSaturday);
      formdata.append("playSunday", voiceTrack?.playSunday);
      formdata.append("seasonalStart", voiceTrack?.seasonalStart);
      formdata.append("seasonalEnd", voiceTrack?.seasonalEnd);
      formdata.append("timeFrom", voiceTrack?.timeFrom);
      formdata.append("timeUntil", voiceTrack?.timeUntil);
      formdata.append("file", voiceTrack?.file);
      formdata.append("CustomerId", voiceTrack?.customerId);
      formdata.append(
        "voiceTrackCategoryId",
        voiceTrack?.voiceTrackCategory?.id
      );
      formdata.append("useCommenceDate", voiceTrack?.useCommenceDate);
      formdata.append("useExpireDate", voiceTrack?.useExpireDate);
      // formdata.append("isFileUpload", voiceTrack?.isFileUpload);
      // formdata.append("fileName", voiceTrack?.fileName);
      // formdata.append("coverImageFile", voiceTrack?.coverImageFile);

      if (location?.pathname?.includes("content-library")) {
        if (!formdata) {
          toast.error(t("main.Data_Not_Found"));
          return;
        } else {
          fetch(
            `${API_URL}/VoiceTrack`,
            {
              method: "PUT",
              headers: {
                Accept: "application/json",
                Authorization: `bearer ${token?.accessToken}`,
              },
              body: formdata,
            }
          )
            .then((response) => {
              if (
                response.ok === false &&
                response.status === 401 &&
                token?.refreshToken !== ""
              ) {
                setIsTokenExpired(true);
              } else if (
                response.status === 401 &&
                token?.refreshToken === ""
              ) {
                navigate("/");
                localStorage.removeItem("token");
                localStorage.clear();
              } else {
                setIsTokenUpdated(false);
                return response.json();
              }
            })
            .then((data) => {
              if (data.success === true) {
                toast.success(t("main.Voice_Track_Updated_Successfully"));
                fetchVoiceTrackList();
              } else {
                toast.error(data.message);
              }
            });
        }
      }
    },
    [id, location?.pathname, tab, voiceTrack]
  );
  useEffect(() => {
    if (location?.pathname.includes("content-library")) fetchVoiceTrackList();
    else fetchData();
  }, [fetchData, fetchVoiceTrackList, location?.pathname]);

  return (
    <>
      <Box
        className="tabs-header"
        sx={{
          backgroundColor: "#34393f",
        }}
      >
        <div className="btn-tab-back">
          {location?.pathname?.includes("music") ? (
            <div className="audio-tab" onClick={handleBack} style={{ cursor: "pointer" }}>

              <ArrowBackOutlinedIcon />

              {/* {t("main.Back")} */}
              {/* </> */}
            </div>
          ) : location?.pathname?.includes("channel") ? (
            <Link to="/channel-editor" className="audio-tab">
              <ArrowBackOutlinedIcon />

              {t("main.Back")}
            </Link>
          ) : (
            <Link to="/content-library" className="audio-tab">
              <ArrowBackOutlinedIcon />

              {t("main.Back")}
            </Link>
          )}
          {location?.pathname?.includes("music") ||
            location?.pathname?.includes("channel") ? (
            <Tabs
              value={tab}
              onChange={(e, v) => {
                setTab(v);
              }}
              className="Audio-Tabs"
              aria-label="audio tabs"
              sx={{
                backgroundColor:
                  theme.palette.mode === "dark" ? "#202020" : "#34393f",
                "& button": {
                  fontSize: "1.1rem",
                  color: "#e8ebe6",
                },
              }}
              variant="fullWidth"
            >
              <Tab className="audio-tab" label={t("main.Song_Data")} />
              <Tab className="audio-tab" label={t("main.Restrictions")} />
              <Tab className="audio-tab" label={t("main.User_Defined")} />
              <Tab className="audio-tab" label={t("main.Meta")} />
              <Tab className="audio-tab" label={t("main.Characteristics")} />
              <Tab
                className="audio-tab"
                label={t("main.Channel_Assignments")}
              />
              <Tab className="audio-tab" label={t("main.Distribution")} />
              <Tab className="audio-tab" label={t("main.History")} />
              <Tab className="audio-tab" label={t("main.Cloud")} />
            </Tabs>
          ) : (
            <Tabs
              value={tab}
              onChange={(e, v) => {
                setTab(v);
              }}
              className="Audio-Tabs"
              aria-label="audio tabs"
              sx={{
                backgroundColor:
                  theme.palette.mode === "dark" ? "#202020" : "#34393f",
                "& button": {
                  fontSize: "1.1rem",
                  color: "#e8ebe6",
                },
              }}
              variant="fullWidth"
            >
              <Tab className="audio-tab" label={t("main.Voice_Track_Data")} />
              <Tab className="audio-tab" label={t("main.Restrictions")} />
              <Tab className="audio-tab" label={t("main.Distribution")} />
              <Tab className="audio-tab" label={t("main.History")} />
              <Tab className="audio-tab" label={t("main.Cloud")} />
            </Tabs>
          )}
        </div>
        <div className="btn-tab">
          {location?.pathname?.includes("music") ||
            location?.pathname?.includes("channel") ? (
            <Button
              onClick={(e: SyntheticEvent) => {
                handleSongUpdate(e);
                handleAddSongAttributes(e);
                setIsUpdated(true);
              }}
            >
              {t("main.save")}
            </Button>
          ) : (
            <Button
              onClick={(e: any) => {
                handleVoiceTrackUpdate(e);
                setIsUpdated(true);
              }}
            >
              {t("main.save")}
            </Button>
          )}
        </div>
      </Box>
      <div style={{
        margin: "2rem", backgroundColor: "#3C735C", padding: "1rem", marginLeft: "0", marginTop: "0", marginRight: "0", borderTop: "1px solid #34393F", color: "#F5F5F5", fontFamily: "Segoe UI", fontStyle: "normal",
        fontWeight: 400,
        fontSize: "18px",
      }}>
        {location.pathname.includes("music") ?
          <>Titel: {selectedSong} - {selectedArtist}</>
          :
          <>Titel: {voiceTrack.title}</>
        }
      </div>
      <SwipeableViews axis={"x"} index={tab} onChangeIndex={(v) => setTab(v)}>
        <div
          role="tabpanel"
          hidden={tab !== 0}
          id={`vertical-tabpanel-${0}`}
          aria-labelledby={`vertical-tab-${0}`}
        >
          {tab === 0 && (

            <Box className="tabContentPage">
              <AudioEditorDetail
                isLoading={isLoading}
                audioData={audioData}
                setAudioData={setAudioData}
                setVoiceTrack={setVoiceTrack}
                category={category}
                setCategory={setCategory}
                voiceTrack={voiceTrack}
                setPlaying={setPlaying}
                playing={playing}
              />
            </Box>
          )}
        </div>
        <div
          role="tabpanel"
          hidden={tab !== 1}
          id={`vertical-tabpanel-${1}`}
          aria-labelledby={`vertical-tab-${1}`}
        >
          {tab === 1 && (
            <Box>
              {location?.pathname?.includes("music") ||
                location?.pathname?.includes("channel") ? (
                <Box className="tabContentPage">
                  <Restrictions
                    isLoading={isLoading}
                    audioData={audioData}
                    setAudioData={setAudioData}
                  />
                </Box>
              ) : (
                <Box className="tabTablePage" sx={{ padding: "2rem" }}>
                  <VoiceTrackRestrictions
                    isLoading={isLoading}
                    voiceTrack={voiceTrack}
                    setVoiceTrack={setVoiceTrack}
                  />
                </Box>
              )}
            </Box>
          )}
        </div>
        <div
          role="tabpanel"
          hidden={tab !== 2}
          id={`vertical-tabpanel-${2}`}
          aria-labelledby={`vertical-tab-${2}`}
        >
          {tab === 2 && (
            <Box>
              {location?.pathname?.includes("music") ||
                location?.pathname?.includes("channel") ? (
                <Box className="tabContentPage">
                  <UserDefinedField
                    audioData={audioData}
                    setAudioData={setAudioData}
                    userDefinitionData={userDefinitionData}
                    setUserDefinitionData={setUserDefinitionData}
                  />
                </Box>
              ) : (
                <Box className="tabTablePage">
                  <VoicetrackDistributionTab />
                </Box>
              )}
            </Box>
          )}
        </div>
        <div
          role="tabpanel"
          hidden={tab !== 3}
          id={`vertical-tabpanel-${3}`}
          aria-labelledby={`vertical-tab-${3}`}
        >
          {tab === 3 && (
            <>
              {location?.pathname?.includes("music") ||
                location?.pathname?.includes("channel") ? (
                <Box className="tabContentPage">
                  <AudioMetaData />
                </Box>
              ) : (
                <Box className="tabTablePage">
                  <AudioLogData />
                </Box>
              )}
            </>
          )}
        </div>
        <div
          role="tabpanel"
          hidden={tab !== 4}
          id={`vertical-tabpanel-${4}`}
          aria-labelledby={`vertical-tab-${4}`}
        >
          {tab === 4 && (
            <>
              {location?.pathname?.includes("content") ? (
                <Box className="tabTablePage">
                  <VoiceTrackCloudList />
                </Box>
              ) : (
                <Box className="tabContentPage">
                  <Characteristics
                    attributeId={attributeId}
                    setAttributeId={setAttributeId}
                  />
                </Box>
              )}
            </>
          )}
        </div>
        <div
          role="tabpanel"
          hidden={tab !== 5}
          id={`vertical-tabpanel-${5}`}
          aria-labelledby={`vertical-tab-${5}`}
        >
          {tab === 5 && (
            <Box className="tabTablePage">
              <ChannelAssignmentsTab />
            </Box>
          )}
        </div>
        {/* Distribution */}
        <div
          role="tabpanel"
          hidden={tab !== 6}
          id={`vertical-tabpanel-${6}`}
          aria-labelledby={`vertical-tab-${6}`}
        >
          {tab === 6 && (
            <Box className="tabTablePage">
              <VoicetrackDistributionTab />
            </Box>
          )}
        </div>
        {/* History */}
        <div
          role="tabpanel"
          hidden={tab !== 7}
          id={`vertical-tabpanel-${7}`}
          aria-labelledby={`vertical-tab-${7}`}
        >
          {tab === 7 && (
            <Box className="tabTablePage">
              <AudioLogData />
            </Box>
          )}
        </div>
        {/* Distribution */}
        <div
          role="tabpanel"
          hidden={tab !== 8}
          id={`vertical-tabpanel-${8}`}
          aria-labelledby={`vertical-tab-${8}`}
        >
          {tab === 8 && (
            <Box className="tabTablePage">
              <CloudList />
            </Box>
          )}
        </div>
      </SwipeableViews>
      <LeaveWithoutSavingDialog
        isOpen={open}
        handleClose={() => setOpen(false)}
        setConfirmation={setConfirmation}
      ></LeaveWithoutSavingDialog>
    </>
  );
}

import React from "react";
import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function VoiceTrackViewDaysofWeek({
  voiceTrack,
  setVoiceTrack,
}: {
  voiceTrack: any;
  setVoiceTrack: React.Dispatch<React.SetStateAction<any>>;
}) {

  const {t} = useTranslation();

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={1}
    >
      <Grid item>
        <FormControlLabel
          control={
            <Checkbox
              size="medium"
              checked={voiceTrack?.playMonday}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setVoiceTrack({
                  ...voiceTrack,
                  playMonday: event.target.checked,
                })
              }
            />
          }
          sx={{ "& .MuiSvgIcon-root": { fontSize: 32 } }}
          label={t("main.monday")}
          labelPlacement="bottom"
        />
        <FormControlLabel
          control={
            <Checkbox
              size="medium"
              checked={voiceTrack?.playTuesday}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setVoiceTrack({
                  ...voiceTrack,
                  playTuesday: event.target.checked,
                })
              }
            />
          }
          sx={{ "& .MuiSvgIcon-root": { fontSize: 32 } }}
          label={t("main.tuesday")}
          labelPlacement="bottom"
        />
        <FormControlLabel
          control={
            <Checkbox
              size="medium"
              checked={voiceTrack?.playWednesday}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setVoiceTrack({
                  ...voiceTrack,
                  playWednesday: event.target.checked,
                })
              }
            />
          }
          sx={{ "& .MuiSvgIcon-root": { fontSize: 32 } }}
          label={t("main.wednesday")}
          labelPlacement="bottom"
        />
        <FormControlLabel
          control={
            <Checkbox
              size="medium"
              checked={voiceTrack?.playThursday}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setVoiceTrack({
                  ...voiceTrack,
                  playThursday: event.target.checked,
                })
              }
            />
          }
          sx={{ "& .MuiSvgIcon-root": { fontSize: 32 } }}
          label={t("main.thursday")}
          labelPlacement="bottom"
        />
        <FormControlLabel
          control={
            <Checkbox
              size="medium"
              checked={voiceTrack?.playFriday}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setVoiceTrack({
                  ...voiceTrack,
                  playFriday: event.target.checked,
                })
              }
            />
          }
          sx={{ "& .MuiSvgIcon-root": { fontSize: 32 } }}
          label={t("main.friday")}
          labelPlacement="bottom"
        />
        <FormControlLabel
          control={
            <Checkbox
              size="medium"
              checked={voiceTrack?.playSaturday}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setVoiceTrack({
                  ...voiceTrack,
                  playSaturday: event.target.checked,
                })
              }
            />
          }
          sx={{ "& .MuiSvgIcon-root": { fontSize: 32 } }}
          label={t("main.saturday")}
          labelPlacement="bottom"
        />
        <FormControlLabel
          control={
            <Checkbox
              size="medium"
              checked={voiceTrack?.playSunday}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setVoiceTrack({
                  ...voiceTrack,
                  playSunday: event.target.checked,
                })
              }
            />
          }
          sx={{ "& .MuiSvgIcon-root": { fontSize: 32 } }}
          label={t("main.sunday")}
          labelPlacement="bottom"
        />
      </Grid>
    </Grid>
  );
}

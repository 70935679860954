import React from "react";
import SidePanel from "../layouts/_old/PersistentSidePanel";
import MusicPlayer from "../component/TestComponent/Player/MusicPlayer";

export default function PlayerPage() {
  return (
    <SidePanel>
      <div
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <MusicPlayer />
      </div>
    </SidePanel>
  );
}

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from "react";
import MiniSidePanel from "../layouts/MiniSidePanel/MiniSidePanel";
import ContentLibraryTable from "../component/Tables/ContentLibraryTable/ContentLibraryTable";
import ContentLibraryNavigationPanel from "../layouts/ContentLibraryNavigationPanel";
import { useChannelIdContext } from "../context/ChannelIdContext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
const API_URL = process.env.REACT_APP_BACKEND_URL;
export default function ContentLibraryPage() {
  const {t} = useTranslation()
  return (
    <MiniSidePanel headerText={t("main.content_library")}>
      <ContentLibraryContent />
    </MiniSidePanel>
  );
}

function ContentLibraryContent() {
  let navigate = useNavigate();
  const { setIsTokenExpired, setIsTokenUpdated, IsTokenUpdated } = useChannelIdContext()
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");

  //useEffect Area
  useEffect(() => {
    const fetchData = async () => {
      fetch(`${API_URL}/VoiceTrack/GetAll`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token?.accessToken}`,
        },
      })
      .then((response) => {
        if(
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ){
          setIsTokenExpired(true)
        }else if (response.status === 401 && token?.refreshToken === ""){
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else{
          setIsTokenUpdated(false)
          return response.json()
        }
      })
      .then((data) => {
        console.log(data)
      })

    };
    // Call the function
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [IsTokenUpdated]);

  return (
    <>
      <ContentLibraryNavigationPanel
        onButtonClick={() => {}}
        importFolder
        importFile={true}
        categoryManager
        wheelManager
      ></ContentLibraryNavigationPanel>
      <ContentLibraryTable></ContentLibraryTable>
    </>
  );
}

import React from "react";
import MiniSidePanel from "../layouts/MiniSidePanel/MiniSidePanel";
import NavigationPanel from "../layouts/NavigationPanel/NavigationPanel";
import ManageCustomerTabs from "../component/Tabs/ManageCustomerTabs";
import { useTranslation } from "react-i18next";

export default function ManageCustomerPage() {
  const {t} = useTranslation()
  return (
    <MiniSidePanel headerText={t("main.manage_customer")}>
      <ManageCustomerPageContent />
    </MiniSidePanel>
  );
}

const ManageCustomerPageContent: React.FC = () => {
  return (
    <>
      <NavigationPanel
        onButtonClick={() => {}}
        channelTools
        manageCustomerSearchbar
      ></NavigationPanel>
      <ManageCustomerTabs></ManageCustomerTabs>
    </>
  );
};

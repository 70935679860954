import {
  Box,
  Theme,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import UserLoginRolesDialog from "./../Dialogs/UserLoginRolesDialog";
import { useChannelIdContext } from "../../context/ChannelIdContext";
import { useTranslation } from "react-i18next";

const API_URL = process.env.REACT_APP_BACKEND_URL;
const tbStyle = (theme: Theme) => ({
  padding: "0px",
  borderRadius: "4px",
  backgroundColor: theme.palette.mode === "dark" ? "#000000ad" : "#ffffffad",
});

enum PopUpEnum {
  None = 0,
  SelectUserRole = 1,
}

interface formData {
  username: string | null;
  password: string | null;
}

export default function SignIn({
  changeOperationHandler,
}: {
  changeOperationHandler: Function;
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [message, setMessage] = useState("");
  const { setcurrentUser } = useChannelIdContext();
  const [popUp, setPopUp] = useState(PopUpEnum.None);
  const [auth, setAuth] = useState<formData>({
    username: null,
    password: null,
  });
  const postAuthData = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: auth.username,
        password: auth.password,
      }),
    };
    const response = await fetch(`${API_URL}/Auth/Login`, requestOptions);
    const data = await response.json();
    if (data?.success === true) {
      localStorage.setItem("token", JSON.stringify(data?.data));
      toast.success(t("main.Logged_in_Successfully"), {
        position: "bottom-right",
        autoClose: 1400,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setMessage("");
      setPopUp(PopUpEnum.SelectUserRole);
      // @ts-ignore
      function myModalCheckFunction() { 
        navigate("/music-library");
      }

      const response = await fetch(`${API_URL}/Group/GetAll`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${data?.data}`,
        },
      });
      const res = await response.json();
      if (res?.success === true) {
        const user = res?.data?.find((group: any) =>
          group?.user?.find((user: any) => user?.userName === auth?.username)
        );
        setcurrentUser(user);
        localStorage.setItem("userData", JSON.stringify(user));
      }
      setTimeout(myModalCheckFunction, 2000);
    } else {
      setMessage("Invalid User or Password !");
      toast.error(data?.message);
      localStorage.clear();
    }
  };

  return (
    <>
      <Box component="form" noValidate sx={{ mt: "1.2rem" }}>
        <Typography color="error" align="center">
          <strong>{message}</strong>
        </Typography>
        <TextField
          style={{
            backgroundColor: "#E0E0E0",
            borderRadius: "9px",
          }}
          margin="normal"
          required
          fullWidth
          id="username"
          placeholder={t("main.username")}
          name="username"
          autoComplete="username"
          autoFocus
          sx={tbStyle}
          value={auth?.username === null ? "" : auth?.username}
          onChange={(e) => {
            setAuth({ ...auth, username: e.target.value });
          }}
        />
        <TextField
          style={{
            backgroundColor: "#E0E0E0",
            borderRadius: "9px",
          }}
          margin="normal"
          required
          fullWidth
          name="password"
          placeholder={t("Password")}
          type="password"
          id="password"
          autoComplete="current-password"
          sx={tbStyle}
          value={auth?.password === null ? "" : auth?.password}
          onChange={(e) => {
            setAuth({ ...auth, password: e.target.value });
          }}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Button
            style={{
              alignSelf: "center",
              textAlign: "center",
              maxWidth: "250px",
              maxHeight: "50px",
              textTransform: "capitalize",
              borderRadius: "9px",
            }}
            type="submit"
            fullWidth
            variant="contained"
            size="large"
            sx={{ mt: 3, mb: 2 }}
            onClick={(e: any) => {
              postAuthData(e)
              setAuth({ username: null, password: null })
            }}
          >
            {t("main.login")}
          </Button>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h3
            style={{
              display: "block",
              color: "#9B9B9B",
              fontFamily: "Ubuntu",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "18px",
            }}
          >
            {t("main.problems_software")}
          </h3>
          <Button
            style={{
              maxWidth: "250px",
              maxHeight: "50px",
              textTransform: "capitalize",
              borderRadius: "9px",
            }}
            fullWidth
            variant="outlined"
            size="large"
            type="submit"
            sx={{ mt: 0, mb: 2 }}
          >
            {t("main.contact_now")}
          </Button>
        </div>
      </Box>
      <UserLoginRolesDialog
        isOpen={popUp === PopUpEnum.SelectUserRole}
        handleClose={() =>setPopUp(PopUpEnum.None)}
      />
    </>
  );
}

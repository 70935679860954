import * as React from "react";
import { Checkbox, FormControlLabel, Grid, Typography, useTheme } from "@mui/material";

export interface ICheckBoxControlProps {
  header: string;
  value: boolean;
  checkText: string;
  inputChanged: Function;
}

export function CheckBoxControl(props: ICheckBoxControlProps) {
  const theme = useTheme();
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        md={5}
        container
        direction="column"
        alignItems="start"
        justifyContent="center"
      >
        <Typography className={theme.palette.mode === "dark" ? "BoxLabel-dark" : "BoxLabel" }>{props?.header}</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={7}
        container
        direction="column"
        justifyContent="center"
      >
        <div className="CustomCheckBox">
          <FormControlLabel
            control={
              <Checkbox
                checked={props?.value ? props.value : false}
                sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
                onChange={(e) => props.inputChanged(e.target.checked)}
              />
            }
            label={props?.checkText}
          />
        </div>
      </Grid>
    </Grid>
  );
}

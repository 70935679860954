/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  FC,
  useMemo,
  useState,
  useEffect,
  useCallback,
  MouseEventHandler,
} from "react";
import {
  Link,
  Paper,
  Select,
  Button,
  Dialog,
  MenuItem,
  PaperProps,
  Typography,
  DialogTitle,
  FormControl,
  DialogActions,
  DialogContent,
  OutlinedInput,
  SelectChangeEvent,
} from "@mui/material";
import { Stack } from "@mui/system";
import "./announcementsDialogs.scss";
import Draggable from "react-draggable";
import { useLocation } from "react-router-dom";
import { Add, Close } from "@mui/icons-material";
import CreateNewWheelDialog from "./CreateNewWheelDailog";
import { WheelDesigner, WheelItem } from "../../../types/Wheel";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import { useTranslation } from "react-i18next";
const API_URL = process.env.REACT_APP_BACKEND_URL;
function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper
        {...props}
        style={{
          backgroundColor: "#34393f",
          color: "#D9D9D9",
          padding: "1rem",
        }}
      />
    </Draggable>
  );
}

const Table: FC = () => {
  const [wheelDesignerList, setWheelDesignerList] = useState<
    WheelDesigner[]
  >([]);
  //should be memoized or stable
  const columns = useMemo<MRT_ColumnDef<WheelDesigner>[]>(
    () => [
      {
        accessorKey: "messageCategory",
        header: "Message Category",
      },
      {
        accessorKey: "channel",
        header: "Channel",
      },
      {
        accessorKey: "condition",
        header: "Condition",
      },
      {
        accessorKey: "conditionArgs",
        header: "Condition Args",
      },
      {
        accessorKey: "conditionOperator",
        header: "Condition Operator",
      },
      {
        accessorKey: "previous",
        header: "Previous",
      },
    ],
    []
  );

  //useEffcet Area
  useEffect(() => {
    let token = JSON.parse(localStorage.getItem("token") ?? "{}");
    const fetchWheelDesignerList = async () => {
      const response = await fetch(`${API_URL}/VoiceWheelItem/GetAll`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token?.accessToken}`,
        },
      });
      const data = await response.json();
      setWheelDesignerList(data?.data);
    };
    // Call the function
    fetchWheelDesignerList();
  }, []);

  return (
    <MaterialReactTable
      columns={columns}
      data={wheelDesignerList}
      enableColumnActions={false}
      enableColumnFilters={false}
      enablePagination={false}
      enableSorting={false}
      enableBottomToolbar={false}
      enableTopToolbar={false}
      muiTableBodyRowProps={{ hover: false }}
    />
  );
};

enum PopUpEnum {
  None = 0,
  AddNewWheel = 1,
}
export default function CreateWheelDesignerDialog({
  isOpen,
  handleClose,
}: {
  isOpen: boolean;
  handleClose: MouseEventHandler;
}) {
  const location = useLocation();
  const {t} = useTranslation();
  const [popUp, setPopUp] = React.useState(PopUpEnum.None);
  const handleNewWheel = () => {
    setPopUp(PopUpEnum.AddNewWheel);
  };

  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  const [voiceWheelList, setVoiceWheelList] = useState<WheelItem[]>([]);
  const [selectVoiceWheel, setSelectVoiceWheel] = useState<string>("");

  const fetchVoiceWheelList = useCallback(async () => {
    const response = await fetch(`${API_URL}/VoiceWheel/GetAll`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    });
    const data = await response.json();
    setVoiceWheelList(data?.data);
  },[]
  );

  useEffect(() => {
    if (location?.pathname?.includes("content-library") && isOpen === true) fetchVoiceWheelList();
  }, [fetchVoiceWheelList, location?.pathname,isOpen]);

  const handleVoiceWheelChange = (
    event: SelectChangeEvent<typeof selectVoiceWheel>
  ) => {
    const {
      target: { value },
    } = event;
    setSelectVoiceWheel(value);
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        maxWidth="lg"
        fullWidth
        className="addModelDialog"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          <Typography variant="h5" sx={{ fontWeight: 700 }}>
          {t("main.Wheel_Designer")}
            <Link className="modelCloseBtn" onClick={handleClose}>
              <Close />
            </Link>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Stack
            direction="row"
            justifyContent="flex-start"
            className="modal-top-actions"
          >
            <Button
              size="medium"
              variant="contained"
              color="primary"
              sx={{ mt: 2, mr: 3 }}
              onClick={handleNewWheel}
            >
              <Add style={{ fontSize: "120%", marginRight: "5px" }} />
              {t("main.add_wheel")}
            </Button>
            <Button
              size="medium"
              variant="outlined"
              color="error"
              sx={{
                mt: 2,
                background: "transparent !important",
                border: "2px solid #c66969 !important",
              }}
            >
              <Close style={{ fontSize: "120%", marginRight: "5px" }} />
              {t("main.remove_wheel")}
            </Button>
            <span className="divider">|</span>
            <Button
              size="medium"
              variant="contained"
              color="primary"
              sx={{ mt: 2, mr: 3 }}
            >
              <Add style={{ fontSize: "120%", marginRight: "5px" }} />
              {t("main.add_item")}
            </Button>
            <Button
              size="medium"
              variant="outlined"
              color="error"
              sx={{ mt: 2 }}
            >
              <Close style={{ fontSize: "120%", marginRight: "5px" }} />
              {t("main.remove_item")}
            </Button>
          </Stack>
          <FormControl className="modal-select">
            <Select
              input={<OutlinedInput label="Select Voice Wheel" />}
              fullWidth
              className="selectBox"
              labelId="simple-select-label"
              id="simple-select"
              value={selectVoiceWheel || ""}
              onChange={handleVoiceWheelChange}
              sx={{
                m: 1,
                minWidth: 300,
                height: "34px",
                margin: "20px 0",
                border: "1px solid #e8e8e8 !important",
                color: "#e8e8e8 !importnat",
                borderRadius: "10px",
              }}
            >
              {voiceWheelList?.map((voiceWheel) => (
                <MenuItem value={voiceWheel?.id} key={voiceWheel?.id}>
                  {voiceWheel?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Paper elevation={3} sx={{ p: "1px", mt: 3 }} className="dialogTable">
            <Table></Table>
          </Paper>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "space-around" }}>
          <Button
            size="medium"
            variant="contained"
            color="primary"
            sx={{ mt: 5 }}
          >
            {t("main.save")}
          </Button>
          <Button
            onClick={handleClose}
            size="medium"
            variant="outlined"
            color="secondary"
            sx={{ mt: 5, borderColor: "#fff" }}
          >
          {t("main.cancel")}
          </Button>
        </DialogActions>
        <CreateNewWheelDialog
          isOpen={popUp === PopUpEnum.AddNewWheel}
          handleClose={() => setPopUp(PopUpEnum.None)}
        ></CreateNewWheelDialog>
      </Dialog>
    </>
  );
}

import {
  Grid,
  Paper,
  Button,
  Select,
  useTheme,
  MenuItem,
  Typography,
  FormControl,
  CircularProgress,
} from "@mui/material";
import "./customerData.scss";
import { toast } from "react-toastify";
import MyTextField from "../Basic/MyTextField";
import React, { SetStateAction, useState } from "react";
import { Region, RegionSiteData } from "../../types/Customer";
import { useChannelIdContext } from "../../context/ChannelIdContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
const API_URL = process.env.REACT_APP_BACKEND_URL;
function DataInputRow({
  header,
  value,
  inputChanged,
}: {
  header: string;
  value: string;
  inputChanged: Function;
}) {
  const theme = useTheme();
  return (
    <>
      <Grid
        item
        xs={12}
        xl={4}
        container
        direction="column"
        alignItems="start"
        justifyContent="center"
        style={{ paddingLeft: "16px" }}
      >
        <Typography
          variant="h6"
          sx={{
            color: theme.palette.mode === "dark" ? "#FFFFFF" : "#3c434b",
          }}
        >
          {header}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        xl={8}
        container
        direction="column"
        justifyContent="center"
      >
        <MyTextField
          hiddenLabel
          value={value}
          onChange={(e) => inputChanged(e.target.value)}
          variant="filled"
          size="small"
        ></MyTextField>
      </Grid>
    </>
  );
}

export default function CustomerSite({
  customerSiteData,
  setCustomerSiteData,
  regionByCustomer,
}: {
  customerSiteData: RegionSiteData;
  setCustomerSiteData: React.Dispatch<SetStateAction<RegionSiteData>>;
  regionByCustomer: Region[];
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const { SiteId, setIsTokenExpired, setIsTokenUpdated } =
    useChannelIdContext();
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  const [isOpen, setIsOpen] = useState(false);
  const copyToClipboad = () => {
    const texttocpoy = `siteUniqueId: ${customerSiteData?.siteUniqueId} \n clientSecretKey: ${customerSiteData?.clientSecretKey}`;
    navigator.clipboard.writeText(texttocpoy);
    toast.success(t("main._clientSecretKey_Copyed"));
  };

  const GetRegionByCustomer = async () => {
    setIsOpen(true);
    fetch(`${API_URL}/Site/exportSiteData/${SiteId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
        Authorization: `bearer ${token?.accessToken}`,
      },
    })
      .then((response) => response?.blob())
      .then((blob) => {
        let url = window?.URL?.createObjectURL(blob);
        let a = document?.createElement("a");
        a.href = url;
        a.download = "site.json";
        a.click();
        toast.success(t("main.File_Is_Downloaded"));
        setIsOpen(false);
      });
  };

  const fetchDataBySiteId = async () => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
      body: JSON.stringify({
        id: customerSiteData?.id,
        name: customerSiteData?.name,
        regionId: customerSiteData?.regionId,
        address1: customerSiteData?.address1,
        address2: customerSiteData?.address2,
        address3: customerSiteData?.address3,
        country: customerSiteData?.country,
        zipcode: customerSiteData?.zipcode,
        phoneNumber1: customerSiteData?.phoneNumber1,
        phoneNumber2: customerSiteData?.phoneNumber2,
        phoneNumber3: customerSiteData?.phoneNumber3,
        contactName: customerSiteData?.contactName,
        siteUniqueId: customerSiteData?.siteUniqueId,
        clientSecretKey: customerSiteData?.clientSecretKey,
      }),
    };
    fetch(`${API_URL}/Site/UpdateNewId/${SiteId}`, requestOptions)
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data?.success) setCustomerSiteData(data?.data);
      });
  };

  const UpdateCustomerSiteData = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
      body: JSON.stringify(customerSiteData),
    };
    fetch(`${API_URL}/Site`, requestOptions)
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data?.success === true) {
          toast.success(t("main.Site_Update_Successfully"));
          // setIsUpdated(true);
        } else {
          toast.error(data?.message);
          // setIsUpdated(false);
        }
      });
  };

  return (
    <Paper className="siteDataTab">
      {isOpen ? (
        <div style={{ justifyContent: "center", display: "flex" }}>
          <CircularProgress style={{ marginTop: "15rem" }} />
        </div>
      ) : (
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} md={5}>            
            <Grid
              container
              rowSpacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <DataInputRow
                header={t("main.site_name")}
                value={customerSiteData?.name}
                inputChanged={(value: string) =>
                  setCustomerSiteData({ ...customerSiteData, name: value })
                }
              />
                <Grid
                  // item
                  xs={13}
                  xl={4}
                  container
                  direction="column"
                  alignItems="start"
                  justifyContent="center"
                  sx={{padding:"0"}}
                >
                  <Typography variant="h6" style={{marginLeft:"15px"}}>{t("main.site_group")}</Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  xl={8}
                  container
                  direction="column"
                  className="CustonSelect"
                  justifyContent="center"
                >
                  <FormControl fullWidth>
                    <Select
                      style={{ width: "width: 186%" }}
                      labelId="regionIdList"
                      id="demo-simple-select"
                      className={theme.palette.mode === "dark" ? 'CustonSelectDark' : "CustomSelect"}
                      value={customerSiteData.regionId}
                      sx={{
                        color:
                          theme.palette.mode === "light"
                            ? "#3c434b"
                            : "#3c434b",
                      }}
                      onChange={(e: any) =>
                        setCustomerSiteData({
                          ...customerSiteData,
                          regionId: e.target.value,
                        })
                      }
                    >
                      <li className="menu-item-header">
                        <div className="menuItemId">{t("main.id")}</div>
                        <div className="menuItemName">{t("main.name")}</div>
                      </li>
                      {regionByCustomer?.length > 0 &&
                        regionByCustomer?.map((item: any) => (
                          <MenuItem key={item?.id} value={item?.id}>
                            <div className="menuItemId">{item?.id}</div>
                            <div className="menuItemName"> {item?.name}</div>
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              <DataInputRow
                header={t("main.address_1")}
                value={customerSiteData?.address1}
                inputChanged={(value: string) =>
                  setCustomerSiteData({ ...customerSiteData, address1: value })
                }
              />
              <DataInputRow
                header={t("main.address_2")}
                value={customerSiteData?.address2}
                inputChanged={(value: string) =>
                  setCustomerSiteData({ ...customerSiteData, address2: value })
                }
              />
              <DataInputRow
                header={t("main.address_2")}
                value={customerSiteData?.address3}
                inputChanged={(value: string) =>
                  setCustomerSiteData({ ...customerSiteData, address3: value })
                }
              />
              <DataInputRow
                header={t("main.country")}
                value={customerSiteData?.country}
                inputChanged={(value: string) =>
                  setCustomerSiteData({ ...customerSiteData, country: value })
                }
              />
              <DataInputRow
                header={t("main.post/area_Code")}
                value={customerSiteData?.zipcode}
                inputChanged={(value: string) =>
                  setCustomerSiteData({ ...customerSiteData, zipcode: value })
                }
              />
              <DataInputRow
                header={t("main.phone_nummer_1")}
                value={customerSiteData?.phoneNumber1}
                inputChanged={(value: string) =>
                  setCustomerSiteData({
                    ...customerSiteData,
                    phoneNumber1: value,
                  })
                }
              />
              <DataInputRow
                header={t("main.phone_nummer_2")}
                value={customerSiteData?.phoneNumber2}
                inputChanged={(value: string) =>
                  setCustomerSiteData({
                    ...customerSiteData,
                    phoneNumber2: value,
                  })
                }
              />
              <DataInputRow
                header={t("main.phone_nummer_3")}
                value={customerSiteData?.phoneNumber3}
                inputChanged={(value: string) =>
                  setCustomerSiteData({
                    ...customerSiteData,
                    phoneNumber3: value,
                  })
                }
              />
              <DataInputRow
                header={t("main.contact_name")}
                value={customerSiteData?.contactName}
                inputChanged={(value: string) =>
                  setCustomerSiteData({
                    ...customerSiteData,
                    contactName: value,
                  })
                }
              />
              <DataInputRow
                header={t("main.site_id")}
                value={customerSiteData?.siteUniqueId}
                inputChanged={(value: string) =>
                  setCustomerSiteData({
                    ...customerSiteData,
                    siteUniqueId: value,
                  })
                }
              />
              <DataInputRow
                header={t("main.client_secret_key")}
                value={customerSiteData?.clientSecretKey}
                inputChanged={(value: string) =>
                  setCustomerSiteData({
                    ...customerSiteData,
                    clientSecretKey: value,
                  })
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              xl={4}
              container
              direction="column"
              alignItems="start"
              justifyContent="center"
            ></Grid>
            <Grid
              item
              xs={12}
              xl={8}
              container
              direction="column"
              justifyContent="space-between"
              marginLeft="auto"
            >
              <Grid item xs={12} sm={12} md={12} className="btn-action-wrapper">
                <Button className="action-btn" onClick={copyToClipboad}>
                  {t("main.copy_ID_&_key")}
                </Button>
                <Button className="action-btn" onClick={UpdateCustomerSiteData}>
                  {t("main.save")}
                </Button>
                <Button className="action-btn" onClick={fetchDataBySiteId}>
                  {t("main.Generate_new")}
                </Button>
              </Grid>
              <div style={{ textAlign: "center" }}>
                <Button
                  className="action-btn-export"
                  onClick={GetRegionByCustomer}
                >
                  {t("main.export_BMS_v6_database")}
                </Button>
              </div>
              
            </Grid>
          </Grid>
        </Grid>
      )}
    </Paper>
  );
}

import {
  Link,
  Paper,
  Button,
  Dialog,
  PaperProps,
  Typography,
  DialogActions,
} from "@mui/material";
import Draggable from "react-draggable";
import { toast } from "react-toastify";
import React, { MouseEventHandler } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useChannelIdContext } from "../../../context/ChannelIdContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
const API_URL = process.env.REACT_APP_BACKEND_URL;
function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper
        {...props}
        style={{
          backgroundColor: "#34393f",
          color: "#D9D9D9",
          padding: "1rem",
        }}
      />
    </Draggable>
  );
}

export default function DeleteSongConfirmDialog({
  isOpen,
  handleClose,
}: {
  isOpen: boolean;
  handleClose: any | MouseEventHandler;
}) {
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  const {
    songId,
    setSongId,
    channelId,
    setChannelId,
    setRowSelection,
    setIsTokenExpired,
    setIsTokenUpdated,
    setisDeleted
  } = useChannelIdContext();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const deleteSongsByChannel = async (e: any) => {
    e.preventDefault();
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
      body: JSON.stringify({
        channelsId: channelId,
        songsId: songId,
      }),
    };
    fetch(`${API_URL}/Channel/ChannelSongs`, requestOptions)
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data?.success === true) {
          setisDeleted(true)
          handleClose();
          const preChannelId = channelId;
          setChannelId(0);
          setRowSelection({});
          toast.success(t("main.Song_Deleted_Successfully"));
          setTimeout(() => setChannelId(preChannelId), 100);
        } else {
          toast.error(data?.message);
        }
        setSongId([]);
      });
  };
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={isOpen}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      className="deleteChannelListDialog"
      aria-labelledby="draggable-dialog-title"
    >
      <div className="dialogForm">
        <form>
          <Typography variant="h5" className="addModel-title">
            {t("main.Are_you_sure_to_delete_this_song")}
            <Link onClick={handleClose} className="modelCloseBtn">
              <CloseIcon />
            </Link>
          </Typography>
          <DialogActions sx={{ display: "flex" }}>
            <Button
              type="submit"
              size="medium"
              variant="contained"
              color="primary"
              sx={{ mt: 5 }}
              onClick={deleteSongsByChannel}
            >
              {t("main.Confirm")}
            </Button>
            <Button
              size="medium"
              variant="outlined"
              color="primary"
              sx={{ mt: 5 }}
              onClick={() => {
                setSongId([]);
                handleClose();
              }}
            >
              {t("main.cancel")}
            </Button>
          </DialogActions>
        </form>
      </div>
    </Dialog>
  );
}

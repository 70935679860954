import * as React from "react";
import MyTextField from "../Basic/MyTextField";

export interface ITextControlProps {
  header: string;
  value: string;
  inputChanged: Function;
}

export function TextControl(props: ITextControlProps) {
  return (
    <MyTextField
      hiddenLabel
      value={props?.value}
      onChange={(e) => props.inputChanged(e.target.value)}
      variant="filled"
      sx={{ width: 630 }}
      size="small"
    />
  );
}

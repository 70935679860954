/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Link,
  Paper,
  Button,
  Dialog,
  PaperProps,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { Stack } from "@mui/system";
import "./announcementsDialogs.scss";
import Draggable from "react-draggable";
import { useLocation, useNavigate } from "react-router-dom";
import { Add, Close } from "@mui/icons-material";
import { VT_Category } from "../../../types/VoiceTrack";
import CreateNewAnnouncementDialog from "./CreateNewAnnouncementDialog";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import React, { FC, MouseEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useChannelIdContext } from "../../../context/ChannelIdContext";
const API_URL = process.env.REACT_APP_BACKEND_URL;
function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper
        {...props}
        style={{
          backgroundColor: "#34393f",
          color: "#D9D9D9",
          padding: "1rem",
        }}
      />
    </Draggable>
  );
}

const Table: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { setIsTokenExpired, setIsTokenUpdated, IsTokenUpdated } =
    useChannelIdContext();
  const [voiceTrackCategoryList, setVoiceTrackCategoryList] = useState<
    VT_Category[]
  >([]);

  const fetchVoiceTrackCategoriesList = async () => {
    let token = JSON.parse(localStorage.getItem("token") ?? "{}");
    fetch(`${API_URL}/VoiceTrackCategory/GetAll`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    })
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data.success === true) {
          setVoiceTrackCategoryList(data?.data);
        } else {
          setVoiceTrackCategoryList([]);
        }
      });
  };

  const columns: MRT_ColumnDef<VT_Category>[] = [
    {
      accessorKey: "name",
      header: `${t("main.title")}`,
    },
    {
      accessorKey: "volumeOffset",
      header: `${t("main.volume_offset")}`,
    },
  ];

  useEffect(() => {
    if (location?.pathname?.includes("content-library"))
      fetchVoiceTrackCategoriesList();
  }, [location?.pathname, IsTokenUpdated]);

  return (
    <MaterialReactTable
      columns={columns}
      enableSorting={false}
      enablePagination={false}
      enableTopToolbar={false}
      enableColumnActions={false}
      enableBottomToolbar={false}
      enableColumnFilters={false}
      data={voiceTrackCategoryList}
      muiTableBodyRowProps={{ hover: false }}
    />
  );
};

enum PopUpEnum {
  None = 0,
  AddNewAnnouncement = 1,
}

export default function CreateCatalogDialog({
  isOpen,
  handleClose,
}: {
  isOpen: boolean;
  handleClose: MouseEventHandler;
}) {
  const { t } = useTranslation();
  const [popUp, setPopUp] = useState(PopUpEnum.None);

  const handleNewAnnouncement = () => {
    setPopUp(PopUpEnum.AddNewAnnouncement); // AddNewAnnouncement
  };

  return (
    <Dialog
      fullWidth
      open={isOpen}
      maxWidth="md"
      onClose={handleClose}
      className="addModelDialog"
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
        <Typography
          variant="h5"
          className="addModelDialog-title"
          sx={{ fontWeight: 700 }}
        >
          {t("main.Announcement_Categories")}
          <Link className="modelCloseBtn" onClick={handleClose}>
            <Close />
          </Link>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Stack
          direction="row"
          justifyContent="flex-start"
          className="modal-top-actions"
        >
          <Button
            size="medium"
            variant="contained"
            color="primary"
            sx={{ mt: 2, mr: 3 }}
            onClick={handleNewAnnouncement}
          >
            <Add style={{ fontSize: "120%", marginRight: "5px" }} />
            {t("main.Add")}
          </Button>
          <Button
            size="medium"
            variant="contained"
            color="error"
            sx={{ mt: 2 }}
          >
            <Close style={{ fontSize: "120%", marginRight: "5px" }} />
            {t("main.Remove_btn")}
          </Button>
        </Stack>
        <Paper elevation={3} sx={{ p: "1px", mt: 3 }} className="dialogTable">
          <Table></Table>
        </Paper>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-around" }}>
        <Button
          size="medium"
          variant="contained"
          color="primary"
          sx={{ mt: 5 }}
        >
          {t("main.save")}
        </Button>
        <Button
          onClick={handleClose}
          size="medium"
          variant="outlined"
          color="secondary"
          sx={{ mt: 5 }}
        >
          {t("main.cancel")}
        </Button>
      </DialogActions>
      <CreateNewAnnouncementDialog
        isOpen={popUp === PopUpEnum.AddNewAnnouncement}
        handleClose={() => setPopUp(PopUpEnum.None)}
      />
    </Dialog>
  );
}

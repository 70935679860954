import {
  Box,
  Grid,
  useTheme,
  Accordion,
  Typography,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
} from "@mui/material";
import ViewTimeofDay from "./ViewTimeOfDay";
import { songList } from "../../types/Audio";
import ViewDaysofWeek from "./ViewDaysofWeek";
import AudioExactDate from "./AudioExactDate";
import { ExpandMore } from "@mui/icons-material";
import React, { SetStateAction, useState } from "react";
import SeasonalityTimeOfDay from "./SeasonalityTimeOfDay";
import { useTranslation } from "react-i18next";

export default function Restrictions({
  isLoading,
  audioData,
  setAudioData,
}: {
  isLoading: boolean;
  audioData: songList;
  setAudioData: React.Dispatch<SetStateAction<songList>>;
}) {
  const [expanded, setExpanded] = useState<{
    files: boolean;
    weeksday: boolean;
    metadata: boolean;
    timeofday: boolean;
    audiowawe: boolean;
    seasonality: boolean;
  }>({
    files: true,
    weeksday: true,
    metadata: true,
    timeofday: true,
    audiowawe: true,
    seasonality: true,
  });
  let theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box className="tabsPage">
      {isLoading ? (
        <Grid
          container
          rowSpacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 5, xl: 8 }}
        >
          <Grid item xs={12} lg={6}>
            <Accordion
              className="SongDataEditor"
              expanded={expanded?.metadata}
              onChange={(e, isExpanded) =>
                setExpanded({ ...expanded, metadata: isExpanded ?? false })
              }
              elevation={5}
            >
              <AccordionSummary
                className="AccordionTitle"
                expandIcon={<ExpandMore />}
                aria-controls="panel1bh-content"
                style={{
                  backgroundColor:
                    theme.palette.mode === "dark" ? "#151515" : "#d9d9d9",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ flexShrink: 0, fontWeight: 600, letterSpacing: 1, color:theme.palette.mode === "dark" ? "#fff" : "#000" }}
                >
                  {t("main.Exact_Dates")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ margin: 5, marginTop: 20 }}>
                <AudioExactDate
                  audio={audioData}
                  setAudioData={setAudioData}
                ></AudioExactDate>
              </AccordionDetails>
            </Accordion>
            <Box mt={"20px"}>
              <Grid container rowSpacing={2} columnSpacing={{ xs: 3 }}>
                <Grid item xs={12} lg={6}>
                  <Accordion
                    className="SongDataEditor"
                    style={{ marginRight: 3 }}
                    expanded={expanded?.timeofday}
                    onChange={(e, isExpanded) =>
                      setExpanded({
                        ...expanded,
                        timeofday: isExpanded ?? false,
                      })
                    }
                    elevation={5}
                  >
                    <AccordionSummary
                      className="AccordionTitle"
                      expandIcon={<ExpandMore />}
                      aria-controls="panel2bh-content"
                      style={{
                        backgroundColor:
                          theme.palette.mode === "dark" ? "#151515" : "#d9d9d9",
                      }}
                    >
                      <Typography
                        variant="h5"
                        sx={{
                          flexShrink: 0,
                          fontWeight: 600,
                          letterSpacing: 1,
                        }}
                      >
                        {t("main.Time_of_Day")}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ margin: 5, marginTop: 20 }}>
                      <ViewTimeofDay
                        audio={audioData}
                        setAudioData={setAudioData}
                      ></ViewTimeofDay>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Accordion
                    className="SongDataEditor"
                    style={{ marginLeft: 3 }}
                    expanded={expanded?.seasonality}
                    onChange={(e, isExpanded) =>
                      setExpanded({
                        ...expanded,
                        seasonality: isExpanded ?? false,
                      })
                    }
                    elevation={5}
                  >
                    <AccordionSummary
                      className="AccordionTitle"
                      expandIcon={<ExpandMore />}
                      aria-controls="panel2bh-content"
                      style={{
                        backgroundColor:
                          theme.palette.mode === "dark" ? "#151515" : "#d9d9d9",
                      }}
                    >
                      <Typography
                        variant="h5"
                        sx={{
                          flexShrink: 0,
                          fontWeight: 600,
                          letterSpacing: 1,
                        }}
                      >
                        {t("main.Seasonality")}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ margin: 5, marginTop: 20 }}>
                      <SeasonalityTimeOfDay
                        audio={audioData}
                        setAudioData={setAudioData}
                      ></SeasonalityTimeOfDay>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Accordion
              expanded={expanded?.weeksday}
              onChange={(e, isExpanded) =>
                setExpanded({ ...expanded, weeksday: isExpanded ?? false })
              }
              elevation={5}
            >
              <AccordionSummary
                className="AccordionTitle"
                expandIcon={<ExpandMore />}
                aria-controls="panel1bh-content"
                style={{
                  backgroundColor:
                    theme.palette.mode === "dark" ? "#151515" : "#d9d9d9",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ flexShrink: 0, fontWeight: 600, letterSpacing: 1 }}
                >
                  {t("main.Week_Days")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ margin: 5, marginTop: 20 }}>
                <ViewDaysofWeek
                  audio={audioData}
                  setAudioData={setAudioData}
                ></ViewDaysofWeek>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      ) : (
        <div style={{ justifyContent: "center", display: "flex" }}>
        <CircularProgress style={{ marginTop: "15rem" }} />
      </div>
      )}
    </Box>
  );
}

import {
  Box,
  alpha,
  Stack,
  Avatar,
  Divider,
  Popover,
  MenuItem,
  Typography,
  ButtonBase,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ThemeTogglerButton from "../../context/ThemeContext";

const accountInfo = {
  displayName: "Burak Rüzgar",
  email: "demo@gmail.com",
  photoURL: "/nouser.png",
};

export default function AccountPopover() {
  const [open, setOpen] = useState<any>(null);
  const navigate = useNavigate();
  const handleOpen = (element: HTMLButtonElement) => {
    setOpen(element);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <ButtonBase
        onClick={(ev) => handleOpen(ev.currentTarget)}
        sx={{
          p: 0,
          width: "100%",
          ...(open !== null && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <Avatar src={accountInfo.photoURL} alt="" />
          <Stack direction="column" pl={2}>
            <Typography variant="h6" component="h6">
              {accountInfo.displayName}
            </Typography>
            <Typography
              variant="caption"
              display="block"
              sx={{ color: "text.disabled" }}
            >
              {accountInfo.email}
            </Typography>
          </Stack>
        </Stack>
      </ButtonBase>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            "& .MuiMenuItem-root": {
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {accountInfo.displayName}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {accountInfo.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Stack sx={{ p: 1 }}>
          <MenuItem onClick={() => navigate("/home")}>Home</MenuItem>
          <MenuItem onClick={() => navigate("/profile")}>Profile</MenuItem>
          <MenuItem>
            <ThemeTogglerButton>
              <span>Change Theme</span>
            </ThemeTogglerButton>
          </MenuItem>
          <MenuItem onClick={() => navigate("/settings")}>Settings</MenuItem>
        </Stack>

        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem onClick={handleClose} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </Popover>
    </>
  );
}

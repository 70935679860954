import {
  Link,
  Paper,
  Button,
  Dialog,
  TextField,
  Typography,
  PaperProps,
  DialogTitle,
  FormControl,
  DialogActions,
  DialogContent,
  useTheme,
} from "@mui/material";
import "./announcementsDialogs.scss";
import Draggable from "react-draggable";
import React, { MouseEventHandler } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

function PaperComponent(props: PaperProps) {
  const theme = useTheme();
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper
        {...props}
        style={{
          backgroundColor: theme.palette.mode === "dark" ? "#000" : "#34393f",
          color: "#D9D9D9",
          padding: "1rem",
        }}
      />
    </Draggable>
  );
}

export default function CreateNewWheelDialog({
  isOpen,
  handleClose,
}: {
  isOpen: boolean;
  handleClose: MouseEventHandler;
}) {
  const { t } = useTranslation();

  return (
    <Dialog
      fullWidth
      open={isOpen}
      maxWidth="lg"
      onClose={handleClose}
      PaperComponent={PaperComponent}
      className="addNewwheelDialog"
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
        <Typography variant="h5" sx={{ fontWeight: 700 }}>
          {t("main.new_wheel_designer")}
          <Link className="modelCloseBtn" onClick={handleClose}>
            <CloseIcon />
          </Link>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <FormControl className="modal-new-wheel-input">
          <TextField sx={{ width: 350 }} label="Title" id="newWheelTitle" />
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-around" }}>
        <Button
          size="medium"
          variant="contained"
          color="primary"
          sx={{ mt: 5 }}
        >
          {t("main.save")}
        </Button>
        <Button
          onClick={handleClose}
          size="medium"
          variant="outlined"
          color="secondary"
          sx={{ mt: 5, borderColor: "#fff" }}
        >
          {t("main.cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

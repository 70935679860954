/* eslint-disable react-hooks/exhaustive-deps */
import {
  Grid,
  Box,
  List,
  Checkbox,
  useTheme,
  Accordion,
  Typography,
  ListItemText,
  OutlinedInput,
  ListItemButton,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import "./channel.scss";
import { toast } from "react-toastify";
import { Attribute } from "../../../types/Audio";
import { ChannelData } from "../../../types/Channel";
import React, { useEffect, useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useChannelIdContext } from "../../../context/ChannelIdContext";
import { Category_Attributes, Category_List } from "../../../types/Category";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const API_URL = process.env.REACT_APP_BACKEND_URL;
export default function ChannelCharacteristic({
  channelData,
  setAttributeId,
  setChannelData,
}: {
  channelData: ChannelData;
  setChannelData: (state: ChannelData) => void;
  setAttributeId: React.Dispatch<React.SetStateAction<number[]>>;
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  let navigate = useNavigate();
  const { channelId, setIsTokenExpired, setIsTokenUpdated, IsTokenUpdated } =
    useChannelIdContext();
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  const [categoryList, setCategoryList] = useState<Category_List[]>([]);
  const [selectedAttributes, setSelectedAttributes] = useState<Attribute[]>([]);

  const fetchCategoriesList = async () => {
    let token = JSON.parse(localStorage.getItem("token") ?? "{}");
    fetch(`${API_URL}/Category/GetAll`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    })
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data.success === true) {
          setCategoryList(data?.data);
        } else {
          setCategoryList([]);
        }
      });
  };

  const handleAttributeSelection = (attribute: Category_Attributes) => {
    const isSelected = selectedAttributes?.some(
      (attr) => attr.id === attribute.id
    );
    if (isSelected) {
      setSelectedAttributes(
        selectedAttributes?.filter((attr) => attr?.id !== attribute?.id)
      );
    } else {
      setSelectedAttributes([...selectedAttributes, attribute]);
    }
  };

  const isAttributeSelected = (attribute: Category_Attributes) => {
    return selectedAttributes?.some((attr) => attr.id === attribute.id);
  };
  useEffect(() => {
    fetchCategoriesList();
  }, [IsTokenUpdated]);

  useEffect(() => {
    if (channelId !== undefined) {
      const fetchData = async () => {
        fetch(`${API_URL}/Channel/${channelId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token?.accessToken}`,
          },
        })
          .then((response) => {
            if (
              response.ok === false &&
              response.status === 401 &&
              token?.refreshToken !== ""
            ) {
              setIsTokenExpired(true);
            } else if (response.status === 401 && token?.refreshToken === "") {
              navigate("/");
              localStorage.removeItem("token");
              localStorage.clear();
            } else {
              setIsTokenUpdated(false);
              return response.json();
            }
          })
          .then((data) => {
            if (data.success === true || data?.data?.attributes?.length !== 0) {
              setSelectedAttributes(data?.data?.attributes);
              setChannelData(data?.data);
            } else {
              toast.error(data?.message);
            }
          });
      };
      fetchData();
    }
  }, [channelId, setChannelData, IsTokenUpdated]);

  useEffect(() => {
    setAttributeId(selectedAttributes?.map((attr) => attr.id));
  }, [selectedAttributes, setAttributeId]);

  return (
    <Box padding={"24px"} className="tabsPage tabsPage">
      <Grid container direction="row" spacing={{ xs: 2, sm: 4, md: 4 }}>
        {categoryList?.map((category) => (
          <Grid item key={category?.id} xs={12} md={6} lg={3} xl={3}>
            <Accordion
              expanded={true}
              elevation={5}
              sx={{ width: "100%", minHeight: "50vh" }}
            >
              <AccordionSummary
                style={{
                  backgroundColor:
                    theme.palette.mode === "dark" ? "#151515" : "#D9D9D9",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    flexShrink: 0,
                    fontWeight: 600,
                    letterSpacing: 1,
                  }}
                >
                  {category?.name}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: 0 }}>
                <List
                  component="div"
                  role="list"
                  className="atributeList"
                  style={{ padding: 0 }}
                >
                  {category?.attributes.length !== 0 &&
                    category?.attributes?.map((attribute: any) => {
                      const labelId = `transfer-list-item-${attribute?.id}-label`;
                      return (
                        <ListItemButton
                          key={attribute?.id}
                          role="checkbox"
                          onClick={() => handleAttributeSelection(attribute)}
                          className={
                            isAttributeSelected(attribute)
                              ? "atributeSelected"
                              : ""
                          }
                          sx={{
                            bgcolor: isAttributeSelected(attribute)
                              ? "#3B5C84"
                              : "#fff",
                          }}
                        >
                          <ListItemText
                            id={labelId}
                            primary={attribute?.name}
                          />
                        </ListItemButton>
                      );
                    })}
                </List>
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))}
      </Grid>
      <div className="channel-break-container">
        <div className="channel-break">
          <FormControlLabel
            control={
              <Checkbox
                style={{ width: "82" }}
                checked={channelData?.attribute1Breakable}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setChannelData({
                    ...channelData,
                    attribute1Breakable: event.target.checked,
                  })
                }
              />
            }
            label={t("main.breakable?")}
          />
          <Typography className="Max">{t("main.max_in_row")}</Typography>
          <OutlinedInput
            className="number-input"
            size="small"
            value={channelData?.attribute1Amount}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setChannelData({
                ...channelData,
                attribute1Amount: event.target.value,
              })
            }
            inputProps={{
              step: 1,
              min: 0,
              max: 100,
              type: "number",
            }}
          />
        </div>
        <div className="channel-break">
          <FormControlLabel
            control={
              <Checkbox
                checked={channelData?.attribute2Breakable}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setChannelData({
                    ...channelData,
                    attribute2Breakable: event.target.checked,
                  })
                }
              />
            }
            label={t("main.breakable?")}
          />
          <Typography className="Max">{t("main.max_in_row")}</Typography>
          <OutlinedInput
            size="small"
            value={channelData?.attribute2Amount}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setChannelData({
                ...channelData,
                attribute2Amount: event.target.value,
              })
            }
            inputProps={{
              step: 1,
              min: 0,
              max: 100,
              type: "number",
            }}
          />
        </div>
        <div className="channel-break">
          <FormControlLabel
            control={
              <Checkbox
                checked={channelData?.attribute3Breakable}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setChannelData({
                    ...channelData,
                    attribute3Breakable: event.target.checked,
                  })
                }
              />
            }
            label={t("main.breakable?")}
          />
          <Typography className="Max">{t("main.max_in_row")}</Typography>
          <OutlinedInput
            size="small"
            value={channelData?.attribute3Amount}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setChannelData({
                ...channelData,
                attribute3Amount: event.target.value,
              })
            }
            inputProps={{
              step: 1,
              min: 0,
              max: 100,
              type: "number",
            }}
          />
        </div>
        <div className="channel-break">
          <FormControlLabel
            control={
              <Checkbox
                checked={channelData?.attribute4Breakable}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setChannelData({
                    ...channelData,
                    attribute4Breakable: event.target.checked,
                  })
                }
              />
            }
            label={t("main.breakable?")}
          />
          <Typography className="Max">{t("main.max_in_row")}</Typography>
          <OutlinedInput
            size="small"
            value={channelData?.attribute4Amount}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setChannelData({
                ...channelData,
                attribute4Amount: event.target.value,
              })
            }
            inputProps={{
              step: 1,
              min: 0,
              max: 100,
              type: "number",
            }}
          />
        </div>
      </div>
    </Box>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import MaterialReactTable, { type MRT_ColumnDef } from "material-react-table";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useChannelIdContext } from "../../context/ChannelIdContext";
import { DistributionLog } from "../../types/VoiceTrack";
import "../Audio/AudioStyles.scss";

const API_URL = process.env.REACT_APP_BACKEND_URL;

const VoicetrackDistributionTab = () => {
  const { id } = useParams();
  const {t} = useTranslation();
  let location = useLocation();
  let navigate = useNavigate();
  const { setIsTokenExpired, setIsTokenUpdated, IsTokenUpdated } = useChannelIdContext()
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  const [songHistoryLog, setSongHistoryLog] = useState<DistributionLog[]>([]);
  const [voiceTrackLog, setVoiceTrackLog] = useState<DistributionLog[]>([]);
  const isLocation = (location.pathname.includes('music') || location.pathname.includes('channel'))

  const columns = useMemo<MRT_ColumnDef<DistributionLog>[]>(
    () => [
      {
        accessorKey: "customerName",
        header: `${t("main.customer")}`,
      },
      {
        accessorKey: "siteName",
        header: `${t("main.name_site")}`,
      },
    ],
    [t]
  );
  useEffect(() => {
    const fetchSongListByChannel = async () => {
      fetch(
        `${API_URL}/Distribution/GetAll?songId=${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token?.accessToken}`,
          },
        }
      )
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true)
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false)
          return response.json()
        }
      })
      .then((data) => {
        if (data.success === true) {
          setSongHistoryLog(data?.data);
        } else {
          setSongHistoryLog([]);
        }
      })
    };
    fetchSongListByChannel();
  }, [id, IsTokenUpdated]);

  useEffect(() => {
    const fetchVoiceTrackByChannel = async () => {
      fetch(
        `${API_URL}/Distribution/VoiceTrack/GetAll?voiceTrackId=${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token?.accessToken}`,
          },
        }
      )
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true)
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false)
          return response.json()
        }
      })
      .then((data) => {
        if (data.success === true) {
          setVoiceTrackLog(data?.data);
        } else {
          setVoiceTrackLog([]);
        }
      })
    };
    fetchVoiceTrackByChannel();
  }, [id, IsTokenUpdated]);

  return (
    <div className="ChannelAssTable">       
      <MaterialReactTable
        columns={columns}
        data={isLocation ? songHistoryLog : voiceTrackLog}
        enableColumnActions={false}
        enableColumnFilters={false}
        enablePagination={true}
        enableStickyHeader={true}
        enablePinning={true}
        enableSorting={true}
        enableRowActions={false}
        enableColumnOrdering={true}
        enableTopToolbar={false}
        muiTableBodyRowProps={{ hover: false }}
        muiTableBodyProps={{
          sx: {
            "& tr:nth-of-type(even)": {
              backgroundColor: "#F0F4F6",
            },
          },
        }}
      />
    </div>
  );
};

export default VoicetrackDistributionTab;

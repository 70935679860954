import {
  Box,
  Grid,
  Link,
  Theme,
  Avatar,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { LoginOperation } from "../../pages/LoginPage";
import MailLockOutlinedIcon from "@mui/icons-material/MailLockOutlined";

const tbStyle = (theme: Theme) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#000000ad" : "#ffffffad",
  borderRadius: "4px",
  padding: "0px",
});

export default function ContactNow({
  changeOperationHandler,
}: {
  changeOperationHandler: Function;
}) {
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    return data;
  };

  return (
    <>
      <Avatar sx={{ m: 1, bgcolor: "warning.main" }}>
        <MailLockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Jetzt Kontact Aufnehmen
      </Typography>
      <Box
        component="form"
        onSubmit={(e) => handleSubmit(e)}
        noValidate
        sx={{ mt: 1 }}
      >
        <TextField
          margin="normal"
          required
          style={{ width: 350 }}
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          sx={tbStyle}
        />
        <Button
          type="submit"
          variant="contained"
          size="large"
          sx={{ mt: 3, mb: 2, width: 350 }}
        >
          Reset Password
        </Button>
        <Grid container>
          <Grid container justifyContent="flex-end">
            <Link
              onClick={() => changeOperationHandler(LoginOperation.SignIn)}
              variant="body2"
              sx={{ cursor: "pointer" }}
            >
              Remember Password?
            </Link>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

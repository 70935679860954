/* eslint-disable react-hooks/exhaustive-deps */
import {
  Table,
  Paper,
  TableRow,
  TableBody,
  TableCell,
  TextField,
  TableHead,
  Typography,
  TableContainer,
} from "@mui/material";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers";
import { NewData, Song, SongData } from "../../types/Customer";
import React, { useEffect, useState, useCallback } from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useChannelIdContext } from "../../context/ChannelIdContext";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useTranslation } from "react-i18next";

const API_URL = process.env.REACT_APP_BACKEND_URL;

const CustomerSongHistoryTable = ({
  rowData,
  SelectedRowSongId,
}: {
  rowData: Song | null;
  SelectedRowSongId: number;
}) => {
  const { t } = useTranslation();
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  const [songsWithPlayDate, setSongsWithPlayDate] = useState<{
    loaded: boolean;
    playedSong: SongData[];
  }>({ loaded: false, playedSong: [] });

  const { SiteId } = useChannelIdContext();
  const [rangeEndDate, setRangeEndDate] = useState<any>(null);
  const [rangeStartDate, setRangeStartDate] = useState<any>(null);
  const [playedSongHistory, setPlayedSongHistory] = useState<NewData[]>([]);

  const adjustTimezoneOffset = (date: moment.MomentInput) => {
    const timezoneOffset = moment().utcOffset();
    return moment(date)?.add(timezoneOffset, "minutes").toISOString();
  };

  const handleRangeStartDateChange = (value: moment.MomentInput) => {
    const adjustedDate = adjustTimezoneOffset(value);
    setRangeStartDate(adjustedDate);
  };

  const handleRangeEndDateChange = (value: moment.MomentInput) => {
    const adjustedDate = adjustTimezoneOffset(value);
    setRangeEndDate(adjustedDate);
  };
  const GetPlayedSongHistoryById = useCallback(async () => {
    const response = await fetch(
      `${API_URL}/Site/GetPlayedSongHistoryById/${SelectedRowSongId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token?.accessToken}`,
        },
      }
    );
    const data = await response.json();
    console.log("Fetched")
    if (data?.success === true) {
      if (rangeStartDate === null && rangeEndDate === null) {
        console.log("No Dates")
        setSongsWithPlayDate({ loaded: true, playedSong: data.data });
      } else {        
        const filteredData: SongData[] = data?.data?.filter((song: any) => {
          const songDate = moment(song?.date).format("YYYY-MM-DD");
          console.log(songDate)
          if (rangeStartDate !== null && rangeEndDate !== null) {
            // Filter between start and end dates
            const startDate = moment(rangeStartDate).format("YYYY-MM-DD");
            const endDate = moment(rangeEndDate).format("YYYY-MM-DD");
            
            return moment(songDate).isBetween(startDate, endDate, null, "[]");
          } else if (rangeStartDate !== null) {
            // Filter based on start date only
            const startDate = moment(rangeStartDate).format("YYYY-MM-DD");
            return moment(songDate).isSame(startDate, "day");
          } else if (rangeEndDate !== null) {
            // Filter based on end date only
            const endDate = moment(rangeEndDate).format("YYYY-MM-DD");
            return moment(songDate).isSame(endDate, "day");
          }
          return true;
        });
        setSongsWithPlayDate({ loaded: true, playedSong: filteredData });
      }
    } else {
      setSongsWithPlayDate({ loaded: true, playedSong: [] });
    }
  }, [SelectedRowSongId, rangeEndDate, rangeStartDate]);
  useEffect(() => {
    if (SelectedRowSongId !== 0) {
      GetPlayedSongHistoryById();
    }
    if (SelectedRowSongId === 0) {
      setSongsWithPlayDate({ loaded: true, playedSong: [] });
    }
  }, [
    GetPlayedSongHistoryById,
    SelectedRowSongId,
    rangeStartDate,
    rangeEndDate,
  ]);

  useEffect(() => {
    setRangeStartDate(null);
    setRangeEndDate(null);
  }, [SelectedRowSongId]);
  const newdata: NewData[] = [];
  const numberToWordMap: { [key: number]: string } = {
    1: "one",
    2: "two",
    3: "three",
    4: "four",
    5: "five",
    6: "six",
    7: "seven",
    8: "eight",
    9: "nine",
    10: "ten",
    11: "eleven",
    12: "twelve",
    13: "thirteen",
    14: "fourteen",
    15: "fifteen",
    16: "sixteen",
    17: "seventeen",
    18: "eighteen",
    19: "nineteen",
    20: "twenty",
    21: "twenty-one",
    22: "twenty-two",
    23: "twenty-three",
  };
  // Create an array of hours from 0 to 23
  const hoursArray: string[] = Array.from({ length: 23 }, (_, i) =>
    (i + 1).toString().padStart(2, "0")
  );
  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    songsWithPlayDate.playedSong?.map((item) => {
      const existingItem = newdata?.find(
        (data) => data?.songId === item.songId && data?.date === item?.date
      );

      if (existingItem) {
        for (const playTime of item?.playTime) {
          const time = playTime?.time;
          const hourIndex = hoursArray?.findIndex(
            (hour) => hour === time?.slice(0, 2)
          );
          if (hourIndex !== -1) {
            const hourKey = numberToWordMap[hourIndex + 1];
            existingItem.playTime[hourIndex][hourKey] = time;
          }
        }
      } else {
        const newEntry: NewData = {
          songId: item?.songId,
          date: item?.date,
          playTime: hoursArray?.map((hour) => ({
            [numberToWordMap[Number(hour)]]: "",
          })),
        };

        for (const playTime of item?.playTime) {
          const time = playTime?.time;
          const hourIndex = hoursArray?.findIndex(
            (hour) => hour === time?.slice(0, 2)
          );
          if (hourIndex !== -1) {
            const hourKey = numberToWordMap[hourIndex + 1];
            newEntry.playTime[hourIndex][hourKey] = time;
          }
        }
        newdata.push(newEntry);
      }
    });
    setPlayedSongHistory(newdata);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [songsWithPlayDate.playedSong, SelectedRowSongId]);

  useEffect(() => {
    if (SiteId === 0) {
      setPlayedSongHistory([]);
    }
  }, [SiteId]);
  return (
    <div>
      <div className="songHistory" style={{ backgroundColor: "#3C434B" }}>
        <div>
          <Typography variant="h6" fontSize={14}>
            {t("main.From")}
          </Typography>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label={""}
              inputFormat="DD.MM.YYYY"
              value={rangeStartDate}
              onChange={handleRangeStartDateChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>
        <div>
          <Typography variant="h6" fontSize={14}>
            {t("main.To")}
          </Typography>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label={""}
              inputFormat="DD.MM.YYYY"
              value={rangeEndDate}
              onChange={handleRangeEndDateChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>
        {rowData !== null && (
          <>
            <div>
              <Typography variant="h6" fontSize={14}>
                {t("main.title")} :
              </Typography>
              <Typography variant="h6" fontSize={14}>
                {rowData?.title}
              </Typography>
            </div>
            <div>
              <Typography variant="h6" fontSize={14}>
                {t("main.Artist")} :
              </Typography>
              <Typography variant="h6" fontSize={14}>
                {rowData?.artist1?.name}
              </Typography>
            </div>
          </>
        )}
      </div>
      <TableContainer component={Paper} className="playedSongTable">
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>{t("main.Date")}</TableCell>
              {hoursArray?.map((item, index) => (
                <TableCell key={index}>{item}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {playedSongHistory.length > 0 ? (
              playedSongHistory?.map((data, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {moment(data?.date).format("dddd, DD.MM.YYYY")}
                  </TableCell>
                  {Object.values(data?.playTime)?.map((value, idx) => (
                    <TableCell
                      className={`${
                        Object.values(value)[0] === "" ? "" : "cellColor"
                      }`}
                      key={idx}
                    >
                      {Object.values(value)[0] !== "" &&
                        moment(Object.values(value)[0], "HH:mm:ss").format(
                          "HH:mm"
                        )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={24} align="center">
                  <Typography variant="subtitle1">
                    {t("main.no_records")}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
export default CustomerSongHistoryTable;

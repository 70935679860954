/* eslint-disable react-hooks/exhaustive-deps */
import {
  Link,
  Paper,
  Button,
  Dialog,
  TextField,
  PaperProps,
  Typography,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import "./addEditCustomerDialog.scss";
import { toast } from "react-toastify";
import Draggable from "react-draggable";
import CloseIcon from "@mui/icons-material/Close";
import React, { MouseEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useChannelIdContext } from "../../../context/ChannelIdContext";
import { useNavigate } from "react-router-dom";
const API_URL = process.env.REACT_APP_BACKEND_URL;
function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper
        {...props}
        style={{
          backgroundColor: "#34393f",
          color: "#D9D9D9",
          padding: "1rem",
        }}
      />
    </Draggable>
  );
}
export type RegionItem = {
  name: string;
};
export default function AddEditRegionDialog({
  isOpen,
  regionId,
  setIsAdded,
  customerId,
  handleClose,
}: {
  isOpen: boolean;
  customerId: number;
  regionId: number | null;
  handleClose: any | MouseEventHandler;
  setIsAdded: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { t } = useTranslation();
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  const [addRegion, setAddRegion] = useState<RegionItem | null | any>(
    regionId ? null : { name: "" }
  );
  const navigate = useNavigate();
  const { setIsTokenExpired, setIsTokenUpdated, IsTokenUpdated } =
    useChannelIdContext();
  const [isDisable, setIsDisable] = useState<boolean>(false);
  const HandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setAddRegion((prevAddRegion: any) => ({
      ...prevAddRegion,
      [name]: value,
    }));
  };

  const AddNewRegion = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (addRegion) {
      const payload = regionId
        ? JSON.stringify({
            id: regionId,
            customerId: customerId,
            name: addRegion?.name,
          })
        : JSON.stringify({
            customerId: customerId,
            name: addRegion?.name,
          });
      const requestOptions = {
        method: regionId ? "PUT" : "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token?.accessToken}`,
        },
        body: payload,
      };
      try {
        fetch(`${API_URL}/Region`, requestOptions)
          .then((response) => {
            if (
              response.ok === false &&
              response.status === 401 &&
              token?.refreshToken !== ""
            ) {
              setIsTokenExpired(true);
            } else if (response.status === 401 && token?.refreshToken === "") {
              navigate("/");
              localStorage.removeItem("token");
              localStorage.clear();
            } else {
              setIsTokenUpdated(false);
              return response.json();
            }
          })
          .then((data) => {
            if (data?.success === true) {
              setIsAdded(true);
              handleClose();
              toast.success(
                regionId
                  ? t("main.Region_Updated_Successfully")
                  : t("main.Region_Added_successfully")
              );
            } else {
              toast.error(data?.message);
            }
          });
      } catch (error) {
        toast.error(t("main.Failed_to_Save_Region_Data"));
      }
    }
  };

  useEffect(() => {
    const fetchRegion = async () => {
      try {
        const requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token?.accessToken}`,
          },
        };
        fetch(`${API_URL}/Region/${regionId}`, requestOptions)
          .then((response) => {
            if (
              response.ok === false &&
              response.status === 401 &&
              token?.refreshToken !== ""
            ) {
              setIsTokenExpired(true);
            } else if (response.status === 401 && token?.refreshToken === "") {
              navigate("/");
              localStorage.removeItem("token");
              localStorage.clear();
            } else {
              setIsTokenUpdated(false);
              return response.json();
            }
          })
          .then((data) => {
            if (data?.success === true) {
              setAddRegion(data?.data);
            } else {
              toast.error(data?.message);
            }
          });
      } catch (error) {
        toast.error(t("main.Failed_to_fetch_Region_data"));
      }
    };
    if (regionId) {
      fetchRegion();
    }
  }, [regionId, IsTokenUpdated]);

  useEffect(() => {
    regionId === null && setAddRegion({ name: "" });
  }, [regionId]);

  useEffect(() => {
    if (addRegion?.name === undefined) {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
  }, [addRegion]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={isOpen}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      className="addCustomerModelDialog"
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle>
        <Typography variant="h5" className="addModel-title">
          {regionId
            ? t("main.Edit_Region_For_Customer")
            : t("main.New_Region_For_Customer")}
          <Link onClick={()=> {
            handleClose()
            setAddRegion({name: null})
          }} className="modelCloseBtn">
            <CloseIcon />
          </Link>
        </Typography>
      </DialogTitle>
      <div className="dialogForm">
        <form>
          <div className="dialogForm-field addchanelFields">
            <Typography variant="h5" className="dialogForm-field-label">
              {t("main.Region_Name")}
            </Typography>
            <TextField
              required
              autoComplete="off"
              name="name"
              value={addRegion ? addRegion.name : ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                HandleChange(e)
              }
            />
          </div>
          <DialogActions sx={{ display: "flex" }}>
            <Button
              type="submit"
              size="medium"
              variant="contained"
              color="primary"
              sx={{ mt: 5 }}
              onClick={AddNewRegion}
              disabled={isDisable}
            >
              {t("main.save")}
            </Button>
            <Button
              size="medium"
              variant="outlined"
              color="primary"
              sx={{ mt: 5 }}
              onClick={handleClose}
            >
              {t("main.cancel")}
            </Button>
          </DialogActions>
        </form>
      </div>
    </Dialog>
  );
}

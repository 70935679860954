import React from "react";
import { ACOption } from "../../types/Common";
import { Autocomplete, Grid, TextField, Typography, useTheme } from "@mui/material";

export interface IComboBoxControlProps {
  header: string;
  value: ACOption;
  inputChanged: Function;
  dataSource: { id: number; label: string }[];
}

export default function ComboBoxControl(props: IComboBoxControlProps) {

  const theme = useTheme();
  
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        md={5}
        container
        direction="column"
        alignItems="start"
        justifyContent="center"
      >
        <Typography className={theme.palette.mode === "dark" ? "BoxLabel-dark" : "BoxLabel" }>{props?.header}</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={7}
        container
        direction="column"
        justifyContent="center"
      >
        <div className="CustomSelectBox">
          <Autocomplete
            disablePortal
            options={props.dataSource}
            size="small"
            renderInput={(params) => <TextField {...params} />}
            value={props?.value}
            isOptionEqualToValue={({ option, value }: any) => option === value}
            onChange={(e, newValue: any) => props.inputChanged(newValue)}
          ></Autocomplete>
        </div>
      </Grid>
    </Grid>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { HistoryLog } from "../../types/VoiceTrack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState, useMemo } from "react";
import MaterialReactTable, { type MRT_ColumnDef } from "material-react-table";
import { useTranslation } from "react-i18next";
import { useChannelIdContext } from "../../context/ChannelIdContext";

const AudioLogData = () => {
  const API_URL = process.env.REACT_APP_BACKEND_URL;
  const { t } = useTranslation();
  const { id } = useParams();
  let location = useLocation();
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  const [songHistoryLog, setSongHistoryLog] = useState<HistoryLog[]>([]);
  const [voiceTrackHistory, setVoiceTrackHistory] = useState<HistoryLog[]>([]);
  const isLocation =
    location?.pathname?.includes("music") ||
    location?.pathname?.includes("channel");
  const { setIsTokenExpired, setIsTokenUpdated, IsTokenUpdated } =
    useChannelIdContext();
  const navigate = useNavigate();
  const formatDate = (value: Date) => {
    const date = moment(value).format("DD.MM.YYYY");
    const time = moment(value).format("HH.mm") + "Uhr";
    return `${date} / ${time}`;
  };

  const column = useMemo<MRT_ColumnDef<HistoryLog>[]>(
    () => [
      {
        accessorKey: "channel.name",
        header: `${t("main.Site")}`,
      },
      {
        accessorKey: "playDate",
        header: `${t("main.Play_date")}`,
        Cell: (renderedCellValue: any) => (
          <span>{formatDate(renderedCellValue.renderedCellValue)}</span>
        ),
      },
      {
        accessorKey: "zone.name",
        header: `${t("main.Zone_ID")}`,
      },
    ],
    [t]
  );
  useEffect(() => {
    if (location?.pathname?.includes("music")) {
      const fetchSongListByChannel = async () => {
        fetch(`${API_URL}/SongHistory/GetAllBySongId/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token?.accessToken}`,
          },
        })
          .then((response) => {
            if (
              response.ok === false &&
              response.status === 401 &&
              token?.refreshToken !== ""
            ) {
              setIsTokenExpired(true);
            } else if (response.status === 401 && token?.refreshToken === "") {
              navigate("/");
              localStorage.removeItem("token");
              localStorage.clear();
            } else {
              setIsTokenUpdated(false);
              return response.json();
            }
          })
          .then((data) => {
            if (data.success === true) {
              setSongHistoryLog(data?.data);
            } else {
              setSongHistoryLog([]);
            }
          });
      };
      id !== undefined && fetchSongListByChannel();
    }
  }, [id, location?.pathname, IsTokenUpdated]);

  useEffect(() => {
    if (location?.pathname?.includes("content")) {
      const fetchVoiceTrackByChannel = async () => {
        fetch(`${API_URL}/VoiceTrackHistory/GetAllByVoiceTrackIdId/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token?.accessToken}`,
          },
        })
          .then((response) => {
            if (
              response.ok === false &&
              response.status === 401 &&
              token?.refreshToken !== ""
            ) {
              setIsTokenExpired(true);
            } else if (response.status === 401 && token?.refreshToken === "") {
              navigate("/");
              localStorage.removeItem("token");
              localStorage.clear();
            } else {
              setIsTokenUpdated(false);
              return response.json();
            }
          })
          .then((data) => {
            if (data.success === true) {
              setVoiceTrackHistory(data?.data);
            } else {
              setVoiceTrackHistory([]);
            }
          });
      };
      id !== undefined && fetchVoiceTrackByChannel();
    }
  }, [id, location?.pathname, IsTokenUpdated]);

  return (
    <div className="HistoryTable">
      <MaterialReactTable
        columns={column}
        data={isLocation ? songHistoryLog : voiceTrackHistory}
        enableSorting={true}
        enablePagination={true}
        enableTopToolbar={false}
        enableColumnActions={true}
        enableColumnOrdering={true}
        enableColumnFilters={false}
        muiTableBodyRowProps={{ hover: false }}
        muiTableBodyProps={{
          sx: {
            "& tr:nth-of-type(even)": {
              backgroundColor: "#F0F4F6",
            },
          },
        }}
      />
    </div>
  );
};

export default AudioLogData;

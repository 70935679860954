/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Link,
  Paper,
  Stack,
  Button,
  Dialog,
  Select,
  MenuItem,
  TextField,
  Typography,
  PaperProps,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import "./DropinsDialog.scss";
import dayjs, { Dayjs } from "dayjs";
import { toast } from "react-toastify";
import Draggable from "react-draggable";
import { Zone } from "../../../types/Zone";
import CloseIcon from "@mui/icons-material/Close";
import { Channel } from "../../../types/Customer";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useCallback, useEffect, useState } from "react";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";
import { useChannelIdContext } from "../../../context/ChannelIdContext";
import { useNavigate } from "react-router-dom";
const API_URL = process.env.REACT_APP_BACKEND_URL;
function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper
        {...props}
        style={{
          backgroundColor: "#34393f",
          color: "#D9D9D9",
          padding: "1rem",
        }}
      />
    </Draggable>
  );
}

interface formData {
  interval: string | null;
  name: string | null;
  siteId: string | null;
  zoneId: string | null;
  channelId: string | null;
  endingTime: Dayjs | null;
  customerId: string | null;
  startingTime: Dayjs | null;
}

export default function AddDropinsDialog({
  isOpen,
  handleClose,
  editDropInsList,
  isEdit,
  setIsEdit,
  fetchDropinsFilteredList,
}: {
  isEdit: any;
  isOpen: boolean;
  setIsEdit: any;
  editDropInsList: any;
  handleClose: any;
  fetchDropinsFilteredList: any;
}) {
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setIsTokenExpired, setIsTokenUpdated, IsTokenUpdated } =
    useChannelIdContext();
  const [zones, setZones] = useState<Zone[]>();
  const [siteData, setSiteData] = useState([]);
  const [siteId, setSiteId] = useState();
  const [customerData, setCustomerData] = useState([]);
  const [channels, setChannels] = useState<Channel[]>([]);
  const [dropinData, setDropinData] = useState<formData>({
    name: isEdit ? editDropInsList?.name : null,
    siteId: isEdit ? editDropInsList?.siteId : null,
    zoneId: isEdit ? editDropInsList?.zoneId : null,
    interval: isEdit ? editDropInsList?.interval : null,
    channelId: isEdit ? editDropInsList?.channelId : null,
    endingTime: isEdit ? dayjs(editDropInsList?.endingTime, "HH:mm") : null,
    customerId: isEdit ? editDropInsList?.customerId : null,
    startingTime: isEdit ? dayjs(editDropInsList?.startingTime, "HH:mm") : null,
  });

  const handleAdddropins = async (e: React.MouseEvent<HTMLElement>) => {
    if (Object.values(dropinData)?.includes(null)) {
      toast.error(t("main.Please_fill_the_form"));
      return;
    }
    e.preventDefault();
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
      body: JSON.stringify({
        name: dropinData?.name,
        zoneId: dropinData?.zoneId,
        siteId: dropinData?.siteId,
        interval: dropinData?.interval,
        channelId: dropinData?.channelId,
        customerId: dropinData?.customerId,
        // endingTime: dayjs(dropinData?.endingTime)?.format("HH:mm:ss"),
        endingTime: dropinData?.endingTime ? dayjs(dropinData?.endingTime).format("HH:mm:ss") : dayjs("00:00:00", "HH:mm:ss").format("HH:mm:ss"),
        // startingTime: dayjs(dropinData?.startingTime)?.format("HH:mm:ss"),
        startingTime: dropinData?.startingTime ? dayjs(dropinData?.startingTime).format("HH:mm:ss") : dayjs("00:00:00", "HH:mm:ss").format("HH:mm:ss"),
      }),
    };
    fetch(`${API_URL}/DropIn`, requestOptions)
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data?.success === true) {
          handleClose();
          fetchDropinsFilteredList();
          toast.success(t("main.Schedule_Created_Successfully"));
        } else {
          toast.error(data?.error);
        }
        setDropinData({
          name: null,
          zoneId: null,
          siteId: null,
          interval: null,
          channelId: null,
          endingTime: null,
          customerId: null,
          startingTime: null,
        });
      });
  };

  const handleEditdropins = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
      body: JSON.stringify({
        id: editDropInsList?.id,
        name: dropinData?.name,
        zoneId: dropinData?.zoneId,
        siteId: dropinData?.siteId,
        interval: dropinData?.interval,
        channelId: dropinData?.channelId,
        customerId: dropinData?.customerId,
        // endingTime: dayjs(dropinData?.endingTime)?.format("HH:mm:ss"),
        endingTime: dropinData?.endingTime ? dayjs(dropinData?.endingTime).format("HH:mm:ss") : dayjs("00:00:00", "HH:mm:ss").format("HH:mm:ss"),
        // startingTime: dayjs(dropinData?.startingTime)?.format("HH:mm:ss"),
        startingTime: dropinData?.startingTime ? dayjs(dropinData?.startingTime).format("HH:mm:ss") : dayjs("00:00:00", "HH:mm:ss").format("HH:mm:ss"),
      }),
    };
    fetch(`${API_URL}/DropIn`, requestOptions)
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data?.success === true) {
          handleClose();
          fetchDropinsFilteredList();
          toast.success(t("main.Dropin_Update_Successfully"));
        } else {
          toast.error(data?.error);
        }
        setIsEdit(false);
        setDropinData({
          name: null,
          zoneId: null,
          siteId: null,
          interval: null,
          channelId: null,
          endingTime: null,
          customerId: null,
          startingTime: null,
        });
      });
  };

  const fetchZoneData = useCallback(async () => {
    fetch(`${API_URL}/Zone/GetByFilters?siteId=${siteId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    })
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data.success === true) {
          setZones(data?.data);
        } else {
          setZones([]);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteId]);

  const getAllChannel = useCallback(async () => {
    fetch(`${API_URL}/Channel/GetAll`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    })
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data.success === true) {
          setChannels(data?.data);
        } else {
          setChannels([]);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllCustomer = useCallback(async () => {
    fetch(`${API_URL}/Customer/GetAll`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    })
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data.success === true) {
          setCustomerData(data?.data);
        } else {
          setCustomerData([]);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [IsTokenUpdated]);

  const getAllsite = useCallback(async () => {
    fetch(`${API_URL}/Site/GetAll`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    })
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data.success === true) {
          setSiteData(data?.data);

        } else {
          setSiteData([]);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [IsTokenUpdated]);
  useEffect(() => {
    if (isOpen) {
      fetchZoneData();
      getAllChannel();
      getAllCustomer();
      getAllsite();
    }
  }, [fetchZoneData, getAllChannel, getAllCustomer, getAllsite, isOpen]);

  useEffect(() => {
    if (isEdit) {
      const startingTime = editDropInsList?.startingTime.split(":");
      const endingTime = editDropInsList?.endingTime.split(":");
      const today = new Date();

      setDropinData({
        ...editDropInsList,
        channelId: editDropInsList.channel.id,
        startingTime: dayjs(
          new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate(),
            startingTime[0],
            startingTime[1],
            startingTime[2]
          )
        ),
        endingTime: dayjs(
          new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate(),
            endingTime[0],
            endingTime[1],
            endingTime[2]
          )
        ),
      });
    }
  }, [editDropInsList, isEdit]);

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        handleClose();
        setDropinData({
          name: null,
          zoneId: null,
          siteId: null,
          interval: null,
          channelId: null,
          endingTime: null,
          customerId: null,
          startingTime: null,
        });
      }}
      PaperComponent={PaperComponent}
      maxWidth="sm"
      fullWidth
      className="addNewwheelDialog"
    >
      <DialogTitle
        style={{ cursor: "move", padding: "0" }}
        id="draggable-dialog-title"
      >
        <Typography className="addModelDialog-title" sx={{ fontWeight: 700 }}>
          {isEdit ? `${t("main.edit_dropin")}` : `${t("main.add_new_dropin")}`}
          <Link
            className="modelCloseBtn"
            onClick={() => {
              handleClose();
              setIsEdit(false);
              setDropinData({
                name: null,
                zoneId: null,
                siteId: null,
                interval: null,
                channelId: null,
                endingTime: null,
                customerId: null,
                startingTime: null,
              });
            }}
          >
            <CloseIcon />
          </Link>
        </Typography>
      </DialogTitle>
      <Box
        className="dialogForm timeManagerForm"
        style={{ padding: "0", maxWidth: "100%" }}
      >
        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 1 },
          }}
          noValidate
          autoComplete="off"
        >
          <div className="dialogForm-field">
            <Typography className="dialogForm-field-label">
              {t("main.name")}
            </Typography>
            <TextField
              sx={{ width: 361 }}
              variant="outlined"
              value={dropinData?.name ? dropinData?.name : ""}
              onChange={(e: any) => {
                setDropinData({ ...dropinData, name: e.target.value });
              }}
              id="name"
              required
            />
          </div>
          <div className="dialogForm-field">
            <Typography className="dialogForm-field-label">
              {t("main.customer")}
            </Typography>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              required
              value={
                customerData.some(
                  (item: any) => item?.id === dropinData?.customerId
                )
                  ? dropinData?.customerId
                  : ""
              }
              onChange={(e: any) => {
                setDropinData({ ...dropinData, customerId: e.target.value });
              }}
            >
              {customerData &&
                customerData?.map((item: any) => (
                  <MenuItem key={item?.id} value={item?.id}>
                    {item?.name}
                  </MenuItem>
                ))}
            </Select>
          </div>
          <div className="dialogForm-field">
            <Typography className="dialogForm-field-label">
              {t("main.Site")}
            </Typography>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              required
              value={
                siteData.some((item: any) => item.id === dropinData.siteId)
                  ? dropinData.siteId
                  : ""
              }
              onChange={(e: any) => {
                setSiteId(e.target.value)
                setDropinData({ ...dropinData, siteId: e.target.value });
              }}
            >
              {siteData &&
                siteData?.map((item: any) => (
                  <MenuItem key={item?.id} value={item?.id}>
                    {item?.name}
                  </MenuItem>
                ))}
            </Select>
          </div>
          <div className="dialogForm-field">
            <Typography className="dialogForm-field-label">
              {t("main.zone")}
            </Typography>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              required
              value={
                zones?.some((item: any) => item?.id === dropinData.zoneId)
                  ? dropinData.zoneId
                  : ""
              }
              onChange={(e: any) => {
                setDropinData({ ...dropinData, zoneId: e.target.value });
              }}
            >
              {zones &&
                zones?.map((item: any) => (
                  <MenuItem key={item?.id} value={item?.id}>
                    {item?.name}
                  </MenuItem>
                ))}
            </Select>
          </div>
          <div className="dialogForm-field">
            <Typography className="dialogForm-field-label">
              {t("main.channel")}
            </Typography>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              required
              value={
                channels.some((item: any) => item.id === dropinData.channelId)
                  ? dropinData?.channelId
                  : ""
              }
              onChange={(e: any) => {
                setDropinData({ ...dropinData, channelId: e.target.value });
              }}
            >
              {channels &&
                channels?.map((item: any) => (
                  <MenuItem key={item?.id} value={item?.id}>
                    {item?.name}
                  </MenuItem>
                ))}
            </Select>
          </div>
          <div className="dialogForm-field">
            <Typography className="dialogForm-field-label">
              {t("main.start_time_(hhmm)")}
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack spacing={3}>
                {/* <TimePicker
                  renderInput={(params) => <TextField {...params} />}
                  value={
                    dropinData?.startingTime ? dropinData?.startingTime : ""
                  }
                  onChange={(newValue: any) => {
                    setDropinData({ ...dropinData, startingTime: newValue });
                  }}
                /> */}
                <TextField
                  id="time"
                  type="time"
                  className="TimePicker"
                  defaultValue={dayjs(dropinData?.startingTime).format("HH:mm")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}

                  sx={{
                    '& input[type="time"]::-webkit-calendar-picker-indicator': {
                      filter:
                        'invert(100%)',
                    }, width: 150
                  }}

                  onChange={(e) => setDropinData({ ...dropinData, startingTime: dayjs(e.target.value + ":00", "HH:mm:ss") })}
                />
              </Stack>
            </LocalizationProvider>
          </div>
          <div className="dialogForm-field">
            <Typography className="dialogForm-field-label">
              {t("main.end_time_(hhmm)")}
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack spacing={3}>
                {/* <TimePicker
                  renderInput={(params) => <TextField {...params} />}
                  value={dropinData?.endingTime ? dropinData?.endingTime : ""}
                  onChange={(newValue: any) => {
                    setDropinData({ ...dropinData, endingTime: newValue });
                  }}
                /> */}
                <TextField
                  id="time"
                  type="time"
                  className="TimePicker"
                  defaultValue={dayjs(dropinData?.endingTime).format("HH:mm")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}

                  sx={{
                    '& input[type="time"]::-webkit-calendar-picker-indicator': {
                      filter:
                        'invert(100%)',
                    }, width: 150
                  }}

                  onChange={(e) => setDropinData({ ...dropinData, endingTime: dayjs(e.target.value + ":00", "HH:mm:ss") })}
                />
              </Stack>
            </LocalizationProvider>
          </div>
          <div className="dialogForm-field">
            <Typography className="dialogForm-field-label">
              {t("main.interval")}
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              value={dropinData?.interval ? dropinData?.interval : ""}
              type="number"
              onChange={(e: any) => {
                setDropinData({ ...dropinData, interval: e.target.value });
              }}
            />
          </div>
        </Box>
      </Box>
      <DialogActions sx={{ display: "flex", justifyContent: "space-around" }}>
        <Button
          size="medium"
          variant="contained"
          color="primary"
          sx={{ mt: 5 }}
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            if (isEdit) {
              handleEditdropins(e);
            } else {
              handleAdddropins(e);
            }
          }}
        >
          {t("main.save")}
        </Button>
        <Button
          onClick={() => {
            setIsEdit(false);
            handleClose();
            setDropinData({
              name: null,
              siteId: null,
              zoneId: null,
              interval: null,
              channelId: null,
              customerId: null,
              endingTime: null,
              startingTime: null,
            });
          }}
          size="medium"
          variant="outlined"
          color="secondary"
          sx={{ mt: 5, borderColor: "#fff" }}
        >
          {t("main.cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useCallback,
  MouseEventHandler,
} from "react";
import {
  Box,
  Link,
  Paper,
  Dialog,
  Select,
  Button,
  MenuItem,
  Typography,
  PaperProps,
  DialogActions,
  DialogContent,
  SelectChangeEvent,
  DialogTitle,
} from "@mui/material";
import "./duplicateZoneDialog.scss";
import { toast } from "react-toastify";
import Draggable from "react-draggable";
import { useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { ScheduleList } from "../../../types/Zone";
import { useChannelIdContext } from "../../../context/ChannelIdContext";
import { useTranslation } from "react-i18next";
const API_URL = process.env.REACT_APP_BACKEND_URL;
function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper
        {...props}
        style={{
          backgroundColor: "#34393F",
          color: "#D9D9D9",
          padding: "1rem",
        }}
      />
    </Draggable>
  );
}

const DuplicateZoneDialog = ({
  isOpen,
  handleClose,
}: {
  isOpen: boolean;
  handleClose: any | MouseEventHandler;
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  let navigate = useNavigate();
  const [zoneList, setZoneList] = useState<ScheduleList[]>([]);
  const [allZoneList, setAllZoneList] = useState<any[]>([]);
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  const {
    isAdded,
    setIsAdded,
    scheduleList,
    setIsTokenExpired,
    setIsTokenUpdated,
    IsTokenUpdated,
  } = useChannelIdContext();
  const [selectSourceZone, setSelectSourceZone] = useState<string>("");
  const [selectDestinationZone, setSelectDestinationZone] =
    useState<string>("");

  const handleSourceZoneChange = (
    event: SelectChangeEvent<typeof selectSourceZone>
  ) => {
    const {
      target: { value },
    } = event;
    setSelectSourceZone(value);
  };

  const handleDestinationZoneChange = (
    event: SelectChangeEvent<typeof selectDestinationZone>
  ) => {
    const {
      target: { value },
    } = event;

    if (value === selectSourceZone) {
      setSelectDestinationZone("");
    } else {
      setSelectDestinationZone(value);
    }
  };

  const handleSubmitZone = async () => {
    if (scheduleList?.schedules?.length === 0) {
      toast.error(t("main.Schedules_Are_Not_Available"));
      handleClose();
      return;
    } else {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token?.accessToken}`,
        },
        body: JSON.stringify({
          sourceZoneId: selectSourceZone,
          destinationZoneId: selectDestinationZone,
        }),
      };
      fetch(`${API_URL}/Schedule/DuplicateZone`, requestOptions)
        .then((response) => {
          if (
            response.ok === false &&
            response.status === 401 &&
            token?.refreshToken !== ""
          ) {
            setIsTokenExpired(true);
          } else if (response.status === 401 && token?.refreshToken === "") {
            navigate("/");
            localStorage.removeItem("token");
            localStorage.clear();
          } else {
            setIsTokenUpdated(false);
            return response.json();
          }
        })
        .then((data) => {
          if (data?.success === true) {
            handleClose();
            setIsAdded(true);
            setSelectSourceZone("");
            setSelectDestinationZone("");
            toast.success(t("main.Duplicate_Zone_Created_Successfully"));
          } else {
            toast.error(t("main.Please_fill_the_form"));
          }
        });
    }
  };

  const fetchAllZoneList = useCallback(async () => {
    fetch(`${API_URL}/Zone/GetAll`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    })
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data.success === true) {
          setAllZoneList(data.data);
          // setZoneList(data.data);
        } else {
          setAllZoneList([]);
          // setZoneList([]);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, IsTokenUpdated]);

  useEffect(() => {
    const fetchZoneList = async () => {
      fetch(`${API_URL}/Schedule/GetAllByZones`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token?.accessToken}`,
        },
      })
        .then((response) => {
          if (
            response.ok === false &&
            response.status === 401 &&
            token?.refreshToken !== ""
          ) {
            setIsTokenExpired(true);
          } else if (response.status === 401 && token?.refreshToken === "") {
            navigate("/");
            localStorage.removeItem("token");
            localStorage.clear();
          } else {
            setIsTokenUpdated(false);
            return response.json();
          }
        })
        .then((data) => {
          console.log(data.data, "duplicate zone");
          if (data.success === true) {
            setZoneList(data?.data);
          } else {
            setZoneList([]);
          }
        });
    };
    isOpen && fetchZoneList();
  }, [isOpen, isAdded]);

  useEffect(() => {
    if (location?.pathname?.includes("channel-scheduler") && isOpen) {
      fetchAllZoneList();
    }
  }, [fetchAllZoneList, isAdded, location?.pathname]);

  useEffect(() => {
    if (isAdded === true) {
      handleClose();
      setSelectSourceZone("");
      setSelectDestinationZone("");
    }
  }, [handleClose, isAdded]);

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        handleClose();
        setSelectSourceZone("");
        setSelectDestinationZone("");
      }}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      maxWidth="sm"
      fullWidth
      className="duplicateZoneDialog"
    >
      <DialogTitle>
        <Typography variant="h5" className="duplicateZoneDialog-title">
          {t("main.duplicate_zone")}
          <Link onClick={() => {
            handleClose()
            setSelectSourceZone("");
            setSelectDestinationZone("");
          }} className="modelCloseBtn">
            <CloseIcon />
          </Link>
        </Typography>
      </DialogTitle>
      <div className="duplicateZoneDialog__inner">
        <DialogContent>
          <Box className="fieldBox">
            <Box className="fieldBox__head">
              <Typography variant="body1" className="fieldBox__head-title">
                {t("main.select_source_zone")}
              </Typography>
            </Box>
            <Box className="fieldBox__body">
              <Select
                className="selectBox CustomDrpSelect"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectSourceZone}
                onChange={handleSourceZoneChange}
              >
                {zoneList?.map((item: any) =>
                  item?.zone !== null && item?.zone !== undefined ? (
                    <MenuItem value={item?.zone?.id} key={item?.zone?.id}>
                      {item?.zone?.name}
                    </MenuItem>
                  ) : (
                    ""
                  )
                )}
              </Select>
            </Box>
          </Box>
          <Box className="fieldBox">
            <Box className="fieldBox__head">
              <Typography variant="body1" className="fieldBox__head-title">
                {t("main.select_destination_zone")}
              </Typography>
            </Box>
            <Box className="fieldBox__body">
              <Select
                className="selectBox CustomDrpSelect"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectDestinationZone || ""}
                onChange={handleDestinationZoneChange}
                disabled={!selectSourceZone}
              >
                {allZoneList.map(
                  (item: any) =>
                    item?.zone !== null && (
                      <MenuItem value={item.id} key={item.id}>
                        {item?.name}
                      </MenuItem>
                    )
                )}
              </Select>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ display: "flex" }}>
          <Button
            size="medium"
            variant="contained"
            color="primary"
            sx={{ mt: 5 }}
            style={{ backgroundColor: "#3C735C", border: "none" }}
            onClick={handleSubmitZone}
          >
            {t("main.save")}
          </Button>
          <Button
            size="medium"
            variant="outlined"
            color="primary"
            sx={{ mt: 5 }}
            onClick={() => {
              handleClose();
              setSelectSourceZone("");
              setSelectDestinationZone("");
            }}
          >
            {t("main.cancel")}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};
export default DuplicateZoneDialog;

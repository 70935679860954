import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          "main.Dropins": "Dropins",
          "main.Remove_Item": "Remove Item",
          "main.AddItem": " Add Item",
          "main.Tools": "Tools",
          "main.Add_File": "Add File",
          "main.Add_Folder": "Add Folder",
          "main.Sites": "Sites",
          "main.Delete_Group": "Delete Group",
          "main.Add_group": "Add group",
          "main.Date": "Date",
          "main.Enter_Comments": "Enter Comments",
          "main.Delete_user": "Delete user",
          "main.Add_User": "Add User",
          "main.No_Category_Found": "No Category Found",
          "main.userRole_Choose": "Choose",
          "main.Select_Profile": "Select Profile",
          "main.Ripper_Dialog": "Ripper Dialog",
          "main.import_Open_btn": "Open",
          "main.No_Customer_Found": "No Customer Found",
          "main.select_voicetrack_category":
            "Please select voice track category",
          "main.Deleted_Successfully": "Site Deleted Successfully",
          "main.No_Customer": "No Customer",
          "main.no_wheel_found": "no wheel found",
          "main.Exact_Time_Manager": "Exact Time Manager",
          "main.Immediate": "Immediate",
          "main.padSeconds": "Pad Seconds",
          "main.Music_Fade": "Music Fade",
          "main.Message": "Message",
          "main.Exact_Time": "Exact Time",
          "main.New_Time_Manager": "New Time Manager",
          "main.New_Announcement": "New Announcement",
          "main.Remove_btn": "Remove",
          "main.Announcement_Categories": "Announcement Categories",
          "main.Failed_to_Edit_Site_Data": "Failed to Edit Site Data",
          "main.This_Folder_Doesnot_Contain_mp3_File":
            "This Folder Doesn't Contain mp3 File",
          "main.This_Folder_Contains_Files_Other_That_mp3":
            "This Folder Contains Files Other That mp3",
          "main.low_priorty": "Low",
          "main.mid_priorty": "Mid",
          "main.high_priorty": "High",
          "main.Action": "Action",
          "main.Attribute_Edited": "Attribute Edited Successfully",
          "main.Auto_Set_Markers": "Auto Set Markers",
          "main.Set_Mix": "Set Mix",
          "main.Set_Start": "Set Start",
          "main.No_Device_Connected": "No Device Connected",
          "main.Pause": "Pause",
          "main.Play": "Play",
          "main.RangeStart": "Range Start",
          "main.RangeEnd": "Range End",
          "main.Site": "Site",
          "main.DropIns": "DropIns",
          "main.Wheel_Designer": "Wheel Designer",
          "main.Zone_Player/Music_Library": "Zone Player / Music Library",
          "main.List_of_uploaded_voice_tracks": "List of uploaded voice tracks",
          "main.List_of_uploaded_songs": "List of uploaded songs",
          "main.Music_Player": "Music Player",
          "main.Dashboard": "Dashboard",
          "main.Channel_Time_Schedule": "Channel Time Schedule",
          "main.Channel_List_Editor": "Channel List Editor",
          "main.Audio_Editor/Song_Data": "Audio Editor / Song Data",
          "main.Settings/Group_Permission": "Settings / Group Permission",
          "main.Add_New_Site": "Add New Site",
          "main.Edit_Site": "Edit Site",
          "main.Edit_Region_For_Customer": "Edit Region For Customer",
          "main.New_Region_For_Customer": "New Region For Customer",
          "main.Are_you_sure_to_delete_this_song":
            "Are you sure to delete this song ?",
          "main.Are_you_sure_to_delete_this_channelList":
            "Are you sure to delete this channelList ?",
          "main.Are_you_sure_to_delete_this_Customer":
            "Are you sure to delete this Customer ?",
          "main.Are_you_sure_to_delete_this": "Are you sure to delete this",
          "main.Are_you_sure_to_delete_this_Channel_Schedule":
            "Are you sure to delete this Channel Schedule ?",
          "main.Update_Channel_Name": "Update Channel Name",
          "main.No_Zone_Found": "No Zone Found",
          "main.Confirm": "Confirm",
          "main.Region_Name": "Region Name",
          "main.Contact_Name": "Contact Name",
          "main.Phone_Number": "Phone Number ",
          "main.Post/Area_Code": "Post / Area Code",
          "main.Country": "Country",
          "main.Address": "Address",
          "main.Customer_Name": "Customer Name",
          "main.Add_New_Customer": "Add New Customer",
          "main.Edit_Customer": "Edit Customer",
          "main.No_Lic_Found": "No License Found",
          "main.Voice_Track_ID": "Voice Track ID",
          "main.VoiceTrack_ID": "VoiceTrack ID",
          "main.voice_track_Category": "voiceTrack Category",
          "main.Song_ID": "Song ID",
          "main.Week_Days": "Week Days",
          "main.Zone_Player/Channel_Scheduler":
            "Zone Player / Channel Scheduler",
          "main.Delete": "Delete",
          "main.Download": "Download",
          "main.Upload": "Upload",
          "main.Edit": "Edit",
          "main.Add": "Add",
          "main.Attribute_Name": "Attribute Name",
          "main.Last_Modified": "Last Modified",
          "main.Zone_ID": "Zone ID",
          "main.Play_date": "Play Date",
          "main.Lookup": "Lookup",
          "main.External_Meta": "External Meta",
          "main.Track_artwork": "Track Artwork",
          "main.User_defined_Fields": "User Defined Fields",
          "main.To": "To",
          "main.From": "From",
          "main.Seasonality": "Seasonality",
          "main.Time_of_Day": "Time of Day",
          "main.Use_expire_date?": "Use Expire Date?",
          "main.Expire": "Expire",
          "main.Use_commence_date?": "Use Commence Date?",
          "main.Commence": "Commence",
          "main.Exact_Dates": "Exact Dates",
          "main.Reset": "Reset",
          "main.Output_device": "Output device",
          "main.Audio": "Audio",
          "main.Type": "Type",
          "main.Year": "Year",
          "main.Artist": "Artist",
          "main.Metadata": "Metadata",
          "main.Voice_Track_Data": "Voice Track Data",
          "main.Cloud": "Cloud",
          "main.History": "History",
          "main.Distribution": "Distribution",
          "main.Channel_Assignments": "Channel Assignments",
          "main.Characteristics": "Characteristics",
          "main.Meta": "Meta",
          "main.User_Defined": "User Defined",
          "main.Restrictions": "Restrictions",
          "main.Song_Data": "Song Data",
          "main.Back": "Back",
          "main.User_have_no_authority_to_edit_song":
            "User have no authority to edit song",
          "main.Audio_characteristics_Updated_Successfully":
            "Audio characteristics Updated Successfully",
          "main.Voice_Track_Updated_Successfully":
            "Voice Track Updated Successfully",
          "main.Data_Not_Found": "Data Not Found",
          "main.Audio_Updated_Successfully": "Audio Updated Successfully",
          "main.Channel_Unsubscribed_Successfully":
            "Channel Unsubscribed Successfully",
          "main.Channel_Subscribed_Successfully":
            "Channel Subscribed Successfully",
          "main.Please_Add_New_Attribute": "Please Add New Attribute",
          "main.Attribute_Added_Successfully": "Attribute Added Successfully",
          "main.Please_Enter_Attribute_to_Edit":
            "Please Enter Attribute to Edit",
          "main.Attribute_Edited_Successfully": "Attribute Edited Successfully",
          "main.Attribute_Is_Deleted": "Attribute Is Deleted",
          "main.Not_Found": "Not Found",
          "main.Select_The_Attributes": "Select The Attributes",
          "main.Data_Uploaded_from_Cloud_Successfully":
            "Data Uploaded from Cloud Successfully",
          "main.Data_Downloaded_from_Cloud_Successfully":
            "Data Downloaded from Cloud Successfully",
          "main.Data_Deleted_from_Cloud_Successfully":
            "Data Deleted from Cloud Successfully",
          "main.Logged_in_Successfully": "Logged in Successfully",
          "main.This_Folder_Doesn't_Contain_Any_File":
            "This Folder Doesn't Contain Any File",
          "main.siteUniqueId_&_clientSecretKey_Copyed":
            "siteUniqueId & clientSecretKey Copyed",
          "main._clientSecretKey_Copyed": "clientSecretKey Copyed",
          "main.Voice_Track_Uploaded_Successfully":
            "Voice Track Uploaded Successfully",
          "main.Audio_Uploaded_Successfully": "Audio Uploaded Successfully",
          "main.SOMETHING_IS_BREAKING": "SOMETHING IS BREAKING",
          "main.File_Imported_Successfully": "File Imported Successfully",
          "main.Channel_Created_Successfully": "Channel Created Successfully",
          "main.Song_Added_Successfully": "Song Added Successfully",
          "main.Customer_Updated_Successfully": "Customer Updated Successfully",
          "main.New_Customer_Added_Successfully":
            "New Customer Added Successfully",
          "main.Failed_to_Save_Customer_Data": "Failed to Save Customer Data",
          "main.Failed_to_Fetch_Customer_Data": "Failed to Fetch Customer Data",
          "main.Region_Updated_Successfully": "Region Updated Successfully",
          "main.Region_Added_successfully": "Region Added successfully",
          "main.Failed_to_Save_Region_Data": "Failed to Save Region Data",
          "main.Failed_to_fetch_Region_data": "Failed to fetch Region data",
          "main.New_Site_Added": "New Site Added",
          "main.Failed_to_Save_Site_Data": "Failed to Save Site Data",
          "main.Failed_to_fetch_Site_data": "Failed to fetch Site data",
          "main.Schedule_Updated_Successfully": "Schedule Updated Successfully",
          "main.Category_Deleted_Successfully": "Category Deleted Successfully",
          "main.Category_Updated_successfully": "Category Updated successfully",
          "main.Error_Occurred_While_Updating_Category":
            "Error Occurred While Updating Category",
          "main.New_Category_Created_Successfully":
            "New Category Created Successfully",
          "main.Error_Occurred_While_Saving_Category":
            "Error Occurred While Saving Category",
          "main.New_Wheel_Created_Successfully":
            "New Wheel Created Successfully",
          "main.Update_Item_Position_Successfully":
            "Update Item Position Successfully",
          "main.Please_Select_Wheel_to_Add_Item":
            "Please Select Wheel to Add Item",
          "main.Wheel_Deleted_Successfully": "Wheel Deleted Successfully",
          "main.Please_Select_Item_First": "Please Select Item First",
          "main.Dropin_Update_Successfully": "Dropin Update Successfully",
          "main.Data_Deleted_Successfully": "Data Deleted Successfully",
          "main.Please_Select_Any_Dropin_to_Remove":
            "Please Select Any Dropin to Remove",
          "main.User_doesn't_have_authority_to_edit_dropins":
            "User doesn't have authority to edit dropins",
          "main.Schedule_Item_Deleted_Successfully":
            "Schedule Item Deleted Successfully",
          "main.ChannelList_Deleted_Successfully":
            "ChannelList Deleted Successfully",
          "main.Duplicate_Day_Created_Successfully":
            "Duplicate Day Created Successfully",
          "main.Schedules_Are_Not_Available": "Schedules Are Not Available",
          "main.Duplicate_Zone_Created_Successfully":
            "Duplicate Zone Created Successfully",
          "main.Channel_Name_Updated_Successfully":
            "Channel Name Updated Successfully",
          "main.Schedule_Created_Successfully": "Schedule Created Successfully",
          "main.User_doesn't_have_authority_to_edit_license":
            "User doesn't have authority to edit license",
          "main.Licensing_Data_Posted_Successfully":
            "Licensing Data Posted Successfully",
          "main.Licensing_Data_Updated_Successfully":
            "Licensing Data Updated Successfully",
          "main.License_Data_According_To_Customer":
            "License Data According To Customer",
          "main.License_Data_According_To_Site":
            "License Data According To Site",
          "main.Please_Select_Site": "Please Select Site",
          "main.Data_Is_Updated": "Data Is Updated",
          "main.Zone_Deleted_Successfully": "Zone Deleted Successfully",
          "main.File_Is_Downloaded": "File Is Downloaded",
          "main.Postion_can't_be_change_until_data_is_saved":
            "Postion can't be change until data is saved",
          "main.Please_fill_the_form": "Please fill the form",
          "main.New_User_Group_Added_Successfully":
            "New User Group Added Successfully",
          "main.Group_Edited_Successfully": "Group Edited Successfully",
          "main.Please_Select_Any_Group_to_Delete":
            "Please Select Any Group to Delete",
          "main.Group_Deleted_Successfully": "Group Deleted Successfully",
          "main.User_have_no_authority_to_edit_group_data":
            "User have no authority to edit group data ",
          "main.Please_Select_only_one": "Please Select only one ",
          "main.User_doesn't_have_authority_to_edit_schedule":
            "User doesn't have authority to edit schedule",
          "main.Song_Deleted_Successfully": "Song Deleted Successfully",
          "main.New_User_Added_Successfully": "New User Added Successfully",
          "main.Please_Select_Only_One_User_to_Edit":
            "Please Select Only One User to Edit",
          "main.User_Edited_Successfully": "User Edited Successfully",
          "main.Please_Select_Any_User_to_Delete":
            "Please Select Any User to Delete",
          "main.User_Deleted_Successfully": "User Deleted Successfully",
          "main.Playlist_Setting_Updated_Successfully":
            "Playlist Setting Updated Successfully",
          "main.Site_Update_Successfully": "Site Update Successfully",
          "main.Setting_Updated_Successfully": "Setting Updated Successfully",
          "main.SOMETHING_WENT_WRONG": "SOMETHING WENT WRONG",
          "main.User_doesn't_have_authority_to_edit_song_category":
            "User doesn't have authority to edit song category",
          "main.User_doesn't_have_authority_to_edit_voice_wheel":
            "User doesn't have authority to edit voice wheel",
          "main.Error_Exporting_Audio": "Error Exporting Audio",
          "main.Audio_File_Saved_Successfully":
            "Audio File Zip Download Successfully",
          "main.Error_Saving_Audio_File": "Error Saving Audio File",
          "main.Error_Exporting_Encrypted_Audio":
            "Error Exporting Encrypted Audio",
          "main.Encrypted_Audio_File_Saved_Successfully":
            "Encrypted Audio File Saved Successfully",
          "main.Error_Saving_Encrypted_Audio_File":
            "Error Saving Encrypted Audio File",
          "main.user_doesn't_have_authority_to_edit_channel_playlist":
            "User doesn't have authority to edit channel playlist",
          "main.user_doesn't_have_authority_to_edit_schedule":
            "User doesn't have authority to edit schedule",
          "main.logged_out_successfully": "Logged Out Successfully",
          "main.user_have_no_authority_to_edit_channel_playlist":
            "User have no authority to edit channel playlist",
          "main.edit_user": "Edit User",
          "main.please_select_customer": "Please select customer",
          "main.select_site": "Select Site",
          "main.zone_name/title": "Zone Name / Title",
          "main.create_new_zone": "Create New Zone",
          "main.please_select_customer_and_site_to_get_zone_data":
            "Please select customer and site to get zone data",
          "main.zone_title": "Zone Title",
          "main.zone_config": "Zone Config",
          "main.synchronize_schedule": "Synchronize Schedule",
          "main.general": "General",
          "main.VST_config_file": "VST Config File",
          "main.VST_File": "VST File",
          "main.External_Effect": "External Effect",
          "main.target_music_dBV": "Target DB Value",
          "main.delete_zone": "Delete Zone",
          "main.EXPORT_XML_FILE": "EXPORT XML FILE",
          "main.hard": "hard",
          "main.medium": "Medium",
          "main.soft": "Soft",
          "main.automatic_gain_control": "Automatic Gain Control",
          "main.audio_processing": "Audio Processing",
          "main.system_sample_rate": "System Sample rate",
          "main.fade_on_zone_stop": "Fade on zone stop",
          "main.windows": "Windows",
          "main.ASIO": "ASIO",
          "main.audio_system_preferred": "Audio System Preferred",
          "main.audio_output": "Audio Output",
          "main.no_zones_found": "No Zones Found",
          "main.comment": "Comment",
          "main.select_group": "Select Group",
          "main.family_name": "Family Name",
          "main.username": "Username",
          "main.add_new_user": "Add New User",
          "main.voice_tracks": "Voice Tracks",
          "main.can_edit_override_function": "Can Edit Override Function",
          "main.can_edit_voice_tracks": "Can Edit Voice Tracks",
          "main.can_edit_voice_wheels": "Can Edit Voice Wheels",
          "main.can_edit_dropIns": "Can Edit DropIns",
          "main.can_edit_schedules": "Can Edit Schedules",
          "main.can_edit_channel_playlist": "Can Edit Channel Playlist",
          "main.can_edit_songs_category": "Can Edit Songs Category",
          "main.can_edit_songs": "Can Edit Songs",
          "main.add_new_group": "Add New group",
          "main.edit_group": "Edit Group",
          "main.song_category": "Song Category",
          "main.songs": "Songs",
          "main.admin": "Admin",
          "main.group": "Group",
          "main.last_name": "Last Name",
          "main.first_name": "First Name",
          "main.Load_Lic_files": "Load Lic files",
          "main.safe_Lic_files": "Safe Lic files",
          "main.set_default_values": "set default values",
          "main.override_player": "Override Player",
          "main.valid_until": "valid until",
          "main.drop-Ins": "Drop-Ins",
          "main.zone_number": "Zone Number",
          "main.license_Is_valid_until": "License Is Valid Until",
          "main.locations": "Locations",
          "main.levels": "Levels",
          "main.user_definition": "User definition",
          "main.zone_configuration": "Zone Configuration",
          "main.permissions": "Permissions",
          "main.licensing": "Licensing",
          "main.application_settings": "Application Settings",
          "main.select_destination_zone": "Select Destination Zone",
          "main.select_source_zone": "Select Source Zone",
          "main.3.select_destination_days": "3.Select Destination Days",
          "main.2.select_day-to_copy": "2.Select Day to Copy",
          "main.1.select_zone": "1.Select Zone",
          "main.end:mmdd": "End:mmdd",
          "main.start:mmddd": "Start:mmddd",
          "main.remove_dropin": "Remove Dropin",
          "main.new_dropin": "New Dropin",
          "main.manage_dropIns": "Manage DropIns",
          "main.edit_dropin": "Edit Dropin",
          "main.add_new_dropin": "Add New Dropin",
          "main.interval": "Interval",
          "main.edit_schedule_item": "Edit Schedule Item",
          "main.end_time_(hhmm)": "End Time (hhmm)",
          "main.start_time_(hhmm)": "Start Time (hhmm)",
          "main.channel_name": "Channel Name",
          "main.new_channel": "New Channel",
          "main.max_in_row": " Max in Row",
          "main.breakable?": "Breakable?",
          "main.enter_the_title_of_the_new_wheel":
            "Enter the title of the new Wheel",
          "main.name_site": "Name Site",
          "main.changed_audio_files": "Changed Audio files",
          "main.empty_audio_file": "Empty Audio File",
          "main.zone": "Zone",
          "main.day": "Day",
          "main.start_time": "Start Time",
          "main.channel": "Channel",
          "main.wheel": "Wheel",
          "main.dropIn": "DropIn",
          "main.switch_to_dark_mode": "Switch to Dark Mode",
          "main.item_not_found": "Item Not Found",
          "main.message_category": "Message Category",
          "main.remove_item": "Remove Item",
          "main.add_item": "Add Item",
          "main.new_wheel_designer": "New Wheel Designer",
          "main.remove_wheel": "Remove Wheel",
          "main.add_wheel": "Add Wheel",
          "main.wheel_manager": "Wheel Manager",
          "main.volume_offset": "Volume Offset",
          "main.add_new_category": "Add New Category",
          "main.new_music_categories": "New Music Categories",
          "main.volume": "Volume",
          "main.add_category": "Add Category",
          "main.category": "Category",
          "main.new_folder": "New Folder",
          "main.expire_date": "Expire Date",
          "main.commence_date": "Commence Date",
          "main.sunday": "Sunday",
          "main.saturday": "Saturday",
          "main.friday": "Friday",
          "main.thursday": "Thursday",
          "main.wednesday": "Wednesday",
          "main.tuesday": "Tuesday",
          "main.monday": "Monday",
          "main.export_BMS_v6_database": "Export BMS v6 Database",
          "main.Generate_new": "Generate New",
          "main.copy_ID_&_key": "Copy ID & Key",
          "main.client_secret_key": "Client Secret Key",
          "main.site_id": "Site ID",
          "main.contact_name": "Contact Name",
          "main.phone_nummer_3": "Phone Nummer 3",
          "main.phone_nummer_2": "Phone Nummer 2",
          "main.phone_nummer_1": "Phone Nummer 1",
          "main.post/area_Code": "Post/Area Code",
          "main.country": "Country",
          "main.address_3": "Address 3",
          "main.address_2": "Address 2",
          "main.address_1": "Address 1",
          "main.id": "id",
          "main.site_group": "Site Group",
          "main.site_name": "Site Name",
          "main.delete": "Delete",
          "main.new_site": "New site",
          "main.new_region": "New region",
          "main.fileName": "FileName",
          "main.priority": "Priority",
          "main.audio_filename": "Audio Filename",
          "main.creation_date": "Creation Date",
          "main.expiry_date": "Expiry Date",
          "main.release_year": "Release Year",
          "main.artist_3": "Artist 3",
          "main.artist_2": "Artist 2",
          "main.artist_1": "Artist 1",
          "main.title": "Title",
          "main.cancel": "Cancel",
          "main.choose_folder": "Choose Folder",
          "main.choose_files": "Choose Files",
          "main.choose_xml_file": "Choose XML File",
          "main.xml_file_import": "XML File Import",
          "main.Import": "Import",
          "main.import_file": "Import File",
          "main.import_to_playlist": "Import to Playlist",
          "main.file_name": "File Name",
          "main.folder_name": "Folder Name",
          "main.import_file(s)": "Import File(s)",
          "main.file_import": "File Import",
          "main.export_encrypted_audiofile": "Export Encrypted Audiofile",
          "main.export_audiofile": "Export Audiofile",
          "main.export_data": "Export Data",
          "main.export": "Export",
          "main.re-generate_channel_song_list": "Re-Generate Channel Song List",
          "main.duplicate_channel": "Duplicate Channel",
          "main.delete_channel": "Delete Channel",
          "main.apply_day-of-week_restrictions":
            "Apply Day-of-week Restrictions",
          "main.apply_time-of-day_restrictions":
            "Apply Time-of-day Restrictions",
          "main.apply_seasonal_restrictions": "Apply Seasonal Restrictions",
          "main.days_to_protect": "Days to protect",
          "main.active?": "Active?",
          "main.last_play_song_rule": "Last Play Song Rule",
          "main.yesterday_song": "Yesterday Song",
          "main.preferred_minutes_either_side_to_protect":
            "Preferred Minutes Either Side To Protect",
          "main.yesterday_artist": "Yesterday Artist",
          "main.yesterday_rules": "Yesterday Rules",
          "main.add_channel": "Add Channel",
          "main.delete_song": "Delete Song",
          "main.add_song": "Add Song",
          "main.sites": "Sites",
          "main.count": "Count",
          "main.static": "Static",
          "main.name": "Name",
          "main.save": "Save",
          "main.subscribed_sites": "Subscribed Sites",
          "main.channel_song_list": "Channel Song List",
          "main.channel_characterstics": "Channel Characteristics",
          "main.channel_configuration": "Channel Configuration",
          "main.rows_per_page": "Rows per page",
          "main.select_category": "Select Category",
          "main.select_customer": "Select Customer",
          "main.category_manager": "Category Manager",
          "main.search_library": "Search Library",
          "main.new_customer": "New customer",
          "main.customer": "Customer",
          "main.site": "Site",
          "main.site_subscriptions": "Site Subscriptions",
          "main.view_songs": "View Songs",
          "main.title/artist...": "Title/Artist...",
          "main.attributes": "Attributes",
          "main.played_songs": "Played Songs",
          "main.exact_time_play": "Exact Time Play",
          "main.filter_by": "Filter By",
          "main.agency_report": "Agency Report",
          "main.clear_filter": "Clear Filter",
          "main.add_content": "Add Content",
          "main.content_library/audio_editor": "Content Library / Voice Track Editor",
          "main.music_player/audio_editor": "Music Player / Audio Editor",
          "main.channel_editor": "Channel Editor",
          "main.content_library": "Content Library",
          "main.music_library": "Music Library",
          "main.manage_customer": " Manage Customer",
          "main.update": "Update",
          "main.logout": "Logout",
          "main.settings": "Settings",
          "main.problems_software":
            "Problems With Your Account or The Software?",
          "main.main_heading": "Welcome to The EBH Radio Software",
          "main.login_problem": "Problem with your Account or Software ?",
          "main.login": "Login",
          "main.contact_now": "Get in touch now",
          "main.import_audio": "Import Audio",
          "main.add_file": "Import  File",
          "main.add_folder": "Import Folder",
          "main.tools": "Tools",
          "main.add_playlist": "Add Playlist",
          "main.delete_playlist": "Delete Playlist",
          "main.playlist_songs": "Playlist Songs",
          "main.playlist_settings": "Playlist Settings",
          "main.playlist": "Playlist",
          "main.channel_list": "Channel list",
          "main.no_records": "No records to display",
          "main.add_schedule_item": "Add Schedule Item",
          "main.delete_schedule_item": "Delete Schedule Item",
          "main.duplicate_day": "Duplicate Day",
          "main.duplicate_zone": "Duplicate Zone",
          "main.channel_scheduler": "Channel Scheduler",
          "main.zone_player": "Zone Player",
          "main.License_Key": "License Key",
          "main.Select_Customer": "Select Customer First",
        },
      },
      de: {
        translation: {
          "main.Dropins": "Dropins",
          "main.Remove_Item": "Gegenstand entfernen",
          "main.AddItem": "Artikel hinzufügen",
          "main.Tools": "Werkzeuge",
          "main.Add_File": "Datei hinzufügen",
          "main.Add_Folder": "Ordner hinzufügen",
          "main.Sites": "Sites",
          "main.Delete_Group": "Gruppe löschen",
          "main.Add_group": "Gruppe hinzufügen",
          "main.Date": "Datum",
          "main.Enter_Comments": "Kommentare eingeben",
          "main.Delete_user": "Benutzer löschen",
          "main.Add_User": "Benutzer hinzufügen",
          "main.No_Category_Found": "Keine Kategorie gefunden",
          "main.userRole_Choose": "Auswählen",
          "main.Select_Profile": "Profil auswählen",
          "main.Ripper_Dialog": "Ripper-Dialog",
          "main.import_Open_btn": "Öffnen",
          "main.No_Customer_Found": "Kein Kunde gefunden",
          "main.select_voicetrack_category":
            "Bitte wählen Sie die Sprachspurkategorie",
          "main.Deleted_Successfully": "Website erfolgreich gelöscht",
          "main.No_Customer": "Kein Kunde",
          "main.no_wheel_found": "Kein Rad gefunden",
          "main.Exact_Time_Manager": "Genauer Zeitmanager",
          "main.Immediate": "Unmittelbar",
          "main.padSeconds": "Sekunden polstern",
          "main.Music_Fade": "Musik verblassen",
          "main.Message": "Nachricht",
          "main.Exact_Time": "Exakte Zeit",
          "main.New_Time_Manager": "Neuer Zeitmanager",
          "main.New_Announcement": "Neue Ankündigung",
          "main.Remove_btn": "Entfernen",
          "main.Announcement_Categories": "Ankündigungskategorien",
          "main.Failed_to_Edit_Site_Data":
            "Fehler beim Bearbeiten der Standortdaten",
          "main.This_Folder_Doesnot_Contain_mp3_File":
            "Dieser Ordner enthält keine mp3-Datei",
          "main.This_Folder_Contains_Files_Other_That_mp3":
            "Dieser Ordner enthält Dateien, die keine mp3-Dateien sind",
          "main.low_priorty": "Niedrig",
          "main.mid_priorty": "Mittel",
          "main.high_priorty": "Hoch",
          "main.Action": "Aktion",
          "main.Attribute_Edited": "Attribut erfolgreich bearbeitet",
          "main.Auto_Set_Markers": "Automatische Markierung setzen",
          "main.Set_Mix": "Mix einstellen",
          "main.Set_Start": "Start einstellen",
          "main.No_Device_Connected": "Kein Gerät verbunden",
          "main.Pause": "Pause",
          "main.Play": "Abspielen",

          "main.RangeStart": "Start der Spanne",
          "main.RangeEnd": "Ende der Spanne",
          "main.Site": "Ort",
          "main.DropIns": "DropIns",
          "main.Wheel_Designer": "Rad-Designer",
          "main.Zone_Player/Music_Library": "Zonenplayer / Musikbibliothek",
          "main.List_of_uploaded_voice_tracks":
            "Liste der hochgeladenen Sprachspuren",
          "main.List_of_uploaded_songs": "Liste der hochgeladenen Lieder",
          "main.Music_Player": "Musikplayer",
          "main.Dashboard": "Armaturenbrett",
          "main.Channel_Time_Schedule": "Zeitplan des Kanals",
          "main.Channel_List_Editor": "Kanal-Listen-Editor",
          "main.Audio_Editor/Song_Data": "Audio-Editor / Song-Daten",
          "main.Settings/Group_Permission":
            "Einstellungen / Gruppenberechtigung",
          "main.Add_New_Site": "Neue Seite hinzufügen",
          "main.Edit_Site": "Seite bearbeiten",
          "main.Edit_Region_For_Customer": "Region für Kunden bearbeiten",
          "main.New_Region_For_Customer": "Neue Region für Kunden",
          "main.Are_you_sure_to_delete_this_song":
            "Möchten Sie dieses Lied wirklich löschen?",
          "main.Are_you_sure_to_delete_this_channelList":
            "Möchten Sie diese Kanalliste wirklich löschen?",
          "main.Are_you_sure_to_delete_this_Customer":
            "Möchten Sie diesen Kunden wirklich löschen?",
          "main.Are_you_sure_to_delete_this":
            "Möchten Sie dies wirklich löschen?",
          "main.Are_you_sure_to_delete_this_Channel_Schedule":
            "Möchten Sie diesen Kanalplan wirklich löschen?",
          "main.No_Zone_Found": "Keine Zone gefunden",
          "main.Confirm": "Bestätigen",
          "main.Region_Name": "Nom de la région",
          "main.Contact_Name": "Kontaktname",
          "main.Phone_Number": "Telefonnummer",
          "main.Post/Area_Code": "Postleitzahl/Vorwahl",
          "main.Country": "Land",
          "main.Address": "Adresse",
          "main.Customer_Name": "Kundenname",
          "main.Edit_Customer": "Kunde bearbeiten",
          "main.Add_New_Customer": "Neuen Kunden hinzufügen",
          "main.No_Lic_Found": "Keine Lizenz gefunden",
          "main.VoiceTrack_ID": "Sprachspur-ID",
          "main.voice_track_Category": "voiceTrack-Kategorie",
          "main.Song_ID": "Song-ID",
          "main.Week_Days": "Wochentage",
          "main.user_doesn't_have_authority_to_edit_channel_playlist":
            "Der Benutzer ist nicht berechtigt, die Kanalwiedergabeliste zu bearbeiten",
          "main.User_have_no_authority_to_edit_song":
            "Der Benutzer hat keine Berechtigung zum Bearbeiten von Songs",
          "main.Zone_Player/Channel_Scheduler": "Zone Player / Kanalplaner",
          "main.Delete": "Löschen",
          "main.Download": "Herunterladen",
          "main.Upload": "Hochladen",
          "main.Edit": "Bearbeiten",
          "main.Add": "Hinzufügen",
          "main.Attribute_Name": "Attributname",
          "main.Last_Modified": "Zuletzt geändert",
          "main.Zone_ID": "Bereichs-ID",
          "main.Play_date": "Abspiel-Datum",
          "main.Lookup": "Suchen",
          "main.External_Meta": "Externe Metadaten",
          "main.Track_artwork": "Titelgrafik",
          "main.User_defined_Fields": "Benutzerdefinierte Felder",
          "main.To": "Bis",
          "main.From": "Von",
          "main.Seasonality": "Saisonalität",
          "main.Time_of_Day": "Tageszeit",
          "main.Use_expire_date?": "Ablaufdatum verwenden?",
          "main.Expire": "Ablauf",
          "main.Use_commence_date?": "Beginndatum verwenden?",
          "main.Commence": "Beginn",
          "main.Exact_Dates": "Genau Datum",
          "main.Reset": "Zurücksetzen",
          "main.Output_device": "Ausgabegerät",
          "main.Audio": "Audio",
          "main.Type": "Typ",
          "main.Year": "Jahr",
          "main.Artist": "Künstler",
          "main.Metadata": "Metadaten",
          "main.Voice_Track_Data": "Sprachtrack-Daten",
          "main.Cloud": "Cloud",
          "main.History": "Verlauf",
          "main.Distribution": "Verteilung",
          "main.Channel_Assignments": "Kanalzuweisungen",
          "main.Characteristics": "Eigenschaften",
          "main.Meta": "Meta",
          "main.User_Defined": "Benutzerdefiniert",
          "main.Restrictions": "Einschränkungen",
          "main.Song_Data": "Song-Daten",
          "main.Back": "Zurück",
          "main.Audio_characteristics_Updated_Successfully":
            "Audiomerkmale erfolgreich aktualisiert",
          "main.Voice_Track_Updated_Successfully":
            "Sprachspur erfolgreich aktualisiert",
          "main.Data_Not_Found": "Daten nicht gefunden",
          "main.Audio_Updated_Successfully": "Audio erfolgreich aktualisiert",
          "main.Channel_Unsubscribed_Successfully":
            "Kanal erfolgreich abgemeldet",
          "main.Channel_Subscribed_Successfully": "Kanal erfolgreich abonniert",
          "main.Please_Add_New_Attribute":
            "Bitte fügen Sie ein neues Attribut hinzu",
          "main.Attribute_Added_Successfully":
            "Attribut erfolgreich hinzugefügt",
          "main.Please_Enter_Attribute_to_Edit":
            "Bitte geben Sie das zu bearbeitende Attribut ein",
          "main.Attribute_Edited_Successfully":
            "Attribut erfolgreich bearbeitet",
          "main.Attribute_Is_Deleted": "Attribut wurde gelöscht",
          "main.Not_Found": "Nicht gefunden",
          "main.Select_The_Attributes": "Wählen Sie die Attribute aus",
          "main.Data_Uploaded_from_Cloud_Successfully":
            "Daten erfolgreich aus der Cloud hochgeladen",
          "main.Data_Downloaded_from_Cloud_Successfully":
            "Daten erfolgreich aus der Cloud heruntergeladen",
          "main.Data_Deleted_from_Cloud_Successfully":
            "Daten erfolgreich aus der Cloud gelöscht",
          "main.Logged_in_Successfully": "Erfolgreich eingeloggt",
          "main.This_Folder_Doesn't_Contain_Any_File":
            "Dieser Ordner enthält keine Dateien",
          "main._clientSecretKey_Copyed": "clientSecretKey kopiert",
          "main.siteUniqueId_&_clientSecretKey_Copyed":
            "siteUniqueId & clientSecretKey kopiert",
          "main.Voice_Track_Uploaded_Successfully":
            "Sprachspur erfolgreich hochgeladen",
          "main.Audio_Uploaded_Successfully": "Audio erfolgreich hochgeladen",
          "main.SOMETHING_IS_BREAKING": "ETWAS BRICHT",
          "main.File_Imported_Successfully": "Datei erfolgreich importiert",
          "main.Channel_Created_Successfully": "Kanal erfolgreich erstellt",
          "main.Song_Added_Successfully": "Song erfolgreich hinzugefügt",
          "main.Customer_Updated_Successfully":
            "Kunde erfolgreich aktualisiert",
          "main.New_Customer_Added_Successfully":
            "Neuer Kunde erfolgreich hinzugefügt",
          "main.Failed_to_Save_Customer_Data":
            "Fehler beim Speichern der Kundendaten",
          "main.Failed_to_Fetch_Customer_Data":
            "Fehler beim Abrufen der Kundendaten",
          "main.Region_Updated_Successfully": "Region erfolgreich aktualisiert",
          "main.Region_Added_successfully": "Region erfolgreich hinzugefügt",
          "main.Failed_to_Save_Region_Data":
            "Fehler beim Speichern der Regionsdaten",
          "main.Failed_to_fetch_Region_data":
            "Fehler beim Abrufen der Regionsdaten",
          "main.New_Site_Added": "Neue Seite hinzugefügt",
          "main.Failed_to_Save_Site_Data":
            "Fehler beim Speichern der Standortdaten",
          "main.Failed_to_fetch_Site_data":
            "Fehler beim Abrufen der Standortdaten",
          "main.Schedule_Updated_Successfully":
            "Zeitplan erfolgreich aktualisiert",
          "main.Category_Deleted_Successfully":
            "Kategorie erfolgreich gelöscht",
          "main.Category_Updated_successfully":
            "Kategorie erfolgreich aktualisiert",
          "main.Error_Occurred_While_Updating_Category":
            "Fehler beim Aktualisieren der Kategorie aufgetreten",
          "main.New_Category_Created_Successfully":
            "Neue Kategorie erfolgreich erstellt",
          "main.Error_Occurred_While_Saving_Category":
            "Fehler beim Speichern der Kategorie aufgetreten",
          "main.New_Wheel_Created_Successfully":
            "Neues Rad erfolgreich erstellt",
          "main.Update_Item_Position_Successfully":
            "Position des Elements erfolgreich aktualisiert",
          "main.Please_Select_Wheel_to_Add_Item":
            "Bitte wählen Sie das Rad aus, um ein Element hinzuzufügen",
          "main.Wheel_Deleted_Successfully": "Rad erfolgreich gelöscht",
          "main.Please_Select_Item_First":
            "Bitte wählen Sie zuerst ein Element aus",
          "main.Dropin_Update_Successfully": "Dropin erfolgreich aktualisiert",
          "main.Data_Deleted_Successfully": "Daten erfolgreich gelöscht",
          "main.Please_Select_Any_Dropin_to_Remove":
            "Bitte wählen Sie einen beliebigen Dropin zum Entfernen aus",
          "main.User_doesn't_have_authority_to_edit_dropins":
            "Benutzer hat keine Berechtigung zum Bearbeiten von Dropins",
          "main.Schedule_Item_Deleted_Successfully":
            "Zeitelement erfolgreich gelöscht",
          "main.ChannelList_Deleted_Successfully":
            "ChannelList erfolgreich gelöscht",
          "main.Duplicate_Day_Created_Successfully":
            "Duplikat-Tag erfolgreich erstellt",
          "main.Schedules_Are_Not_Available": "Zeitpläne sind nicht verfügbar",
          "main.Duplicate_Zone_Created_Successfully":
            "Duplikat-Zone erfolgreich erstellt",
          "main.Channel_Name_Updated_Successfully":
            "Kanalname erfolgreich aktualisiert",
          "main.Schedule_Created_Successfully": "Zeitplan erfolgreich erstellt",
          "main.User_doesn't_have_authority_to_edit_license":
            "Benutzer hat keine Berechtigung zum Bearbeiten der Lizenz",
          "main.Licensing_Data_Posted_Successfully":
            "Lizenzdaten erfolgreich übermittelt",
          "main.Licensing_Data_Updated_Successfully":
            "Lizenzdaten erfolgreich aktualisiert",
          "main.License_Data_According_To_Customer": "Lizenzdaten gemäß Kunde",
          "main.License_Data_According_To_Site": "Lizenzdaten gemäß Standort",
          "main.Please_Select_Site": "Bitte wählen Sie den Standort aus",
          "main.Data_Is_Updated": "Daten wurden aktualisiert",
          "main.Zone_Deleted_Successfully": "Zone erfolgreich gelöscht",
          "main.File_Is_Downloaded": "Datei wurde heruntergeladen",
          "main.Postion_can't_be_change_until_data_is_saved":
            "Position kann erst geändert werden, wenn die Daten gespeichert sind",
          "main.Please_fill_the_form": "Bitte füllen Sie das Formular aus",
          "main.New_User_Group_Added_Successfully":
            "Neue Benutzergruppe erfolgreich hinzugefügt",
          "main.Group_Edited_Successfully": "Gruppe erfolgreich bearbeitet",
          "main.Please_Select_Any_Group_to_Delete":
            "Bitte wählen Sie eine Gruppe zum Löschen aus",
          "main.Group_Deleted_Successfully": "Gruppe erfolgreich gelöscht",
          "main.User_have_no_authority_to_edit_group_data":
            "Benutzer hat keine Berechtigung zum Bearbeiten von Gruppendaten",
          "main.Please_Select_only_one": "Bitte wählen Sie nur eine aus",
          "main.User_doesn't_have_authority_to_edit_schedule":
            "Benutzer hat keine Berechtigung zum Bearbeiten des Zeitplans",
          "main.Song_Deleted_Successfully": "Song erfolgreich gelöscht",
          "main.New_User_Added_Successfully":
            "Neuer Benutzer erfolgreich hinzugefügt",
          "main.Please_Select_Only_One_User_to_Edit":
            "Bitte wählen Sie nur einen Benutzer zum Bearbeiten aus",
          "main.User_Edited_Successfully": "Benutzer erfolgreich bearbeitet",
          "main.Please_Select_Any_User_to_Delete":
            "Bitte wählen Sie einen beliebigen Benutzer zum Löschen aus",
          "main.User_Deleted_Successfully": "Benutzer erfolgreich gelöscht",
          "main.Playlist_Setting_Updated_Successfully":
            "Playlist-Einstellungen erfolgreich aktualisiert",
          "main.Site_Update_Successfully": "Standort erfolgreich aktualisiert",
          "main.Setting_Updated_Successfully":
            "Einstellungen erfolgreich aktualisiert",
          "main.SOMETHING_WENT_WRONG": "ETWAS IST SCHIEF GELAUFEN",
          "main.User_doesn't_have_authority_to_edit_song_category":
            "Benutzer hat keine Berechtigung zum Bearbeiten der Songkategorie",
          "main.User_doesn't_have_authority_to_edit_voice_wheel":
            "Benutzer hat keine Berechtigung zum Bearbeiten des Sprachrads",
          "main.Error_Exporting_Audio": "Fehler beim Exportieren von Audio",
          "main.Audio_File_Saved_Successfully":
            "Audiodatei-Zip-Download erfolgreich",
          "main.Error_Saving_Audio_File":
            "Fehler beim Speichern der Audio-Datei",
          "main.Error_Exporting_Encrypted_Audio":
            "Fehler beim Exportieren von verschlüsseltem Audio",
          "main.Encrypted_Audio_File_Saved_Successfully":
            "Verschlüsselte Audio-Datei erfolgreich gespeichert",
          "main.Error_Saving_Encrypted_Audio_File":
            "Fehler beim Speichern der verschlüsselten Audio-Datei",
          "main.user_doesn't_have_authority_to_edit_schedule":
            "Der Benutzer ist nicht berechtigt, den Zeitplan zu bearbeiten",
          "main.logged_out_successfully": "Erfolgreich abgemeldet",
          "main.user_have_no_authority_to_edit_channel_playlist":
            "Der Benutzer hat keine Berechtigung zum Bearbeiten der Kanal-Playlist",
          "main.please_select_customer": "Bitte Kunden auswählen",
          "main.select_site": "Standort auswählen",
          "main.zone_name/title": "Zonenname / Titel",
          "main.create_new_zone": "Neue Zone erstellen",
          "main.please_select_customer_and_site_to_get_zone_data":
            "Bitte wählen Sie Kunde und Standort aus, um Zonendaten zu erhalten",
          "main.zone_title": "Zonentitel",
          "main.zone_config": "Zonenkonfiguration",
          "main.synchronize_schedule": "Zeitplan synchronisieren",
          "main.general": "Allgemein",
          "main.VST_config_file": "VST Konfigurationsdatei",
          "main.VST_File": "VST Datei",
          "main.External_Effect": "Externer Effekt",
          "main.target_music_dBV": "Target DB Wert",
          "main.delete_zone": "Zone löschen",
          "main.EXPORT_XML_FILE": "XML-Datei exportieren",
          "main.hard": "Stark",
          "main.medium": "Mittel",
          "main.soft": "Sanft",
          "main.automatic_gain_control": "Automatische Verstärkungsregelung",
          "main.audio_processing": "Audioverarbeitung",
          "main.system_sample_rate": "System-Sample-Rate",
          "main.fade_on_zone_stop": "Übergang beim Stoppen der Zone",
          "main.windows": "Windows",
          "main.ASIO": "ASIO",
          "main.audio_system_preferred": "Bevorzugtes Audio-System",
          "main.audio_output": "Audioausgabe",
          "main.no_zones_found": "Keine Zonen gefunden",
          "main.add_new_user": "Neuen Benutzer hinzufügen",
          "main.comment": "Kommentar",
          "main.select_group": "Gruppe auswählen",
          "main.family_name": "Familienname",
          "main.username": "Benutzername",
          "main.voice_tracks": "Sprachspuren",
          "main.can_edit_override_function":
            "Kann die Überschreibungsfunktion bearbeiten",
          "main.can_edit_voice_tracks": "Kann Sprachspuren bearbeiten",
          "main.can_edit_voice_wheels": "Kann Sprachräder bearbeiten",
          "main.can_edit_dropIns": "Kann Drop-Ins bearbeiten",
          "main.can_edit_schedules": "Kann Zeitpläne bearbeiten",
          "main.can_edit_channel_playlist": "Kann Kanalplaylist bearbeiten",
          "main.can_edit_songs_category": "Kann Song-Kategorie bearbeiten",
          "main.can_edit_songs": "Kann Songs bearbeiten",
          "main.add_new_group": "Neue Gruppe hinzufügen",
          "main.edit_group": "Gruppe bearbeiten",
          "main.song_category": "Song-Kategorie",
          "main.songs": "Songs",
          "main.admin": "Admin",
          "main.group": "Gruppe",
          "main.last_name": "Nachname",
          "main.first_name": "Vorname",
          "main.Load_Lic_files": "Lade Lizenzdateien",
          "main.safe_Lic_files": "Speichere Lizenzdateien",
          "main.set_default_values": "Setze Standardwerte",
          "main.override_player": "Überschreibe Player",
          "main.valid_until": "Gültig bis",
          "main.drop-Ins": "Drop-Ins",
          "main.zone_number": "Zonenummer",
          "main.license_Is_valid_until": "Lizenz ist gültig bis",
          "main.locations": "Standorte",
          "main.levels": "Ebenen",
          "main.user_definition": "Benutzerdefinition",
          "main.zone_configuration": "Zonenkonfiguration",
          "main.permissions": "Berechtigungen",
          "main.licensing": "Lizenzierung",
          "main.application_settings": "Anwendungseinstellungen",
          "main.select_destination_zone": "Zielzone auswählen",
          "main.select_source_zone": "Quellzone auswählen",
          "main.3.select_destination_days": "3. Ziel-Tage auswählen",
          "main.2.select_day-to_copy": "2. Tag zum Kopieren auswählen",
          "main.1.select_zone": "1. Zone auswählen",
          "main.end:mmdd": "Ende:MM TT",
          "main.start:mmddd": "Beginn: MM TT",
          "main.remove_dropin": "Dropin entfernen",
          "main.new_dropin": "Neuer Dropin",
          "main.manage_dropIns": "DropIns verwalten",
          "main.edit_dropin": "Dropin bearbeiten",
          "main.add_new_dropin": "Neues Dropin hinzufügen",
          "main.interval": "Intervall",
          "main.edit_schedule_item": "Zeitplanelement bearbeiten",
          "main.end_time_(hhmm)": "Endzeit (hhmm)",
          "main.start_time_(hhmm)": "Startzeit (hhmm)",
          "main.channel_name": "Kanal Name",
          "main.new_channel": "Neuer Kanal",
          "main.max_in_row": "Max in der Reihe",
          "main.breakable?": "zerbrechlich?",
          "main.enter_the_title_of_the_new_wheel":
            "Geben Sie den Titel des neuen Rades ein",
          "main.name_site": "Benennen Sie die Site",
          "main.changed_audio_files": "Geänderte Audiodateien",
          "main.empty_audio_file": "Leere Audiodatei",
          "main.zone": "Zone",
          "main.day": "Tag",
          "main.start_time": "Startzeit",
          "main.channel": "Kanal",
          "main.wheel": "Rad",
          "main.dropIn": "Einsteigen",
          "main.switch_to_dark_mode": "Wechseln Sie in den Dunkelmodus",
          "main.item_not_found": "Element nicht gefunden",
          "main.message_category": "Nachrichtenkategorie",
          "main.remove_item": "Element entfernen",
          "main.add_item": "Element hinzufügen",
          "main.remove_wheel": "Rad entfernen",
          "main.add_wheel": "Rad hinzufügen",
          "main.new_wheel_designer": "Neuer Raddesigner",
          "main.wheel_manager": "Rad-Manager",
          "main.volume_offset": "Lautstärke-Offset",
          "main.add_new_category": "Neue Kategorie hinzufügen",
          "main.new_music_categories": "Neue Musik-Kategorien",
          "main.volume": "Lautstärke",
          "main.add_category": "Kategorie hinzufügen",
          "main.category": "Kategorie",
          "main.new_folder": "Neuer Ordner",
          "main.expire_date": "Ablaufdatum",
          "main.commence_date": "Startdatum",
          "main.sunday": "Sonntag",
          "main.saturday": "Samstag",
          "main.friday": "Freitag",
          "main.thursday": "Donnerstag",
          "main.wednesday": "Mittwoch",
          "main.tuesday": "Dienstag",
          "main.monday": "Montag",
          "main.export_BMS_v6_database": "BMS v6 Datenbank exportieren",
          "main.Generate_new": "Neu generieren",
          "main.copy_ID_&_key": "ID & Schlüssel kopieren",
          "main.client_secret_key": "Client Geheimschlüssel",
          "main.site_id": "Standort-ID",
          "main.contact_name": "Kontaktname",
          "main.phone_nummer_3": "Telefonnummer 3",
          "main.phone_nummer_2": "Telefonnummer 2",
          "main.phone_nummer_1": "Telefonnummer 1",
          "main.post/area_Code": "Post-/Bereichscode",
          "main.country": "Land",
          "main.address_3": "Adresse 3",
          "main.address_2": "Adresse 2",
          "main.address_1": "Adresse 1",
          "main.id": "ID",
          "main.site_group": "Standortgruppe",
          "main.site_name": "Standortname",
          "main.delete": "Löschen",
          "main.new_site": "Neue Seite",
          "main.new_region": "Neue Region",
          "main.fileName": "Dateiname",
          "main.priority": "Priorität",
          "main.audio_filename": "Audio-Dateiname",
          "main.creation_date": "Erstellungsdatum",
          "main.expiry_date": "Ablaufdatum",
          "main.release_year": "Veröffentlichungsjahr",
          "main.artist_3": "Künstler 3",
          "main.artist_2": "Künstler 2",
          "main.artist_1": "Künstler 1",
          "main.title": "Titel",
          "main.cancel": "Stornieren",
          "main.choose_folder": "Wähle einen Ordner",
          "main.choose_files": "Wähle Dateien",
          "main.choose_xml_file": "Wähle Dateien",
          "main.xml_file_import": "XML-Dateiimport",
          "main.Import": "Importieren",
          "main.import_file": "Datei importieren",
          "main.import_to_playlist": "In Playlist importieren",
          "main.file_name": "Dateiname",
          "main.import_file(s)": "Datei(en) importieren",
          "main.file_import": "Dateiimport",
          "main.folder_name": "Ordnernamen",
          "main.export_encrypted_audiofile":
            "Verschlüsselte Audiodatei exportieren",
          "main.export_audiofile": "Audiodatei exportieren",
          "main.export_data": "Daten exportieren",
          "main.export": "Export",
          "main.re-generate_channel_song_list":
            "Generieren Sie die Songliste des Kanals neu",
          "main.duplicate_channel": "Doppelter Kanal",
          "main.delete_channel": "Kanal löschen",
          "main.apply_day-of-week_restrictions":
            "Wochentagsbeschränkungen anwenden",
          "main.apply_time-of-day_restrictions":
            "Tageszeitbeschränkungen anwenden",
          "main.apply_seasonal_restrictions":
            "Saisonale Beschränkungen anwenden",
          "main.days_to_protect": "Zu schützende Tage",
          "main.active?": "Aktiv?",
          "main.last_play_song_rule": "Letzte abgespielte Song-Regel",
          "main.yesterday_song": "Gestriger Song",
          "main.preferred_minutes_either_side_to_protect":
            "Bevorzugte Minuten zum Schutz auf beiden Seiten",
          "main.yesterday_artist": "Gestriger Künstler",
          "main.yesterday_rules": "Gestrige Regeln",
          "main.add_channel": "Kanal hinzufügen",
          "main.delete_song": "Song löschen",
          "main.add_song": "Song hinzufügen",
          "main.sites": "Seiten",
          "main.count": "Anzahl",
          "main.static": "Statisch",
          "main.name": "Name",
          "main.save": "Speichern",
          "main.subscribed_sites": "Abonnierte Seiten",
          "main.channel_song_list": "Kanal-Songliste",
          "main.channel_characterstics": "Kanalmerkmale",
          "main.channel_configuration": "Kanal-Konfiguration",
          "main.rows_per_page": "Zeilen pro Seite",
          "main.select_category": "Kategorie wählen",
          "main.select_customer": "Kunden auswählen",
          "main.category_manager": "Kategoriemanager",
          "main.search_library": "Bibliothek durchsuchen",
          "main.new_customer": "Neukunde",
          "main.customer": "Kunde",
          "main.site": "Website",
          "main.site_subscriptions": "Site-Abonnements",
          "main.view_songs": "Songs anzeigen",
          "main.title/artist...": "Titel/Künstler...",
          "main.attributes": "Attributes",
          "main.played_songs": "Played Songs",
          "main.exact_time_play": "Genaues Zeitspiel",
          "main.filter_by": "Filtern nach",
          "main.agency_report": "Agenturbericht",
          "main.clear_filter": "klarer Filter",
          "main.add_content": "Inhalt hinzufügen",
          "main.content_library/audio_editor":
            "Inhalt  Bibliothek/Sprachspur-Editor",
          "main.music_player/audio_editor": "Musik-Player / Audio-Editor",
          "main.channel_scheduler": "Kanalplaner",
          "main.channel_editor": "Kanaleditor",
          "main.content_library": "Inhaltsbibliothek",
          "main.music_library": "Musiksammlung",
          "main.manage_customer": "Kunden verwalten",
          "main.update": "Aktualisieren",
          "main.logout": "Ausloggen",
          "main.settings": "Einstellungen",
          "main.problems_software":
            "Probleme mit ihrem Konto oder der Software ?",
          "main.main_heading": "Willkommen in der EBH Radio Software",
          "main.login_problem": "Probleme mit ihrem Konto oder der Software ?",
          "main.login": "Einloggen",
          "main.contact_now": "Jetzt Kontakt aufnehmen",
          "main.import_audio": "Audio importieren",
          "main.add_file": "Datei hinzufügen",
          "main.add_folder": "Ordner hinzufügen",
          "main.tools": "Werkzeuge",
          "main.add_playlist": "Playlist hinzufügen",
          "main.delete_playlist": "Playlist löschen",
          "main.playlist_songs": "Playlist Lieder",
          "main.playlist_settings": "Einstellungen der Playlist",
          "main.playlist": "Playlist",
          "main.channel_list": "Kanal-Liste",
          "main.no_records": "Keine Datensätze zum Anzeigen",
          "main.add_schedule_item": "Schedule Item hinzufügen",
          "main.delete_schedule_item": "Schedule Item löschen",
          "main.duplicate_day": "Tag duplizieren",
          "main.duplicate_zone": "Zone duplizieren",
          "main.zone_player": "Zone Spieler",
          "main.License_Key": "Lizenzschlüssel",
          "main.Select_Customer": "Wählen Sie zuerst einen Kunden aus",
        },
      },
      fr: {
        translation: {
          "main.Dropins": "Dropins",
          "main.Remove_Item": "Retirer l'objet",
          "main.AddItem": "Ajouter un item",
          "main.Tools": "Outils",
          "main.Add_File": "Ajouter un fichier",
          "main.Add_Folder": "Ajouter un dossier",
          "main.Sites": "Des sites",
          "main.Delete_Group": "Supprimer le groupe",
          "main.Add_group": "Ajouter un groupe",
          "main.Date": "Date",
          "main.Enter_Comments": "Saisir des commentaires",
          "main.Delete_user": "Supprimer l'utilisateur",
          "main.Add_User": "Ajouter un utilisateur",
          "main.No_Category_Found": "Aucune catégorie trouvée",
          "main.userRole_Choose": "Choisir",
          "main.Select_Profile": "Sélectionner le profil",
          "main.Ripper_Dialog": "Dialogue Ripper",
          "main.import_Open_btn": "Ouvrir",
          "main.No_Customer_Found": "Aucun client trouvé",
          "main.select_voicetrack_category":
            "Veuillez sélectionner la catégorie de piste vocale",
          "main.Deleted_Successfully": "Site supprimé avec succès",
          "main.No_Customer": "Aucun client",
          "main.no_wheel_found": "Aucune roue trouvée",
          "main.Exact_Time_Manager": "Gestionnaire de temps exact",
          "main.Immediate": "Immédiat",
          "main.padSeconds": "Secondes de rembourrage",
          "main.Music_Fade": "Atténuation de la musique",
          "main.Message": "Message",
          "main.Exact_Time": "Temps exact",
          "main.New_Time_Manager": "Nouveau gestionnaire de temps",
          "main.New_Announcement": "Nouvelle annonce",
          "main.Remove_btn": "Supprimer",
          "main.Announcement_Categories": "Catégories d'annonces",
          "main.Failed_to_Edit_Site_Data":
            "Échec de la modification des données du site",
          "main.This_Folder_Doesnot_Contain_mp3_File":
            "Ce dossier ne contient pas de fichier mp3",
          "main.This_Folder_Contains_Files_Other_That_mp3":
            "Ce dossier contient des fichiers autres que mp3",
          "main.low_priorty": "Faible priorité",
          "main.mid_priorty": "Moyenne priorité",
          "main.high_priorty": "Haute priorité",
          "main.Action": "Action",
          "main.Attribute_Edited": "Attribut modifié avec succès",
          "main.Auto_Set_Markers": "Réglage automatique des marqueurs",
          "main.Set_Mix": "Définir le mixage",
          "main.Set_Start": "Définir le début",
          "main.No_Device_Connected": "Aucun appareil connecté",
          "main.Pause": "Pause",
          "main.Play": "Lecture",
          "main.RangeStart": "Début de la plage",
          "main.RangeEnd": "Fin de la plage",
          "main.Site": "Site",
          "main.DropIns": "DropIns",
          "main.Wheel_Designer": "Conception de roues",
          "main.Zone_Player/Music_Library":
            "Lecteur de zone / Bibliothèque musicale",
          "main.List_of_uploaded_voice_tracks":
            "Liste des pistes vocales téléchargées",
          "main.List_of_uploaded_songs": "Liste des chansons téléchargées",
          "main.Music_Player": "Lecteur de musique",
          "main.Dashboard": "Tableau de bord",
          "main.Channel_Time_Schedule": "Programme horaire de la chaîne",
          "main.Channel_List_Editor": "Éditeur de liste de chaînes",
          "main.Audio_Editor/Song_Data": "Éditeur audio / Données de chanson",
          "main.Settings/Group_Permission":
            "Paramètres / Autorisation de groupe",
          "main.Add_New_Site": "Ajouter un nouveau site",
          "main.Edit_Site": "Modifier le site",
          "main.Edit_Region_For_Customer": "Modifier la région pour le client",
          "main.New_Region_For_Customer": "Nouvelle région pour le client",
          "main.Are_you_sure_to_delete_this_song":
            "Êtes-vous sûr de vouloir supprimer cette chanson ?",
          "main.Are_you_sure_to_delete_this_channelList":
            "Êtes-vous sûr de vouloir supprimer cette liste de chaînes ?",
          "main.Are_you_sure_to_delete_this_Customer":
            "Êtes-vous sûr de vouloir supprimer ce client ?",
          "main.Are_you_sure_to_delete_this":
            "Êtes-vous sûr de vouloir supprimer ceci ?",
          "main.Are_you_sure_to_delete_this_Channel_Schedule":
            "Êtes-vous sûr de vouloir supprimer ce programme de chaîne ?",
          "main.No_Zone_Found": "Aucune zone trouvée",
          "main.Confirm": "Confirmer",
          "main.Region_Name": "Regionsname",
          "main.Contact_Name": "Nom du contact",
          "main.Phone_Number": "Numéro de téléphone",
          "main.Post/Area_Code": "Code postal/régional",
          "main.Country": "Pays",
          "main.Address": "Adresse",
          "main.Customer_Name": "Nom du client",
          "main.Edit_Customer": "Modifier le client",
          "main.Add_New_Customer": "Ajouter un nouveau client",
          "main.No_Lic_Found": "Aucune licence trouvée",
          "main.voice_track_Category": "Catégorie de piste vocale",
          "main.VoiceTrack_ID": "ID de piste vocale",
          "main.Song_ID": "ID de la chanson",
          "main.Week_Days": "Jours de la semaine",
          "main.user_doesn't_have_authority_to_edit_channel_playlist":
            "L'utilisateur n'est pas autorisé à modifier la playlist de la chaîne",
          "main.User_have_no_authority_to_edit_song":
            "L'utilisateur n'a aucune autorité pour modifier la chanson",
          "main.Zone_Player/Channel_Scheduler":
            "Lecteur de zone/programmation des chaînes",
          "main.Delete": "Supprimer",
          "main.Download": "Télécharger",
          "main.Upload": "Téléverser",
          "main.Edit": "Modifier",
          "main.Add": "Ajouter",
          "main.Attribute_Name": "Nom de l'attribut",
          "main.Last_Modified": "Dernière modification",
          "main.Zone_ID": "ID de la zone",
          "main.Play_date": "Date de lecture",
          "main.Lookup": "Rechercher",
          "main.External_Meta": "Méta externe",
          "main.Track_artwork": "Illustration de la piste",
          "main.User_defined_Fields": "Champs définis par l'utilisateur",
          "main.To": "À",
          "main.From": "De",
          "main.Seasonality": "Saisonnalité",
          "main.Time_of_Day": "Heure de la journée",
          "main.Use_expire_date?": "Utiliser la date d'expiration ?",
          "main.Expire": "Expire",
          "main.Use_commence_date?": "Utiliser la date de début ?",
          "main.Commence": "Commencer",
          "main.Exact_Dates": "Dates exactes",
          "main.Reset": "Réinitialiser",
          "main.Output_device": "Appareil de sortie",
          "main.Audio": "Audio",
          "main.Type": "Type",
          "main.Year": "Année",
          "main.Artist": "Artiste",
          "main.Metadata": "Métadonnées",
          "main.Voice_Track_Data": "Données de la piste vocale",
          "main.Cloud": "Nuage",
          "main.History": "Historique",
          "main.Distribution": "Distribution",
          "main.Channel_Assignments": "Attributions de canal",
          "main.Characteristics": "Caractéristiques",
          "main.Meta": "Méta",
          "main.User_Defined": "Défini par l'utilisateur",
          "main.Restrictions": "Restrictions",
          "main.Song_Data": "Données de la chanson",
          "main.Back": "Retour",
          "main.Audio_characteristics_Updated_Successfully":
            "Caractéristiques audio mises à jour avec succès",
          "main.Voice_Track_Updated_Successfully":
            "Piste vocale mise à jour avec succès",
          "main.Data_Not_Found": "Données non trouvées",
          "main.Audio_Updated_Successfully": "Audio mis à jour avec succès",
          "main.Channel_Unsubscribed_Successfully":
            "Désabonnement du canal réussi",
          "main.Channel_Subscribed_Successfully": "Abonnement au canal réussi",
          "main.Please_Add_New_Attribute":
            "Veuillez ajouter un nouvel attribut",
          "main.Attribute_Added_Successfully": "Attribut ajouté avec succès",
          "main.Please_Enter_Attribute_to_Edit":
            "Veuillez saisir l'attribut à éditer",
          "main.Attribute_Edited_Successfully": "Attribut édité avec succès",
          "main.Attribute_Is_Deleted": "Attribut supprimé",
          "main.Not_Found": "Non trouvé",
          "main.Select_The_Attributes": "Sélectionnez les attributs",
          "main.Data_Uploaded_from_Cloud_Successfully":
            "Données téléchargées depuis le cloud avec succès",
          "main.Data_Downloaded_from_Cloud_Successfully":
            "Données téléchargées depuis le cloud avec succès",
          "main.Data_Deleted_from_Cloud_Successfully":
            "Données supprimées du cloud avec succès",
          "main.Logged_in_Successfully": "Connexion réussie",
          "main.This_Folder_Doesn't_Contain_Any_File":
            "Ce dossier ne contient aucun fichier",
          "main.siteUniqueId_&_clientSecretKey_Copyed":
            "siteUniqueId et clientSecretKey copiés",
          "main._clientSecretKey_Copyed": "clientSecretKey copiés",
          "main.Voice_Track_Uploaded_Successfully":
            "Piste vocale téléchargée avec succès",
          "main.Audio_Uploaded_Successfully": "Audio téléchargé avec succès",
          "main.SOMETHING_IS_BREAKING": "QUELQUE CHOSE SE CASSE",
          "main.File_Imported_Successfully": "Fichier importé avec succès",
          "main.Channel_Created_Successfully": "Création du canal réussie",
          "main.Song_Added_Successfully": "Chanson ajoutée avec succès",
          "main.Customer_Updated_Successfully": "Client mis à jour avec succès",
          "main.New_Customer_Added_Successfully":
            "Nouveau client ajouté avec succès",
          "main.Failed_to_Save_Customer_Data":
            "Échec de l'enregistrement des données client",
          "main.Failed_to_Fetch_Customer_Data":
            "Échec de la récupération des données client",
          "main.Region_Updated_Successfully": "Région mise à jour avec succès",
          "main.Region_Added_successfully": "Région ajoutée avec succès",
          "main.Failed_to_Save_Region_Data":
            "Échec de l'enregistrement des données de la région",
          "main.Failed_to_fetch_Region_data":
            "Échec de la récupération des données de la région",
          "main.New_Site_Added": "Nouveau site ajouté",
          "main.Failed_to_Save_Site_Data":
            "Échec de l'enregistrement des données du site",
          "main.Failed_to_fetch_Site_data":
            "Échec de la récupération des données du site",
          "main.Schedule_Updated_Successfully":
            "Programme mis à jour avec succès",
          "main.Category_Deleted_Successfully":
            "Catégorie supprimée avec succès",
          "main.Category_Updated_successfully":
            "Catégorie mise à jour avec succès",
          "main.Error_Occurred_While_Updating_Category":
            "Erreur lors de la mise à jour de la catégorie",
          "main.New_Category_Created_Successfully":
            "Nouvelle catégorie créée avec succès",
          "main.Error_Occurred_While_Saving_Category":
            "Erreur lors de l'enregistrement de la catégorie",
          "main.New_Wheel_Created_Successfully":
            "Nouvelle roue créée avec succès",
          "main.Update_Item_Position_Successfully":
            "Mise à jour réussie de la position de l'élément",
          "main.Please_Select_Wheel_to_Add_Item":
            "Veuillez sélectionner une roue pour ajouter un élément",
          "main.Wheel_Deleted_Successfully": "Roue supprimée avec succès",
          "main.Please_Select_Item_First":
            "Veuillez d'abord sélectionner un élément",
          "main.Dropin_Update_Successfully": "Mise à jour du Dropin réussie",
          "main.Data_Deleted_Successfully": "Données supprimées avec succès",
          "main.Please_Select_Any_Dropin_to_Remove":
            "Veuillez sélectionner un Dropin à supprimer",
          "main.User_doesn't_have_authority_to_edit_dropins":
            "L'utilisateur n'a pas l'autorité pour éditer les Dropins",
          "main.Schedule_Item_Deleted_Successfully":
            "Élément de programme supprimé avec succès",
          "main.ChannelList_Deleted_Successfully":
            "Liste de canaux supprimée avec succès",
          "main.Duplicate_Day_Created_Successfully":
            "Jour dupliqué créé avec succès",
          "main.Schedules_Are_Not_Available":
            "Les horaires ne sont pas disponibles",
          "main.Duplicate_Zone_Created_Successfully":
            "Zone dupliquée créée avec succès",
          "main.Channel_Name_Updated_Successfully":
            "Nom du canal mis à jour avec succès",
          "main.Schedule_Created_Successfully": "Programme créé avec succès",
          "main.User_doesn't_have_authority_to_edit_license":
            "L'utilisateur n'a pas l'autorité pour éditer la licence",
          "main.Licensing_Data_Posted_Successfully":
            "Données de licence postées avec succès",
          "main.Licensing_Data_Updated_Successfully":
            "Données de licence mises à jour avec succès",
          "main.License_Data_According_To_Customer":
            "Données de licence selon le client",
          "main.License_Data_According_To_Site":
            "Données de licence selon le site",
          "main.Please_Select_Site": "Veuillez sélectionner le site",
          "main.Data_Is_Updated": "Les données sont mises à jour",
          "main.Zone_Deleted_Successfully": "Zone supprimée avec succès",
          "main.File_Is_Downloaded": "Le fichier a été téléchargé",
          "main.Postion_can't_be_change_until_data_is_saved":
            "La position ne peut pas être modifiée tant que les données ne sont pas enregistrées",
          "main.Please_fill_the_form": "Veuillez remplir le formulaire",
          "main.New_User_Group_Added_Successfully":
            "Nouveau groupe d'utilisateurs ajouté avec succès",
          "main.Group_Edited_Successfully": "Groupe édité avec succès",
          "main.Please_Select_Any_Group_to_Delete":
            "Veuillez sélectionner un groupe à supprimer",
          "main.Group_Deleted_Successfully": "Groupe supprimé avec succès",
          "main.User_have_no_authority_to_edit_group_data":
            "L'utilisateur n'a pas l'autorité pour éditer les données de groupe",
          "main.Please_Select_only_one":
            "Veuillez en sélectionner seulement un",
          "main.User_doesn't_have_authority_to_edit_schedule":
            "L'utilisateur n'a pas l'autorité pour éditer le programme",
          "main.Song_Deleted_Successfully": "Chanson supprimée avec succès",
          "main.New_User_Added_Successfully":
            "Nouvel utilisateur ajouté avec succès",
          "main.Please_Select_Only_One_User_to_Edit":
            "Veuillez sélectionner seulement un utilisateur à éditer",
          "main.User_Edited_Successfully": "Utilisateur édité avec succès",
          "main.Please_Select_Any_User_to_Delete":
            "Veuillez sélectionner un utilisateur à supprimer",
          "main.User_Deleted_Successfully": "Utilisateur supprimé avec succès",
          "main.Playlist_Setting_Updated_Successfully":
            "Paramètres de la playlist mis à jour avec succès",
          "main.Site_Update_Successfully": "Mise à jour du site réussie",
          "main.Setting_Updated_Successfully":
            "Paramètres mis à jour avec succès",
          "main.SOMETHING_WENT_WRONG": "QUELQUE CHOSE S'EST MAL PASSÉ",
          "main.User_doesn't_have_authority_to_edit_song_category":
            "L'utilisateur n'a pas l'autorité pour éditer la catégorie de chansons",
          "main.User_doesn't_have_authority_to_edit_voice_wheel":
            "L'utilisateur n'a pas l'autorité pour éditer la roue vocale",
          "main.Error_Exporting_Audio": "Erreur lors de l'exportation audio",
          "main.Audio_File_Saved_Successfully":
            "Téléchargement réussi du fichier audio Zip",
          "main.Error_Saving_Audio_File":
            "Erreur lors de l'enregistrement du fichier audio",
          "main.Error_Exporting_Encrypted_Audio":
            "Erreur lors de l'exportation audio chiffrée",
          "main.Encrypted_Audio_File_Saved_Successfully":
            "Fichier audio chiffré enregistré avec succès",
          "main.Error_Saving_Encrypted_Audio_File":
            "Erreur lors de l'enregistrement du fichier audio chiffré",
          "main.user_doesn't_have_authority_to_edit_schedule":
            "L'utilisateur n'est pas autorisé à modifier le planning",
          "main.logged_out_successfully": "Déconnexion réussie",
          "main.user_have_no_authority_to_edit_channel_playlist":
            "L'utilisateur n'a pas le pouvoir de modifier la liste de lecture de la chaîne",
          "main.edit_user": "Modifier l'utilisateur",
          "main.please_select_customer": "Veuillez sélectionner le client",
          "main.select_site": "Sélectionner le site",
          "main.zone_name/title": "Nom de la zone / Titre",
          "main.please_select_customer_and_site_to_get_zone_data":
            "Veuillez sélectionner le client et le site pour obtenir les données de la zone",
          "main.zone_title": "Titre de la zone",
          "main.zone_config": "Configuration de la zone",
          "main.synchronize_schedule": "Synchroniser l'horaire",
          "main.general": "Général",
          "main.VST_config_file": "Fichier de configuration VST",
          "main.VST_File": "Fichier VST",
          "main.External_Effect": "Effet externe",
          "main.target_music_dBV": "Niveau sonore cible de la musique",
          "main.create_new_zone": "Créer une nouvelle zone",
          "main.delete_zone": "Supprimer la zone",
          "main.EXPORT_XML_FILE": "EXPORTER LE FICHIER XML",
          "main.hard": "Fort",
          "main.medium": "Moyen",
          "main.soft": "Doux",
          "main.automatic_gain_control": "Contrôle automatique du gain",
          "main.audio_processing": "Traitement audio",
          "main.system_sample_rate": "Taux d'échantillonnage du système",
          "main.fade_on_zone_stop": "Fondu à l'arrêt de la zone",
          "main.windows": "Windows",
          "main.ASIO": "ASIO",
          "main.audio_system_preferred": "Système audio préféré",
          "main.audio_output": "Sortie audio",
          "main.no_zones_found": "Aucune zone trouvée",
          "main.add_new_user": "Ajouter un nouvel utilisateur",
          "main.comment": "Commentaire",
          "main.select_group": "Sélectionner un groupe",
          "main.family_name": "Nom de famille",
          "main.username": "Nom d'utilisateur",
          "main.voice_tracks": "Pistes vocales",
          "main.can_edit_override_function":
            "Peut modifier la fonction de remplacement",
          "main.can_edit_voice_tracks": "Peut modifier les pistes vocales",
          "main.can_edit_voice_wheels": "Peut modifier les roues vocales",
          "main.can_edit_dropIns": "Peut modifier les DropIns",
          "main.can_edit_schedules": "Peut modifier les plannings",
          "main.can_edit_channel_playlist":
            "Peut modifier la playlist de la chaîne",
          "main.can_edit_songs_category":
            "Peut modifier la catégorie de chansons",
          "main.can_edit_songs": "Peut modifier les chansons",
          "main.add_new_group": "Ajouter un nouveau groupe",
          "main.edit_group": "Modifier le groupe",
          "main.song_category": "Catégorie de chansons",
          "main.songs": "Chansons",
          "main.admin": "Admin",
          "main.group": "Groupe",
          "main.last_name": "Nom de famille",
          "main.first_name": "Prénom",
          "main.Load_Lic_files": "Charger les fichiers de licence",
          "main.safe_Lic_files": "Sauvegarder les fichiers de licence",
          "main.set_default_values": "Définir les valeurs par défaut",
          "main.override_player": "Remplacer le lecteur",
          "main.valid_until": "Valide jusqu'au",
          "main.drop-Ins": "Drop-Ins",
          "main.zone_number": "Numéro de zone",
          "main.license_Is_valid_until": "La licence est valide jusqu'au",
          "main.locations": "Emplacements",
          "main.levels": "Niveaux",
          "main.user_definition": "Définition de l'utilisateur",
          "main.zone_configuration": "Configuration de la zone",
          "main.permissions": "Autorisations",
          "main.licensing": "Licence",
          "main.application_settings": "Paramètres de l'application",
          "main.select_destination_zone": "Sélectionner la zone de destination",
          "main.select_source_zone": "Sélectionner la zone source",
          "main.3.select_destination_days":
            "3. Sélectionner les jours de destination",
          "main.2.select_day-to_copy": "2. Sélectionner le jour à copier",
          "main.1.select_zone": "1. Sélectionner la zone",
          "main.end:mmdd": "Fin : mmdd",
          "main.start:mmddd": "Début : mmddd",
          "main.remove_dropin": "Supprimer le DropIn",
          "main.new_dropin": "Nouveau DropIn",
          "main.manage_dropIns": "Gérer les DropIns",
          "main.edit_dropin": "Modifier le DropIn",
          "main.add_new_dropin": "Ajouter un nouveau DropIn",
          "main.interval": "Intervalle",
          "main.edit_schedule_item": "Modifier l'élément de planning",
          "main.end_time_(hhmm)": "Heure de fin (hhmm)",
          "main.start_time_(hhmm)": "Heure de début (hhmm)",
          "main.channel_name": "Nom de la chaîne",
          "main.new_channel": "Nouvelle chaîne",
          "main.max_in_row": "Max dans la rangée",
          "main.breakable?": "Peut être interrompu ?",
          "main.enter_the_title_of_the_new_wheel":
            "Entrer le titre de la nouvelle roue",
          "main.name_site": "Nom du site",
          "main.changed_audio_files": "Fichiers audio modifiés",
          "main.empty_audio_file": "Fichier audio vide",
          "main.zone": "Zone",
          "main.day": "Jour",
          "main.start_time": "Heure de début",
          "main.channel": "Chaîne",
          "main.wheel": "Roue",
          "main.dropIn": "DropIn",
          "main.switch_to_dark_mode": "Passer en mode sombre",
          "main.item_not_found": "Élément non trouvé",
          "main.message_category": "Catégorie de message",
          "main.remove_item": "Supprimer l'élément",
          "main.add_item": "Ajouter l'élément",
          "main.new_wheel_designer": "Nouveau concepteur de roue",
          "main.Remove Wheel": "Supprimer la roue",
          "main.add_wheel": "Ajouter une roue",
          "main.wheel_manager": "Gestionnaire de roues",
          "main.volume_offset": "Décalage du volume",
          "main.add_new_category": "Ajouter une nouvelle catégorie",
          "main.new_music_categories": "Nouvelles catégories de musique",
          "main.volume": "Volume",
          "main.add_category": "Ajouter une catégorie",
          "main.category": "Catégorie",
          "main.new_folder": "Nouveau dossier",
          "main.expire_date": "Date d'expiration",
          "main.commence_date": "Date de début",
          "main.sunday": "Dimanche",
          "main.saturday": "Samedi",
          "main.friday": "Vendredi",
          "main.thursday": "Jeudi",
          "main.wednesday": "Mercredi",
          "main.tuesday": "Mardi",
          "main.monday": "Lundi",
          "main.export_BMS_v6_database": "Exporter la base de données BMS v6",
          "main.Generate_new": "Générer un nouveau",
          "main.copy_ID_&_key": "Copier l'ID et la clé",
          "main.client_secret_key": "Clé secrète du client",
          "main.site_id": "ID du site",
          "main.contact_name": "Nom du contact",
          "main.phone_nummer_3": "Numéro de téléphone 3",
          "main.phone_nummer_2": "Numéro de téléphone 2",
          "main.phone_nummer_1": "Numéro de téléphone 1",
          "main.post/area_Code": "Code postal / Zone",
          "main.country": "Pays",
          "main.address_3": "Adresse 3",
          "main.address_2": "Adresse 2",
          "main.address_1": "Adresse 1",
          "main.id": "ID",
          "main.site_group": "Groupe de site",
          "main.site_name": "Nom du site",
          "main.delete": "Supprimer",
          "main.new_site": "Nouveau site",
          "main.new_region": "Nouvelle région",
          "main.fileName": "Nom du fichier",
          "main.priority": "Priorité",
          "main.audio_filename": "Nom du fichier audio",
          "main.creation_date": "Date de création",
          "main.expiry_date": "Date d'expiration",
          "main.release_year": "Année de sortie",
          "main.artist_3": "Artiste 3",
          "main.artist_2": "Artiste 2",
          "main.artist_1": "Artiste 1",
          "main.title": "Titre",
          "main.cancel": "Annuler",
          "main.choose_folder": "Choisir un dossier",
          "main.choose_files": "Choisir des fichiers",
          "main.choose_xml_file": "Choisir un fichier XML",
          "main.xml_file_import": "Importation du fichier XML",
          "main.Import": "Importer",
          "main.import_file": "Importer le fichier",
          "main.import_to_playlist": "Importer dans la playlist",
          "main.file_name": "Nom du fichier",
          "main.folder_name": "Nom du dossier",
          "main.import_file(s)": "Importer le(s) fichier(s)",
          "main.file_import": "Importation de fichiers",
          "main.export_encrypted_audiofile":
            "Exporter le fichier audio chiffré",
          "main.export_audiofile": "Exporter le fichier audio",
          "main.export_data": "Exporter les données",
          "main.export": "Exporter",
          "main.re-generate_channel_song_list":
            "Régénérer la liste de chansons de la chaîne",
          "main.duplicate_channel": "Dupliquer la chaîne",
          "main.delete_channel": "Supprimer la chaîne",
          "main.apply_day-of-week_restrictions":
            "Appliquer les restrictions par jour de la semaine",
          "main.apply_time-of-day_restrictions":
            "Appliquer les restrictions par heure de la journée",
          "main.apply_seasonal_restrictions":
            "Appliquer les restrictions saisonnières",
          "main.days_to_protect": "Jours à protéger",
          "main.active?": "Actif ?",
          "main.last_play_song_rule": "Règle de dernière lecture de chanson",
          "main.yesterday_song": "Chanson d'hier",
          "main.preferred_minutes_either_side_to_protect":
            "Minutes préférées de chaque côté à protéger",
          "main.yesterday_artist": "Artiste d'hier",
          "main.yesterday_rules": "Règles d'hier",
          "main.add_channel": "Ajouter une chaîne",
          "main.sites": "Sites",
          "main.count": "Compte",
          "main.static": "Statique",
          "main.name": "Nom",
          "main.save": "Enregistrer",
          "main.subscribed_sites": "Sites abonnés",
          "main.channel_song_list": "Liste de chansons de la chaîne",
          "main.channel_characterstics": "Caractéristiques de la chaîne",
          "main.channel_configuration": "Configuration de la chaîne",
          "main.rows_per_page": "Lignes par page",
          "main.select_category": "Sélectionner une catégorie",
          "main.select_customer": "Sélectionner un client",
          "main.category_manager": "Gestionnaire de catégories",
          "main.search_library": "Rechercher dans la bibliothèque",
          "main.new_customer": "Nouveau client",
          "main.customer": "Client",
          "main.site": "Site",
          "main.site_subscriptions": "Abonnements au site",
          "main.view_songs": "Voir les chansons",
          "main.title/artist...": "Titre/Artiste...",
          "main.attributes": "Attributs",
          "main.played_songs": "Chansons jouées",
          "main.exact_time_play": "Lecture à l'heure exacte",
          "main.filter_by": "Filtrer par",
          "main.agency_report": "Rapport de l'agence",
          "main.clear_filter": "Effacer le filtre",
          "main.add_content": "Ajouter du contenu",
          "main.content_library/audio_editor":
            "Contenu  Bibliothèque / Éditeur de pistes vocales",
          "main.music_player/audio_editor":
            "Lecteur de musique / Éditeur audio",
          "main.channel_editor": "Éditeur de chaîne",
          "main.content_library": "Bibliothèque de contenu",
          "main.music_library": "Bibliothèque musicale",
          "main.manage_customer": "Gérer le client",
          "main.update": "Mettre à jour",
          "main.logout": "Déconnexion",
          "main.settings": "Paramètres",
          "main.problems_software":
            "Problèmes avec votre compte ou le logiciel ?",
          "main.main_heading": "Bienvenue dans le logiciel EBH Radio",
          "main.login_problem": "Problème avec votre compte ou le logiciel ?",
          "main.login": "Connexion",
          "main.contact_now": "Contactez-nous maintenant",
          "main.import_audio": "Importer de l'audio",
          "main.add_file": "Ajouter un fichier",
          "main.add_folder": "Ajouter un dossier",
          "main.tools": "Outils",
          "main.add_playlist": "Ajouter une playlist",
          "main.delete_playlist": "Supprimer la playlist",
          "main.add_song": "Ajouter une chanson",
          "main.delete_song": "Supprimer la chanson",
          "main.playlist_songs": "Chansons de la playlist",
          "main.playlist_settings": "Paramètres de la playlist",
          "main.playlist": "Playlist",
          "main.channel_list": "Liste des chaînes",
          "main.no_records": "Aucun enregistrement à afficher",
          "main.add_schedule_item": "Ajouter un él",
          "main.delete_schedule_item": "Supprimer l'élément de l'horaire",
          "main.duplicate_day": "Dupliquer le jour",
          "main.duplicate_zone": "Dupliquer la zone",
          "main.channel_scheduler": "Planificateur de chaîne",
          "main.zone_player": "Lecteur de zone",
          "main.License_Key": "Clé de Licence",
          "main.Select_Customer": "Sélectionnez d'abord le Client",
        },
      },
      // ... other languages
    },
    lng: "en",
    fallbackLng: "en",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

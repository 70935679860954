import React from "react";
import MiniSidePanel from "../layouts/MiniSidePanel/MiniSidePanel";
import SettingsTabs from "../component/Tabs/SettingsTabs";
import { useTranslation } from "react-i18next";

export default function SettingPage() {
  const {t} = useTranslation()
  return (
    <MiniSidePanel headerText={t("main.settings")}>
      <SettingsContent />
    </MiniSidePanel>
  );
}

function SettingsContent() {
  return <SettingsTabs isOpen={false} handleClose={undefined}></SettingsTabs>;
}

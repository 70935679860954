/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Tab,
  Tabs,
  Select,
  MenuItem,
  useTheme,
  Typography,
  SelectChangeEvent,
} from "@mui/material";
import React, { useEffect, useState, useCallback } from "react";
import { useChannelIdContext } from "../../context/ChannelIdContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
const API_URL = process.env.REACT_APP_BACKEND_URL;
const ZoneConfigurationSideBar = ({
  siteId,
  setSiteId,
  setAppSettings,
  selectedCustomerId,
  setSelectedCustomerId,
}: {
  siteId: string;
  setSiteId: any;
  setAppSettings: any;
  selectedCustomerId: string;
  setSelectedCustomerId: any;
}) => {
  let theme = useTheme();
  const { t } = useTranslation();
  let navigate = useNavigate();
  const [tab, setTab] = useState<number>(0);
  const [zoneList, setZonelList] = useState<string[]>([]);
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  const [customerList, setCustomerList] = useState<any[]>([]);
  const {
    selectedZoneId,
    setSelectedZoneId,
    setSelectedZoneName,
    isDeleted,
    setisDeleted,
    setIsAdded,
    setIsTokenExpired,
    setIsTokenUpdated,
    IsTokenUpdated,
    isAdded,
  } = useChannelIdContext();
  const [siteList, setSiteList] = useState<any[]>([]);

  const handleChange = (event: React.SyntheticEvent, newValue: any) => {
    event.preventDefault();
    setTab(newValue);
  };

  const fetchzoneList = useCallback(async () => {
    if (siteId) {
      fetch(`${API_URL}/Zone/GetByFilters?siteId=${siteId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token?.accessToken}`,
        },
      })
        .then((response) => {
          if (
            response.ok === false &&
            response.status === 401 &&
            token?.refreshToken !== ""
          ) {
            setIsTokenExpired(true);
          } else if (response.status === 401 && token?.refreshToken === "") {
            navigate("/");
            localStorage.removeItem("token");
            localStorage.clear();
          } else {
            setIsTokenUpdated(false);
            return response.json();
          }
        })
        .then((data) => {
          if (data.success === true) {
            setTab(0);
            setZonelList(data?.data);
            setSelectedZoneId(data?.data[0]?.id);
            setSelectedZoneName(data?.data[0]?.name);
            setisDeleted(false);
            setIsAdded(false);
          }
        });
    } else {
      setZonelList([]);
      setSelectedZoneId("");
    }
  }, [
    setSelectedZoneId,
    setSelectedZoneName,
    isDeleted,
    siteId,
    isAdded,
    IsTokenUpdated,
  ]);

  const handleChangeSite = (event: SelectChangeEvent) => {
    const {
      target: { value },
    } = event;
    setSiteId(value);
    setTab(0);
  };

  const getSiteById = useCallback(
    async (selectedCustomerId: string) => {
      fetch(`${API_URL}/Site/Get/` + selectedCustomerId, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token?.accessToken}`,
        },
      })
        .then((response) => {
          if (
            response.ok === false &&
            response.status === 401 &&
            token?.refreshToken !== ""
          ) {
            setIsTokenExpired(true);
          } else if (response.status === 401 && token?.refreshToken === "") {
            navigate("/");
            localStorage.removeItem("token");
            localStorage.clear();
          } else {
            setIsTokenUpdated(false);
            return response.json();
          }
        })
        .then((data) => {
          if (data.success === true) {
            setSiteList(data?.data);
            if (!selectedZoneId) {
              setAppSettings({});
            }
          } else {
            setSiteList([]);
          }
        });
    },
    [selectedZoneId, setAppSettings, IsTokenUpdated]
  );

  const getSitesId = useCallback(async (id: number) => {
    const response = await fetch(`${API_URL}/Site/Get/` + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    });
    const data = await response.json();
    return data?.data;
  }, []);

  const fetchCustomerList = useCallback(async () => {
    const response = await fetch(`${API_URL}/Customer/GetAll`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    });
    const data = await response.json();
    let temp = [];
    for (let index = 0; index < data?.data?.length; index++) {
      const element = data?.data[index];
      let customer = await getSitesId(element?.id);
      if (customer?.length > 0) {
        temp.push({ name: element?.name, value: element?.id });
      }
    }
    setCustomerList(temp);
  }, [getSitesId]);

  useEffect(() => {
    if (!selectedCustomerId) {
    } else {
      fetchzoneList();
    }
  }, [fetchzoneList]);

  useEffect(() => {
    fetchCustomerList();
  }, [fetchCustomerList]);

  useEffect(() => {
    if (selectedCustomerId) {
      getSiteById(selectedCustomerId);
    }
  }, [getSiteById, selectedCustomerId]);

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          maxWidth: 600,
          height: "100%",
        }}
      >
        <div
          key="zoneCustomerSite"
          style={{ display: "flex", marginBottom: 25, gap: 10 }}
        >
          <Select
            style={{
              height: 45,
              width: 140,
              backgroundColor: "#3C735C",
              color: "#fff",
              borderRadius: 10,
            }}
            displayEmpty
            value={
              customerList?.some(
                (item) => item.value === parseInt(selectedCustomerId)
              )
                ? selectedCustomerId
                : ""
            }
            renderValue={(value) => {
              if (value) {
                return customerList?.find(
                  (item: any) => item?.value === parseInt(selectedCustomerId)
                ).name;
              } else {
                return <Typography>{t("main.customer")}</Typography>;
              }
            }}
            onChange={(event: SelectChangeEvent) => {
              setSelectedCustomerId(event.target.value);
              setSiteId("");
            }}
          >
            {customerList?.length === 0 ? (
              <MenuItem>{t("main.No_Customer")}</MenuItem>
            ) : (
              customerList?.map((item: any) => (
                <MenuItem value={item?.value} key={item?.value}>
                  {item?.name}
                </MenuItem>
              ))
            )}
          </Select>
          <Select
            style={{
              height: 45,
              width: 120,
              backgroundColor: "#3C735C",
              color: "#fff",
              borderRadius: 10,
            }}
            displayEmpty
            placeholder="Sites"
            value={siteId ? siteId : ""}
            renderValue={(value) => {
              if (value) {
                return siteList?.find(
                  (item: any) => item?.id === parseInt(siteId)
                )?.name;
              } else {
                return <Typography>{t("main.Site")}</Typography>;
              }
            }}
            onChange={handleChangeSite}
          >
            {siteList?.length === 0 ? (
              <MenuItem key="customer">
                {t("main.please_select_customer")}
              </MenuItem>
            ) : (
              siteList?.map((item: any) => (
                <MenuItem key={item?.id} value={item?.id}>
                  {item?.name}
                </MenuItem>
              ))
            )}
          </Select>
        </div>
        <Box sx={{ flexGrow: 1, bgcolor: "background.paper", display: "flex" }}>
          {zoneList?.length === 0 ? (
            <Box
              className="horizontalTabs__item"
              sx={{
                width: "100%",
                border: "1px solid #9E9E9E",
                backgroundColor:
                  theme.palette.mode === "dark" ? "#202020" : "#d9d9d9",
              }}
            >
              {t("main.no_zones_found")}
            </Box>
          ) : (
            <Tabs
              orientation="vertical"
              className="horizontalTabs"
              value={tab}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              sx={{
                width: "100%",
                backgroundColor:
                  theme.palette.mode === "dark" ? "#202020" : "#FFFFFF",
                "& button": {
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "21px",
                  borderRadius: 0,
                  opacity: 1,
                  color: "#3C434B",
                  background: "transparent",
                },
                "& button.Mui-selected": {
                  color: "#FFFFFF",
                  background: "#3C735C",
                },
                "& .Mui-selected": {
                  background:
                    theme.palette.mode === "dark" ? "#303030" : "#34393f",
                  borderRight: "1px solid #9E9E9E",
                  borderLeft: "1px solid #9E9E9E",
                },
              }}
              variant="fullWidth"
            >
              {zoneList?.map((item: any) => (
                <Tab
                  className="horizontalTabs__item"
                  label={
                    <div
                      style={{
                        width: "100%",
                        maxWidth: "400px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          key={item?.name}
                          style={{ width: "100px", fontSize: "14px" }}
                        >
                          {item?.name}
                        </Typography>
                      </div>
                    </div>
                  }
                  key={item?.id}
                  sx={{ textTransform: "none", fontSize: "16px" }}
                  onClick={() => {
                    setSelectedZoneId(item?.id);
                    setSelectedZoneName(item?.name);
                  }}
                ></Tab>
              ))}
            </Tabs>
          )}
        </Box>
      </Box>
    </>
  );
};
export default ZoneConfigurationSideBar;

import { Box, Button, Tab, Tabs, useTheme } from "@mui/material";
import React, { MouseEventHandler, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import { toast } from "react-toastify";
import { useChannelIdContext } from "../../context/ChannelIdContext";
import { formData } from "../../types/Settings";
import EditCharacteristics from "../Audio/EditCharacteristics";
import ApplicationSetting from "../Settings/ApplicationSetting/ApplicationSetting";
import Licensing from "../Settings/Licensing/Licensing";
import MessageImport from "../Settings/UserPermissions";
import ZoneConfiguration from "../Settings/ZoneConfiguration";
const API_URL = process.env.REACT_APP_BACKEND_URL;
export default function SettingsTabs({
  isOpen,
  handleClose,
}: {
  isOpen: boolean;
  handleClose: any | MouseEventHandler;
}) {
  const [tab, setTab] = useState(0);
  const navigate = useNavigate();
  const { setIsTokenExpired, setIsTokenUpdated } = useChannelIdContext();
  const { t } = useTranslation();
  let theme = useTheme();
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  const [levelsFormData, setLevelsFormData] = useState<formData[]>([]);
  const [locationFormData, setLocationFormData] = useState<formData[]>([]);
  const [userDefinitionData, setUserDefinitionData] = useState<formData[]>([]);

  const UpdatedUSerDefinedData = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    const mergedArray = userDefinitionData.concat(
      levelsFormData,
      locationFormData
    );
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
      body: JSON.stringify(mergedArray),
    };
    fetch(`${API_URL}/Settings`, requestOptions)
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data?.success === true) {
          toast.success(t("main.Setting_Updated_Successfully"));
        } else {
          toast.error(t("main.SOMETHING_WENT_WRONG"));
        }
      });
  };

  return (
    <>
      <Box
        sx={{
          bgcolor: "background.paper",
        }}
      >
        <div
          className="btn-tab-back"
          style={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor:
              theme.palette.mode === "dark" ? "#202020" : "#34393f",
          }}
        >
          <Tabs
            value={tab}
            onChange={(e, v) => setTab(v)}
            aria-label="audio tabs"
            sx={{
              backgroundColor:
                theme.palette.mode === "dark" ? "#202020" : "#34393f",
              "& button": {
                fontSize: "1.1rem",
                color: "#e8ebe6",
              },
              "& .Mui-selected": {
                backgroundColor:
                  theme.palette.mode === "dark" ? "#303030" : "#44494f",
              },
            }}
          >
            <Tab
              label={t("main.application_settings")}
              sx={{ textTransform: "none" }}
            />
            <Tab label={t("main.licensing")} sx={{ textTransform: "none" }} />
            <Tab label={t("main.permissions")} sx={{ textTransform: "none" }} />
            <Tab
              label={t("main.zone_configuration")}
              sx={{ textTransform: "none" }}
            />
            <Tab
              label={"Kategorien"}
              sx={{ textTransform: "none" }}
            />
          </Tabs>
          {tab === 0 ? (
            <Button
              style={{
                background: "#3c735c",
                fontWeight: "500",
                fontSize: "18px",
                color: "#ffffff",
                padding: "15px 32px",
                lineHeight: "normal",
                borderRadius: "0",
              }}
              onClick={(e: any) => UpdatedUSerDefinedData(e)}
            >
              {t("main.save")}
            </Button>
          ) : (
            ""
          )}
        </div>
      </Box>
      <SwipeableViews axis={"x"} index={tab} onChangeIndex={(v) => setTab(v)}>
        <div
          role="tabpanel"
          hidden={tab !== 0}
          id={`vertical-tabpanel-${0}`}
          aria-labelledby={`vertical-tab-${0}`}
        >
          {tab === 0 && (
            <ApplicationSetting
              levelsFormData={levelsFormData}
              locationFormData={locationFormData}
              setLevelsFormData={setLevelsFormData}
              userDefinitionData={userDefinitionData}
              setLocationFormData={setLocationFormData}
              setUserDefinitionData={setUserDefinitionData}
            />
          )}
        </div>
        <div
          role="tabpanel"
          hidden={tab !== 1}
          id={`vertical-tabpanel-${1}`}
          aria-labelledby={`vertical-tab-${1}`}
        >
          {tab === 1 && <Licensing />}
        </div>
        <div
          role="tabpanel"
          hidden={tab !== 2}
          id={`vertical-tabpanel-${2}`}
          aria-labelledby={`vertical-tab-${2}`}
        >
          {tab === 2 && <MessageImport />}
        </div>
        <div
          role="tabpanel"
          hidden={tab !== 3}
          id={`vertical-tabpanel-${3}`}
          aria-labelledby={`vertical-tab-${3}`}
        >
          {tab === 3 && <ZoneConfiguration />}
        </div>
        <div
          role="tabpanel"
          hidden={tab !== 4}
          id={`vertical-tabpanel-${4}`}
          aria-labelledby={`vertical-tab-${4}`}
        >
          {tab === 4 && <EditCharacteristics />}
        </div>
      </SwipeableViews>
    </>
  );
}

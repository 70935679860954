/* eslint-disable react-hooks/exhaustive-deps */
import {
  Search
} from "@mui/icons-material";
import {
  Box,
  IconButton,
  Tooltip
} from "@mui/material";
import MaterialReactTable, { type MRT_ColumnDef } from "material-react-table";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useChannelIdContext } from "../../context/ChannelIdContext";
import { Channel } from "../../types/Customer";
import DisplayChannelSongDialog from "../Dialogs/DisplayChannelSongDialog/DisplayChannelSongDialog";

const API_URL = process.env.REACT_APP_BACKEND_URL;
const CustomerSiteSubscription = () => {
  let navigate = useNavigate();
  const {t} = useTranslation();
  const [open, setOpen] = useState<boolean>(false)
  const { SiteId, setIsTokenExpired, setIsTokenUpdated, IsTokenUpdated } = useChannelIdContext();
  const [channels, setChannels] = useState<Channel[]>([]);
  const [rowSelection, setRowSelection] = useState<string>("");
  const [actionSelection, setActionSelection] = useState<number>(0);
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  const [subscribeChannel, setSubscribeChannel] = useState<Channel[]>([]);

  const getAllChannel = useCallback(async () => {
    fetch(`${API_URL}/Channel/GetAll`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    })
    .then((response) => {
      if (
        response.ok === false &&
        response.status === 401 &&
        token?.refreshToken !== ""
      ) {
        setIsTokenExpired(true)
      } else if (response.status === 401 && token?.refreshToken === "") {
        navigate("/");
        localStorage.removeItem("token");
        localStorage.clear();
      } else {
        setIsTokenUpdated(false)
        return response.json()
      }
    })
    .then((data) => {
      if (data.success === true) {
        setChannels(data?.data);
      } else {
        setChannels([]);
      }
    })
  }, [IsTokenUpdated]);

  const fetchSiteByIdChannel = useCallback(async () => {
    fetch(`${API_URL}/Site/GetSiteChannel/${SiteId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    })
    .then((response) => {
      if (
        response.ok === false &&
        response.status === 401 &&
        token?.refreshToken !== ""
      ) {
        setIsTokenExpired(true)
      } else if (response.status === 401 && token?.refreshToken === "") {
        navigate("/");
        localStorage.removeItem("token");
        localStorage.clear();
      } else {
        setIsTokenUpdated(false)
        return response.json()
      }
    })
    .then((data) => {
      if (data.success === true) {
        setSubscribeChannel(data?.data);
      } else {
        setSubscribeChannel([]);
      }
    })
  }, [SiteId, IsTokenUpdated]);

  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "name",
        header: t("main.name"),
      },
    ],
    [t]
  );

  const isChannelSelected = (rowData: Channel) => {
    const isSubscribed = subscribeChannel?.some(
      (attr) => attr.id === rowData.id
    );
    return {
      backgroundColor: isSubscribed ? "#42876A" : "",
      color: isSubscribed ? "#fff" : "",
    };
  };

  const handleAChannelSelection = async (rowData: Channel) => {
    const isSelected = subscribeChannel?.some((attr) => attr.id === rowData.id);
    if (SiteId !== 0) {
      if (isSelected) {
        // Unsubscribe the channel
       fetch(`${API_URL}/Site/SiteChannel`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token?.accessToken}`,
          },
          body: JSON.stringify({
            siteId: SiteId,
            channelsId: [rowData.id],
          }),
        }).then((response) => {
          if (
            response.ok === false &&
            response.status === 401 &&
            token?.refreshToken !== ""
          ) {
            setIsTokenExpired(true);
          } else if (response.status === 401 && token?.refreshToken === "") {
            navigate("/");
            localStorage.removeItem("token");
            localStorage.clear();
          } else {
            setIsTokenUpdated(false);
            return response.json();
          }
        })
        .then((data) => {
          if (data?.success) {
            setSubscribeChannel(
              subscribeChannel?.filter((attr) => attr.id !== rowData.id)
            );
          }
        });
      } else {
        // Subscribe the channel
         await fetch(`${API_URL}/Site/SiteChannel`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token?.accessToken}`,
          },
          body: JSON.stringify({
            siteId: SiteId,
            channelsId: [rowData.id],
          }),
        }).then((response) => {
          if (
            response.ok === false &&
            response.status === 401 &&
            token?.refreshToken !== ""
          ) {
            setIsTokenExpired(true);
          } else if (response.status === 401 && token?.refreshToken === "") {
            navigate("/");
            localStorage.removeItem("token");
            localStorage.clear();
          } else {
            setIsTokenUpdated(false);
            return response.json();
          }
        })
        .then((data) => {
          if (data?.success) {
            setSubscribeChannel([...subscribeChannel, rowData]);
          }
        });

      }
    }
  };

  useEffect(() => {
    if (SiteId !== 0) {
      fetchSiteByIdChannel();
      getAllChannel();
    }
    if (SiteId === 0) {
      setChannels([]);
      setSubscribeChannel([]);
    }
  }, [SiteId, fetchSiteByIdChannel, getAllChannel]);

  return (
    <>
    <div className="ChannelAssTable">
      <MaterialReactTable
        columns={columns}
        data={channels}        
        enableRowActions={true}
        enableColumnActions={true}
        enableColumnFilters={false}
        enableColumnOrdering={true}
        enablePagination={true}
        enableSorting={true}
        enableBottomToolbar={true}
        enableTopToolbar={false}     
        layoutMode="grid"     
        muiTableBodyProps={{
          sx: {
            "& tr:nth-of-type(even)": {
              backgroundColor: "#F0F4F6",
            },
          },
        }}
        
        displayColumnDefOptions={{
          'mrt-row-actions': {    
            minSize: 0,     
            size: 10            
          },
        }}
        renderRowActions={({ row }) => (
          <Box className="music-library-table-box">
            <Tooltip title="See songs">
              <IconButton
                onClick={(event) => {       
                  event.stopPropagation()
                  setActionSelection(row.original.id)         
                  setOpen(true)
                }}
                aria-label="more"
                id="long-button"
                aria-haspopup="true"
                style={{ color: "#000" }}
              >
                <Search />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        muiTableBodyRowProps={({ row }) => ({
          onClick: () => {
            setRowSelection(row.id);          
            handleAChannelSelection(row.original);
          },
          hover: false,
          style: isChannelSelected(row.original),
          selected: rowSelection === row.id,
          sx: {
            cursor: "pointer",
          },
        })}
        muiTableBodyCellProps={({ row }) => ({
          style: {
            color: isChannelSelected(row.original).color,
          },
        })}
      />
    </div>
    <DisplayChannelSongDialog
    isOpen={open}
    channelId={actionSelection}
    handleClose={() => setOpen(false)}
    ></DisplayChannelSongDialog>
    </>
  );
  
};

export default CustomerSiteSubscription;

/* eslint-disable react-hooks/exhaustive-deps */
import "../../component/Audio/AudioStyles.scss";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { Channel } from "../../types/Customer";
import { SongChannelList } from "../../types/Audio";
import { CircularProgress } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import MaterialReactTable, { type MRT_ColumnDef } from "material-react-table";
import { useTranslation } from "react-i18next";
import { useChannelIdContext } from "../../context/ChannelIdContext";
const API_URL = process.env.REACT_APP_BACKEND_URL;

const ChannelAssignmentsTab = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  let navigate = useNavigate();
  const { setIsTokenExpired, setIsTokenUpdated, IsTokenUpdated } =
    useChannelIdContext();
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  const [rowSelection, setRowSelection] = useState<string>("");
  const [subscribeChannel, setSubscribeChannel] = useState<SongChannelList[]>(
    []
  );
  const [Channels, setChannels] = useState<{
    loaded: boolean;
    Channel: Channel[];
  }>({ loaded: false, Channel: [] });

  const getAllChannel = useCallback(async () => {
    fetch(`${API_URL}/Channel/GetAll`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    })
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data.success === true) {
          setChannels({ loaded: true, Channel: data?.data });
        } else {
          setChannels({ loaded: true, Channel: [] });
        }
      });
  }, [IsTokenUpdated]);

  const fetchChannelList = useCallback(async () => {
    fetch(`${API_URL}/Song/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    })
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data.success === true) {
          setSubscribeChannel(data?.data?.channels);
        } else {
          setSubscribeChannel([]);
        }
      });
  }, [id, IsTokenUpdated]);

  const columns = useMemo<MRT_ColumnDef<Channel>[]>(
    () => [
      {
        accessorKey: "name",
        header: t("main.name"),
      },
    ],
    [t]
  );
  const isChannelSelected = (rowData: Channel) => {
    const isSubscribed = subscribeChannel?.some(
      (attr) => attr.id === rowData.id
    );
    return {
      backgroundColor: isSubscribed ? "#42876A" : "",
      color: isSubscribed ? "#fff" : "",
    };
  };
  const handleChannelSelection = async (rowData: Channel) => {
    const isSelected = subscribeChannel?.some((attr) => attr.id === rowData.id);

    if (id !== "") {
      if (isSelected) {
        // Unsubscribe the channel
        fetch(`${API_URL}/Channel/ChannelSongs`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token?.accessToken}`,
          },
          body: JSON.stringify({
            songsId: [id],
            channelsId: rowData.id,
          }),
        })
          .then((response) => {
            if (
              response.ok === false &&
              response.status === 401 &&
              token?.refreshToken !== ""
            ) {
              setIsTokenExpired(true);
            } else if (response.status === 401 && token?.refreshToken === "") {
              navigate("/");
              localStorage.removeItem("token");
              localStorage.clear();
            } else {
              setIsTokenUpdated(false);
              return response.json();
            }
          })
          .then((data) => {
            if (data?.success) {
              toast.success(t("main.Channel_Unsubscribed_Successfully"));
              setSubscribeChannel(
                subscribeChannel?.filter((attr) => attr.id !== rowData.id)
              );
            }
          });
      } else {
        // Subscribe the channel
        fetch(`${API_URL}/Channel/ChannelSongs`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token?.accessToken}`,
          },
          body: JSON.stringify({
            songsId: [id],
            channelsId: rowData.id,
          }),
        })
          .then((response) => {
            if (
              response.ok === false &&
              response.status === 401 &&
              token?.refreshToken !== ""
            ) {
              setIsTokenExpired(true);
            } else if (response.status === 401 && token?.refreshToken === "") {
              navigate("/");
              localStorage.removeItem("token");
              localStorage.clear();
            } else {
              setIsTokenUpdated(false);
              return response.json();
            }
          })
          .then((data) => {
            if (data?.success) {
              toast.success(t("main.Channel_Subscribed_Successfully"));
              setSubscribeChannel([...subscribeChannel, rowData]);
            }
          });
      }
    }
  };
  useEffect(() => {
    getAllChannel();
    id !== undefined && fetchChannelList();
  }, [fetchChannelList, getAllChannel, id]);

  return Channels.loaded === false ? (
    <div style={{ justifyContent: "center", display: "flex" }}>
      <CircularProgress style={{ marginTop: "15rem" }} />
    </div>
  ) : (
    <div className="ChannelAssTable">
      <MaterialReactTable
        columns={columns}
        data={Channels.Channel}
        enableColumnActions={false}
        enableColumnFilters={false}
        enablePagination={true}
        enableSorting={false}
        enableTopToolbar={false}
        muiTableBodyRowProps={({ row }) => ({
          // onClick: () => {
          //   setRowSelection(row.id);
          //   handleChannelSelection(row.original);
          // },
          hover: false,
          style: isChannelSelected(row.original),
          selected: rowSelection === row.id,
          sx: {
            cursor: "pointer",
          },
        })}
        muiTableBodyCellProps={({ row }) => ({
          style: {
            color: isChannelSelected(row.original).color,
          },
        })}
        muiTableBodyProps={{
          sx: {
            "& tr:nth-of-type(even)": {
              backgroundColor: "#F0F4F6",
            },
          },
        }}
      />
    </div>
  );
};

export default ChannelAssignmentsTab;

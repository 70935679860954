/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  List,
  Button,
  useTheme,
  ListItem,
  Collapse,
  IconButton,
  Typography,
  ListItemText,
  ListItemButton,
  Tooltip,
} from "@mui/material";
import "./customerListSidebar.scss";
import { Add, Remove } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { CustomerList } from "../../types/Customer";
import { useChannelIdContext } from "../../context/ChannelIdContext";
import AddEditSitesDialog from "../../component/Dialogs/AddEditCustomerDialog/AddEditSitesDialog";
import AddEditRegionDialog from "../../component/Dialogs/AddEditCustomerDialog/AddEditRegionDialog";
import DeleteCustomerDialog from "../../component/Dialogs/DeleteCustomerDialog/DeleteCustomerDialog";
import AddEditCustomerDialog from "../../component/Dialogs/AddEditCustomerDialog/AddEditCustomerDialog";
import { ReactComponent as Edit } from "../../assets/images/EditIcon.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const API_URL = process.env.REACT_APP_BACKEND_URL;
const CustomerListSidebar = ({
  setIsSiteSelected,
  isUpdated,
  setIsUpdated,
  setCustId,
}: {
  setIsSiteSelected: React.Dispatch<React.SetStateAction<boolean>>;
  isUpdated: boolean;
  setIsUpdated: React.Dispatch<React.SetStateAction<boolean>>;
  setCustId: React.Dispatch<React.SetStateAction<number>>;
}) => {
  let theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [openStates, setOpenStates] = useState<{ [x: string]: boolean }>({});
  const [isActive, setIsActive] = useState<any>();
  const [Activeitem, setActiveitem] = useState<{
    id: number;
    name: string;
  }>({ id: 0, name: "" });
  const [customerList, setCustomerList] = useState<CustomerList[]>([]);
  const [isAddCustomer, setIsAddCustomer] = useState<boolean>(false);
  const [customerId, setCustomerId] = useState<number | null>(null);
  const [regionId, setRegionId] = useState<number | null>(null);
  const [selecttedRegionId, setSelecttedRegionId] = useState<number | null>(
    null
  );
  const [selectedSiteId, setSelectedSiteId] = useState<number | null>(null);
  const [IsRegion, setIsRegion] = useState<boolean>(false);
  const [IsSite, setIsSite] = useState<boolean>(false);
  const [isAdded, setIsAdded] = useState<boolean>(false);
  const [isDelete, setIsDelete] = useState<boolean>(false);
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  const {
    deleteCustomeId,
    setDeleteCustomeId,
    setSiteId,
    setIsTokenExpired,
    setIsTokenUpdated,
    IsTokenUpdated,
  } = useChannelIdContext();
  const handleClick = (index: string) => {
    setOpenStates((prevState: { [x: string]: boolean }) => {
      const newState = { ...prevState };
      newState[index] = !prevState[index];
      return newState;
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      fetch(`${API_URL}/Customer/GetAll`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token?.accessToken}`,
        },
      })
        .then((response) => {
          if (
            response.ok === false &&
            response.status === 401 &&
            token?.refreshToken !== ""
          ) {
            setIsTokenExpired(true);
          } else if (response.status === 401 && token?.refreshToken === "") {
            navigate("/");
            localStorage.removeItem("token");
            localStorage.clear();
          } else {
            setIsTokenUpdated(false);
            return response.json();
          }
        })
        .then((data) => {
          if (data.success === true) {
            setIsAdded(false);
            setIsUpdated(false);
            setDeleteCustomeId(false);
            setCustomerList(data.data);
          } else {
            setCustomerList([]);
          }
        });
    };
    fetchData();
  }, [
    isAdded,
    isUpdated,
    deleteCustomeId,
    setIsUpdated,
    setDeleteCustomeId,
    IsTokenUpdated,
  ]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        borderRight: "1px solid #9E9E9E",
        height: "100%",
      }}
    >
      <Box
        className="CustomerBtn"
        style={{
          display: "flex",
          backgroundColor: "#34393F",
          height: "50px",
        }}
      >
        <IconButton
          aria-label="New region"
          sx={{
            display: "flex",
            flexDirection: "column",
            p: 1,
            pt: 4,
            aspectRatio: "1/1",
            color: theme.palette.mode === "dark" ? "#D9D9D9" : "#D9D9D9",
          }}
          onClick={() => setIsRegion(true)}
          disabled={
            Activeitem.name === "" ||
            Activeitem.name === "site" ||
            Activeitem.name === "Region"
              ? true
              : false
          }
        >
          <Add />
          <Typography variant="subtitle1" gutterBottom>
            {t("main.new_region")}
          </Typography>
        </IconButton>
        <IconButton
          aria-label="New site"
          sx={{
            display: "flex",
            flexDirection: "column",
            p: 1,
            aspectRatio: "1/1",
            width: "8rem",
            color: theme.palette.mode === "dark" ? "#D9D9D9" : "#D9D9D9",
          }}
          disabled={
            Activeitem.name === "" || Activeitem.name === "Customer"
              ? true
              : false
          }
          onClick={() => setIsSite(true)}
        >
          <Add />
          <Typography variant="subtitle1" gutterBottom>
            {t("main.new_site")}
          </Typography>
        </IconButton>
        <IconButton
          aria-label="Delete"
          sx={{
            display: "flex",
            flexDirection: "column",
            p: 1,
            aspectRatio: "1/1",
            width: "8rem",
            color: theme.palette.mode === "dark" ? "#D9D9D9" : "#D9D9D9",
          }}
          disabled={Activeitem?.name === "" ? true : false}
          onClick={() => setIsDelete(true)}
        >
          <Remove />
          <Typography variant="subtitle1" gutterBottom>
            {t("main.delete")}
          </Typography>
        </IconButton>
      </Box>
      <Typography
        style={{
          backgroundColor: "#E0E0E0",
          padding: theme.spacing(2),
          textAlign: "left",
          color: "#34393F",
          fontSize: "18px",
          lineHeight: "normal",
          paddingTop: "15px",
          paddingBottom: "15px",
        }}
      >
        {t("main.customer")}
      </Typography>
      <Box sx={{ flexGrow: 1, bgcolor: "background.paper" }}>
        <List component="nav" style={{ padding: "0" }}>
          {customerList?.length !== 0 &&
            customerList?.map((item: any, index: any) => {
              const isOpen = openStates[index];
              const hasSubItems = item.regions && item.regions.length > 0;
              return (
                <div key={item?.id}>
                  <ListItemButton
                    className={`listItem ${
                      isActive === item.name + "-" + item.id ? "active" : ""
                    }`}
                    onClick={() => {
                      handleClick(index);
                      setIsActive(item.name + "-" + item.id);
                      setActiveitem({
                        id: item.id,
                        name: "Customer",
                      });
                      setIsSiteSelected(false);
                      setSiteId(0);
                      setCustId(item.id);
                    }}
                    // onDoubleClick={() => {
                    //   setCustomerId(item.id);
                    //   setIsAddCustomer(true);
                    //   setRegionId(null);
                    // }}
                  >
                    {isActive === item.name + "-" + item.id ? (
                      <span className="ChannelHorizontalTabs__item-icon">
                        <Tooltip title={t("main.Edit")}>
                          <Edit
                            style={{
                              marginRight: "20px",
                              cursor: "pointer",
                              backgroundColor: "unset",
                              borderRadius: "unset",
                              color: "#fff",
                            }}
                            onClick={() => {
                              setCustomerId(item.id);
                              setIsAddCustomer(true);
                              setRegionId(null);
                            }}
                          />
                        </Tooltip>
                      </span>
                    ) : (
                      ""
                    )}
                    <ListItemText primary={item.name} />
                    {hasSubItems && (isOpen ? <Remove /> : <Add />)}
                  </ListItemButton>
                  {hasSubItems && (
                    <Collapse in={isOpen} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {item?.regions?.map((region: any, regionIndex: any) => (
                          <div key={index + "-" + region?.id}>
                            <ListItemButton
                              className={`subItem ${
                                isActive === region.name + "-" + region.id
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() => {
                                handleClick(index + "-" + regionIndex);
                                setIsActive(region.name + "-" + region.id);
                                setActiveitem({
                                  id: region.id,
                                  name: "Region",
                                });
                                setIsSiteSelected(false);
                                setSiteId(0);
                                setSelecttedRegionId(region.id);
                              }}
                              // onDoubleClick={() => {
                              //   setIsRegion(true);
                              //   setRegionId(region.id);
                              // }}
                              sx={{ paddingLeft: "2rem" }}
                            >
                              {isActive === region.name + "-" + region.id ? (
                                <span className="ChannelHorizontalTabs__item-icon">
                                  <Tooltip title={t("main.Edit")}>
                                    <Edit
                                      style={{
                                        marginRight: "20px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setIsRegion(true);
                                        setRegionId(region.id);
                                        setSelecttedRegionId(region.id);
                                      }}
                                    />
                                  </Tooltip>
                                </span>
                              ) : (
                                ""
                              )}
                              <ListItemText primary={region.name} />
                              {region.sites && region.sites.length > 0 ? (
                                openStates[index + "-" + regionIndex] ? (
                                  <Remove />
                                ) : (
                                  <Add />
                                )
                              ) : null}
                            </ListItemButton>
                            <Collapse
                              in={openStates[index + "-" + regionIndex]}
                              timeout="auto"
                              unmountOnExit
                            >
                              <List component="div" disablePadding>
                                {region.sites &&
                                  region.sites?.map(
                                    (site: any, siteIndex: any) => (
                                      <>
                                        <ListItem
                                          key={
                                            index +
                                            "-" +
                                            regionIndex +
                                            "-" +
                                            siteIndex
                                          }
                                          className={`subItem ${
                                            isActive ===
                                            site.name + "-" + site.id
                                              ? "active"
                                              : ""
                                          } siteItem`}
                                          onClick={() => {
                                            handleClick(
                                              index +
                                                "-" +
                                                regionIndex +
                                                "-" +
                                                siteIndex
                                            );
                                            setIsActive(
                                              site.name + "-" + site.id
                                            );
                                            setIsSiteSelected(true);
                                            setSiteId(site.id);
                                            setActiveitem({
                                              id: site.id,
                                              name: "site",
                                            });
                                            setSelecttedRegionId(region.id);
                                          }}
                                          // onDoubleClick={() => {
                                          //   setIsSite(true);
                                          //   setSelectedSiteId(site.id);
                                          //   setSelecttedRegionId(region.id);
                                          // }}
                                          sx={{ paddingLeft: "4rem" }}
                                        >
                                          {isActive ===
                                          site.name + "-" + site.id ? (
                                            <span className="ChannelHorizontalTabs__item-icon">
                                              <Tooltip title={t("main.Edit")}>
                                                <Edit
                                                  style={{
                                                    marginRight: "20px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() => {
                                                    setIsSite(true);
                                                    setSelectedSiteId(site.id);
                                                    setSelecttedRegionId(
                                                      region.id
                                                    );
                                                  }}
                                                />
                                              </Tooltip>
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                          <ListItemText primary={site.name} />
                                        </ListItem>
                                      </>
                                    )
                                  )}
                              </List>
                            </Collapse>
                          </div>
                        ))}
                      </List>
                    </Collapse>
                  )}
                </div>
              );
            })}
        </List>
        <Box style={{ textAlign: "center" }}>
          <Button
            aria-label="New customer"
            variant="outlined"
            className="newCustomerBtn"
            sx={{
              color: theme.palette.mode === "dark" ? "#D9D9D9" : "##3C735C",
            }}
            onClick={() => setIsAddCustomer(true)}
            disabled={
              Activeitem?.name === "Region" || Activeitem?.name === "site"
                ? true
                : false
            }
          >
            <Add /> {t("main.new_customer")}
          </Button>
        </Box>
      </Box>
      <AddEditCustomerDialog
        isOpen={isAddCustomer}
        handleClose={() => {
          setIsAddCustomer(false);
          setCustomerId(null);
        }}
        customerId={customerId}
        setIsAdded={setIsAdded}
        isAdded={isAdded}
      />
      <AddEditRegionDialog
        isOpen={IsRegion}
        regionId={regionId}
        handleClose={() => {
          setIsRegion(false);
          setRegionId(null);
        }}
        customerId={Activeitem.id}
        setIsAdded={setIsAdded}
      />
      <AddEditSitesDialog
        isOpen={IsSite}
        selectedSiteId={selectedSiteId}
        setSelectedSiteId={setSelectedSiteId}
        handleClose={() => {
          setIsSite(false);
          setSelectedSiteId(null);
        }}
        RegionId={selecttedRegionId}
        setIsAdded={setIsAdded}
      />
      <DeleteCustomerDialog
        isOpen={isDelete}
        handleClose={() => setIsDelete(false)}
        setActiveitem={setActiveitem}
        title={Activeitem?.name}
        selectedId={Activeitem.id}
        setIsAdded={setIsAdded}
      />
    </Box>
  );
};

export default CustomerListSidebar;

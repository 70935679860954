/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useEffect, useState } from "react";

export interface ChannelIdContextValue {
  IsCategoryAdded: boolean;
  setIsCategoryAdded:React.Dispatch<React.SetStateAction<boolean>>;
  channelId: number | undefined;
  setChannelId: React.Dispatch<React.SetStateAction<number | undefined>>;
  songId: number[];
  Language: string;
  setLanguage: React.Dispatch<React.SetStateAction<string>>;
  setSongId: React.Dispatch<React.SetStateAction<number[]>>;
  channelSongData: any[];
  songListByChannel: any[];
  setSongListByChannel: React.Dispatch<React.SetStateAction<any[]>>;
  setChannelSongData: React.Dispatch<React.SetStateAction<any[]>>;
  deleteChannelId: number[];
  deleteZoneId: string[];
  dropinsData: any[];
  setDropinsdata: React.Dispatch<React.SetStateAction<any[]>>;
  setDeleteChannelId: React.Dispatch<React.SetStateAction<number[]>>;
  setDeleteZoneId: React.Dispatch<React.SetStateAction<string[]>>;
  searchInput: string;
  setSearchInput: React.Dispatch<React.SetStateAction<string>>;
  currentUser: any;
  setcurrentUser: React.Dispatch<React.SetStateAction<any>>;
  searchContent: string;
  setSearchContent: React.Dispatch<React.SetStateAction<string>>;
  selectedFile: File | null | any;
  setSelectedFile: React.Dispatch<React.SetStateAction<File | null | any>>;
  isAdded: boolean;
  setIsAdded: React.Dispatch<React.SetStateAction<boolean>>;
  isAddedSong: boolean;
  setIsAddedSong: React.Dispatch<React.SetStateAction<boolean>>;
  isDeleted: boolean;
  setisDeleted: React.Dispatch<React.SetStateAction<boolean>>;
  isUploadFile: boolean;
  setIsUploadFile: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  scheduleId: string;
  setScheduleId: React.Dispatch<React.SetStateAction<string>>;
  deleteCustomeId: boolean;
  setDeleteCustomeId: React.Dispatch<React.SetStateAction<boolean>>;
  SiteId: number;
  setSiteId: React.Dispatch<React.SetStateAction<number>>;
  VoiceTrackChannelId: number;
  setVoiceTrackChannelId: React.Dispatch<React.SetStateAction<number>>;
  VoiceTrackCategoryId: number;
  setVoiceTrackCategoryId: React.Dispatch<React.SetStateAction<number>>;
  isUploadAudio: boolean;
  setIsUploadAudio: React.Dispatch<React.SetStateAction<boolean>>;
  categoryModalOpen: boolean;
  setCategoryModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedAudioEditor: string;
  setSelectedAudioEditor: React.Dispatch<React.SetStateAction<string>>;
  rowSelection: any;
  setRowSelection: React.Dispatch<React.SetStateAction<any>>;
  selectedZoneId: string;
  setSelectedZoneId: React.Dispatch<React.SetStateAction<string>>;
  selectedZoneName: string;
  setSelectedZoneName: React.Dispatch<React.SetStateAction<string>>;
  scheduleList: any;
  setScheduleList: React.Dispatch<React.SetStateAction<any>>;
  importXMLFile: boolean;
  setIsTokenExpired: React.Dispatch<React.SetStateAction<boolean>>;
  IsTokenExpired: boolean;
  setIsTokenUpdated: React.Dispatch<React.SetStateAction<boolean>>;
  IsTokenUpdated: boolean;
  setImportXMLFile: React.Dispatch<React.SetStateAction<boolean>>;
  selectedCustomer: any;
  setSelectedCustomer: React.Dispatch<React.SetStateAction<any>>;
  selectedSite: any;
  setSelectedSite: React.Dispatch<React.SetStateAction<any>>;
  RegenerateChannelData: boolean;
  setRegenerateChannelData: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChannelIdContext = createContext<ChannelIdContextValue | undefined>(
  undefined
);

export const ChannelIdContextProvider = ({ children }: any) => {
  const [channelId, setChannelId] = useState<number>();
  const [songId, setSongId] = useState<number[]>([]);
  const [IsCategoryAdded, setIsCategoryAdded] = useState<any>(false);
  const [songListByChannel, setSongListByChannel] = useState<any[]>([]);
  const [deleteChannelId, setDeleteChannelId] = useState<number[]>([]);
  const [deleteZoneId, setDeleteZoneId] = useState<string[]>([]);
  const [searchInput, setSearchInput] = useState<string>("");
  const [Language, setLanguage] = useState("en");
  const [currentUser, setcurrentUser] = useState<any>();
  const [selectedFile, setSelectedFile] = useState<File | null | any>(null);
  const [isAdded, setIsAdded] = useState<boolean>(false);
  const [isAddedSong, setIsAddedSong] = useState<boolean>(false);
  const [isUploadFile, setIsUploadFile] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [deleteCustomeId, setDeleteCustomeId] = useState<boolean>(false);
  const [VoiceTrackChannelId, setVoiceTrackChannelId] = useState<number>(0);
  const [VoiceTrackCategoryId, setVoiceTrackCategoryId] = useState<number>(0);
  const [SiteId, setSiteId] = useState<number>(0);
  const [isUploadAudio, setIsUploadAudio] = useState<boolean>(false);
  const [categoryModalOpen, setCategoryModalOpen] = useState(false);
  const [searchContent, setSearchContent] = useState<string>("");
  const [isDeleted, setisDeleted] = useState<boolean>(false);
  const [scheduleId, setScheduleId] = useState<string>("");
  const [selectedZoneId, setSelectedZoneId] = useState<string>("");
  const [selectedZoneName, setSelectedZoneName] = useState<string>("");
  const [dropinsData, setDropinsdata] = useState<any>([]);
  const [channelSongData, setChannelSongData] = useState<any>([]);
  const [selectedAudioEditor, setSelectedAudioEditor] = useState<string>("");
  const [rowSelection, setRowSelection] = useState<any>({});
  const [scheduleList, setScheduleList] = useState<any>({
    loaded: false,
    schedules: [],
  });
  const [importXMLFile,setImportXMLFile] = useState<boolean>(false);
  const [selectedCustomer, setSelectedCustomer] = useState<any>(null);
  const [selectedSite, setSelectedSite] = useState<any>(null);
  const [IsTokenExpired, setIsTokenExpired] = useState<any>(false);
  const [IsTokenUpdated, setIsTokenUpdated] = useState<any>(false);
  const [RegenerateChannelData, setRegenerateChannelData] = useState<boolean>(false);

  const API_URL = process.env.REACT_APP_BACKEND_URL;
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  const RefreshToken = async () => {
    try {
      const response = await fetch(`${API_URL}/Auth/Refresh`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: token?.username,
          refreshToken: token?.refreshToken,
        }),
      });
      const data = await response.json();
      if (data.success === true) {
        setIsTokenUpdated(true)
        localStorage.setItem("token", JSON.stringify(data.data));
        setIsTokenExpired(false)
      }
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    if(IsTokenExpired === true){
      RefreshToken()
    }
  }, [IsTokenExpired])

  return (
    <ChannelIdContext.Provider
      value={{
        IsCategoryAdded,
        setIsCategoryAdded,
        isAddedSong,
        setIsAddedSong,
        channelId,
        setChannelId,
        Language,
        setLanguage,
        songId,
        setSongId,
        currentUser,
        setcurrentUser,
        selectedZoneId,
        setSelectedZoneId,
        selectedZoneName,
        setSelectedZoneName,
        deleteChannelId,
        setDeleteChannelId,
        deleteZoneId,
        setDeleteZoneId,
        songListByChannel,
        setSongListByChannel,
        searchInput,
        setSearchInput,
        selectedFile,
        setSelectedFile,
        isDeleted,
        setisDeleted,
        isAdded,
        setIsAdded,
        isUploadFile,
        setIsUploadFile,
        isLoading,
        setIsLoading,
        deleteCustomeId,
        setDeleteCustomeId,
        SiteId,
        setSiteId,
        scheduleId,
        setScheduleId,
        VoiceTrackChannelId,
        setVoiceTrackChannelId,
        VoiceTrackCategoryId,
        setVoiceTrackCategoryId,
        isUploadAudio,
        setIsUploadAudio,
        categoryModalOpen,
        setCategoryModalOpen,
        searchContent,
        setSearchContent,
        dropinsData,
        setDropinsdata,
        setSelectedAudioEditor,
        selectedAudioEditor,
        channelSongData,
        setChannelSongData,
        rowSelection,
        setRowSelection,
        scheduleList,
        setScheduleList,
        importXMLFile,
        setImportXMLFile,
        selectedCustomer,
        setSelectedCustomer,
        selectedSite,
        setSelectedSite,
        IsTokenUpdated,
        setIsTokenUpdated,
        IsTokenExpired,
        setIsTokenExpired,
        RegenerateChannelData, setRegenerateChannelData
      }}
    >
      {children}
    </ChannelIdContext.Provider>
  );
};

export const useChannelIdContext = (): ChannelIdContextValue => {
  const context = useContext(ChannelIdContext);
  if (!context) {
    throw new Error( "useChannelIdContext must be used within a StateProvider !");
  }
  return context;
};

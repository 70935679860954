import Main from "../pages/Main";
import React, { useEffect } from "react";
import AudioPage from "../pages/AudioPage";
import LoginPage from "../pages/LoginPage";
import PlayerPage from "../pages/PlayerPage";
import ProfilePage from "../pages/ProfilePage";
import EditSongPage from "../pages/EditSongPage";
import SettingsPage from "../pages/SettingsPage";
import TrackPage from "../pages/MusicLibraryPage";
import PrivateRoutes from "./PrivateRoutesconfig";
import VoiceTrackPage from "../pages/VoiceTrackPage";
import ChannelEditorPage from "../pages/ChannelEditor";
import MusicLibraryPage from "../pages/MusicLibraryPage";
import AnnouncementPage from "../pages/ContentLibraryPage";
import ResetPassword from "../component/Auth/ResetPassword";
import ManageCustomerPage from "../pages/ManageCustomerPage";
import ContentLibraryPage from "../pages/ContentLibraryPage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ChannelSchedulerPage from "../pages/ChannelSchedulerPage";
import { useChannelIdContext } from "../context/ChannelIdContext";

function RouterConfig() {
  const { setcurrentUser } = useChannelIdContext();

  useEffect(() => {
    const stringData = localStorage.getItem("userData");
    if (stringData) {
      setcurrentUser(JSON.parse(stringData));
    }
  }, [setcurrentUser]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<LoginPage></LoginPage>} />
          <Route path="Login" element={<LoginPage></LoginPage>} />
        </Route>
        <Route element={<PrivateRoutes />}>
          {/* <Route path="zone-player">
            <Route index element={<ZonePage></ZonePage>} />
          </Route> */}
          <Route path="music-library">
            <Route index element={<MusicLibraryPage></MusicLibraryPage>} />
            <Route path=":id" element={<TrackPage></TrackPage>} />
          </Route>
          <Route path="channel-scheduler">
            <Route
              index
              element={<ChannelSchedulerPage></ChannelSchedulerPage>}
            />
          </Route>
          <Route path="channel-editor">
            <Route index element={<ChannelEditorPage></ChannelEditorPage>} />
          </Route>
          <Route path="music-library/audio-editor/:id">
            <Route index element={<EditSongPage></EditSongPage>} />
          </Route>
          <Route path="content-library/voice-track-editor/:id">
            <Route index element={<EditSongPage></EditSongPage>} />
          </Route>
          <Route path="channel-editor/audio-editor/:id">
            <Route index element={<EditSongPage></EditSongPage>} />
          </Route>
          <Route path="announcements">
            <Route index element={<AnnouncementPage></AnnouncementPage>} />
          </Route>
          <Route path="voicetrack">
            <Route index element={<VoiceTrackPage></VoiceTrackPage>} />
          </Route>
          <Route path="audio">
            <Route index element={<AudioPage></AudioPage>} />
          </Route>
          <Route path="audio-player">
            <Route index element={<PlayerPage></PlayerPage>} />
          </Route>
          <Route path="manage-customers">
            <Route index element={<ManageCustomerPage></ManageCustomerPage>} />
          </Route>
          <Route path="content-library">
            <Route index element={<ContentLibraryPage></ContentLibraryPage>} />
          </Route>
          <Route path="settings">
            <Route index element={<SettingsPage></SettingsPage>} />
          </Route>
          <Route path="resetpassword">
            <Route index element={<Main></Main>} />
            <Route path=":encoded" element={<ResetPassword />}></Route>
          </Route>
          <Route path="profile">
            <Route index element={<ProfilePage></ProfilePage>} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default RouterConfig;

/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { Song } from "../../types/Customer";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useChannelIdContext } from "../../context/ChannelIdContext";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import {
  ChevronLeft,
  ChevronRight,
  Edit,
  FirstPage,
  LastPage,
  Search,
} from "@mui/icons-material";
import React, { useMemo, useEffect, useState, useCallback } from "react";
import {
  CircularProgress, TextField, Typography, Box,
  IconButton,
  MenuItem,
  Button,
  Select
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface Zone {
  id: number;
  name: string;
}

const API_URL = process.env.REACT_APP_BACKEND_URL;
const CustomerPlayedHistoryTable = () => {
  const [playedSongs, setPlayedSongs] = useState<{
    loaded: boolean;
    songs: Song[];
  }>({ loaded: false, songs: [] });
  const { t } = useTranslation();
  const [zones, setZones] = useState<Zone[]>();
  const [selectedZone, setSelectedZone] = useState<any>(0);
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  const { SiteId, searchInput, setIsTokenExpired, setIsTokenUpdated, IsTokenUpdated } = useChannelIdContext();
  const [rangeStartDate, setRangeStartDate] = useState<any>(null);
  const [rangeEndDate, setRangeEndDate] = useState<any>(null);
  const [filteredData, setFilteredData] = useState<any>([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 25,
  });
  const [rowCount, setRowCount] = useState<any>(0)
  const navigate = useNavigate()
  const FilterChannelSongData = useCallback(() => {
    const filteredList = playedSongs?.songs?.filter((item: any) => {
      return item?.title?.toLowerCase()?.includes(searchInput?.toLowerCase());
    });
    setFilteredData(filteredList);
  }, [searchInput, playedSongs?.songs]);

  const adjustTimezoneOffset = (date: moment.MomentInput) => {
    const timezoneOffset = moment().utcOffset();
    return moment(date).add(timezoneOffset, "minutes").toISOString();
  };

  const handleRangeStartDateChange = (value: moment.MomentInput) => {
    const adjustedDate = adjustTimezoneOffset(value);
    setRangeStartDate(adjustedDate);
  };

  const handleRangeEndDateChange = (value: moment.MomentInput) => {
    const adjustedDate = adjustTimezoneOffset(value);
    setRangeEndDate(adjustedDate);
  };
  const formatDate = (value: any) => {
    const date = moment(value.renderedCellValue).format("DD.MM.YYYY");
    const time = moment(value.renderedCellValue).format("HH:mm") + "";
    return `${date} ${time}`;
  };
  const [pageSize] = useState<number | any>(25);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(1);
  const [tablePageSize, setTablePageSize] = useState(
    pageSize
      ? JSON.parse(pageSize).trackTable
        ? JSON.parse(pageSize).trackTable
        : 25
      : 25
  );

  const fetchZoneData = useCallback(async () => {

    fetch(`${API_URL}/Zone/GetByFilters?siteId=${SiteId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    })
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true)
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false)
          return response.json()
        }
      })
      .then((data) => {
        if (data.success === true) {
          setZones(data?.data);

          if (data?.data.length > 0) {
            setSelectedZone(data?.data[0].id)
          } else {
            setSelectedZone(0)
          }
        } else {
          setZones([]);
        }
      })
  }, [SiteId, IsTokenUpdated]);

  useEffect(() => {
    FilterChannelSongData();
  }, [FilterChannelSongData]);

  const handleLastPage = () => {
    if (
      totalCount / tablePageSize >
      parseInt((totalCount / tablePageSize).toString())
    ) {
      setCurrentPage(parseInt((totalCount / tablePageSize + 1).toString()));
    } else {
      setCurrentPage(parseInt((totalCount / tablePageSize).toString()));
    }
  };

  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "id",
        header: t("main.Song_ID"),
        size: 10,
      },
      {
        accessorKey: "playDate",
        header: t("main.Play_date"),
        Cell: (renderedCellValue: any) => (
          <span>{formatDate(renderedCellValue)}</span>
        ),
      },
      {
        accessorKey: "title",
        header: t("main.title"),
      },
      {
        accessorKey: "artist1.name",
        header: t("main.artist_1"),
      },
      {
        accessorKey: "artist2.name",
        header: t("main.artist_2"),
      },
      {
        accessorKey: "artist3.name",
        header: t("main.artist_3"),
      },
    ],
    [t]
  );

  const fetchData = useCallback(async () => {
    fetch(
      `${API_URL}/Site/GetPlayedSongByZoneId/${selectedZone}?page=${currentPage - 1}&pageSize=${tablePageSize}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token?.accessToken}`,
        },
      }
    ).then((response) => {
      if (
        response.ok === false &&
        response.status === 401 &&
        token?.refreshToken !== ""
      ) {
        setIsTokenExpired(true);
      } else if (response.status === 401 && token?.refreshToken === "") {
        navigate("/");
        localStorage.removeItem("token");
        localStorage.clear();
      } else {
        setIsTokenUpdated(false);
        return response.json();
      }
    })
      .then((data) => {
        if (data.success === true) {
          setTotalCount(data?.pagination?.totalCount)
          if (rangeStartDate === null && rangeEndDate === null) {
            setPlayedSongs({ loaded: true, songs: data?.data });
          } else {
            const filteredData: any[] = data?.data?.filter((song: any) => {
              const playDate = moment(song?.playDate).format("YYYY-MM-DD");
              if (rangeStartDate !== null && rangeEndDate !== null) {
                // Filter between start and end dates
                const startDate = moment(rangeStartDate).format("YYYY-MM-DD");
                const endDate = moment(rangeEndDate).format("YYYY-MM-DD");
                return moment(playDate).isBetween(startDate, endDate, null, "[]");
              } else if (rangeStartDate !== null) {
                // Filter based on start date only
                const startDate = moment(rangeStartDate).format("YYYY-MM-DD");
                return moment(playDate).isSame(startDate, "day");
              } else if (rangeEndDate !== null) {
                // Filter based on end date only
                const endDate = moment(rangeEndDate).format("YYYY-MM-DD");
                return moment(playDate).isSame(endDate, "day");
              }
              return true;
            });
            setPlayedSongs({ loaded: true, songs: filteredData });
          }
        } else {
          setPlayedSongs({ loaded: true, songs: [] });
        }
      });

  }, [SiteId, rangeEndDate, rangeStartDate, currentPage, tablePageSize, selectedZone]);

  useEffect(() => {
    fetchZoneData();
  }, [SiteId])
  useEffect(() => {
    if (SiteId !== 0) {

      fetchData();
    }
    if (SiteId === 0) {
      setPlayedSongs({ loaded: true, songs: [] });
    }
  }, [SiteId, fetchData, rangeEndDate, IsTokenUpdated]);

  return (
    <div className="playSong">
      <div className="music-library">
        <div style={{ display: "flex" }}>
          <Typography variant="h6" fontSize={14}>
            {t("main.zone")}
          </Typography>
          <Select
            className="CustomDrpSelect"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={
              zones?.some((item: any) => item.id === selectedZone)
                ? selectedZone
                : ""
            }
            onChange={(e) => {
              setSelectedZone(e.target.value);
            }}
            required
          >
            {zones &&
              zones?.map((item) => (
                <MenuItem key={item?.id} value={item?.id}>
                  {item?.name}
                </MenuItem>
              ))}
          </Select>
          <Typography variant="h6" fontSize={14}>
            {t("main.RangeStart")}
          </Typography>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label={""}
              inputFormat="DD.MM.YYYY"
              value={rangeStartDate}
              onChange={handleRangeStartDateChange}
              renderInput={(params) => (
                <TextField {...params} autoComplete="off" />
              )}
            />
          </LocalizationProvider>

          <Typography variant="h6" fontSize={14}>
            {t("main.RangeEnd")}
          </Typography>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label={""}
              inputFormat="DD.MM.YYYY"
              value={rangeEndDate}
              onChange={handleRangeEndDateChange}
              renderInput={(params) => (
                <TextField {...params} autoComplete="off" />
              )}
            />
          </LocalizationProvider>
          <Button
            className="Button"
            onClick={() => {
              setRangeStartDate(null)
              setRangeEndDate(null)
            }}
            style={{ backgroundColor: "#34393f", color: "#fff" }}
          >
            {t("main.clear_filter")}
          </Button>


        </div>
      </div>
      {playedSongs.loaded === false ? (
        <div style={{ justifyContent: "center", display: "flex" }}>
          <CircularProgress style={{ marginTop: "15rem" }} />
        </div>
      ) : (
        <>
          <MaterialReactTable
            columns={columns}
            data={searchInput ? filteredData : playedSongs?.songs}
            enableStickyHeader
            enablePagination={false}
            enableSorting={true}
            enableBottomToolbar={true}
            enableTopToolbar={false}
            enableColumnActions={true}
            enableColumnOrdering={true}
            enableRowActions={false}

            muiTableBodyCellProps={{
              sx: {
                paddingTop: 0,
                paddingBottom: 0,
              },
            }}
            enableGlobalFilterModes
            initialState={{
              showGlobalFilter: true,
            }}
            enablePinning
            rowCount={rowCount}
            muiTableBodyProps={{
              sx: {
                "& tr:nth-of-type(even)": {
                  backgroundColor: "#F0F4F6",
                },
              },
            }}
          />
          <div className="customPagination">
            <span>
              {t("main.rows_per_page")}
            </span>
            <Select
              sx={{ height: "30px" }}
              value={tablePageSize}
              onChange={(e) => {
                setTablePageSize(e.target.value);
                let tablePageSize = {
                  ...JSON.parse(pageSize),
                  trackTable: e.target.value,
                };
                localStorage.setItem("pageSize", JSON.stringify(tablePageSize));
              }}
            >
              <MenuItem value="25">25</MenuItem>
              <MenuItem value="50">50</MenuItem>
              <MenuItem value="100">100</MenuItem>
            </Select>
            <span>
              {(currentPage - 1) * tablePageSize + 1} -{" "}
              {currentPage * tablePageSize < totalCount
                ? currentPage * tablePageSize
                : currentPage * tablePageSize -
                (currentPage * tablePageSize - totalCount)}{" "}
              of {totalCount}
            </span>
            <Box className="customPagination__buttons">
              <IconButton
                onClick={() => setCurrentPage(1)}
                disabled={(currentPage - 1) * tablePageSize + 1 <= 1}
              >
                <FirstPage />
              </IconButton>
              <IconButton
                onClick={() => setCurrentPage(currentPage - 1)}
                disabled={(currentPage - 1) * tablePageSize + 1 <= 1}
              >
                <ChevronLeft />
              </IconButton>
              <IconButton
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={currentPage * tablePageSize >= totalCount}
              >
                <ChevronRight />
              </IconButton>
              <IconButton
                onClick={handleLastPage}
                disabled={currentPage * tablePageSize >= totalCount}
              >
                <LastPage />
              </IconButton>
            </Box>
          </div>
        </>
      )}
    </div>
  );
};

export default CustomerPlayedHistoryTable;

import {
  alpha,
  styled,
  TextField,
  TextFieldProps,
  OutlinedInputProps,
} from "@mui/material";

const MyTextField = styled((props: TextFieldProps) => (
  <TextField
    autoComplete="off"
    InputProps={{ disableunderline: "true" } as Partial<OutlinedInputProps>}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiFilledInput-root": {
    border: "1px solid #d6d6d5",
    overflow: "hidden",
    borderRadius: 4,
    color: theme.palette.mode === "light" ? "#3c434b" : "#fff",
    backgroundColor: theme.palette.mode === "light" ? "#e5e5e5" : "#2b2b2b",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:hover": {
      backgroundColor: "#e0e0e077",
    },
    "&.Mui-focused": {
      backgroundColor: "#e0e0e055",
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

export default MyTextField;

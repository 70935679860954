import {
  Box,
  Theme,
  Avatar,
  Button,
  useTheme,
  Container,
  TextField,
  Typography,
  CssBaseline,
  ThemeProvider,
} from "@mui/material";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import MailLockOutlinedIcon from "@mui/icons-material/MailLockOutlined";

const tbStyle = (theme: Theme) => ({
  padding: "0px",
  borderRadius: "4px",
  backgroundColor: theme.palette.mode === "dark" ? "#000000ad" : "#ffffffad",
});

export default function ResetPassword() {
  const [info, setInfo] = useState<{ email: string; token: string }>({
    email: "",
    token: "",
  });
  const { encoded } = useParams<string>();
  const theme = useTheme();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  //useEffect Area
  useEffect(() => {
    let params: string[] = encoded?.split("&") ?? [];
    setInfo({ email: params[0] ?? "", token: params[1] ?? "" });
  }, [encoded]);

  return (
    <ThemeProvider theme={theme}>
      <div className="main-centered">
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <Box
            sx={{
              paddingLeft: 6,
              paddingRight: 6,
              paddingTop: 3,
              paddingBottom: 3,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              bgcolor: "#f0f0f0",
              borderRadius: 4,
              backgroundColor:
                theme.palette.mode === "dark"
                  ? "rgba(0,0,0,0.6)"
                  : "rgba(255,255,255,0.4)",
              backdropFilter: "blur(40px)",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "warning.main" }}>
              <MailLockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Change your password
            </Typography>
            <Box
              component="form"
              onSubmit={(e) => handleSubmit(e)}
              noValidate
              sx={{ mt: 5 }}
            >
              <Typography variant="h6" gutterBottom>
                {info?.email}
              </Typography>
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="none"
                sx={tbStyle}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password-conf"
                label="Password Confirm"
                type="password"
                id="password-conf"
                autoComplete="none"
                sx={tbStyle}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="large"
                sx={{ mt: 3, mb: 2 }}
              >
                New Password
              </Button>
            </Box>
          </Box>
        </Container>
      </div>
    </ThemeProvider>
  );
}

import {
  Link,
  Paper,
  Stack,
  Button,
  Dialog,
  Select,
  MenuItem,
  TextField,
  PaperProps,
  Typography,
  DialogActions,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { toast } from "react-toastify";
import Draggable from "react-draggable";
import "./addChannelTimeScheduleDialog.scss";
import CloseIcon from "@mui/icons-material/Close";
import React, { MouseEventHandler, useState } from "react";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useTranslation } from "react-i18next";
import { useChannelIdContext } from "../../../context/ChannelIdContext";
import { useNavigate } from "react-router-dom";
const API_URL = process.env.REACT_APP_BACKEND_URL;
function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper
        {...props}
        style={{
          backgroundColor: "#34393f",
          color: "#D9D9D9",
          padding: "1rem",
        }}
      />
    </Draggable>
  );
}
interface Zone {
  id: number;
  name: string;
}
interface Wheel {
  id: number;
  name: string;
}
interface Channel {
  id: number;
  name: string;
}
interface Dropins {
  id: number;
  name: string;
}
interface formData {
  zoneId: string | null;
  dropInId: string | null;
  dayOfWeek: string | null;
  channelId: string | null;
  voiceWheelId: string | null;
  startingTime: Dayjs | null;
}

export default function AddChannelTimeScheduleDialog({
  isOpen,
  handleClose,
}: {
  isOpen: boolean;
  handleClose: any | MouseEventHandler;
}) {
  const { t } = useTranslation();
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  const navigate = useNavigate();
  const { setIsTokenExpired, setIsTokenUpdated } = useChannelIdContext();
  const [zones] = useState<Zone[]>();
  const [wheels] = useState<Wheel[]>();
  const [dropins] = useState<Dropins[]>();
  const [channels] = useState<Channel[]>();
  const [schedule, setSchedule] = useState<formData>({
    zoneId: null,
    dropInId: null,
    dayOfWeek: null,
    channelId: null,
    voiceWheelId: null,
    startingTime: null,
  });

  const handleAddSchedule = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
      body: JSON.stringify({
        zoneId: schedule?.zoneId,
        dropInId: schedule?.dropInId,
        dayOfWeek: schedule?.dayOfWeek,
        channelId: schedule?.channelId,
        voiceWheelId: schedule?.voiceWheelId,
        startingTime: dayjs(schedule?.startingTime).format("HH:mm:ss"),
      }),
    };
    fetch(`${API_URL}/Schedule`, requestOptions)
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data?.success === true) {
          handleClose();
          toast.success(t("main.Schedule_Created_Successfully"));
        } else {
          toast.error(data?.message);
        }
        setSchedule({
          zoneId: null,
          dropInId: null,
          channelId: null,
          dayOfWeek: null,
          voiceWheelId: null,
          startingTime: null,
        });
      });
  };
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={isOpen}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      className="addModelDialog"
      aria-labelledby="draggable-dialog-title"
    >
      <div className="dialogForm">
        <form>
          <Typography variant="h5" className="addModelDialog-title">
            {t("main.add_schedule_item")}
            <Link onClick={handleClose} className="modelCloseBtn">
              <CloseIcon />
            </Link>
          </Typography>
          <div className="dialogForm-field ">
            <Typography variant="h5" className="dialogForm-field-label">
              {t("main.start_time_(hhmm)")}
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack spacing={3}>
                <TimePicker
                  className="TimePicker"
                  renderInput={(params) => <TextField {...params} />}
                  value={schedule?.startingTime}
                  onChange={(newValue) => {
                    setSchedule({ ...schedule, startingTime: newValue });
                  }}
                />
              </Stack>
            </LocalizationProvider>
          </div>
          <div className="dialogForm-field">
            <Typography variant="h5" className="dialogForm-field-label">
              {t("main.zone")}
            </Typography>
            <Select
              style={{ height: "50px" }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={schedule?.zoneId || ""}
              onChange={(e) => {
                setSchedule({ ...schedule, zoneId: e.target.value });
              }}
              required
            >
              {zones &&
                zones?.map((item) => (
                  <MenuItem key={item?.id} value={item?.id}>
                    {item?.name}
                  </MenuItem>
                ))}
            </Select>
          </div>
          <div className="dialogForm-field">
            <Typography variant="h5" className="dialogForm-field-label">
              {t("main.day")}
            </Typography>
            <Select
              className="CustomDrpSelect"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={schedule?.dayOfWeek}
              onChange={(e) => {
                setSchedule({ ...schedule, dayOfWeek: e.target.value });
              }}
              required
            >
              <MenuItem value="1">{t("main.monday")}</MenuItem>
              <MenuItem value="2">{t("main.tuesday")}</MenuItem>
              <MenuItem value="3">{t("main.wednesday")}</MenuItem>
              <MenuItem value="4">{t("main.thursday")}</MenuItem>
              <MenuItem value="5">{t("main.friday")}</MenuItem>
              <MenuItem value="6">{t("main.saturday")}</MenuItem>
              <MenuItem value="0">{t("main.sunday")}</MenuItem>
            </Select>
            s
          </div>
          <div className="dialogForm-field">
            <Typography variant="h5" className="dialogForm-field-label">
              {t("main.channel")}
            </Typography>
            <Select
              className="CustomDrpSelect"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={schedule?.channelId}
              onChange={(e) => {
                setSchedule({ ...schedule, channelId: e.target.value });
              }}
              required
            >
              {channels &&
                channels?.map((item) => (
                  <MenuItem key={item?.id} value={item?.id}>
                    {item?.name}
                  </MenuItem>
                ))}
            </Select>
          </div>
          <div className="dialogForm-field">
            <Typography variant="h5" className="dialogForm-field-label">
              {t("main.wheel")}
            </Typography>
            <Select
              className="CustomDrpSelect"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              required
              value={schedule?.voiceWheelId}
              onChange={(e) => {
                setSchedule({ ...schedule, voiceWheelId: e.target.value });
              }}
            >
              {wheels &&
                wheels?.map((item) => (
                  <MenuItem key={item?.id} value={item?.id}>
                    {item?.name}
                  </MenuItem>
                ))}
            </Select>
          </div>
          <div className="dialogForm-field">
            <Typography variant="h5" className="dialogForm-field-label">
              {t("main.dropIn")}
            </Typography>
            <Select
              className="CustomDrpSelect"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={schedule?.dropInId}
              onChange={(e) => {
                setSchedule({ ...schedule, dropInId: e.target.value });
              }}
              required
            >
              {dropins &&
                dropins?.map((item) => (
                  <MenuItem key={item?.id} value={item?.id}>
                    {item?.name}
                  </MenuItem>
                ))}
            </Select>
          </div>
          <DialogActions sx={{ display: "flex" }}>
            <Button
              type="submit"
              onClick={(e: React.MouseEvent<HTMLElement>) =>
                handleAddSchedule(e)
              }
              size="medium"
              variant="contained"
              color="primary"
              sx={{ mt: 5 }}
            >
              {t("main.save")}
            </Button>
            <Button
              size="medium"
              variant="outlined"
              color="primary"
              sx={{ mt: 5 }}
            >
              {t("main.cancel")}
            </Button>
          </DialogActions>
        </form>
      </div>
    </Dialog>
  );
}

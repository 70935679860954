/* eslint-disable react-hooks/exhaustive-deps */
import {
  Grid,
  Paper,
  useTheme,
  Accordion,
  Typography,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import dayjs from "dayjs";
import { Stack } from "@mui/system";
import { songList } from "../../../types/Audio";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useCallback, useState } from "react";
import { TextControl } from "../../Controls/TextControl";
import UserDefinedField from "../../Audio/UserDefinedField";
import { formData, grouData } from "../../../types/Settings";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import './ApplicationSetting.scss';
import { useTranslation } from "react-i18next";
import { useChannelIdContext } from "../../../context/ChannelIdContext";

const API_URL = process.env.REACT_APP_BACKEND_URL;
export default function ApplicationSetting({
  levelsFormData,
  locationFormData,
  setLevelsFormData,
  userDefinitionData,
  setLocationFormData,
  setUserDefinitionData,
}: {
  levelsFormData: any;
  locationFormData: any;
  setLevelsFormData: any;
  userDefinitionData: any;
  setLocationFormData: any;
  setUserDefinitionData: any;
}) {
  const { id } = useParams();
  const [expanded, setExpanded] = useState<{
    mqs: boolean;
    files: boolean;
    levels: boolean;
    updating: boolean;
    locations: boolean;
    userDefinations: boolean;
  }>({
    mqs: true,
    files: true,
    levels: true,
    updating: true,
    locations: true,
    userDefinations: true,
  });

  const theme = useTheme();
  const {
    setIsTokenExpired,
    setIsTokenUpdated,
    IsTokenUpdated
  } = useChannelIdContext();
  const { t } = useTranslation();
  let navigate = useNavigate();
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  const [audioData, setAudioData] = useState<songList | any>({
    id: id,
    title: "",
    fileName: "",
    fileData: "",
    isFileUpload: false,
    artist1Id: 1,
    artist1Name: "",
    artist2Id: 1,
    artist2Name: "",
    artist3Id: 1,
    artist3Name: "",
    releaseYear: "2020",
    commenceDate: dayjs(),
    useCommenceDate: false,
    expireDate: dayjs(),
    useExpireDate: false,
    priority: 0,
    creationDate: dayjs(),
    lastAudioUpdate: dayjs(),
    userDefinition1: "",
    userDefinition2: "",
    userDefinition3: "",
    userDefinition4: "",
    userDefinition5: "",
    userDefinition6: "",
    userDefinition7: "",
    userDefinition8: "",
    userDefinition9: "",
    userDefinition10: "",
    coverImageFile: "",
    queueInPoint: 0,
    queueOutPoint: 0,
    introPoint: 0,
    playMonday: true,
    playTuesday: true,
    playWednesday: true,
    playThursday: true,
    playFriday: true,
    playSaturday: true,
    playSunday: true,
    seasonalStart: Date(),
    seasonalEnd: Date(),
    timeFrom: "10:26:52",
    timeUntil: "11:26:52",
    duration: "0001-01-01T00:00:00",
    attributes: [],
  });

  const levelBySettingGroup = useCallback(async () => {
    fetch(`${API_URL}/Settings/GetByGroup`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    })
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true)
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false)
          return response.json()
        }
      })
      .then((data) => {
        if (data.success === true) {
          let levelsByGroup = data?.data
            .filter((item: grouData) => item?.groupName === "Other")
            .map((item: grouData) => item?.settings);
          let newData = levelsByGroup[0]?.filter(
            (item: grouData) => item?.name !== "Auto Set Markers"
          );
          let newlevelsDataWithGroupName = newData.map((item: grouData) => ({
            ...item,
            groupName: "Other",
          }));
          setLevelsFormData(newlevelsDataWithGroupName);

          let locationByGroup = data?.data
            .filter((item: grouData) => item?.groupName === "Levels")
            .map((item: grouData) => item?.settings);
          let newLocationData = locationByGroup[0]?.filter(
            (item: grouData) => item?.name === "Override dB adjustment"
          );
          let newLocationDataWithGroupName = newLocationData.map((item: grouData) => ({
            ...item,
            groupName: "Locations",
          }));
          setLocationFormData(newLocationDataWithGroupName);

          let userDefinedByGroup = data?.data
            ?.filter((item: grouData) => item.groupName === "User Definition")
            ?.map((item: grouData) => item.settings);
          let newUserData = userDefinedByGroup[0]?.filter((item: grouData) => item.value);
          let newUserDefinitionDataWithGroupName = newUserData.map((item: grouData) => ({
            ...item,
            groupName: "User Definition",
          }));
          setUserDefinitionData(newUserDefinitionDataWithGroupName);
        }
      })

  }, [setLevelsFormData, setLocationFormData, IsTokenUpdated]);

  useEffect(() => {
    levelBySettingGroup();
  }, [levelBySettingGroup]);

  return (
    <Paper elevation={2} sx={{ p: 3 }}>
      <Grid
        container
        rowSpacing={1}
        justifyContent="center"
        direction="row"
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        className={theme.palette.mode === "dark" ? "ApplicationSetting-dark" : "ApplicationSetting"}
      >
        <Grid item xs={12} lg={9} xl={7}>
          <div className="card-block">
            <Accordion
              expanded={expanded.userDefinations}
              onChange={(e, isExpanded) =>
                setExpanded({ ...expanded, userDefinations: isExpanded ?? false })
              }
              elevation={5}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                sx={{
                  backgroundColor:
                    theme.palette.mode === "dark" ? "#151515" : "#AEAEAE",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ flexShrink: 0, fontWeight: 600, letterSpacing: 1 }}
                >
                  {t("main.user_definition")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ margin: 2, marginTop: 2 }}>
                <Stack direction="column" spacing={1}>
                  {userDefinitionData?.map((item: any) => (
                    <div key={item?.id} style={{ display: "flex", justifyContent: 'space-between' }}>
                      <Typography sx={{ maxWidth: 145 }}>
                        {item?.name}
                      </Typography>
                      <TextControl
                        key={item?.id}
                        header={item?.name}
                        value={item?.value}
                        inputChanged={(value: string) => {
                          const filteredData = userDefinitionData?.map((i: any) => {
                            if (i.id === item.id) {
                              return { ...i, value };
                            } else {
                              return i;
                            }
                          });
                          setUserDefinitionData(filteredData);
                        }}
                      />
                    </div>
                  ))}
                </Stack>
              </AccordionDetails>
            </Accordion>
          </div>
        </Grid>
        <Grid item xs={12} lg={9} xl={7}>
          <div className="card-block">
            <Accordion
              expanded={expanded.levels}
              onChange={(e, isExpanded) =>
                setExpanded({ ...expanded, levels: isExpanded ?? false })
              }
              elevation={5}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                style={{
                  backgroundColor:
                    theme.palette.mode === "dark" ? "#151515" : "#AEAEAE",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ flexShrink: 0, fontWeight: 600, letterSpacing: 1 }}
                >
                  {t("main.levels")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ margin: 2, marginTop: 2 }}>
                <Stack direction="column" spacing={1}>
                  {levelsFormData?.map((item: grouData) => (
                    <div key={item?.id} style={{ display: "flex", justifyContent: 'space-between' }}>
                      <Typography sx={{ maxWidth: 145 }}>
                        {item?.name}
                      </Typography>
                      <TextControl
                        header={item?.name}
                        value={item?.value ? item.value : ""}
                        inputChanged={(value: grouData) => {
                          const filteredLevelData: formData[] =
                            levelsFormData?.map((i: grouData) => {
                              if (i.id === item?.id) {
                                return { ...i, value };
                              } else {
                                return i;
                              }
                            });
                          setLevelsFormData(filteredLevelData);
                        }}
                      />
                    </div>
                  ))}
                </Stack>
              </AccordionDetails>
            </Accordion>
          </div>
        </Grid>
        <Grid item xs={12} lg={9} xl={7}>
          <div className="card-block">
            <Accordion
              expanded={expanded?.locations}
              onChange={(e, isExpanded) =>
                setExpanded({ ...expanded, locations: isExpanded ?? false })
              }
              elevation={5}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                sx={{
                  backgroundColor:
                    theme.palette.mode === "dark" ? "#151515" : "#AEAEAE",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ flexShrink: 0, fontWeight: 600, letterSpacing: 1 }}
                >
                  {t("main.locations")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ margin: 5, marginTop: 2 }}>
                {locationFormData?.map((item: grouData) => (
                  <div key={item?.id} style={{ display: "flex", justifyContent: 'space-between' }}>
                    <Typography sx={{ maxWidth: 145 }}>{item?.name}</Typography>
                    <TextControl
                      header={item?.name}
                      value={item?.value ? item.value : ""}
                      inputChanged={(value: grouData) => {
                        const filteredLocationData: formData[] =
                          locationFormData?.map((i: grouData) => {
                            if (i.id === item?.id) {
                              return { ...i, value };
                            } else {
                              return i;
                            }
                          });
                        setLocationFormData(filteredLocationData);
                      }}
                    />
                  </div>
                ))}
              </AccordionDetails>
            </Accordion>
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
}

import {
  Box,
  Grid,
  useTheme,
  Accordion,
  Typography,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
} from "@mui/material";
import Waveform from "./AudioWave";
import { songList } from "../../types/Audio";
import VoiceTrackWave from "../VoiceTrack/VoiceTrackWave";
import { useLocation } from "react-router-dom";
import AudioFileUpload from "./AudioFileUpload";
import ViewAudioMetaData from "./ViewAudioMetaData";
import React, { SetStateAction, useState } from "react";
import VoiceTrackFileUpload from "../VoiceTrack/VoiceTrackFileUpload";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AudioVoiceTrackEditor } from "../../types/VoiceTrack";
import ViewContentAudioMetaData from "../VoiceTrack/ViewContentAudioMetaData";
import { useTranslation } from "react-i18next";

export default function AudioEditorDetail({
  isLoading,
  audioData,
  setAudioData,
  setVoiceTrack,
  voiceTrack,
  category,
  setCategory,
  playing,
  setPlaying,
}: {
  isLoading: boolean;
  audioData: songList;
  setAudioData: React.Dispatch<SetStateAction<songList>>;
  setVoiceTrack: React.Dispatch<SetStateAction<AudioVoiceTrackEditor>>;
  category: any;
  setCategory: React.Dispatch<SetStateAction<any[]>>;
  voiceTrack: AudioVoiceTrackEditor;
  playing: boolean;
  setPlaying: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [expanded, setExpanded] = useState<{
    metadata: boolean;
    timeofday: boolean;
    seasonality: boolean;
    weeksday: boolean;
    files: boolean;
    audiowawe: boolean;
  }>({
    metadata: true,
    timeofday: true,
    seasonality: true,
    weeksday: true,
    files: true,
    audiowawe: true,
  });
  let theme = useTheme();
  let location = useLocation();
  const { t } = useTranslation();
  return (
    <Box className="tabsPage">
      {isLoading ? (
        <Grid
          container
          rowSpacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 5, xl: 8 }}
        >
          <Grid item xs={12} lg={6}>
            <Accordion
              className="SongDataEditor"
              expanded={expanded?.metadata}
              onChange={(e, isExpanded) =>
                setExpanded({ ...expanded, metadata: isExpanded ?? false })
              }
              elevation={5}
            >
              <AccordionSummary
                className="AccordionTitle"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                style={{
                  backgroundColor:
                    theme.palette.mode === "dark" ? "#151515" : "#d9d9d9",
                }}
              >
                <Typography
                  variant="h5"
                  style={{
                    color: theme.palette.mode === "dark" ? "#fff" : "#000",
                  }}
                  sx={{ flexShrink: 0, fontWeight: 600, letterSpacing: 1 }}
                >
                  {t("main.Metadata")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ margin: 5, marginTop: 20 }}>
                {location?.pathname?.includes("music") ||
                  location?.pathname?.includes("channel") ? (
                  <ViewAudioMetaData
                    audio={audioData}
                    setAudioData={setAudioData}
                  />
                ) : (
                  <ViewContentAudioMetaData
                    category={category}
                    setCategory={setCategory}
                    voiceTrack={voiceTrack}
                    setVoiceTrack={setVoiceTrack}
                  />
                )}
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Accordion
              expanded={expanded?.weeksday}
              onChange={(e, isExpanded) =>
                setExpanded({ ...expanded, weeksday: isExpanded ?? false })
              }
              elevation={5}
            >
              <AccordionSummary
                className="AccordionTitle"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                style={{
                  backgroundColor:
                    theme.palette.mode === "dark" ? "#151515" : "#d9d9d9",
                }}
              >
                <Typography
                  variant="h5"
                  style={{
                    color: theme.palette.mode === "dark" ? "#fff" : "#000",
                  }}
                  sx={{ flexShrink: 0, fontWeight: 600, letterSpacing: 1 }}
                >
                  {t("main.Audio")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ margin: 5, marginTop: 20 }}>
                {location?.pathname?.includes("music") ||
                  location?.pathname?.includes("channel") ? (
                  <>
                    {/* <Box sx={{ marginBottom: "1.5rem" }}>
                      <AudioFileUpload
                        audio={audioData}
                        setAudioData={setAudioData}
                      />
                    </Box> */}
                    <Waveform
                      audio={audioData}
                      setAudioData={setAudioData}
                      setPlaying={setPlaying}
                      playing={playing}
                    />
                  </>
                ) : (
                  <>
                    {/* <Box sx={{ marginBottom: "1.5rem" }}>
                      <VoiceTrackFileUpload
                        voiceTrack={voiceTrack}
                        setVoiceTrack={setVoiceTrack}
                      />
                    </Box> */}
                    <VoiceTrackWave
                      voiceTrack={voiceTrack}
                      setVoiceTrack={setVoiceTrack}
                      setPlaying={setPlaying}
                      playing={playing}
                    />
                  </>
                )}
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      ) : (
        <div style={{ justifyContent: "center", display: "flex" }}>
          <CircularProgress style={{ marginTop: "15rem" }} />
        </div>
      )}
    </Box>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import "../App.css";
import React, { useEffect, useState } from "react";
import SignIn from "../component/Auth/SignIn";
import SignUp from "../component/Auth/SignUp";
import MiniSidePanel from "../layouts/MiniSidePanel/MiniSidePanel";
import ContactNow from "../component/Auth/ContactNow";
import ForgotPassword from "../component/Auth/ForgotPassword";
import { useTranslation } from "react-i18next";
import { Box, Container, useTheme, ThemeProvider } from "@mui/material";

export enum LoginOperation {
  SignIn = 1,
  SignUp = 2,
  ForgotPass = 3,
  ContactNow = 4,
}

export default function LoginPage() {
  const {t} = useTranslation()
  return (
    <MiniSidePanel headerText={t("main.login")}>
      <LoginPageContent />
    </MiniSidePanel>
  );
}

function LoginPageContent() {
  const theme = useTheme();
  const { t } = useTranslation();
  const [operation, setOperation] = useState(LoginOperation.SignIn);
  let pathname = window.location.pathname;
  useEffect(() => {
    if (pathname === "/") {
      localStorage.removeItem("userData");
      localStorage.removeItem("token")
    }
  }, [pathname]);
  return (
    <ThemeProvider theme={theme}>
      {/* <BgWallPaper></BgWallPaper> */}
      <div className="main-centered">
        <h1
          style={{
            display: "block",
            fontFamily: "Ubuntu",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "32px",
            marginRight: "55px",
            textAlign: "center",
          }}
        >
          {t("main.main_heading")}
        </h1>
        <Container component="main" maxWidth="sm">
          <Box
            sx={{
              paddingLeft: 0,
              paddingRight: 0,
              paddingTop: 0,
              paddingBottom: 0,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              borderRadius: 4,
            }}
          >
            {
              {
                1: (
                  <SignIn
                    changeOperationHandler={(op: LoginOperation) =>
                      setOperation(op)
                    }
                  />
                ),
                2: (
                  <SignUp
                    changeOperationHandler={(op: LoginOperation) =>
                      setOperation(op)
                    }
                  />
                ),
                3: (
                  <ForgotPassword
                    changeOperationHandler={(op: LoginOperation) =>
                      setOperation(op)
                    }
                  />
                ),
              }[operation as number]
            }
          </Box>
          {
            {
              4: (
                <ContactNow
                  changeOperationHandler={(op: LoginOperation) =>
                    setOperation(op)
                  }
               />
              ),
            }[operation as number]
          }
        </Container>
      </div>
    </ThemeProvider>
  );
}

import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Link,
  Paper,
  PaperProps,
  Typography,
} from "@mui/material";
import { MouseEventHandler, SyntheticEvent } from "react";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useChannelIdContext } from "../../../context/ChannelIdContext";
import "./deletePlaylistDialog.scss";
const API_URL = process.env.REACT_APP_BACKEND_URL;
function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper
        {...props}
        style={{
          backgroundColor: "#34393f",
          color: "#D9D9D9",
          padding: "1rem",
        }}
      />
    </Draggable>
  );
}

export default function DeletePlaylistDialog({
  isOpen,
  handleClose,
}: {
  isOpen: boolean;
  handleClose: any | MouseEventHandler;
}) {
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  const {
    deleteChannelId,
    setIsAdded,
    setSongListByChannel,
    setIsTokenExpired,
    setIsTokenUpdated,
  } = useChannelIdContext();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const deleteChannelList = async (e: SyntheticEvent) => {
    e.preventDefault();
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
      body: JSON.stringify({
        channelsId: deleteChannelId,
      }),
    };
    fetch(`${API_URL}/Channel/${deleteChannelId}`, requestOptions)
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data?.success === true) {
          handleClose();
          setIsAdded(true);
          if (data?.data?.length === 0) setSongListByChannel([]);
          toast.success(t("main.ChannelList_Deleted_Successfully"));
        } else {
          toast.error(data?.message);
        }
      });
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={isOpen}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      className="deleteChannelListDialog"
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle>
        <Typography variant="h5" className="addModel-title">
          {t("main.Are_you_sure_to_delete_this_channelList")}
          <Link onClick={handleClose} className="modelCloseBtn">
            <CloseIcon />
          </Link>
        </Typography>
      </DialogTitle>
      <div className="dialogForm">
        <form>
          <DialogActions sx={{ display: "flex" }}>
            <Button
              type="submit"
              size="medium"
              variant="contained"
              color="primary"
              sx={{ mt: 5 }}
              onClick={(e: SyntheticEvent) => deleteChannelList(e)}
            >
              {t("main.Confirm")}
            </Button>
            <Button
              size="medium"
              variant="outlined"
              color="primary"
              sx={{ mt: 5 }}
              onClick={handleClose}
            >
              {t("main.cancel")}
            </Button>
          </DialogActions>
        </form>
      </div>
    </Dialog>
  );
}

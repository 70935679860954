import {
  Box,
  Grid,
  Button,
  useTheme,
  Accordion,
  TextField,
  Typography,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ReactComponent as FileOpenicon } from "../../assets/images/folder-open.svg";
import { useTranslation } from "react-i18next";

export default function AudioMetaData() {
  const [expanded, setExpanded] = useState<{
    files: boolean;
    metadata: boolean;
    weeksday: boolean;
    timeofday: boolean;
    audiowawe: boolean;
    seasonality: boolean;
  }>({
    files: true,
    metadata: true,
    timeofday: true,
    weeksday: true,
    audiowawe: true,
    seasonality: true,
  });
  const {t} = useTranslation();
  let theme = useTheme();
  const [imageUrl, setImageUrl] = useState<string>("");
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  useEffect(() => {
    if (selectedImage) {
      setImageUrl(selectedImage?.name);
    }
  }, [selectedImage]);

  return (
    <Box className="tabsPage">
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 5, xl: 8 }}>
        <Grid item xs={12} md={6}>
          <Accordion style={{ height: '100%' }}
            className="SongDataEditor"
            expanded={expanded?.metadata}
            onChange={(e, isExpanded) =>
              setExpanded({ ...expanded, metadata: isExpanded ?? false })
            }
            elevation={5}
          >
            <AccordionSummary
              className="AccordionTitle"
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              style={{
                backgroundColor:
                  theme.palette.mode === "dark" ? "#151515" : "#d9d9d9",
              }}
            >
              <Typography
                variant="h5"
                sx={{ flexShrink: 0, fontWeight: 600, letterSpacing: 1 }}
              >
                {t("main.Track_artwork")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ margin: 5, marginTop: 20 }}>
              <Grid className="AudioFileUpload">
                <Typography>{t("main.Audio")}</Typography>
                <TextField sx={{ width: 350 }} value={imageUrl}></TextField>
                <Button variant="contained" color="primary" component="span">
                  <input
                    accept="audio/mp3"
                    type="file"
                    id="select-image"
                    value={""}
                    onChange={(e: any) => setSelectedImage(e.target.files[0])}
                  />
                  <FileOpenicon />
                </Button>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12} md={6}>
          <Accordion
            expanded={expanded.weeksday}
            onChange={(e, isExpanded) =>
              setExpanded({ ...expanded, weeksday: isExpanded ?? false })
            }
            elevation={5}
          >
            <AccordionSummary
              className="AccordionTitle"
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              style={{
                backgroundColor:
                  theme.palette.mode === "dark" ? "#151515" : "#d9d9d9",
              }}
            >
              <Typography
                variant="h5"
                sx={{ flexShrink: 0, fontWeight: 600, letterSpacing: 1 }}
              >
                {t("main.External_Meta")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ margin: 5, marginTop: 20 }}>
              <Button
                variant="contained"
                color="primary"
                sx={{ mt: 1, textTransform: "none", borderRadius: "12px" }}
              >
                {t("main.Lookup")}
              </Button>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </Box>
  );
}
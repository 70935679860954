import {
  Link,
  Paper,
  Button,
  Dialog,
  PaperProps,
  Typography,
  DialogActions,
} from "@mui/material";
import Draggable from "react-draggable";
import { toast } from "react-toastify";
import React, { MouseEventHandler } from "react";
import CloseIcon from "@mui/icons-material/Close";
import "../AddEditCustomerDialog/addEditCustomerDialog.scss";
import { useTranslation } from "react-i18next";
import { useChannelIdContext } from "../../../context/ChannelIdContext";
import { useNavigate } from "react-router-dom";
const API_URL = process.env.REACT_APP_BACKEND_URL;
function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper
        {...props}
        style={{
          backgroundColor: "#34393f",
          color: "#D9D9D9",
          padding: "1rem",
        }}
      />
    </Draggable>
  );
}

export default function DeleteCustomerDialog({
  title,
  isOpen,
  selectedId,
  setIsAdded,
  handleClose,
  setActiveitem,
}: {
  title: string;
  isOpen: boolean;
  selectedId: number;
  handleClose: any | MouseEventHandler;
  setActiveitem: React.Dispatch<
    React.SetStateAction<{
      id: number;
      name: string;
    }>
  >;
  setIsAdded: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const {t} = useTranslation()
  const {setIsTokenExpired,setIsTokenUpdated}= useChannelIdContext()
  const navigate = useNavigate()
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  const handleDelete = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (title || selectedId) {
      const payload =
        title === "Customer"
          ? JSON.stringify({
              customersId: selectedId,
            })
          : title === "Region"
          ? JSON.stringify({ regionsId: selectedId })
          : JSON.stringify({ sitesId: selectedId });
      const requestOptions = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token?.accessToken}`,
        },
        body: payload,
      };
      const url =
        title === "Customer"
          ? `${API_URL}/Customer`
          : title === "Region"
          ? `${API_URL}/Region`
          : `${API_URL}/Site/sites`;
      try {
     fetch(url, requestOptions).then((response) => {
      if (
        response.ok === false &&
        response.status === 401 &&
        token?.refreshToken !== ""
      ) {
        setIsTokenExpired(true);
      } else if (response.status === 401 && token?.refreshToken === "") {
        navigate("/");
        localStorage.removeItem("token");
        localStorage.clear();
      } else {
        setIsTokenUpdated(false);
        return response.json();
      }
    })
    .then((data) => {
      if (data?.success === true) {
        setIsAdded(true);
        setActiveitem({
          id: 0,
          name: "",
        });
        handleClose();
        toast.success(`${t("main.Deleted_Successfully")}`);
      } else {
        toast.error(data?.message);
      }
    });
      } catch (error) {
        toast.error(`${t("main.Failed_to_Save_Region_Data")}`);
      }
    }
  };
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={isOpen}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      className="addCustomerModelDialog"
      aria-labelledby="draggable-dialog-title"
    >
      <div className="dialogForm">
        <form>
          <Typography variant="h5" className="addModel-title">
            {t("main.Are_you_sure_to_delete_this")} {title} ?
            <Link onClick={handleClose} className="modelCloseBtn">
              <CloseIcon />
            </Link>
          </Typography>
          <DialogActions sx={{ display: "flex" }}>
            <Button
              type="submit"
              size="medium"
              variant="contained"
              color="primary"
              sx={{ mt: 5 }}
              onClick={handleDelete}
            >
              {t("main.Confirm")}
            </Button>
            <Button
              size="medium"
              variant="outlined"
              color="primary"
              sx={{ mt: 5 }}
              onClick={() => {
                handleClose();
              }}
            >
             {t("main.cancel")}
            </Button>
          </DialogActions>
        </form>
      </div>
    </Dialog>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Checkbox,
  FormGroup,
  Grid,
  Paper,
  Slider,
  Typography,
  useTheme,
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useChannelIdContext } from "../../../context/ChannelIdContext";
import { ChannelData } from "../../../types/Channel";
import "./channelListSettings.scss";

const API_URL = process.env.REACT_APP_BACKEND_URL;
const ChannelListSettings = ({
  isUpdated,
  channelData,
  setChannelData,
}: {
  isUpdated: boolean;
  channelData: ChannelData;
  setChannelData: (state: ChannelData) => void;
}) => {
  let theme = useTheme();
  const { t } = useTranslation();
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  const { channelId, IsTokenUpdated, setIsTokenExpired, setIsTokenUpdated } =
    useChannelIdContext();
  const navigate = useNavigate();
  useEffect(() => {
    const fetchSongListByChannel = async () => {
      fetch(`${API_URL}/Channel/${channelId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token?.accessToken}`,
        },
      })
        .then((response) => {
          if (
            response.ok === false &&
            response.status === 401 &&
            token?.refreshToken !== ""
          ) {
            setIsTokenExpired(true);
          } else if (response.status === 401 && token?.refreshToken === "") {
            navigate("/");
            localStorage.removeItem("token");
            localStorage.clear();
          } else {
            setIsTokenUpdated(false);
            return response.json();
          }
        })
        .then((data) => {
          if (data.success === true) {
            setChannelData(data.data);
          }
        });
    };
    if (channelId !== undefined) {
      fetchSongListByChannel();
    }
  }, [channelId, isUpdated, IsTokenUpdated]);

  return (
    <Paper elevation={2} sx={{ p: 3 }}>
      <Grid
        container
        spacing={{ xs: 2, md: 2 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <Grid item xs={12} md={8}>
          <Box className="grayCard">
            <Typography className="grayCard__head">
              {t("main.yesterday_rules")}
            </Typography>
            <Box style={{ paddingLeft: "8px", paddingRight: "20px" }}>
              <Grid container spacing={1} padding={"15px 0"}>
                <Grid item xs={12} md={5}>
                  <FormGroup style={{ padding: theme.spacing(1) }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={channelData?.yesterdayArtistRuleOn}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            setChannelData({
                              ...channelData,
                              yesterdayArtistRuleOn: event.target.checked,
                            })
                          }
                        />
                      }
                      label={t("main.yesterday_artist")}
                      style={{
                        padding: theme.spacing(1),
                      }}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} md={7}>
                  <Typography
                    style={{
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "16px",
                      color: theme.palette.mode === "dark" ? "#fff" : "#000",
                      padding: "15px 0",
                    }}
                  >
                    {t("main.preferred_minutes_either_side_to_protect") + ": " + channelData?.yesterdayArtistRuleMinutes }
                  </Typography>
                  <Slider
                    sx={{ color: "#777d8569" }}
                    value={
                      typeof channelData?.yesterdayArtistRuleMinutes ===
                      "number"
                        ? channelData?.yesterdayArtistRuleMinutes
                        : 0
                    }
                    onChange={(event: Event, newValue: number | number[]) =>
                      setChannelData({
                        ...channelData,
                        yesterdayArtistRuleMinutes: newValue,
                      })
                    }
                    step={1}
                    min={0}
                    max={120}
                    marks
                    valueLabelDisplay="auto"
                    track={false}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1} padding={"15px 0"}>
                <Grid item xs={12} md={5}>
                  <FormGroup style={{ padding: theme.spacing(1) }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={channelData?.yesterdaySongRuleOn}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            setChannelData({
                              ...channelData,
                              yesterdaySongRuleOn: event.target.checked,
                            })
                          }
                        />
                      }
                      label={t("main.yesterday_song")}
                      style={{
                        padding: theme.spacing(1),
                      }}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} md={7}>
                  <Typography
                    style={{
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "16px",
                      color: theme.palette.mode === "dark" ? "#fff" : "#000",
                      padding: "15px 0",
                    }}
                  >
                    {t("main.preferred_minutes_either_side_to_protect") + ": " + channelData?.yesterdaySongRuleMinutes}
                  </Typography>
                  <Slider
                    sx={{ color: "#777d8569" }}
                    value={
                      typeof channelData?.yesterdaySongRuleMinutes === "number"
                        ? channelData?.yesterdaySongRuleMinutes
                        : 0
                    }
                    onChange={(event: Event, newValue: number | number[]) =>
                      setChannelData({
                        ...channelData,
                        yesterdaySongRuleMinutes: newValue,
                      })
                    }
                    aria-label="Always visible"
                    step={1}
                    min={0}
                    max={120}
                    marks
                    valueLabelDisplay="auto"
                    track={false}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Box className="grayCard">
            <Typography className="grayCard__head">
              {t("main.last_play_song_rule")}
            </Typography>
            <Box style={{ paddingLeft: "8px", paddingRight: "20px" }}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={5}>
                  <FormGroup style={{ padding: theme.spacing(1) }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={channelData?.lastPlaySongRuleOn}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            setChannelData({
                              ...channelData,
                              lastPlaySongRuleOn: event.target.checked,
                            })
                          }
                        />
                      }
                      label={t("main.active?")}
                      style={{
                        padding: theme.spacing(1),
                      }}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} md={7}>
                  <Typography
                    style={{
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "16px",
                      color: theme.palette.mode === "dark" ? "#fff" : "#000",
                      padding: "15px 0",
                    }}
                  >
                    {t("main.days_to_protect") + ": " + channelData?.lastPlaySongRuleDays}
                  </Typography>
                  <Slider
                    sx={{ color: "#777d8569" }}
                    value={
                      typeof channelData?.lastPlaySongRuleDays === "number"
                        ? channelData?.lastPlaySongRuleDays
                        : 0
                    }
                    onChange={(event: Event, newValue: number | number[]) =>
                      setChannelData({
                        ...channelData,
                        lastPlaySongRuleDays: newValue,
                      })
                    }
                    aria-label="sliderValue3"
                    step={1}
                    min={0}
                    max={10}
                    marks
                    valueLabelDisplay="auto"
                    track={false}
                  />
                  <Typography
                    style={{
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "16px",
                      color: theme.palette.mode === "dark" ? "#fff" : "#000",
                      padding: "15px 0",
                    }}
                  >
                    {t("main.preferred_minutes_either_side_to_protect") + ": " + channelData?.lastPlaySongRuleMinutes}
                  </Typography>
                  <Slider
                    sx={{ color: "#777d8569" }}
                    value={
                      typeof channelData?.lastPlaySongRuleMinutes === "number"
                        ? channelData?.lastPlaySongRuleMinutes
                        : 0
                    }
                    onChange={(event: Event, newValue: number | number[]) =>
                      setChannelData({
                        ...channelData,
                        lastPlaySongRuleMinutes: newValue,
                      })
                    }
                    aria-label="sliderValue4"
                    step={1}
                    min={0}
                    max={500}
                    marks
                    valueLabelDisplay="auto"
                    track={false}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={12}>
          <Box>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={channelData?.seasonalRestrictionBreakable}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setChannelData({
                        ...channelData,
                        seasonalRestrictionBreakable: event.target.checked,
                      })
                    }
                  />
                }
                label={t("main.apply_seasonal_restrictions")}
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={channelData?.timeRestrictionBreakable}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setChannelData({
                        ...channelData,
                        timeRestrictionBreakable: event.target.checked,
                      })
                    }
                  />
                }
                label={t("main.apply_time-of-day_restrictions")}
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={channelData?.dayRestrictionBreakable}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setChannelData({
                        ...channelData,
                        dayRestrictionBreakable: event.target.checked,
                      })
                    }
                  />
                }
                label={t("main.apply_day-of-week_restrictions")}
              />
            </FormGroup>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ChannelListSettings;
